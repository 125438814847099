import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../core/data.service';
import { Globals } from '../globals';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http' 
export class CsvData {
  public stateCode: any;
  public state: any;
  public sm: any;
  public om: any;

}
@Component({
  selector: 'app-lhdcpentryform',
  templateUrl: './lhdcpentryform.component.html',
  styleUrls: ['./lhdcpentryform.component.scss']
})
export class LhdcpentryformComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  jsondatadisplay: any;
  registerForm: any = FormGroup;
  lhdcpDetailInJson: any = new Array()
  submitted = false;
  colsUsers: any;
  csvData: any;
  globals!: Globals;


  uploadListener($event: any): void {

    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        this.csvData = reader.result;
        let csvRecordsArray = (this.csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord = new CsvData();
        csvRecord.stateCode = curruntRecord[1].trim();
        csvRecord.state = curruntRecord[2].trim();
        csvRecord.sm = this.globals.setDefault(curruntRecord[3]).trim();
        csvRecord.om = this.globals.setDefault(curruntRecord[4]).trim();       
        csvArr.push(csvRecord);
      }
    }
    console.log(csvArr)
    return csvArr;
  }


  //check etension
  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }


  getHeaderArray(csvRecordsArr: any) {
    let headers = (csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
    this.jsondatadisplay = '';
  }

  getJsonData() {
    this.lhdcpDetailInJson = '';
    this.lhdcpDetailInJson = this.records;
    console.log(this.lhdcpDetailInJson)
  }
  constructor(private formBuilder: FormBuilder, private router: Router, globals: Globals, private dataService: DataService,private JsonToCSVService:JsonToCSVService,public http: HttpClient) {
    this.globals = globals;
  }
  get f() { return this.registerForm.controls; }
  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //True if all the fields are filled
    if (this.submitted) {
     		var state = this.globals.indianState.find(c => c.code ===this.registerForm.value.state );

	if(state === undefined || state === null){
	  
        console.log("your key or value exists!");
      }
      else {
        var finalObj = Object.assign
          ({ "state": state.name }, { "stateCode": state.code }, { "sm": this.registerForm['value']['sm'] },
            { "om": this.registerForm['value']['om'] });
        this.lhdcpDetailInJson.push(finalObj);
      }
    }
    console.log("form submmit value")
    console.log(this.lhdcpDetailInJson)
  }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      state: new FormControl('', [Validators.required]),
      sm: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      om: new FormControl('', [Validators.pattern(this.globals.intRegex)]),    
    });

    this.initializeColumns();
  }
  initializeColumns() {
    this.colsUsers = [
      { field: 'state', header: 'State' }, 
      { field: 'sm', header: 'Sanctioned MVUs' },
	  { field: 'om', header: 'Operational MVUs' },
    ];
  }
  getEventValue($event: any): string {
    return $event.target.value;
  }
  deleteRow(state: any) {
    console.log(state);
    for (let [i, user] of this.lhdcpDetailInJson.entries()) {
      if (user.state === state) {
        this.lhdcpDetailInJson.splice(i, 1); // Tim is now removed from "users"
      }
    }
    console.log(this.lhdcpDetailInJson)
  }
  onUpload() {

    const data = this.lhdcpDetailInJson;
    if (data.length > 0) {
      this.dataService.mvuCreate(data).subscribe((result: any) => {
        //alert(result.message);
		alert("Added Successfully ");
        this.lhdcpDetailInJson = [];
      });
    }

  }
  // -- Add by Abhishek for JSON To CSV
   allMVUData:any
   download(){
     this.getMVUDashboardData();
   }
   getMVUData() {
    return this.http.get('https://dahddashboard.ndlm.co.in/api/mvu');
   //return this.http.get('/assets/MVU.json');
    }
  
   getMVUDashboardData()
 {
   this.getMVUData().subscribe((res)=>{
     console.log("MVU Data Result");
     console.log(res);
     this.allMVUData=res;
     this.JsonToCSVService.downloadFile(this.allMVUData, 'lhdcp');
 },
 (err)=>{
   console.log(err)
 }
 ); 
 }
 // ---- End Abhi Code -----
}
