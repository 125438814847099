///TODO - 111
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from './auth.service';
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService, private router: Router) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = this.authService.getCurrentUser();
        if (user && user.token) {
            const cloned = req.clone({ headers: req.headers.set('Authorization', user.token) });
            return next.handle(cloned).pipe(tap(() => { }, (err: any) => {

                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 || err.status === 0) {
                        this.authService.logout();
                        this.router.navigate(['/login']);
                    }
                }
            }));

        } else {
            return next.handle(req);
        }
    }
}
