
<div class="container-fluid" style="
padding-left: 0px;
">
    <div class="row no-gutter">
        <!-- The image half -->

    <!-- The content half -->
    <div class="col-md-6 " >
        <div class="login-img1"> <img src="https://dahddashboard.ndlm.co.in/assets/imgs/background/login.jpg" alt=""> </div>
      <div class="login d-flex align-items-center ">
  
          <!-- Demo content-->
          <div class="container">
              <div class="row">
  
              
                  <div class="col-lg-10 col-xl-7 mx-auto">
  
  
                  </div>
              </div>
          </div><!-- End -->
  
      </div>
  </div><!-- End -->
  
  
  
        <!-- The content half -->
        <div class="col-md-6 " >
            <div class="login d-flex align-items-center">
  
                <!-- Demo content-->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 col-xl-9 mx-auto">
                    <div class="login-img2"> <img src="https://dahddashboard.ndlm.co.in/assets/imgs/background/national-abulum.jpg" alt=""> </div>
                            <!-- <h2 class="mt-5"> Dashboard Sign In</h2> -->
                           
                           <!-- <br/>  <br/> 
                           <div class="d-grid gap-2 col-12 mx-auto">
                            <a href="https://dahddashboard.ndlm.co.in/login"><button type="button" class="btn btn-primary btn-layout" style="margin-top: 62px;">Click Here to Department of Animal Husbandry and Dairying</button></a>
                          </div>
                          <br>
                            
                                <div class="d-grid gap-2 col-12 mx-auto">
                                    <a href="https://pmmsymis.dof.gov.in/"><button type="button" class="btn btn-primary btn-layout" style="margin-top: 62px;">Click Here to Department of Fisheries</button></a>
                                  </div> -->


                                  <div class="d-grid gap-2 col-12 mx-auto"> 
                                    <a href="https://dahddashboard.ndlm.co.in/login">
                                    <div class="bg-img1"> <img src="https://dahddashboard.ndlm.co.in/assets/imgs/background/Image1.png" alt=""> </div></a>
                                    <!-- <div class="text-left text-left-mobile">
                                  <h3><b>Department of Animal Husbandry and Dairying</b></h3>  
                                 
                                  <a href="https://dahddashboard.ndlm.co.in/login"><button type="button" class="btn btn-success btn-layout" style="margin-top: 62px;">Click Here</button></a>
                                 </div>   -->
                                
                                   </div> 
<br>
                                   <div class="d-grid gap-2 col-12 mx-auto"> 
                                    <a href="https://pmmsymis.dof.gov.in/">
                                    <div class="bg-img2"> <img src="https://dahddashboard.ndlm.co.in/assets/imgs/background/Image2.png" alt=""> </div></a>
                                     <!-- <div class="text-right text-right-mobile">
                                   <h3><b>Department of Fisheries<br></b></h3>   
                                 
                                  <button type="button" class="btn btn-primary btn-layout" style="margin-top: 62px;">Click Here</button></a>
                                 </div>   -->
                                   </div> 
                                <div class="text-center d-flex justify-content-between mt-4"></div>
                                          
                    </div>
                </div><!-- End -->
  
            </div>
        </div><!-- End -->
  
    </div>
  </div>