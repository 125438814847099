import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../core/data.service';
import { Globals } from '../globals';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http' 
export class CsvData {
  public stateCode: any;
  public state: any;
  public nop: any;
  public tpoa: any;
  public tla: any;
  public tld: any;
  public mpc: any;
  public vapc: any;
  public dc: any;
  public ndprs: any;
  public pmc: any;
}
@Component({
  selector: 'app-didfentryform',
  templateUrl: './didfentryform.component.html',
  styleUrls: ['./didfentryform.component.scss']
})
export class DidfentryformComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  jsondatadisplay: any;
  registerForm: any = FormGroup;
  npddDetailInJson: any = new Array()
  submitted = false;
  colsUsers: any;
  csvData: any;
  globals!: Globals;


  uploadListener($event: any): void {

    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        this.csvData = reader.result;
        let csvRecordsArray = (this.csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord = new CsvData();
        csvRecord.stateCode = curruntRecord[1].trim();
        csvRecord.state = curruntRecord[2].trim();
        csvRecord.nop = this.globals.setDefault(curruntRecord[3]).trim();
        csvRecord.tpoa = this.globals.setDefault(curruntRecord[4]).trim();
        csvRecord.tla = this.globals.setDefault(curruntRecord[5]).trim();
        csvRecord.tld = this.globals.setDefault(curruntRecord[6]).trim();
        csvRecord.mpc = this.globals.setDefault(curruntRecord[7]).trim();
        csvRecord.vapc = this.globals.setDefault(curruntRecord[8]).trim();
        csvRecord.dc = this.globals.setDefault(curruntRecord[9]).trim();
        csvRecord.ndprs = this.globals.setDefault(curruntRecord[10]).trim();
        csvRecord.pmc = this.globals.setDefault(curruntRecord[11]).trim();
        csvArr.push(csvRecord);
      }
    }
    console.log(csvArr)
    return csvArr;
  }


  //check etension
  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }


  getHeaderArray(csvRecordsArr: any) {
    let headers = (csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
    this.jsondatadisplay = '';
  }

  getJsonData() {
    this.npddDetailInJson = '';
    this.npddDetailInJson = this.records;
    console.log(this.npddDetailInJson)
  }
  constructor(private formBuilder: FormBuilder, private router: Router, globals: Globals, private dataService: DataService,private JsonToCSVService:JsonToCSVService,public http: HttpClient) {
    this.globals = globals;
  }
  get f() { return this.registerForm.controls; }
  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //True if all the fields are filled
    if (this.submitted) {
     		var state = this.globals.indianState.find(c => c.code ===this.registerForm.value.state );

	if(state === undefined || state === null){
	  
        console.log("your key or value exists!");
      }
      else {
        var finalObj = Object.assign
          ({ "state": state.name }, { "stateCode": state.code }, { "nop": this.registerForm['value']['nop'] },
            { "tpoa": this.registerForm['value']['tpoa'] },
            { "tla": this.registerForm['value']['tla'] }, { "tld": this.registerForm['value']['tld'] },
            { "mpc": this.registerForm['value']['mpc'] }, { "vapc": this.registerForm['value']['vapc'] },
            { "dc": this.registerForm['value']['dc'] }, { "ndprs": this.registerForm['value']['ndprs'] },
            { "pmc": this.registerForm['value']['pmc'] });
        this.npddDetailInJson.push(finalObj);
      }
    }
    console.log("form submmit value")
    console.log(this.npddDetailInJson)
  }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      state: new FormControl('', [Validators.required]),
      nop: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      tpoa: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      tla: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      tld: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      mpc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      vapc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      dc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      ndprs: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      pmc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
    });

    this.initializeColumns();
  }
  initializeColumns() {
    this.colsUsers = [
      { field: 'state', header: 'State' },
      { field: 'nop', header: 'Number of Projects' },
      // { field: 'so', header: 'Scheme Outlay' },
      // { field: 'lc', header: 'Loan Component' },
      // { field: 'ebc', header: 'End Borrower Contribution' },
      // { field: 'is', header: 'Interest Subvention' },
      { field: 'tpoa', header: 'Total Project Outlay Approved' },
      { field: 'tla', header: 'Total Loan Approved' },
      { field: 'tld', header: 'Total Loan disbursed' },
      { field: 'mpc', header: 'Milk Processing Capacity' },
      { field: 'vapc', header: 'Value added and Product Capacity' },
      { field: 'dc', header: 'Drying Capacity' },
      { field: 'ndprs', header: 'No. of Dairy Plant Refurbished Strengthened' },
	  { field: 'pmc', header: 'Producer Member Covered' },
    ];
  }
  getEventValue($event: any): string {
    return $event.target.value;
  }
  deleteRow(state: any) {
    console.log(state);
    for (let [i, user] of this.npddDetailInJson.entries()) {
      if (user.state === state) {
        this.npddDetailInJson.splice(i, 1); // Tim is now removed from "users"
      }
    }
    console.log(this.npddDetailInJson)
  }
  onUpload() {

    const data = this.npddDetailInJson;
    if (data.length > 0) {
      this.dataService.didfCreate(data).subscribe((result: any) => {
        //alert(result.message);
		alert("Added Successfully ");
        this.npddDetailInJson = [];
      });
    }

  }
  // -- Add by Abhishek for JSON To CSV
   allDIDFData:any
   download(){
     this.getDIDFDashboardData();
   }
   getDidfData() {
    return this.http.get('https://dahddashboard.ndlm.co.in/api/didf');
   //return this.http.get('/assets/didf.json');
    }
  
   getDIDFDashboardData()
 {
   this.getDidfData().subscribe((res)=>{
     console.log("DIDF Data Result");
     console.log(res);
     this.allDIDFData=res;
	 this.JsonToCSVService.downloadFile(this.allDIDFData, 'DIDF');
 },
 (err)=>{
   console.log(err)
 }
 ); 
 }
 // ---- End Abhi Code -----
}
