<app-headerone></app-headerone>
<div class="heding-middle-text">Supporting Dairy Cooperatives and Farmer Producer Organizations</div>
<div class="container">
<div class="row">
    <div class="col-sm-0" style="text-align: right;">      
      <button (click)="download()" class="btn-float btn btn-primary"> Download Current Data</button>     
    </div>
  </div>

  <div style="">

    <div class="row "
      style="border: 1px solid beige;margin-top: 16px; background-color: white; box-shadow: 0 0 1px 0 #9f9b9b, 0 0 2px 0 #878682, 0 0 0px 0 #b1aeae inset !important;">

      <div class="col-sm-12 ">
        <div class="title_container mt-1">
        </div>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="row g-3">
            <div class="col-sm-12">
              <label for="title" class="form-label">Number of Milk Cooperatives/
                FPOs Aassisted</label>
              <input type="text" class="form-control" id="noofmilk" formControlName="noofmilk" placeholder="" value="">

              <span class="error-message" *ngIf="error('noofmilk', 'pattern')">
                Invalid number
              </span>

            </div>



            <div class="col-12">
              <label for="description" class="form-label">Working Capital Loan Amount
                Considered for Interest Subvention</label>
              <div class="input-group has-validation">
                <!-- <span class="input-group-text">@</span> -->

                <input type="text" class="form-control" id="workingcapital" formControlName="workingcapital"
                  placeholder="" value="">
                  <span class="error-message" *ngIf="error('noofmilk', 'pattern')">
                    Invalid number
                  </span>
              </div>
            </div>


            <div class="col-6">
              <label for="description" class="form-label">Interest Subvention
                Disbursed</label>
              <div class="input-group has-validation">
                <!-- <span class="input-group-text">@</span> -->

                <input type="text" class="form-control" id="interestsubvention" formControlName="interestsubvention"
                  placeholder="" value="">

              </div>
            </div>

            <div class="col-6">
              <!--  <label for="description" class="form-label">Date</label>-->
              <div class="input-group has-validation">
                <!-- <span class="input-group-text">@</span> -->

                <input type="hidden" class="form-control" id="interestsubvention" formControlName="interestsubvention"
                  placeholder="" value="">
                  <span class="error-message" *ngIf="error('noofmilk', 'pattern')">
                    Invalid number
                  </span>
              </div>
            </div>


          </div>
          <hr class="my-4">
          <div class="col-12">

            <div class="title_container mt-1">
              <button type="submit" class="btn btn-primary btn-block text-uppercase mb-2 w-25 shadow-sm" style="
                    background-color: #2a4075; margin-left:40%">submit</button>
            </div>

          </div>
        </form>
      </div>

    </div>




  </div>
</div>