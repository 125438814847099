import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { AhidfschemeService } from './ahidfscheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-ahidfscheme',
  templateUrl: './ahidfscheme.component.html',
  styleUrls: ['./ahidfscheme.component.scss']
})
export class AhidfschemeComponent implements OnInit {
  
  yearWiseEnroleMentChart:any;
  typeOfProjectChart:any;
  typeOfProjectCostChart:any;
  states_data = [['State Code','State','Projects Sanctioned','Project Cost(In Cr)']];
  result:  any = new Array()
  response:  any = new Array()
  totalSateWiseahidfDataCount:  any = new Array()
  totalSchemeStateWiseData:  any = new Array()
  totalSateWiseahidfData:  any = new Array()
  totalTopFiveStateWiseData:  any = new Array()
  projectCostInArray:  any = new Array()
  totalNoOfProjectsInArray:  any = new Array()
  totalStateWise:  any = new Array()
  totalgroupByTypeProjectsSanctionedByBank:  any = new Array()
  totalTopFiveStateWiseDataKpi:  any = new Array()
  totalAggregateStateProjectCountData:  any = new Array()
  totalAggregateStateProjectCostData:  any = new Array()
  mapReady=false;
  showPieChartpageload = true;
  showPieChartMapclick= false;
  selectState: any;
  statePercent=0
  ahidfNavData: any;
  noOfEntrepreneursRegistered:any;
  projectCostApprovedForinterestsubvetionData:any;
  projectCostProjectCostEntrepreneursRegisteredData:any
  projectCostEligibleByDepartmentData:any
  projectCostSanctionedByBankData:any;
  projectsEligibleByDepartment: any;
  ProjectsSanctionedByBank: any;
  approvedForInterestSubvention:any
  interestSubventionAmount:any
  ahidfDashboardData: any;
  valueInFormate:any
  projectSchemeCost: any;
  projectSchemeLoanAmount: any;
  animalFeedPlantSchemeName: any;
  animalFeedPlantloanAmount: any;
  animalFeedPlantprojectCost: any;
  dairyProcessingValueAdditionSchemeName: any;
  dairyProcessingValueAdditionloanAmount: any;
  dairyProcessingValueAdditionprojectCost: any;
  meatProcessingValueAdditionSchemeName: any;
  meatProcessingValueAdditionloanAmount: any;
  meatProcessingValueAdditionprojectCost: any;
  breedimprovementTechnologyandBreedMultiplicationfarmSchemeName: any;
  breedimprovementTechnologyandBreedMultiplicationfarmloanAmount: any;
  breedimprovementTechnologyandBreedMultiplicationfarmprojectCost: any;
  AnimalWastetoWealthManagementSchemeName: any;
  AnimalWastetoWealthManagementloanAmount: any;
  AnimalWastetoWealthManagementprojectCost: any;
  topFirstPerfomanceState: any;
  topFirstPerformingnoOfProjectsDone: any;
  topFirstPerformingprojectCost: any;
  topSecondPerfomanceState: any;
  topSecondPerformingnoOfProjectsDone: any;
  topSecondPerformingprojectCost: any;
  topThirdPerfomanceState: any;
  topThirdPerformingnoOfProjectsDone: any;
  topThirdPerformingprojectCost: any;
  topFourthPerfomanceState: any;
  topFourthPerformingnoOfProjectsDone: any;
  topFourthPerformingprojectCost: any;
  topFifthPerfomanceState: any;
  topFifthPerformingnoOfProjectsDone: any;
  topFifthPerformingprojectCost: any;
  projectSchemeCostInCr: any;
  totalNoOfProjectsInAhidf: any;
  projectCostWithoutCr: any;
  lastDateFromArray: any;
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  noday:any;
  curentDate: any;
  projectSchemeCostInCrs: any;
  
  
  
  State_AndhraPradesh :any
  AndhraPradesh_noOfProjects :any
  AndhraPradesh_ProjectCost :any
  State_ArunachalPradesh :any
  ArunachalPradesh_noOfProjects :any
  ArunachalPradesh_ProjectCost :any
  State_Assam :any
  Assam_noOfProjects :any 
  Assam_ProjectCost :any
  State_Bihar :any
  Bihar_noOfProjects :any
  Bihar_ProjectCost :any
  State_Chhattisgarh :any
  Chhattisgarh_noOfProjects :any
  Chhattisgarh_ProjectCost :any
  State_Goa :any
  Goa_noOfProjects :any
  Goa_ProjectCost :any
  State_Gujarat :any
  Gujarat_noOfProjects :any
  Gujarat_ProjectCost :any
  State_Haryana :any
  Haryana_noOfProjects :any
  Haryana_ProjectCost :any
  State_HimachalPradesh :any
  HimachalPradesh_noOfProjects :any
  HimachalPradesh_ProjectCost :any
  State_Jharkhand :any
  Jharkhand_noOfProjects :any
  Jharkhand_ProjectCost :any
  State_Karnataka :any
  Karnataka_noOfProjects :any
  Karnataka_ProjectCost :any
  State_Kerala :any
  Kerala_noOfProjects :any
  Kerala_ProjectCost :any
  State_MadhyaPradesh :any
  MadhyaPradesh_noOfProjects :any
  MadhyaPradesh_ProjectCost :any
  State_Maharashtra :any
  Maharashtra_noOfProjects :any
  Maharashtra_ProjectCost :any
  State_Manipur :any
  Manipur_noOfProjects :any
  Manipur_ProjectCost :any
  State_Meghalaya :any
  Meghalaya_noOfProjects :any
  Meghalaya_ProjectCost :any
  State_Mizoram :any
  Mizoram_noOfProjects :any
  Mizoram_ProjectCost :any
  State_Nagaland :any
  Nagaland_noOfProjects :any
  Nagaland_ProjectCost :any
  State_Odisha :any
  Odisha_noOfProjects :any
  Odisha_ProjectCost :any
  State_Punjab :any
  Punjab_noOfProjects :any
  Punjab_ProjectCost :any
  State_Rajasthan :any
  Rajasthan_noOfProjects :any
  Rajasthan_ProjectCost :any
  State_Sikkim :any
  Sikkim_noOfProjects :any
  Sikkim_ProjectCost :any
  State_TamilNadu :any
  TamilNadu_noOfProjects :any
  TamilNadu_ProjectCost :any
  State_Telangana :any
  Telangana_noOfProjects :any
  Telangana_ProjectCost :any
  State_Tripura :any
  Tripura_noOfProjects :any
  Tripura_ProjectCost :any
  State_Uttarakhand :any
  Uttarakhand_noOfProjects :any
  Uttarakhand_ProjectCost :any
  State_UttarPradesh :any
  UttarPradesh_noOfProjects :any
  UttarPradesh_ProjectCost :any
  State_WestBengal :any
  WestBengal_noOfProjects :any
  WestBengal_ProjectCost :any
  State_Andaman :any
  Andaman_noOfProjects :any
  Andaman_ProjectCost :any
  State_Chandigarh :any
  Chandigarh_noOfProjects :any
  Chandigarh_ProjectCost :any
  State_Dadra :any
  Dadra_noOfProjects :any
  Dadra_ProjectCost :any
  State_Delhi :any
  Delhi_noOfProjects :any
  Delhi_ProjectCost :any
  State_Jammu :any
  Jammu_noOfProjects :any
  Jammu_ProjectCost :any
  State_Ladakh :any
  Ladakh_noOfProjects :any
  Ladakh_ProjectCost :any
  State_Lakshadweep :any
  Lakshadweep_noOfProjects :any
  Lakshadweep_ProjectCost :any
  State_Puducherry :any
  Puducherry_noOfProjects :any
  Puducherry_ProjectCost :any
  
  
  constructor(public serv: AhidfschemeService) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'},
    {id: '3'}
  ];
  ngOnInit(): void {
    this.getAhidfDashboardData();
    this.getAhidfData();
  }



  getAhidfDashboardData()
{
  this.serv.getAhidfDashboardData().subscribe((res)=>{
    console.log("ahidf dashboard Result");
    this.ahidfDashboardData=res
   console.log(this.ahidfDashboardData)
   this.lastDateFromArray =this.ahidfDashboardData[this.ahidfDashboardData.length - 1];
   console.log("last date--------------")

this.dateres = this.lastDateFromArray['created_at'].split("T");
console.log( this.dateres[0])
this.dateresone=this.dateres[0].split("-")
console.log( this.dateresone)
this.year=this.dateresone[0]
this.month=this.dateresone[1]
this.day=this.dateresone[2]
this.noday=Number(this.day)
this.noday+=1
console.log(this.noday)
this.curentDate=this.noday+'/'+this.month+'/'+this.year;
    this.totalSateWiseahidfDataCount=[];
var groupByTypeState = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalStateData=groupByTypeState(this.ahidfDashboardData, 'Schemes')
this.totalSateWiseahidfDataCount = Object.entries(totalStateData)
console.log(this.totalSateWiseahidfDataCount)
console.log("current date all data----")
this.totalSchemeStateWiseData=[];
this.totalSateWiseahidfDataCount.forEach((y:any) =>
{
const noOfProjects = y[1].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
const projectCost = y[1].map((item:any) => Number(item['ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
const loanAmount = y[1].map((item:any) => Number(item['LoanAmount'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.projectSchemeCost=this.numDifferentiation(projectCost);
this.projectSchemeLoanAmount=this.numDifferentiation(loanAmount);

var finalObj1 = Object.assign({"Scheme": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"loanAmount": this.projectSchemeLoanAmount});
this.totalSchemeStateWiseData.push(finalObj1)

});

// this.zeroSchemeName=this.totalSchemeStateWiseData[0]['Scheme'];
// this.zeroloanAmount=this.totalSchemeStateWiseData[0]['loanAmount']
// this.zeroprojectCost=this.totalSchemeStateWiseData[0]['projectCost']

this.animalFeedPlantSchemeName=this.totalSchemeStateWiseData[1]['noOfProjects'];
this.animalFeedPlantloanAmount=this.totalSchemeStateWiseData[1]['loanAmount']
this.animalFeedPlantprojectCost=this.totalSchemeStateWiseData[1]['projectCost']

this.dairyProcessingValueAdditionSchemeName=this.totalSchemeStateWiseData[0]['noOfProjects'];
this.dairyProcessingValueAdditionloanAmount=this.totalSchemeStateWiseData[0]['loanAmount']
this.dairyProcessingValueAdditionprojectCost=this.totalSchemeStateWiseData[0]['projectCost']

this.meatProcessingValueAdditionSchemeName=this.totalSchemeStateWiseData[4]['noOfProjects'];
this.meatProcessingValueAdditionloanAmount=this.totalSchemeStateWiseData[4]['loanAmount']
this.meatProcessingValueAdditionprojectCost=this.totalSchemeStateWiseData[4]['projectCost']

this.breedimprovementTechnologyandBreedMultiplicationfarmSchemeName=this.totalSchemeStateWiseData[2]['noOfProjects'];
this.breedimprovementTechnologyandBreedMultiplicationfarmloanAmount=this.totalSchemeStateWiseData[2]['loanAmount']
this.breedimprovementTechnologyandBreedMultiplicationfarmprojectCost=this.totalSchemeStateWiseData[2]['projectCost']

this.AnimalWastetoWealthManagementSchemeName=this.totalSchemeStateWiseData[3]['noOfProjects'];
this.AnimalWastetoWealthManagementloanAmount=this.totalSchemeStateWiseData[3]['loanAmount']
this.AnimalWastetoWealthManagementprojectCost=this.totalSchemeStateWiseData[3]['projectCost']
this.totalSateWiseahidfData=[];
var groupByTypeState = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalStateData=groupByTypeState(this.ahidfDashboardData, 'StateName')
this.totalSateWiseahidfData = Object.entries(totalStateData)
this.totalTopFiveStateWiseData=[];
this.totalStateWise=[];
this.totalSateWiseahidfData.forEach((y:any) =>
{
const noOfProjects = y[1].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
const projectCost = y[1].map((item:any) => Number(item['ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);

this.projectSchemeCost=this.numDifferentiation(projectCost);

var finalObj2 = Object.assign({"StateName": y[0]}, {"noOfProjects": noOfProjects},{"projectCost":this.numDifferentiations(projectCost)});
this.totalStateWise.push(finalObj2)

var finalObj1 = Object.assign({"StateName": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"projectCostWithOutCr":projectCost},{"loanAmount": this.projectSchemeLoanAmount});
this.totalTopFiveStateWiseData.push(finalObj1)


});


this.projectCostInArray=[];
this.totalNoOfProjectsInArray=[];

this. totalNoOfProjectsInAhidf = this.totalTopFiveStateWiseData.map((item:any) => Number(item['noOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.projectCostWithoutCr = this.totalTopFiveStateWiseData.map((item:any) => Number(item['projectCostWithOutCr'])).reduce((prev:any, curr:any) => prev + curr, 0);

this.projectSchemeCostInCr=this.numDifferentiations(this.projectCostWithoutCr);
this.projectCostInArray.push(this.projectSchemeCostInCr)
this.totalNoOfProjectsInArray.push(this. totalNoOfProjectsInAhidf)
console.log("project cost  in array")
console.log(this.projectCostInArray)

for(let state of this.totalStateWise){
 
  let temp = [state.StateName,state.StateName,Number(state.noOfProjects),Number(state.projectCost)];  
 
 this.State_Goa = "GOA";
        this.Goa_noOfProjects = 0;  
        this.Goa_ProjectCost = 0;      
        this.State_Lakshadweep = "LAKSHADWEEP";
        this.Lakshadweep_noOfProjects = 0;
        this.Lakshadweep_ProjectCost = 0; 
        this.State_Ladakh = "LADAKH";
        this.Ladakh_noOfProjects = 0;
        this.Ladakh_ProjectCost = 0; 
        this.State_Andaman = "ANDAMAN AND NICOBAR ISLANDS";
        this.Andaman_noOfProjects = 0;   
        this.Andaman_ProjectCost = 0;      
        this.State_Delhi = "DELHI";
        this.Delhi_noOfProjects = 0;
        this.Delhi_ProjectCost = 0; 
        this.State_HimachalPradesh = "HIMACHAL PRADESH";
        this.HimachalPradesh_noOfProjects = 0;
        this.HimachalPradesh_ProjectCost = 0; 
        this.State_Andaman = "ANDAMAN AND NICOBAR ISLANDS";
        this.Andaman_noOfProjects = 0;  
        this.Andaman_ProjectCost = 0;       
        this.State_Manipur = "MANIPUR";
        this.Manipur_noOfProjects = 0;
        this.Manipur_ProjectCost = 0; 
        this.State_ArunachalPradesh = "ARUNACHAL PRADESH";
        this.ArunachalPradesh_noOfProjects = 0;
        this.ArunachalPradesh_ProjectCost = 0; 
        this.State_Mizoram = "MIZORAM";
        this.Mizoram_noOfProjects = 0;  
        this.Mizoram_ProjectCost = 0;       
        this.State_Nagaland = "NAGALAND";
        this.Nagaland_noOfProjects = 0;
        this.Nagaland_ProjectCost = 0; 
        this.State_Jharkhand = "JHARKHAND";
        this.Jharkhand_noOfProjects = 0;
        this.Jharkhand_ProjectCost = 0; 
        this.State_Tripura = "TRIPURA";
        this.Tripura_noOfProjects = 0; 
        this.Tripura_ProjectCost = 0;        
        this.State_Meghalaya = "MEGHALAYA";
        this.Meghalaya_noOfProjects = 0;
        this.Meghalaya_ProjectCost = 0; 
        this.State_Sikkim = "SIKKIM";
        this.Sikkim_noOfProjects = 0;
        this.Sikkim_ProjectCost = 0; 
  if( state.StateName=="ANDAMAN AND NICOBAR ISLANDS"){
    temp = ['IN-AN','ANDAMAN AND NICOBAR ISLANDS',Number(state.noOfProjects)];
    this.State_Andaman = state.StateName;
    this.Andaman_noOfProjects = Number(state.noOfProjects);
    this.Andaman_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="UTTAR PRADESH"){    
    this.State_UttarPradesh = state.StateName;
    this.UttarPradesh_noOfProjects = Number(state.noOfProjects);
    this.UttarPradesh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="RAJASTHAN"){    
    this.State_Rajasthan = state.StateName;
    this.Rajasthan_noOfProjects = Number(state.noOfProjects);
    this.Rajasthan_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="DELHI"){    
    this.State_Delhi = state.StateName;
    this.Delhi_noOfProjects = Number(state.noOfProjects);
    this.Delhi_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="BIHAR"){    
    this.State_Bihar = state.StateName;
    this.Bihar_noOfProjects = Number(state.noOfProjects);
    this.Bihar_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="PUNJAB"){    
    this.State_Punjab = state.StateName;
    this.Punjab_noOfProjects = Number(state.noOfProjects);
    this.Punjab_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="HIMACHAL PRADESH"){    
    this.State_HimachalPradesh = state.StateName;
    this.HimachalPradesh_noOfProjects = Number(state.noOfProjects);
    this.HimachalPradesh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="CHANDIGARH"){    
    this.State_Chandigarh = state.StateName;
    this.Chandigarh_noOfProjects = Number(state.noOfProjects);
    this.Chandigarh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="JAMMU AND KASHMIR"){    
    this.State_Jammu = state.StateName;
    this.Jammu_noOfProjects = Number(state.noOfProjects);
    this.Jammu_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="HARYANA"){    
    this.State_Haryana = state.StateName;
    this.Haryana_noOfProjects = Number(state.noOfProjects);
    this.Haryana_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="UTTARAKHAND"){    
    this.State_Uttarakhand = state.StateName;
    this.Uttarakhand_noOfProjects = Number(state.noOfProjects);
    this.Uttarakhand_ProjectCost = Number(state.projectCost); 
  }  
  else if( state.StateName=="MAHARASHTRA"){    
    this.State_Maharashtra = state.StateName;
    this.Maharashtra_noOfProjects = Number(state.noOfProjects);
    this.Maharashtra_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="ANDHRA PRADESH"){    
    this.State_AndhraPradesh = state.StateName;
    this.AndhraPradesh_noOfProjects = Number(state.noOfProjects);
    this.AndhraPradesh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="GUJARAT"){    
    this.State_Gujarat = state.StateName;
    this.Gujarat_noOfProjects = Number(state.noOfProjects);
    this.Gujarat_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="MADHYA PRADESH"){    
    this.State_MadhyaPradesh = state.StateName;
    this.MadhyaPradesh_noOfProjects = Number(state.noOfProjects);
    this.MadhyaPradesh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU"){    
    this.State_Dadra = state.StateName;
    this.Dadra_noOfProjects = Number(state.noOfProjects);
    this.Dadra_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="TAMIL NADU"){    
    this.State_TamilNadu = state.StateName;
    this.TamilNadu_noOfProjects = Number(state.noOfProjects);
    this.TamilNadu_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="PUDUCHERRY"){    
    this.State_Puducherry = state.StateName;
    this.Puducherry_noOfProjects = Number(state.noOfProjects);
    this.Puducherry_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="TELANGANA"){    
    this.State_Telangana = state.StateName;
    this.Telangana_noOfProjects = Number(state.noOfProjects);
    this.Telangana_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="LADAKH"){    
    this.State_Ladakh = state.StateName;
    this.Ladakh_noOfProjects = Number(state.noOfProjects);
    this.Ladakh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="KARNATAKA"){    
    this.State_Karnataka = state.StateName;
    this.Karnataka_noOfProjects = Number(state.noOfProjects);
    this.Karnataka_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="KERALA"){    
    this.State_Kerala = state.StateName;
    this.Kerala_noOfProjects = Number(state.noOfProjects);
    this.Kerala_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="LAKSHADWEEP"){    
    this.State_Lakshadweep = state.StateName;
    this.Lakshadweep_noOfProjects = Number(state.noOfProjects);
    this.Lakshadweep_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="GOA"){    
    this.State_Goa = state.StateName;
    this.Goa_noOfProjects = Number(state.noOfProjects);
    this.Goa_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="MEGHALAYA"){    
    this.State_Meghalaya = state.StateName;
    this.Meghalaya_noOfProjects = Number(state.noOfProjects);
    this.Meghalaya_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="ASSAM"){    
    this.State_Assam = state.StateName;
    this.Assam_noOfProjects = Number(state.noOfProjects);
    this.Assam_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="TRIPURA"){    
    this.State_Tripura = state.StateName;
    this.Tripura_noOfProjects = Number(state.noOfProjects);
    this.Tripura_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="WEST BENGAL"){    
    this.State_WestBengal = state.StateName;
    this.WestBengal_noOfProjects = Number(state.noOfProjects);
    this.WestBengal_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="ARUNACHAL PRADESH"){    
    this.State_ArunachalPradesh = state.StateName;
    this.ArunachalPradesh_noOfProjects = Number(state.noOfProjects);
    this.ArunachalPradesh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="SIKKIM"){    
    this.State_Sikkim = state.StateName;
    this.Sikkim_noOfProjects = Number(state.noOfProjects);
    this.Sikkim_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="MANIPUR"){    
    this.State_Manipur = state.StateName;
    this.Manipur_noOfProjects = Number(state.noOfProjects);
    this.Manipur_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="MIZORAM"){    
    this.State_Mizoram = state.StateName;
    this.Mizoram_noOfProjects = Number(state.noOfProjects);
    this.Mizoram_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="NAGALAND"){    
    this.State_Nagaland = state.StateName;
    this.Nagaland_noOfProjects = Number(state.noOfProjects);
    this.Nagaland_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="CHHATTISGARH"){    
    this.State_Chhattisgarh = state.StateName;
    this.Chhattisgarh_noOfProjects = Number(state.noOfProjects);
    this.Chhattisgarh_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="JHARKHAND"){    
    this.State_Jharkhand = state.StateName;
    this.Jharkhand_noOfProjects = Number(state.noOfProjects);
    this.Jharkhand_ProjectCost = Number(state.projectCost); 
  }
  else if( state.StateName=="ODISHA"){    
    this.State_Odisha = state.StateName;
    this.Odisha_noOfProjects = Number(state.noOfProjects);
    this.Odisha_ProjectCost = Number(state.projectCost); 
  }
  
 // this.states_data.push(delhitemp);
  this.states_data.push(temp);
}
this.states_data.push(['DELHI','DELHI','0','0'],['JAMMU AND KASHMIR','JAMMU AND KASHMIR','0','0'],['HIMACHAL PRADESH','HIMACHAL PRADESH','0','0']
,['ASSAM','ASSAM','0','0'],['JHARKHAND','JHARKHAND','0','0'],['TRIPURA','TRIPURA','0','0'],['MEGHALAYA','MEGHALAYA','0','0'],
['Ladakh','Ladakh','0','0'],['GOA','GOA','0','0'],['PUDUCHERRY','PUDUCHERRY','0','0'],['ARUNACHAL PRADESH','ARUNACHAL PRADESH','0','0'],['CHANDIGARH','CHANDIGARH','0','0'],
['MANIPUR','MANIPUR','0','0'],['NAGALAND','NAGALAND','0','0'],['SIKKIM','SIKKIM','0','0'],['THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU','THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU','0','0'],
['IN-AN','ANDAMAN AND NICOBAR ISLANDS','0','0'],['MIZORAM','MIZORAM','0','0'],['IN-LD','LAKSHADWEEP','0','0']);
console.log("states data------------")
console.log(this.states_data)
 this.mapReady=true

this.showEnrolementGraph();
this.showchartonpageload();
},
(err)=>{
  console.log(err)
}
); 
}

  getAhidfData()
{
  this.serv.getAhidfData().subscribe((res)=>{
    console.log("ahidf Data Result");
    console.log(res);
    this.ahidfNavData=res
    this.noOfEntrepreneursRegistered = this.ahidfNavData.map((item:any) => Number(item['NoOfEntrepreneursRegistered'])).reduce((prev:any, curr:any) => prev + curr, 0);
    const projectCostApprovedForinterestsubvetion = this.ahidfNavData.map((item:any) => Number(item['ProjectCostInterestSubvention'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.projectCostApprovedForinterestsubvetionData=this.numDifferentiation(projectCostApprovedForinterestsubvetion);
	
	 const projectCostEntrepreneursRegisterd = this.ahidfNavData.map((item:any) => Number(item['ProjectCostEntrepreneursRegistered'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.projectCostProjectCostEntrepreneursRegisteredData=this.numDifferentiation(projectCostEntrepreneursRegisterd);
	
	
	 const projectCostProjectsEligibleByDepartment = this.ahidfNavData.map((item:any) => Number(item['ProjectsCostOfEligible'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.projectCostEligibleByDepartmentData=this.numDifferentiation(projectCostProjectsEligibleByDepartment);
	
	
	
	
	const projectCostOfSanctionedByBank = this.ahidfNavData.map((item:any) => Number(item['ProjectCostOfSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.projectCostSanctionedByBankData=this.numDifferentiation(projectCostOfSanctionedByBank);
	
	
    this.projectsEligibleByDepartment = this.ahidfNavData.map((item:any) => Number(item['ProjectsEligibleByDepartment'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.ProjectsSanctionedByBank = this.ahidfNavData.map((item:any) => Number(item['ProjectsSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.approvedForInterestSubvention = this.ahidfNavData.map((item:any) => Number(item['ApprovedForInterestSubvention'])).reduce((prev:any, curr:any) => prev + curr, 0);
    const interestSubvention = this.ahidfNavData.map((item:any) => Number(item['InterestSubventionAmount'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.interestSubventionAmount=this.numDifferentiation(interestSubvention);
   this.totalgroupByTypeProjectsSanctionedByBank=[];
    var groupByTypeProjectsSanctionedByBank = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var ProjectsSanctionedByBank=groupByTypeProjectsSanctionedByBank(this.ahidfNavData, 'StateName')
    this.totalgroupByTypeProjectsSanctionedByBank = Object.entries(ProjectsSanctionedByBank);


this.totalTopFiveStateWiseDataKpi=[];
    this.totalgroupByTypeProjectsSanctionedByBank.forEach((y:any) =>
    {
    var ProjectsSanctionedByBanks = y[1].map((item:any) => Number(item['ProjectsSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
    var ProjectCostOfSanctionedByBanks = y[1].map((item:any) => Number(item['ProjectCostOfSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
    
    this.projectSchemeCostInCrs=this.numDifferentiation(ProjectCostOfSanctionedByBanks);
    var finalObj1 = Object.assign({"StateName": y[0]}, {"noOfProjects": ProjectsSanctionedByBanks},{"projectCost": this.projectSchemeCostInCrs});
    this.totalTopFiveStateWiseDataKpi.push(finalObj1)
    
    
    });

    console.log("total state wise dataaaaaaaaaa")
    console.log(this.totalStateWise)
    var topValues = this.totalTopFiveStateWiseDataKpi.sort((a:any,b:any) => b['noOfProjects']-a['noOfProjects']).slice(0,5);
    console.log("top states")
    console.log(topValues)
    console.log(this.totalTopFiveStateWiseDataKpi)
    
    this.topFirstPerfomanceState=topValues[0]['StateName'];
    this.topFirstPerformingnoOfProjectsDone=topValues[0]['noOfProjects'];
    this.topFirstPerformingprojectCost=topValues[0]['projectCost'];
    
    this.topSecondPerfomanceState=topValues[1]['StateName'];
    this.topSecondPerformingnoOfProjectsDone=topValues[1]['noOfProjects'];
    this.topSecondPerformingprojectCost=topValues[1]['projectCost'];
    
    this.topThirdPerfomanceState=topValues[2]['StateName'];
    this.topThirdPerformingnoOfProjectsDone=topValues[2]['noOfProjects'];
    this.topThirdPerformingprojectCost=topValues[2]['projectCost'];
    
    this.topFourthPerfomanceState=topValues[3]['StateName'];
    this.topFourthPerformingnoOfProjectsDone=topValues[3]['noOfProjects'];
    this.topFourthPerformingprojectCost=topValues[3]['projectCost'];
    
    this.topFifthPerfomanceState=topValues[4]['StateName'];
    this.topFifthPerformingnoOfProjectsDone=topValues[4]['noOfProjects'];
    this.topFifthPerformingprojectCost=topValues[4]['projectCost'];

    console.log("project sectionad by bank--------")
    console.log(this.totalTopFiveStateWiseDataKpi)
},
(err)=>{
  console.log(err)
}
); 
}
numDifferentiation(value:any) {

  var val = Math.abs(value)
  if (val >= 10000000) {
    this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
  } else if (val >= 1000) {
    this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
  }
  else if (val >= 0) {
    this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
  }
  return this.valueInFormate;
}
numDifferentiations(value:any) {

  var val = Math.abs(value)
  if (val >= 10000000) {
    this.valueInFormate = (val / 10000000).toFixed(2) ;
  } else if (val >= 100000) {
    this.valueInFormate    = (val / 100000).toFixed(2) ;
  } else if (val >= 1000) {
    this.valueInFormate    = (val / 1000).toFixed(2) ;
  }
  else if (val >= 0) {
    this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
  }
  return this.valueInFormate;
}
  public geoChart: GoogleChartInterface = {
    chartType: 'GeoChart',
    dataTable: this.states_data,
    options: {
      domain:'IN',
      region: 'IN',
      colorAxis: {colors: ['#67cdcd','#67cdcd']},
      resolution: 'provinces',
      zoom: 6,
     // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
  disableDefaultUI: true,
  //displayMode: 'text',
  defaultColor:'#40e0d0',
  backgroundColor: 'transparent',
  //tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
      //'height': 600,
      'width': 850,
     

      
    }
  };


  showEnrolementGraph()
  {
      
      this.yearWiseEnroleMentChart =  {
        
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['No. of Projects Sanctioned', 'Project Cost (In Cr)']
        },
        toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          
          {
            type: 'category',
            axisTick: { show: true },
            axisLabel: {
              interval: 0,
              rotate: 0 //If the label names are too long you can manage this by rotating the label.
            },
            data:['Sanctioned Application']
          },
        
        ],
        yAxis: [
          {
            splitLine: {
              show: false
           },
            axisLine:{                 //Coordinate axis
              show:true, 
                        //Show Axis axis or not        //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
          },
            type: 'value'
          }
        ],
        series: [
          {
            name: 'No. of Projects Sanctioned',
            type: 'bar',
            barGap: 0,
          //  label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data:this.totalNoOfProjectsInArray
          },
          {
            name: 'Project Cost (In Cr)',
            type: 'bar',
           // label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data:this.projectCostInArray
          }
         
        ]
      };
  
    }
    // public select(event: ChartSelectEvent) {
    //   console.log(event.selectedRowValues[0])
    //   if(event.selectedRowValues[0]=='Rajasthan')
    //   {
    //     this.statePercent=40;
    //   }
    //   else if(event.selectedRowValues[0]=='Uttar Pradesh'){
    //     this.statePercent=70;
    //   }
    //   } 
    public select(event: any) {
      this.showPieChartpageload = false;
      this.showPieChartMapclick= true;
      console.log(event)     
      console.log(this.totalSateWiseahidfData)
      this.selectState=[];
      this.totalSateWiseahidfData.forEach((y:any) =>
      {
     if(y[0]==event)
     {
       this.selectState=y[1]
     }
     
      });
      var groupByTypeState = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      console.log("Raj")
      console.log(this.selectState)
  var totalTypeProjectStateData=groupByTypeState(this.selectState, 'StateName')
  var totalTypeProjectStateDataCount = Object.entries(totalTypeProjectStateData)
  console.log(totalTypeProjectStateDataCount)
  console.log(totalTypeProjectStateDataCount.length)
  this.totalAggregateStateProjectCountData=[];
  this.totalAggregateStateProjectCostData=[];
  var StateName = null;
  var StateName1 = null;
if(event.message != "deselect")
{
  if(totalTypeProjectStateDataCount.length!=0)
  {
  totalTypeProjectStateDataCount.forEach((y:any) =>
  {
    var finalObj = Object.assign({"name": "Total Projects"}, {"value": this.totalNoOfProjectsInAhidf}); 
    
    this.totalAggregateStateProjectCountData.push(finalObj);
   
      var totalProjectCount =  y[1].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);    
      //var finalObj = Object.assign({"name": y[0]}, {"value": totalProjectCount});
      finalObj = Object.assign({"name": "Projects"}, {"value": totalProjectCount});
      this.totalAggregateStateProjectCountData.push(finalObj);     
      StateName = event;

  });
  totalTypeProjectStateDataCount.forEach((y:any) =>
  {   
    var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.projectCostWithoutCr});   
    this.totalAggregateStateProjectCostData.push(finalObj1);      
      var totalProjectCost =  y[1].map((item:any) => Number(item['ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);      
      finalObj1 = Object.assign({"name": "Project Cost"}, {"value": totalProjectCost});
      this.totalAggregateStateProjectCostData.push(finalObj1);
      StateName1 = event;
  });
}
else{
  var finalObj = Object.assign({"name": "Total Projects"}, {"value": this.totalNoOfProjectsInAhidf});     
  this.totalAggregateStateProjectCountData.push(finalObj);
  finalObj = Object.assign({"name": "Projects"}, {"value": "0"}); 
  this.totalAggregateStateProjectCountData.push(finalObj);
  var finalObj1 = Object.assign({"name": "Project Cost"}, {"value": this.projectCostWithoutCr}); 
  this.totalAggregateStateProjectCostData.push(finalObj1);
  finalObj1 = Object.assign({"name": "Project Cost"}, {"value": 0});
      this.totalAggregateStateProjectCostData.push(finalObj1);
      StateName = event;
      StateName1 = event;
}
}
else{
   finalObj = Object.assign({"name": "Projects"}, {"value": this.totalNoOfProjectsInAhidf}); 
  this.totalAggregateStateProjectCountData.push(finalObj);
  var finalObj1 = Object.assign({"name": "Project Cost"}, {"value": this.projectCostWithoutCr}); 
  this.totalAggregateStateProjectCostData.push(finalObj1);

  StateName = "Total Projects";
  StateName1 = "Total Cost";
}
  console.log("this.totalAggregateStateProjectCountData")
console.log(this.totalAggregateStateProjectCountData)
console.log(this.totalAggregateStateProjectCostData)

      this.typeOfProjectChart = {
        title: {
          show:true,
          text: StateName,
          // text: event.selectedRowValues[0],
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          show:false
        },
        series: [
          {
           // name: 'Access From',
            type: 'pie',
            radius: '44%',
            // radius: '53%',
          //  radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateProjectCountData
          }
        ]
      }
      this.typeOfProjectCostChart = {
        title: {
          show:true,
          text: StateName1,
          // text: event.selectedRowValues[0],
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          show:false
        },
        series: [
          {
           // name: 'Access From',
            type: 'pie',
            radius: '44%',
            // radius: '53%',
          //  radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateProjectCostData
          }
        ]
      }
  };

  public showchartonpageload() {
    this.showPieChartpageload = true;
    this.showPieChartMapclick= false;
    this.totalAggregateStateProjectCountData=[];
    this.totalAggregateStateProjectCostData=[];
  var StateName = null;
  var StateName1 = null;
  
  var finalObj = Object.assign({"name": "Projects"}, {"value": this.totalNoOfProjectsInAhidf}); 
  this.totalAggregateStateProjectCountData.push(finalObj);
  var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.projectCostWithoutCr}); 
  this.totalAggregateStateProjectCostData.push(finalObj1);
  StateName = "Total Projects";
  StateName1 = "Total Project Cost";
          console.log("Total AI Done")
  console.log(this.totalAggregateStateProjectCountData)
  
  
      this.typeOfProjectChart = {
        title: {
          show:true,
          // text: event.selectedRowValues[0],
          text: StateName,
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'right'
        // },
        series: [
          {
           // name: 'Access From',
           type: 'pie',
            radius: '44%',
           // radius: ['40%', '70%'],
            avoidLabelOverlap: false,
           
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '80%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateProjectCountData
          }
        ]
      };
      this.typeOfProjectCostChart = {
        title: {
          show:true,
          // text: event.selectedRowValues[0],
          text: StateName1,
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'right'
        // },
        series: [
          {
           // name: 'Access From',
           type: 'pie',
            radius: '44%',
           // radius: ['40%', '70%'],
            avoidLabelOverlap: false,
           
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '80%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateProjectCostData
          }
        ]
      };
  
  } 
    
}
