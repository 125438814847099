import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class NlmschemeService {

  constructor(public http: HttpClient) { }
  getData() {
    // return this.http.get('/assets/finalsud25012022.json');
    return this.http.get('/assets/geo.json');
     }
     getDashboardData()
     {
      return this.http.get('http://dahddashboard.ndlm.co.in/api/nlmdashboard');
     }
     getDashboardKpiData()
     {
      return this.http.get('http://dahddashboard.ndlm.co.in/api/nlmkpi');
     } 
	 getDashboardstatewisekpiData()
     {
      return this.http.get('http://dahddashboard.ndlm.co.in/api/nlmstatewisekpi');
     }
	 getDashboardsubsidykpiData()
     {
      return this.http.get('http://dahddashboard.ndlm.co.in/api/nlmsubsidykpi');
     }
}
