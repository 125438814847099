import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { StatedashboardService } from './statedashboard.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as _ from 'lodash';
@Component({
  selector: 'app-statedashboard',
  templateUrl: './statedashboard.component.html',
  styleUrls: ['./statedashboard.component.scss']
})
export class StatedashboardComponent implements OnInit {
  
  yearWiseEnroleMentChart:any;
  typeOfVacinationChart:any
  states_data = [['State Code','State','Total Vaccinated']];
  result:  any = new Array()
  lhdcAllData:  any = new Array()
  response:  any = new Array()
  totalStateDataCount:  any = new Array()
  totalfarmerRegisterdArray:  any = new Array()
  totalVaccinationStateWise:  any = new Array()
  topFiveVaccinatedArray:  any = new Array()
  totalBuffeloVaccinatedArray:  any = new Array()
  totalCatleVaccinatedArray:  any = new Array()
  totalSateWiseTypeVacinationDataCount:  any = new Array()
  totalDateWiseFMDDataCount:  any = new Array()
  totalDateWiseBrucellosisDataCount:  any = new Array()
  totalTypeVacinationDataCount:  any = new Array()
  totalAggregateStateVaccinationData:  any = new Array()
  totalDateWiseFmdVaccinationDoneArray:  any = new Array()
  totalDateWiseBrucellosisVaccinationDoneArray:  any = new Array()
  fmdVaccinationDate:  any = new Array()
  totalNoOfFmdVaccinationDoneArray:  any = new Array()
  BrucellosisVaccinationDate:  any = new Array()
  totalNoOfBrucellosisVaccinationDoneArray:  any = new Array()
  totalfarmerRegisterd:any;
  currentDateData:  any = new Array()
  valueInFormate:any
  totalFarmerRegisterdCount:any
  totalBuffeloVacinatedCount:any
  totalBuffeloVaccinated:any
  totalCatleVacinatedCount:any
  totalCatleVaccinated:any
  totalvaccinationDoneInBrucellosis:any
  showBrucellosisEnrolementChart:any;
  totalvaccinationDoneInFmd:any;
  totalAnimalCoverdInBrucellosis:any;
  totalAnimalCoverdInBrucellosisWithoutNumDif:any;
  totalAnimalCoverdInFmd:any;
  totalAnimalCoverdInFmdWithoutNumDif:any;
  vaccinationTypeFMDData: any;
  vaccinationTypeBrucellosisData:any;
  totalVaccinationDoneArray:  any = new Array()
  statePercent=0
  mapReady=false;
  showChartBrucellosischart=false;
  showChartFmdchart=true;
  myCSSclass=true;
  myCSSclass1=false;
  showPieChartpageload = true;
  showPieChartMapclick= false;
  selectState: any;
  topFirstPerfomanceState: any;
  topFirstPerformingVaccinationDone: any;
  topSecondPerfomanceState: any;
  topSecondPerformingVaccinationDone: any;
  topThirdPerfomanceState: any;
  topThirdPerformingVaccinationDone: any;
  topFourthPerfomanceState: any;
  topFourthPerformingVaccinationDone: any;
  topFifthPerfomanceState: any;
  topFifthPerformingVaccinationDone: any;
  allMvuData: any;
  allMvuOperationalData: any;
  allMvuSanctioned:any;
  sumallMvuSanctioned: any;
  topFirstPerformingAnimalCoverd: any;
  topSecondPerformingAnimalCoverd: any;
  topThirdPerformingAnimalCoverd: any;
  topFourthPerformingAnimalCoverd: any;
  topFifthPerformingAnimalCoverd: any;
  lastDateFromArray: any;
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  noday:any;
  curentDate: any;
  
  
  State_AndhraPradesh :any
  AndhraPradesh_TotalVacinated :any
  State_ArunachalPradesh :any
  ArunachalPradesh_TotalVacinated :any
  State_Assam :any
  Assam_TotalVacinated :any 
  State_Bihar :any
  Bihar_TotalVacinated :any
  State_Chhattisgarh :any
  Chhattisgarh_TotalVacinated :any
  State_Goa :any
  Goa_TotalVacinated :any
  State_Gujarat :any
  Gujarat_TotalVacinated :any
  State_Haryana :any
  Haryana_TotalVacinated :any
  State_HimachalPradesh :any
  HimachalPradesh_TotalVacinated :any
  State_Jharkhand :any
  Jharkhand_TotalVacinated :any
  State_Karnataka :any
  Karnataka_TotalVacinated :any
  State_Kerala :any
  Kerala_TotalVacinated :any
  State_MadhyaPradesh :any
  MadhyaPradesh_TotalVacinated :any
  State_Maharashtra :any
  Maharashtra_TotalVacinated :any
  State_Manipur :any
  Manipur_TotalVacinated :any
  State_Meghalaya :any
  Meghalaya_TotalVacinated :any
  State_Mizoram :any
  Mizoram_TotalVacinated :any
  State_Nagaland :any
  Nagaland_TotalVacinated :any
  State_Odisha :any
  Odisha_TotalVacinated :any
  State_Punjab :any
  Punjab_TotalVacinated :any
  State_Rajasthan :any
  Rajasthan_TotalVacinated :any
  State_Sikkim :any
  Sikkim_TotalVacinated :any
  State_TamilNadu :any
  TamilNadu_TotalVacinated :any
  State_Telangana :any
  Telangana_TotalVacinated :any
  State_Tripura :any
  Tripura_TotalVacinated :any
  State_Uttarakhand :any
  Uttarakhand_TotalVacinated :any
  State_UttarPradesh :any
  UttarPradesh_TotalVacinated :any
  State_WestBengal :any
  WestBengal_TotalVacinated :any
  State_Andaman :any
  Andaman_TotalVacinated :any
  State_Chandigarh :any
  Chandigarh_TotalVacinated :any
  State_Dadra :any
  Dadra_TotalVacinated :any
  State_Delhi :any
  Delhi_TotalVacinated :any
  State_Jammu :any
  Jammu_TotalVacinated :any
  State_Ladakh :any
  Ladakh_TotalVacinated :any
  State_Lakshadweep :any
  Lakshadweep_TotalVacinated :any
  State_Puducherry :any
  Puducherry_TotalVacinated :any
  

  constructor(public serv: StatedashboardService) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'},
    {id: '3'}
  ];
  ngOnInit(): void {
  //  this.showEnrolementGraph();
this.lhdcDashboardData();
this.getMvuDashboardData();
  }


lhdcDashboardData()
{
  this.serv.getLhdcData().subscribe((res)=>{
    console.log("new data commimng")
    this.lhdcAllData=res;
    console.log(this.lhdcAllData)
    
    var groupByDataDate = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };


/////////////////////////////////////Group By On Date////////////////////////////////////
var totalCurrentDateData=groupByDataDate(this.lhdcAllData, 'DataDate')
var totalCurrentDateDataCount = Object.entries(totalCurrentDateData)
console.log(totalCurrentDateDataCount)
this.currentDateData=[];
this.currentDateData.push(totalCurrentDateDataCount[totalCurrentDateDataCount.length-1]['1']);
console.log("curentdate")
console.log(this.currentDateData)
console.log("last date--------------")
this.lastDateFromArray = this.lhdcAllData[this.lhdcAllData.length - 1];
this.dateres = this.lastDateFromArray['created_at'].split("T");
console.log( this.dateres[0])
this.dateresone=this.dateres[0].split("-")
console.log( this.dateresone)
this.year=this.dateresone[0]
this.month=this.dateresone[1]
this.day=this.dateresone[2]
this.noday=Number(this.day)
this.noday+=1
console.log(this.noday)
this.curentDate=this.noday+'/'+this.month+'/'+this.year;
 /////////////////////////////////////Group By On Last Date OF Vaccination Type////////////////////////////////////
var groupByTypeVacination = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


var totalTypeVacinationData=groupByTypeVacination(this.currentDateData['0'], 'Type_of_Vaccination')
var totalTypeOfVaccinationDataCount = Object.entries(totalTypeVacinationData)

console.log(totalTypeOfVaccinationDataCount)

this.totalfarmerRegisterdArray=[];
this.totalBuffeloVaccinatedArray=[];
this.totalCatleVaccinatedArray=[];
this.totalVaccinationDoneArray=[];

totalTypeOfVaccinationDataCount.forEach((y:any) =>
{
  console.log("scheme typeeeeeeeeeeeeeeeeeeeeeee")
  console.log(y)
  
const sumallVaccination = y['1'].map((item:any) => Number(item['Total_no_of_Vaccination_done'])).reduce((prev:any, curr:any) => prev + curr, 0);
console.log(sumallVaccination)
  

const sumallBuffeloVaccinated = y[1].map((item:any) => Number(item['Buffalo_Vaccinated'])).reduce((prev:any, curr:any) => prev + curr, 0);
const sumallCattleVaccinated = y[1].map((item:any) => Number(item['Cattle_Vaccinated'])).reduce((prev:any, curr:any) => prev + curr, 0);
const sumallAnimalVaccinated = y[1].map((item:any) => Number(item['No_of_Animals_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
if(y[0]=="FMD")
{
const sumallFarmersBenifitted = y[1].map((item:any) => Number(item['Total_no_of_Farmers_benefitted'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.totalfarmerRegisterdArray.push(sumallFarmersBenifitted);
}
this.totalBuffeloVaccinatedArray.push(sumallBuffeloVaccinated);
this.totalCatleVaccinatedArray.push(sumallCattleVaccinated);
var vaccinationData = Object.assign({"typeOfVaccination": y[0]}, {"totalNoOfVaccinationDone": this.numDifferentiation(sumallVaccination)}, {"totalNoOfAnimalCoverd": this.numDifferentiation(sumallAnimalVaccinated)}, {"totalNoOfAnimalCoverdWithoutNumDiff": sumallAnimalVaccinated});
this.totalVaccinationDoneArray.push(vaccinationData)
});

console.log(this.totalVaccinationDoneArray)
//---------------- sum of all former registerd----------------------------------------------//
console.log("fomers dataaaaaaaaaaaaaa")
console.log(this.totalfarmerRegisterdArray)
// if(this.totalfarmerRegisterdArray.length>1)
// {
// this. totalFarmerRegisterdCount = this.totalfarmerRegisterdArray.reduce((a:any, b:any) => {
//   return a + b;
// });
//}
this.totalfarmerRegisterd= this.numDifferentiation(this.totalfarmerRegisterdArray[0])

//-----------------sum of all buffalo Vaccinated---------------------------------------------//
console.log("buffelo")
console.log(this.totalBuffeloVaccinatedArray)
if(this.totalBuffeloVaccinatedArray.length>1)
{
this. totalBuffeloVacinatedCount = this.totalBuffeloVaccinatedArray.reduce((a:any, b:any) => {
  return a + b;
});
}
this.totalBuffeloVaccinated= this.numDifferentiation(this.totalBuffeloVacinatedCount)

//-----------------sum of all Catles Vaccinated---------------------------------------------//
console.log("Catles-------")
console.log(this.totalCatleVaccinatedArray)
if(this.totalCatleVaccinatedArray.length>1)
{
this. totalCatleVacinatedCount = this.totalCatleVaccinatedArray.reduce((a:any, b:any) => {
  return a + b;
});
}
if(this.totalVaccinationDoneArray[0]['typeOfVaccination']=='Brucellosis')
{ 
  this.totalAnimalCoverdInBrucellosisWithoutNumDif=this.totalVaccinationDoneArray[0]['totalNoOfAnimalCoverdWithoutNumDiff'];
}
if(this.totalVaccinationDoneArray[1]['typeOfVaccination']=='Brucellosis')
{ 
  this.totalAnimalCoverdInBrucellosisWithoutNumDif=this.totalVaccinationDoneArray[1]['totalNoOfAnimalCoverdWithoutNumDiff'];
}
if(this.totalVaccinationDoneArray[0]['typeOfVaccination']=='FMD')
{ 
  this.totalAnimalCoverdInFmdWithoutNumDif=this.totalVaccinationDoneArray[0]['totalNoOfAnimalCoverdWithoutNumDiff'];
}
if(this.totalVaccinationDoneArray[1]['typeOfVaccination']=='FMD')
{ 
  this.totalAnimalCoverdInFmdWithoutNumDif=this.totalVaccinationDoneArray[1]['totalNoOfAnimalCoverdWithoutNumDiff'];
}

this.totalCatleVaccinated= this.numDifferentiation(this.totalCatleVacinatedCount)


console.log(this.totalVaccinationDoneArray)
if(this.totalVaccinationDoneArray[0]['typeOfVaccination']=='FMD')
{
  this.totalvaccinationDoneInFmd=  this.totalVaccinationDoneArray[0]['totalNoOfVaccinationDone'];
  this.totalAnimalCoverdInFmd=this.totalVaccinationDoneArray[0]['totalNoOfAnimalCoverd'];
}
 if(this.totalVaccinationDoneArray[1]['typeOfVaccination']=='FMD')
{
  this.totalvaccinationDoneInFmd=  this.totalVaccinationDoneArray[1]['totalNoOfVaccinationDone'];
  this.totalAnimalCoverdInFmd=this.totalVaccinationDoneArray[1]['totalNoOfAnimalCoverd'];
}
if(this.totalVaccinationDoneArray[0]['typeOfVaccination']=='Brucellosis')
{
  this.totalvaccinationDoneInBrucellosis=  this.totalVaccinationDoneArray[0]['totalNoOfVaccinationDone'];
  this.totalAnimalCoverdInBrucellosis=this.totalVaccinationDoneArray[0]['totalNoOfAnimalCoverd'];
}
 if(this.totalVaccinationDoneArray[1]['typeOfVaccination']=='Brucellosis')
{
  this.totalvaccinationDoneInBrucellosis=  this.totalVaccinationDoneArray[1]['totalNoOfVaccinationDone'];
  this.totalAnimalCoverdInBrucellosis=this.totalVaccinationDoneArray[1]['totalNoOfAnimalCoverd'];
}
// this.totalvaccinationDoneInFmd=  this.totalVaccinationDoneArray[0]['totalNoOfVaccinationDone'];
// this.totalvaccinationDoneInBrucellosis=  this.totalVaccinationDoneArray[1]['totalNoOfVaccinationDone'];
// this.totalAnimalCoverdInFmd=this.totalVaccinationDoneArray[0]['totalNoOfAnimalCoverd'];
// this.totalAnimalCoverdInBrucellosis=this.totalVaccinationDoneArray[1]['totalNoOfAnimalCoverd'];


/////////////////////////////////////Group By On Vaccination Type////////////////////////////////////
this.totalTypeVacinationDataCount=[];
this.totalDateWiseFMDDataCount=[];
this.totalDateWiseBrucellosisDataCount=[];
var groupByTotalVaccinatioTypeData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalVaccinationTypeData=groupByTotalVaccinatioTypeData(this.lhdcAllData, 'Type_of_Vaccination')
this.totalTypeVacinationDataCount = Object.entries(totalVaccinationTypeData)
console.log(this.totalTypeVacinationDataCount)
this.totalDateWiseFmdVaccinationDoneArray=[];
this.totalDateWiseBrucellosisVaccinationDoneArray=[];
this.totalTypeVacinationDataCount.forEach((y:any) =>
{
if(y[0]=="FMD")
{
  console.log('fmd data')
  console.log(y[1])
 this.vaccinationTypeFMDData=y[1];

 var groupByDateWiseFMDData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalDateWiseFMDData=groupByDateWiseFMDData(this.vaccinationTypeFMDData, 'DataDate')
this.totalDateWiseFMDDataCount = Object.entries(totalDateWiseFMDData)
console.log("date Wise Data ")
console.log(this.totalDateWiseFMDDataCount)
this.totalDateWiseFMDDataCount.forEach((y:any) =>
{
const sumallDateWiseVaccination = y['1'].map((item:any) => Number(item['Total_no_of_Vaccination_done'])).reduce((prev:any, curr:any) => prev + curr, 0);
console.log(sumallDateWiseVaccination)

var vaccinationData = Object.assign({"date": y[0]}, {"totalNoOfFmdVaccinationDone": sumallDateWiseVaccination});
this.totalDateWiseFmdVaccinationDoneArray.push(vaccinationData)
});
console.log("fmd data datewise")

this.fmdVaccinationDate=[];
this.totalNoOfFmdVaccinationDoneArray=[];
this.totalDateWiseFmdVaccinationDoneArray.forEach((y:any) =>
  {
this.fmdVaccinationDate.push(y['date']);
this.totalNoOfFmdVaccinationDoneArray.push(y['totalNoOfFmdVaccinationDone']);
  });
 // this.lastDateFromArray = this.fmdVaccinationDate.pop();
  console.log( this.lastDateFromArray)
  this.showEnrolementGraph();
  console.log(this.fmdVaccinationDate)
  console.log(this.totalNoOfFmdVaccinationDoneArray)
  console.log(this.totalDateWiseFmdVaccinationDoneArray)
}
if(y[0]=="Brucellosis")
{
  console.log('Brucellosis data')
  console.log(y[1])
 this.vaccinationTypeBrucellosisData=y[1];

 var groupByDateWiseBrucellosisData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalDateWiseBrucellosisData=groupByDateWiseBrucellosisData(this.vaccinationTypeBrucellosisData, 'DataDate')
this.totalDateWiseBrucellosisDataCount = Object.entries(totalDateWiseBrucellosisData)
this.totalDateWiseBrucellosisDataCount.forEach((y:any) =>
{
const sumallDateWiseVaccination = y['1'].map((item:any) => Number(item['Total_no_of_Vaccination_done'])).reduce((prev:any, curr:any) => prev + curr, 0);
console.log(sumallDateWiseVaccination)

var vaccinationData = Object.assign({"date": y[0]}, {"totalNoOfBrucellosisVaccinationDone": sumallDateWiseVaccination});
this.totalDateWiseBrucellosisVaccinationDoneArray.push(vaccinationData)
});
console.log("brucelise data datewise")
console.log(this.totalDateWiseBrucellosisVaccinationDoneArray)
this.BrucellosisVaccinationDate=[];
this.totalNoOfBrucellosisVaccinationDoneArray=[];
this.totalDateWiseBrucellosisVaccinationDoneArray.forEach((y:any) =>
  {
this.BrucellosisVaccinationDate.push(y['date']);
this.totalNoOfBrucellosisVaccinationDoneArray.push(y['totalNoOfBrucellosisVaccinationDone']);
  });
}
});



//////////////////////////////////////Group By State Data/////////////////////////////////////////
this.totalSateWiseTypeVacinationDataCount=[];
var groupByTypeState = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalStateData=groupByTypeState(this.currentDateData[0], 'State_Name')
this.totalSateWiseTypeVacinationDataCount = Object.entries(totalStateData)
console.log("current date all data----")
console.log(this.totalSateWiseTypeVacinationDataCount);
this.totalVaccinationStateWise=[];
this.totalSateWiseTypeVacinationDataCount.forEach((y:any) =>
{
const sumallStateVaccination = y[1].map((item:any) => Number(item['Total_no_of_Vaccination_done'])).reduce((prev:any, curr:any) => prev + curr, 0);
const sumallStateAnimalCoverd = y[1].map((item:any) => Number(item['No_of_Animals_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
var finalObj1 = Object.assign({"State": y[0]}, {"VacinationDone": sumallStateVaccination},{"AnimalCoverd": sumallStateAnimalCoverd});
this.totalVaccinationStateWise.push(finalObj1)
});

var topValues = this.totalVaccinationStateWise.sort((a:any,b:any) => b['VacinationDone']-a['VacinationDone']).slice(0,5);
console.log("top states")
console.log(topValues)
console.log(this.totalVaccinationStateWise)
this.topFiveVaccinatedArray=[];

topValues.forEach((y:any) =>
{
var finalObj1 = Object.assign({"State": y['State']}, {"VacinationDone": this.numDifferentiation(y['VacinationDone'])},{"AnimalCoverd": this.numDifferentiation(y['AnimalCoverd'])});
this.topFiveVaccinatedArray.push(finalObj1);
});
console.log("top five vacination state")
console.log(this.topFiveVaccinatedArray)
this.topFirstPerfomanceState=this.topFiveVaccinatedArray[0]['State'];
this.topFirstPerformingVaccinationDone=this.topFiveVaccinatedArray[0]['VacinationDone'];
this.topFirstPerformingAnimalCoverd=this.topFiveVaccinatedArray[0]['AnimalCoverd'];

this.topSecondPerfomanceState=this.topFiveVaccinatedArray[1]['State'];
this.topSecondPerformingVaccinationDone=this.topFiveVaccinatedArray[1]['VacinationDone'];
this.topSecondPerformingAnimalCoverd=this.topFiveVaccinatedArray[1]['AnimalCoverd'];

this.topThirdPerfomanceState=this.topFiveVaccinatedArray[2]['State'];
this.topThirdPerformingVaccinationDone=this.topFiveVaccinatedArray[2]['VacinationDone'];
this.topThirdPerformingAnimalCoverd=this.topFiveVaccinatedArray[2]['AnimalCoverd'];

this.topFourthPerfomanceState=this.topFiveVaccinatedArray[3]['State'];
this.topFourthPerformingVaccinationDone=this.topFiveVaccinatedArray[3]['VacinationDone'];
this.topFourthPerformingAnimalCoverd=this.topFiveVaccinatedArray[3]['AnimalCoverd'];

this.topFifthPerfomanceState=this.topFiveVaccinatedArray[4]['State'];
this.topFifthPerformingVaccinationDone=this.topFiveVaccinatedArray[4]['VacinationDone'];
this.topFifthPerformingAnimalCoverd=this.topFiveVaccinatedArray[4]['AnimalCoverd'];

for(let state of this.totalVaccinationStateWise){
  let temp = [state.State,state.State,Number(state.VacinationDone)];
  if( state.State=="ANDAMAN AND NICOBAR ISLANDS"){
    temp = ['IN-AN','ANDAMAN AND NICOBAR ISLANDS',Number(state.VacinationDone)];
    this.State_Andaman = state.State;
    this.Andaman_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="UTTAR PRADESH"){    
    this.State_UttarPradesh = state.State;
    this.UttarPradesh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="RAJASTHAN"){    
    this.State_Rajasthan = state.State;
    this.Rajasthan_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="DELHI"){    
    this.State_Delhi = state.State;
    this.Delhi_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="BIHAR"){    
    this.State_Bihar = state.State;
    this.Bihar_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="PUNJAB"){    
    this.State_Punjab = state.State;
    this.Punjab_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="HIMACHAL PRADESH"){    
    this.State_HimachalPradesh = state.State;
    this.HimachalPradesh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="CHANDIGARH"){    
    this.State_Chandigarh = state.State;
    this.Chandigarh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="JAMMU AND KASHMIR"){    
    this.State_Jammu = state.State;
    this.Jammu_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="HARYANA"){    
    this.State_Haryana = state.State;
    this.Haryana_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="UTTARAKHAND"){    
    this.State_Uttarakhand = state.State;
    this.Uttarakhand_TotalVacinated = Number(state.VacinationDone);
  }  
  else if( state.State=="MAHARASHTRA"){    
    this.State_Maharashtra = state.State;
    this.Maharashtra_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="ANDHRA PRADESH"){    
    this.State_AndhraPradesh = state.State;
    this.AndhraPradesh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="GUJARAT"){    
    this.State_Gujarat = state.State;
    this.Gujarat_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="MADHYA PRADESH"){    
    this.State_MadhyaPradesh = state.State;
    this.MadhyaPradesh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU"){    
    this.State_Dadra = state.State;
    this.Dadra_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="TAMIL NADU"){    
    this.State_TamilNadu = state.State;
    this.TamilNadu_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="PUDUCHERRY"){    
    this.State_Puducherry = state.State;
    this.Puducherry_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="TELANGANA"){    
    this.State_Telangana = state.State;
    this.Telangana_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="LADAKH"){    
    this.State_Ladakh = state.State;
    this.Ladakh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="KARNATAKA"){    
    this.State_Karnataka = state.State;
    this.Karnataka_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="KERALA"){    
    this.State_Kerala = state.State;
    this.Kerala_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="LAKSHADWEEP"){    
    this.State_Lakshadweep = state.State;
    this.Lakshadweep_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="GOA"){    
    this.State_Goa = state.State;
    this.Goa_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="MEGHALAYA"){    
    this.State_Meghalaya = state.State;
    this.Meghalaya_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="ASSAM"){    
    this.State_Assam = state.State;
    this.Assam_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="TRIPURA"){    
    this.State_Tripura = state.State;
    this.Tripura_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="WEST BENGAL"){    
    this.State_WestBengal = state.State;
    this.WestBengal_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="ARUNACHAL PRADESH"){    
    this.State_ArunachalPradesh = state.State;
    this.ArunachalPradesh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="SIKKIM"){    
    this.State_Sikkim = state.State;
    this.Sikkim_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="MANIPUR"){    
    this.State_Manipur = state.State;
    this.Manipur_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="MIZORAM"){    
    this.State_Mizoram = state.State;
    this.Mizoram_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="NAGALAND"){    
    this.State_Nagaland = state.State;
    this.Nagaland_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="CHHATTISGARH"){    
    this.State_Chhattisgarh = state.State;
    this.Chhattisgarh_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="JHARKHAND"){    
    this.State_Jharkhand = state.State;
    this.Jharkhand_TotalVacinated = Number(state.VacinationDone);
  }
  else if( state.State=="ODISHA"){    
    this.State_Odisha = state.State;
    this.Odisha_TotalVacinated = Number(state.VacinationDone);
  }
  
  this.states_data.push(temp);
}
console.log("states data------------")
console.log(this.states_data)
 this.mapReady=true
this.showchartonpageload();
  },
  (err)=>{
    console.log(err)
  }
); 

}

getMvuDashboardData()
{
  this.serv.getMvuData().subscribe((res)=>{
    console.log("mvu Data Result");
    console.log(res);
    this.allMvuData=res;
this.allMvuOperationalData = this.allMvuData.map((item:any) => Number(item['MVUs_operational'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allMvuSanctioned = this.allMvuData.map((item:any) => Number(item['MVUs_sanctioned'])).reduce((prev:any, curr:any) => prev + curr, 0);
console.log("Mvu and Operational data")
console.log(this.allMvuOperationalData);
console.log(this.allMvuSanctioned);

},
(err)=>{
  console.log(err)
}
); 
}
 numDifferentiation(value:any) {

  var val = Math.abs(value)
  if (val >= 10000000) {
    this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
  } else if (val >= 1000) {
    this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
  }
  else if (val >= 0) {
    this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
  }
  return this.valueInFormate;
}

chartButtonClick()
{
  console.log("hello")
  this.showChartBrucellosischart=true;
  this.showChartFmdchart=false;
  this.myCSSclass1=true
  this.myCSSclass=false
  console.log(this.BrucellosisVaccinationDate)
  console.log(this.totalNoOfBrucellosisVaccinationDoneArray)
  this.showBrucellosisEnrolementChart=
  {
        title: {
               show: false,
               left: 'center',
               text: 'COURSE  ENROLMENT STATICS GRAPH  ',
             },
        tooltip: {
               trigger: 'axis'
             },
             grid: { containLabel: true },
            axisLabel: {
              interval:0,
              rotate: 65,
          },
          
        xAxis: {
          type: 'category',
        
          data: this.BrucellosisVaccinationDate,
         
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
         },
          axisLine:{                 //Coordinate axis
            show:true,             //Show Axis axis or not
            onZero:false,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
        },
        },
        height: 130,
        series: [
          {
            data:this.totalNoOfBrucellosisVaccinationDoneArray,
            type: 'bar',
            color:'#8dd9cc'
  
          //  color: '#8E24AA'
          }
        ]
      };
}
  showEnrolementGraph()
  {
    this.showChartFmdchart=true
    this.showChartBrucellosischart=false
    this.myCSSclass=true
    this.myCSSclass1=false
      this.yearWiseEnroleMentChart =  {
        tooltip: {
               trigger: 'axis'
             },
      
         radius: [70, 180], 
         grid: { containLabel: true },
        xAxis: {
          type: 'category',
         
          data: this.fmdVaccinationDate,
         
        },
       
        yAxis: {
          type: 'value',
         // interval:1000000,
          // axisLabel: {
          //   interval:1000000,
          //   rotate:30
          // },
          axisLine:{                 //Coordinate axis
            show:true,             //Show Axis axis or not
            onZero:false,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
        },
          splitLine: {
            show: false
         },
        
         
       
       // nameGap: 50,
        showGrid: false,
        },
        height: 130,
        series: [
          {
            data:this.totalNoOfFmdVaccinationDoneArray,
            type: 'bar',
           // radius: ['40%', '70%'],
            color:'#8dd9cc',
           // showBackground: true,
            
            
          //  color: '#8E24AA'
          }
        ]
      
        
      };
    }


    public select(event: any) {
      this.showPieChartpageload = false;
      this.showPieChartMapclick= true;
      console.log(event)
      console.log(this.totalSateWiseTypeVacinationDataCount)

      this.totalSateWiseTypeVacinationDataCount.forEach((y:any) =>
      {
     if(y[0]==event)
     {
       this.selectState=y[1]
     }
     
      });
      var groupByTypeVacination = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalTypeVacinationStateData=groupByTypeVacination(this.selectState, 'Type_of_Vaccination')
  var totalTypeVacinationStateDataCount = Object.entries(totalTypeVacinationStateData)
  console.log(totalTypeVacinationStateDataCount)
  this.totalAggregateStateVaccinationData=[];
  var StateName = null;
if(event.message != "deselect")
{
  totalTypeVacinationStateDataCount.forEach((y:any) =>
  {
      var totalVaccination =  y[1].map((item:any) => Number(item['Total_no_of_Vaccination_done'])).reduce((prev:any, curr:any) => prev + curr, 0);

      var finalObj = Object.assign({"name": y[0]}, {"value": totalVaccination});
      this.totalAggregateStateVaccinationData.push(finalObj);
      StateName = event;

  });
}
else{
  var finalObj = Object.assign({"name": "FMD"}, {"value": this.totalAnimalCoverdInFmdWithoutNumDif}); 
  this.totalAggregateStateVaccinationData.push(finalObj);
  finalObj = Object.assign({"name": "Brucellosis"}, {"value": this.totalAnimalCoverdInBrucellosisWithoutNumDif}); 
  this.totalAggregateStateVaccinationData.push(finalObj);  
  StateName = "TOTAL VACCINATED";
}
  console.log("this.totalAggregateStateVaccinationData")
console.log(this.totalAggregateStateVaccinationData)


      this.typeOfVacinationChart = {
        title: {
          show:true,
          text: StateName,
          // text: event.selectedRowValues[0],
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          show:false
        },
        series: [
          {
           // name: 'Access From',
            type: 'pie',
            radius: '44%',
            // radius: '53%',
          //  radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateVaccinationData
          }
        ]
      };



  } 
      public geoChart: GoogleChartInterface = {
        chartType: 'GeoChart',
        dataTable: this.states_data,
        options: {
          domain:'IN',
          region: 'IN',
          colorAxis: {colors: ['#67cdcd','#67cdcd']},
         // colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
          resolution: 'provinces',
          zoom: 6,
         // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
      disableDefaultUI: true,
      //displayMode: 'text',
      defaultColor:'#67cdcd',
      backgroundColor: 'transparent',
     
      legend: 'none',
      datalessRegionColor: 'transparent',
          //'height': 600,
          'width': 850,
        }
      };
      
  public showchartonpageload() {
    this.showPieChartpageload = true;
    this.showPieChartMapclick= false;
  this.totalAggregateStateVaccinationData=[];
  var StateName = null;
  var finalObj = Object.assign({"name": "FMD"}, {"value": this.totalAnimalCoverdInFmdWithoutNumDif}); 
  this.totalAggregateStateVaccinationData.push(finalObj);
  finalObj = Object.assign({"name": "Brucellosis"}, {"value": this.totalAnimalCoverdInBrucellosisWithoutNumDif}); 
  this.totalAggregateStateVaccinationData.push(finalObj);

  StateName = "TOTAL VACCINATED";
          // var today = new Date();    
          // var pipe = new DatePipe('en-US');      
          // let ChangedFormat = pipe.transform(today, 'dd/MM/YYYY');       
          console.log("TOTAL VACCINATED")
  console.log(this.totalAggregateStateVaccinationData)
  
  
      this.typeOfVacinationChart = {
        title: {
          show:true,
          // text: event.selectedRowValues[0],
          text: StateName,
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'right'
        // },
        series: [
          {
           // name: 'Access From',
           type: 'pie',
            radius: '44%',
           // radius: ['40%', '70%'],
            avoidLabelOverlap: false,
           
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '80%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateVaccinationData
          }
        ]
      }  
  };
}
