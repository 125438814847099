<app-headerone></app-headerone>
<div class="heding-middle-text">Dairy Processing and Infrastructure Development Fund (DIDF)</div>


<div class="container" >
<div class="row">
  <div class="col-sm-0 ">
       <!-- <a href="https://dahddashboard.ndlm.co.in/assets/didf.csv" class="btn-float" >Download Sample File</a> -->
    <button (click)="download()" class="btn-float btn btn-primary"> Download Current Data</button>&nbsp;
	
<button type="button" class="btn btn-success btn-float-left" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Upload File</button>
  </div>
  <div class="col-sm-6 " style="
    margin-top: -25px;
">
    <a  routerLink="/didfundingpattern">FUNDING PATTERN AND SOURCE OF FUNDS FORM</a>
      </div> 
</div>
        <div class="row" style="border: 1px solid #b9b9b4; margin-top: 16px; border-radius: 9px;max-width: 100%;margin-left: 0px;">
          
          <div class="col-sm-12 ">
            <div class="title_container mt-1">            
              </div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
              <div class="row g-3">
                <div class="col-sm-3">
                  <label for="title" class="form-label">State</label>
                  <select class="form-select" formControlName="state" id="state">
              <option value="">Choose...</option>
              <option *ngFor="let item of globals.indianState" value="{{item.code}}">{{item.name}} </option>
            </select>
			<span class="error-message" *ngIf="error('state', 'required') && submitted">
              State is required
            </span>
                </div>
    
               
    
                <div class="col-sm-3">
                  <label for="description" class="form-label">No. of Projects</label>
                  <div class="input-group has-validation">
                
                   
                    <input type="text" class="form-control" id="nop" formControlName="nop"  placeholder="" value="" >
                  
                  </div>
				  <span class="error-message" *ngIf="error('nop', 'pattern')">
              Invalid number
            </span>
                </div>


                <!-- <div class="col-sm-3">
                    <label for="description" class="form-label"> Scheme Outlay</label>
                    <div class="input-group has-validation">
                   
                     
                      <input type="text" class="form-control" id="so" formControlName="so"  placeholder="" value="" >
                     
                    </div>
                  </div> -->



                  <!-- <div class="col-sm-3">
                    <label for="description" class="form-label">Loan Component</label>
                    <div class="input-group has-validation">
                     
                     
                      <input type="text" class="form-control" id="lc" formControlName="lc"  placeholder="" value="" >
                     
                    </div>
                  </div> -->



                  <!-- <div class="col-sm-3">
                    <label for="description" class="form-label">End Borrower Contribution</label>
                    <div class="input-group has-validation">
                     
                     
                      <input type="text" class="form-control" id="ebc" formControlName="ebc"  placeholder="" value="" >
                     
                    </div>
                  </div> -->

                  <!-- <div class="col-sm-3">
                    <label for="description" class="form-label">Interest Subvention</label>
                    <div class="input-group has-validation">
                   
                     
                      <input type="text" class="form-control" id="is" formControlName="is"  placeholder="" value="" >
                     
                    </div>
                  </div> -->

                  <div class="col-sm-3">
                    <label for="description" class="form-label">Total Project Outlay Approved</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="tpoa" formControlName="tpoa"  placeholder="" value="" >
                     
                    </div>
					<span class="error-message" *ngIf="error('tpoa', 'pattern')">
              Invalid number
            </span>
                  </div>


                  <div class="col-sm-3">
                    <label for="description" class="form-label">Total Loan Approved</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="tla" formControlName="tla"  placeholder="" value="" >
                     
                    </div>
					<span class="error-message" *ngIf="error('tla', 'pattern')">
              Invalid number
            </span>
                  </div>

                  <div class="col-sm-3">
                    <label for="description" class="form-label">Total Loan disbursed</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="tld" formControlName="tld"  placeholder="" value="" >
                     
                    </div>
					 <span class="error-message" *ngIf="error('tld', 'pattern')">
              Invalid number
            </span>
                  </div>

                  <div class="col-sm-3">
                    <label for="description" class="form-label">Milk Processing Capacity</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="mpc" formControlName="mpc"  placeholder="" value="" >
                     
                    </div>
					<span class="error-message" *ngIf="error('mpc', 'pattern')">
              Invalid number
            </span>
                  </div>


                  <div class="col-sm-3">
                    <label for="description" class="form-label">Value added and Product Capacity</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="vapc" formControlName="vapc"  placeholder="" value="" >
                     
                    </div>
					<span class="error-message" *ngIf="error('vapc', 'pattern')">
              Invalid number
            </span>
                  </div>


                  <div class="col-sm-3">
                    <label for="description" class="form-label">Drying Capacity</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="dc" formControlName="dc"  placeholder="" value="" >
                     
                    </div>
					<span class="error-message" *ngIf="error('dc', 'pattern')">
              Invalid number
            </span>
                  </div>
       

                  <div class="col-sm-3">
                    <label for="description" class="form-label">No. of Dairy Plant Refurbished/
                        Strengthened</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="ndprs" formControlName="ndprs"  placeholder="" value="" >
                     
                    </div>
					<span class="error-message" *ngIf="error('ndprs', 'pattern')">
              Invalid number
            </span>
                  </div>

                  <div class="col-sm-3">
                    <label for="description" class="form-label">Producer Member Covered<br> &nbsp;</label>
                    <div class="input-group has-validation">
                      <!-- <span class="input-group-text">@</span> -->
                     
                      <input type="text" class="form-control" id="pmc" formControlName="pmc"  placeholder="" value="" >
                     
                    </div>
					 <span class="error-message" *ngIf="error('pmc', 'pattern')">
              Invalid number
            </span>
                  </div>
       
              </div>
              <hr class="my-4">
              <div class="col-12">

                <div class="title_container mt-1">
                    <button type="submit" class="btn btn-primary btn-block text-uppercase mb-2 w-25 shadow-sm"style="
                    background-color: #2a4075; margin-left:40%"  >Add</button>
                  </div>
            
              </div>
            </form>
          </div>
       
        </div> 
        
        
 
        <div class="row mt-3" >
            <div class="col-sm-12">
              <div class="card">
                  <h5 class="card-header" style="border-color: skyblue;background-color: #2db8f10f;"> DETAILS </h5>
  
                  <div class="card-body" *ngIf="npddDetailInJson.length>0" >
                    <p-table #dt id="myTable" [value]="npddDetailInJson" styleClass="noHeader" [columns]="colsUsers" [paginator]="true" [rows]="37" [style]="{'background-color':'#cecece'}" [resizableColumns]="true">
  
                      <ng-template pTemplate="caption" >
                                <div class="ui small left icon input align-left">
                                    <input pInputText type="text" class="search-boxx" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search keyword" />
                                    <button type="button"  (click)="onUpload()" style="float:right; width: 85px; margin-left:15px;overflow: unset;box-shadow: 0 0 0px 0 #9f9b9b, 0 0 2px 0 #878682, 0 0 0px 0 #b1aeae inset;" 
                                    class="btn btn-primary">Save
                                   </button>
                                  </div>
                            </ng-template>
                        <ng-template pTemplate="header" let-columns  >
                          <tr>
                            <th *ngFor="let col of columns" pResizableColumn [style]="{'background-color':'#e5f4fe'}" [pSortableColumn]="col.field" >
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                          
                            <th style="background-color:#e5f4fe">Action</th>
                            </tr>
                            <tr>
                                </tr>
                        </ng-template>
                        <ng-template pTemplate="body"  let-npddDetailInJson let-columns="columns">
                          <tr>
                            <td *ngFor="let col of columns" class="ui-resizable-column" >
                                {{npddDetailInJson[col.field]}}
                               
                            </td>

                            <td> 
                              <button type="button" class="btn btn-danger" (click)="deleteRow(npddDetailInJson.state)">Delete</button>
                                </td>
                          </tr>
                          </ng-template>
                      </p-table>
                </div>
              </div>
            </div>
           
  
          </div>

                
   </div>

   <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)" accept=".csv" />

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button"  class="btn btn-primary" (click)="getJsonData()" data-bs-dismiss="modal" style="background-color: #2a4075;">Add</button>
        </div>
      </div>
    </div>
  </div>