import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AhidfschemeComponent } from './ahidfscheme/ahidfscheme.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DidfschemeComponent } from './didfscheme/didfscheme.component';
import { LoginComponent } from './login/login.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import { NlmschemeComponent } from './nlmscheme/nlmscheme.component';
import { NpddschemeComponent } from './npddscheme/npddscheme.component';
import { RgmschemeComponent } from './rgmscheme/rgmscheme.component';
import { SdcfposchemeComponent } from './sdcfposcheme/sdcfposcheme.component';
import { StatedashboardComponent } from './statedashboard/statedashboard.component';
import { StatewiseLhdcschemeComponent } from './statewise-lhdcscheme/statewise-lhdcscheme.component';
import { AuthenticationGuard } from './authentication.guard';
import { SdcfpoentryformComponent } from './sdcfpoentryform/sdcfpoentryform.component';
import { RgmentryformComponent } from './rgmentryform/rgmentryform.component';
import { NpddentryformComponent } from './npddentryform/npddentryform.component';
import { DidfentryformComponent } from './didfentryform/didfentryform.component';
import { LhdcpentryformComponent } from './lhdcpentryform/lhdcpentryform.component';
import { LogoutComponent } from './logout/logout.component';
import { StatewiseNlmschemeComponent } from './statewise-nlmscheme/statewise-nlmscheme.component';
import { StatewiseRgmschemeComponent } from './statewise-rgmscheme/statewise-rgmscheme.component';
import { StatewiseAhidfschemeComponent } from './statewise-ahidfscheme/statewise-ahidfscheme.component';
import { StatewiseNpddschemeComponent } from './statewise-npddscheme/statewise-npddscheme.component';
import { StatewiseDidfschemeComponent } from './statewise-didfscheme/statewise-didfscheme.component';
import { DidffundingpatternentryformComponent } from './didffundingpatternentryform/didffundingpatternentryform.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AuthGuard } from './core/auth.guard';
import { NlmentryformComponent } from './nlmentryform/nlmentryform.component';
import { NlmsubsidyentryformComponent } from './nlmsubsidyentryform/nlmsubsidyentryform.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [{
  path: '',
  component: IndexComponent,
  //component: LoginComponent,
  // canActivate: [PublicauthGuard]
},
{
  path: 'login',
  component:LoginComponent,
   //canActivate: [AuthGuard]
},
{
  path: 'mainpage',
  component: MainpageComponent,canActivate:[AuthGuard]
},
{
  path: 'dashboard',
  component: DashboardComponent,canActivate:[AuthGuard]
},
{
  path: 'NLM',
  component: NlmschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'sdcfpoentryform',
  component: SdcfpoentryformComponent,
   canActivate: [AuthGuard]
},
{
  path: 'rgmentryform',
  component: RgmentryformComponent,
  canActivate: [AuthGuard]
},
{
  path: 'changepassword',
  component: ChangepasswordComponent,
   canActivate: [AuthGuard]
},
{
  path: 'didfentryform',
  component: DidfentryformComponent,
   canActivate: [AuthGuard]
},
{
  path: 'npddentryform',
  component: NpddentryformComponent,
   canActivate: [AuthGuard]
},
{
  path: 'didfundingpattern',
  component: DidffundingpatternentryformComponent,
   canActivate: [AuthGuard]
},
{
  path: 'lhdcpentryform',
  component: LhdcpentryformComponent,
   canActivate: [AuthGuard]
},
{
   path: 'statewise-NLM',
   component: StatewiseNlmschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'AHIDF',
  component: AhidfschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'NPDD',
  component: NpddschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'DIDF',
  component: DidfschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'SDCFPO',
  component: SdcfposchemeComponent,canActivate:[AuthGuard]
},
{
  path: 'RGM',
  component: RgmschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'statewise-RGM',
  component: StatewiseRgmschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'statewise-LHDCP',
  component: StatewiseLhdcschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'statewise-AHIDF',
  component: StatewiseAhidfschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'statewise-NPDD',
  component: StatewiseNpddschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'statewise-DIDF',
  component: StatewiseDidfschemeComponent,canActivate:[AuthGuard]
},
{
  path: 'LHDCP',
  component: StatedashboardComponent,canActivate:[AuthGuard]
},
{
  path: 'nlmentryform',
  component:NlmentryformComponent,
  canActivate: [AuthGuard]
},
{
  path: 'nlmsubsidyentryform',
  component:NlmsubsidyentryformComponent,
  canActivate: [AuthGuard]
},
{
  path: 'index',
  component:IndexComponent,
  //canActivate: [AuthGuard]
},
{
  path: 'logout',
  component: LogoutComponent,
   canActivate: [AuthGuard]
},
{ path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
