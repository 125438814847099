import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../core/data.service';
import { Globals } from '../globals';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http';
@Component({
  selector: 'app-didffundingpatternentryform',
  templateUrl: './didffundingpatternentryform.component.html',
  styleUrls: ['./didffundingpatternentryform.component.scss']
})
export class DidffundingpatternentryformComponent implements OnInit {
  registerForm: any = FormGroup;
  npddDetailInJson: any = new Array()
  submitted = false;
  colsUsers: any;
  globals!: Globals;
  constructor(private formBuilder: FormBuilder, private router: Router, globals: Globals, private dataService: DataService,
    private JsonToCSVService:JsonToCSVService,public http: HttpClient) {
    this.globals = globals;
  }
  get f() { return this.registerForm.controls; }
  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if (this.submitted) {
      var finalObj = Object.assign({ "so": this.registerForm['value']['so'] },
        { "lc": this.registerForm['value']['lc'] }, { "ebc": this.registerForm['value']['ebc'] },
        { "is": this.registerForm['value']['is'] });

      this.npddDetailInJson.push(finalObj);

    }
  ///TODO - 111
    this.registerForm.patchValue({ so: '', lc: '', ebc: '', is: '', pmc: '', });
  }
  ///TODO - 111
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      so: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      lc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      ebc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      is: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      pmc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
    });
    this.initializeColumns();
  }
  initializeColumns() {
    this.colsUsers = [
      { field: 'so', header: 'Scheme Outlay' },
      { field: 'lc', header: 'Loan Component' },
      { field: 'ebc', header: 'End Borrower Contribution' },
      { field: 'is', header: 'Interest Subvention' },
    ];
  }
  getEventValue($event: any): string {
    return $event.target.value;
  }
  deleteRow(state: any) {
    console.log(state);
    for (let [i, user] of this.npddDetailInJson.entries()) {
      if (user.state === state) {
        this.npddDetailInJson.splice(i, 1);
      }
    }
    console.log(this.npddDetailInJson)
  }
  ///TODO - 111
  onUpload() {

    const data = this.npddDetailInJson;
    if (data.length > 0) {
      this.dataService.didfFundingPatternCreate(data).subscribe((result: any) => {
        //alert(result.message);
		alert("Added Successfully ");
        this.npddDetailInJson = [];
      });
    }

  }
  // -- Add by Abhishek for JSON To CSV
alldidfFundingPatternData:any
download(){
  this.getDidfFundingPatternDashboardData();
  
}
getDidfFundingPatternData() {
  return this.http.get('https://dahddashboard.ndlm.co.in/api/didfFundingPattern');
//return this.http.get('/assets/didf.json');
 }

getDidfFundingPatternDashboardData()
{
this.getDidfFundingPatternData().subscribe((res)=>{
  console.log("didf funding Data Result");
  console.log(res);
  this.alldidfFundingPatternData=res;
  this.JsonToCSVService.downloadFile(this.alldidfFundingPatternData, 'DidfFundingPattern');
},
(err)=>{
console.log(err)
}
); 
}
// ---- End Abhi Code -----

}
