<app-header></app-header>

      <div class="container" style="max-width: 1205px;">
       
        <div class="row" >
           
           

<div class="col-sm-6">
          <p style=" font-weight: 700;font-size: 14px; margin-bottom: 2px; ">State-wise Information for Animal Husbandry Infrastructure Development Fund (AHIDF)</p> 
          <p-dropdown [options]="districtsName" [(ngModel)]="selectedCity1" (onChange)="OnChangeDistrict($event)" autoWidth="false" [style]="{'width':'100%', 'border-radius': '1px'}"  [filter]="true" filterBy="name"
          [showClear]="false" placeholder="Please Select Districts" styleClass="my-dropdown">
          <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="selectedCity1">
              <div>{{selectedCity1.name}}</div>
            </div>
          </ng-template>
          <ng-template let-usertype pTemplate="item">
            <div class="country-item">
              <div>{{usertype.name}}</div>
            </div>
          </ng-template>
        </p-dropdown>
    </div> 
        

          <div class="col-sm-6">
            <div class="card" style="background-color: #005588; color: white; border-radius: 10px;"  >
                <div class="card-header border-bottom bg-transparent" style="font-weight: 700;">
                    TOP 5 PERFORMING DISTRICTS  (No. Of Projects)
                  </div>
              <div class="card-body" style="text-align: center; min-height: 250px;">
           
                <!-- <div class="container"> -->


                    <div class="row" >
                 
                        <div class="col-sm-3" style="border-right: 1px solid; width: 117px;">
                          <p  class="top-five-box-font-heading">{{topFirstPerfomanceDistrict}}</p>      
                         
                        </div>
                        <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 117px;">
                          <p class="top-five-box-font-heading"> {{topSecondPerfomanceDistrict}}</p>      
                            <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                          
                        </div>
                        <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 117px;">
                          <p  class="top-five-box-font-heading"> {{topThirdPerfomanceDistrict}}</p>    
                          <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                        
                      </div>
                      <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 113px;">
                          <p  class="top-five-box-font-heading"> {{topFourthPerfomanceDistrict}}</p>    
                          <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                       
                      </div>
                      <div class="col-sm-3 text-right" style="width: 117px;" >
                          <p  class="top-five-box-font-heading">{{topFifthPerfomanceDistrict}}</p>    
                          <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                      
                      </div>
                      </div>



                <div class="row">
                 
                  <div class="col-sm-3" style="border-right: 1px solid; width: 117px;">
                    <p class="card-text-one">No. Of Projects</p>      
                   
                         
                  </div>
                  <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 117px;">
                    <p class="card-text-one">No. Of Projects</p>      
                   
                  </div>
                  <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 117px;">
                    <p class="card-text-one">No. Of Projects</p>      
                   
                </div>
                <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 113px;">
                    <p class="card-text-one">No. Of Projects</p>      
                   
                </div>
                <div class="col-sm-3 text-right" style="width: 117px;" >
                    <p class="card-text-one">No. Of Projects</p>      
                   
                </div>
                </div>
                <div class="row">
                 
                    <div class="col-sm-3" style="border-right: 1px solid; width: 117px;">
                          
                        <h5>{{topFirstPerformingDistrictAiDone}}</h5>
                           
                    </div>
                    <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 117px;">
                        
                        <h5>{{topSecondPerformingDistrictAiDone}}</h5>
                    </div>
                    <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 117px;">
                     
                        <h5>{{topThirdPerformingDistrictAiDone}}</h5>
                  </div>
                  <div class="col-sm-2 text-right" style="border-right: 1px solid; width: 113px;">
                         
                    <h5>{{topFourthPerformingDistrictAiDone}}</h5>
                  </div>
                  <div class="col-sm-3 text-right" style="width: 117px;">
                         
                      <h5>{{topFifthPerformingDistrictAiDone}}</h5>
                  </div>
                  </div>

                <!-- </div> -->
              </div>
            </div>
          </div>


        </div>

<div class="row" style="
margin-top: -218px;
">


    <div class="col-sm-6">
        <div class="card" style="background-color: #005588; color: white; border-radius: 10px;" >
           
          <div class="card-body" >
         
            <!-- <div class="container"> -->
            <div class="row" style="border-bottom: solid 1px #8ab9e0;">
             
             
              <div class="col-sm-6" >
                <div class="row" >
                    <div class="col-sm-8" > 
                        <p class="" style="font-size: 11px; ">No. of Entrepreneurs Registered</p>
                        <!-- <p class="" style="font-size: 11px; ">Farmers Benefited</p> -->
                    </div> 
                    <div class="col-sm-4"> 
                        <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm">  {{allNoOfEntrepreneursRegistered}}</h5>
                        <!-- <h5 _ngcontent-tda-c67="" class="total-livestock-count-dark-blue-nlm"> {{totalNoOfFarmersBenefittedInNaipIII}}</h5> -->
                    </div> 
                 
                </div>
             </div>
             <div class="col-sm-6">
                <div class="row">
                    <div class=" col-sm-7" > <p class="" style="font-size: 11px; ">Project Cost</p></div> 
                    <div class=" col-sm-4">  <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm">{{allProjectCostEntrepreneursRegistered}}</h5></div> 
                 
                </div>
             </div>

            
            </div>

            <div class="row mt-5">
             
             
                <div class="col-sm-6">
                  <div class="row" >
                      <div class="col-sm-8" > 
                          <p class="" style="font-size: 11px; ">Projects Eligible by Department</p>
                         
                      </div> 
                      <div class="col-sm-4" > 
                          <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm">{{allProjectsEligibleByDepartment}}</h5>
                         
                      </div> 
                   
                  </div>
               </div>
                <div class="col-sm-6" >
                  <div class="row">
                      <div class=" col-sm-7" > <p class="" style="font-size: 12px; ">Project Cost</p></div> 
                      <div class=" col-sm-4">  <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm"> {{allProjectsCostOfEligible}}</h5></div> 
                   
                  </div>
               </div> 

              
              </div>



              <div class="row mt-3">
             
             
                <div class="col-sm-6">
                  <div class="row" >
                      <div class="col-sm-8" > 
                          <p class="" style="font-size: 11px; ">Projects Sanctioned by Bank</p>
                         
                      </div> 
                      <div class="col-sm-4" > 
                          <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm">{{allProjectsSanctionedByBank}}</h5>
                         
                      </div> 
                   
                  </div>
               </div>
                <div class="col-sm-6" >
                  <div class="row">
                      <div class=" col-sm-7" > <p class="" style="font-size: 12px; ">Project Cost</p></div> 
                      <div class=" col-sm-4">  <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm"> {{allProjectCostOfSanctionedByBank}}</h5></div> 
                   
                  </div>
               </div> 

              
              </div>




           <div class="row mt-3">
             
             
                <div class="col-sm-6">
                  <div class="row" >
                      <div class="col-sm-8" > 
                          <!-- <p class="" style="font-size: 11px; ">Approved for Interest Subvention</p>-->
                         
                      </div> 
                      <div class="col-sm-4" > 
                         <!-- <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm">{{allApprovedForInterestSubvention}}</h5>-->
                         
                      </div> 
                   
                  </div>
               </div>
                <div class="col-sm-6" >
                  <div class="row">
                     <!-- <div class=" col-sm-7" > <p class="" style="font-size: 12px; ">Project Cost</p></div> -->
                      <!--<div class=" col-sm-4">  <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm"> {{allInterestSubventionAmount}}</h5></div> -->
                   
                  </div>
               </div> 

              
              </div>

            
            <!-- </div> -->
          </div>
        </div>
      </div>
</div>


        <div class="heding-middle">
            <div class="row">
           
          
              <div class="col">
                <div class="card" style="border-radius: 10px;">
                  <div class="card-body">
                   <p class="box-heading">SUCCESS STORIES</p>
                                           
                    <!-- <div class="container"> -->
                        <div class="row" style="
                        height: 278px;
                    ">
                          <div class="col-sm-12">
                                   
        <owl-carousel-o [options]="customOptions">
   
            <ng-container *ngFor="let slide of slides">

              <ng-template carouselSlide [id]="slide.id">
                <div class="col">
                   <div class="card" style="
                   border: 0px;
               " >
                  
                     <div class="card-body" >
                        
                         <!-- <div class="container"> -->
                                                     <div class="row" *ngIf="slide.id == '1'">
                          
                            <div class="col-sm-2">
                             
                               <div  class="icon-box"style=" margin-top: 15px;">
                                 <span  class="registrations-today-icon"><img  src="/assets/imgs/background/05.png" style="height: 70px;" alt=""></span></div>
                             
                            </div>
                            <div class="col-sm-10 text-right" >
                             <span class="box-heading-two">Jyotirling Milk Foods</span>
                              <p style="font-size: 14px;">Jyotirling Milk Foods has set up a Dairy Processing & Value Addition Plant of capacity 60000 LPD at Kolhapur, Maharashtra. The project cost was INR 1.46 Cr and employment opportunities for 26 people have been generated.</p>
                            </div>
                          </div>
                          <div class="row" *ngIf="slide.id == '2'">
                           
                           <div class="col-sm-2">
                            
                              <div  class="icon-box"style=" margin-top: 15px;">
                                <span  class="registrations-today-icon"><img  src="/assets/imgs/background/06.png" style="height: 70px;" alt=""></span></div>
                            
                           </div>
                           <div class="col-sm-10 text-right" >
                            <span class="box-heading-two">Narmada Milk and Milk Products</span>
                             <p style="font-size: 14px;">Narmada Milk and Milk Products has set up a Dairy Processing and Value Addition plant of capacity 50000 LPD at Bhopal, Madhya Pradesh. The project cost was INR 9.22Cr. and employment opportunities for 50 people have been generated.</p>
                           </div>
                         </div>
                         <!-- </div> -->
                       </div>               
        </div>
      </div>
    </ng-template>
</ng-container>
   
</owl-carousel-o>
                          </div>
                          
                          </div>
                  </div>
                </div>
              </div>
              </div>

        </div>


<div style="margin-top: -351px;">
        <div class="row " >
            <div class="col-sm-6">
              <div class="" style="border-radius: 10px;" >
               
                <div class=""  >
                    <div class="row" style="
                    padding: 7px;
                ">
                    <div class="col">
                      <div class="card" style="border-radius: 10px;" >
                        <div class="card-body">
                         <p class="box-heading"> DAIRY PROCESSING AND VALUE ADDITION INFRASTRUCTURE</p>
                          <!-- <div class="container"> -->
                            <div class="row ">
                           
                              <div class="col-sm-4 mt-0">
                                <p class="card-text-one"> No. of Projects </p>      
                               
                                <p class="total-livestock-count-blue-nlm-one mb-0">{{dairyProcessingValueAdditionSchemeName}}</p>
                              </div>
                              <div class="col-sm-4 text-right mt-0">
                                <p class="card-text-one" > Project Cost &nbsp;&nbsp;</p>      
                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                  <p class="total-livestock-count-green-nlm-one mb-0" style="
                                  font-size: 12px;
                                  height: 24px;
                                  font-weight: 800;
                              ">{{dairyProcessingValueAdditionprojectCost}}</p>
                              </div>
                              <div class="col-sm-4 text-right mt-0">
                                <p class="card-text-one"> Loan Amount</p>    
                                <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                <p class="total-livestock-count-green-nlm-one mb-0" style="
                                font-size: 12px;
                                height: 24px;
                                font-weight: 800;
                            ">{{dairyProcessingValueAdditionloanAmount}}</p>
                            </div>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>
                      </div>


                      <div class="col">
                        <div class="card" style="border-radius: 10px;">
                          <div class="card-body">
                           <p class="box-heading"> MEAT PROCESSING AND VALUE ADDITION INFRASTRUCTURE</p>
                            <!-- <div class="container"> -->
                              <div class="row">
                             
                                <div class="col-sm-4">
                                  <p class="card-text-one"> No. of Projects</p>      
                                 
                                  <p class="total-livestock-count-blue-nlm-one mb-0">{{meatProcessingValueAdditionSchemeName}}</p>
                                </div>
                                <div class="col-sm-4 text-right">
                                  <p class="card-text-one"> Project Cost &nbsp;&nbsp;</p>      
                                    <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                    <p class="total-livestock-count-green-nlm-one mb-0">{{meatProcessingValueAdditionprojectCost}}</p>
                                </div>
                                <div class="col-sm-4 text-right">
                                  <p class="card-text-one"> Loan Amount</p>    
                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                  <p class="total-livestock-count-green-nlm-one mb-0">{{meatProcessingValueAdditionloanAmount}}</p>
                              </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>



              <div class="row " style="margin-top: -8px;" >
                <div class="col-sm-6">
                  <div class="" style="border-radius: 10px;" >
                   
                    <div class=""  >
                        <div class="row" style="
                        padding: 7px;
                    ">
                                     <div class="col">
                                      <div class="card" style="border-radius: 10px;">
                                        <div class="card-body">
                                         <p class="box-heading"> ANIMAL FEED PLANT</p>
                                          <!-- <div class="container"> -->
                                            <div class="row mt-4">
                                       
                                              <div class="col-sm-4 mt-3 ">
                                                <p class="card-text-one"> No. of Projects</p>      
                                               
                                                <p class="total-livestock-count-blue-nlm-one mb-0">{{animalFeedPlantSchemeName}}</p>
                                              </div>
                                              <div class="col-sm-4 text-right mt-3 ">
                                                <p class="card-text-one"> Project Cost &nbsp; &nbsp;</p>      
                                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                  <p class="total-livestock-count-green-nlm-one mb-0" style="
                                                  font-size: 12px;
                                                  height: 24px;
                                                  font-weight: 800;
                                              ">{{animalFeedPlantprojectCost}}</p>
                                              </div>
                                              <div class="col-sm-4 text-right mt-3">
                                                <p class="card-text-one"> Loan Amount</p>    
                                                <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                <p class="total-livestock-count-green-nlm-one mb-0" style="
                                                font-size: 12px;
                                                height: 24px;
                                                font-weight: 800;
                                            ">{{animalFeedPlantloanAmount}}</p>
                                            </div>
                                            </div>
                                        </div>
                                      </div>
                                      </div>


                     
                                      <div class="col-sm-6">
                                        <div class="card" style="border-radius: 10px;">
                                          <div class="card-body">
                                           <p class="box-heading">BREED IMPROVE TECHNOLOGY & BREED MULTIPLICATION FARM</p>
                                                                   
                                            <!-- <div class="container"> -->
                                              <div class="row">
                                         
                                                <div class="col-sm-4">
                                                  <p class="card-text-one"> No. of Projects</p>      
                                                 
                                                  <p class="total-livestock-count-blue-nlm-one mb-0">{{breedimprovementTechnologyandBreedMultiplicationfarmSchemeName}}</p>
                                                </div>
                                                <div class="col-sm-4 text-right">
                                                  <p class="card-text-one"> Project Cost &nbsp;&nbsp;</p>      
                                                    <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                    <p class="total-livestock-count-green-nlm-one mb-0">{{breedimprovementTechnologyandBreedMultiplicationfarmprojectCost}}</p>
                                                </div>
                                                <div class="col-sm-4 text-right">
                                                  <p class="card-text-one"> Loan Amount</p>    
                                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                  <p class="total-livestock-count-green-nlm-one mb-0">{{breedimprovementTechnologyandBreedMultiplicationfarmloanAmount}}</p>
                                              </div>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
      





                        <div class="row " style="margin-top: -8px;" >
                          <div class="col-sm-6">
                            <div class="" style="border-radius: 10px;" >
                             
                              <div class=""  >
                                  <div class="row" style="
                                  padding: 7px;
                              ">
                                               <div class="col">
                                                <div class="card" style="border-radius: 10px;" >
                                                  <div class="card-body" style="
                                                  height: 154px;
                                              ">
                                                   <p class="box-heading"> SETTING UP OF VETERINARY VACCINE & DRUGS UNITS</p>
                                                    <!-- <div class="container"> -->
                                                      <div class="row">
                                                   
                                                        <div class="col-sm-4">
                                                          <p class="card-text-one"> No. of Projects</p>      
                                                         
                                                          <p class="total-livestock-count-blue-nlm-one mb-0">0</p>
                                                        </div>
                                                        <div class="col-sm-4 text-right">
                                                          <p class="card-text-one"> Project Cost &nbsp; &nbsp;</p>      
                                                            <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                            <p class="total-livestock-count-green-nlm-one mb-0">0</p>
                                                        </div>
                                                        <div class="col-sm-4 text-right">
                                                          <p class="card-text-one"> Loan Amount</p>    
                                                          <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                          <p class="total-livestock-count-green-nlm-one mb-0">0</p>
                                                      </div>
                                                      </div>
                                                    <!-- </div> -->
                                                  </div>
                                                </div>
                                                </div>
          
          
                               
                                                <div class="col-sm-6">
                                                  <div class="card" style="border-radius: 10px;" >
                                                    <div class="card-body" style="
                                                    height: 154px;
                                                ">
                                                     <p class="box-heading"> ANIMAL WASTE TO WEALTH MANAGEMENT</p>
                                                      <!-- <div class="container"> -->
                                                        <div class="row">
                                                     
                                                          <div class="col-sm-4">
                                                            <p class="card-text-one"> No. of Projects &nbsp;&nbsp;</p>      
                                                           
                                                            <p class="total-livestock-count-blue-nlm-one mb-0">{{AnimalWastetoWealthManagementSchemeName}}</p>
                                                          </div>
                                                          <div class="col-sm-4 text-right">
                                                            <p class="card-text-one"> Project Cost &nbsp;&nbsp;</p>      
                                                              <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                              <p class="total-livestock-count-green-nlm-one mb-0">{{AnimalWastetoWealthManagementprojectCost}}</p>
                                                          </div>
                                                          <div class="col-sm-4 text-right">
                                                            <p class="card-text-one"> Loan Amount</p>    
                                                            <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                                            <p class="total-livestock-count-green-nlm-one mb-0">{{AnimalWastetoWealthManagementloanAmount}}</p>
                                                        </div>
                                                        </div>
                                                      <!-- </div> -->
                                                    </div>
                                                  </div>
                                                </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>



                                </div>                     
          <!-- <div class="heding-middle-one">STATE WISE RASHTRIYA GOKUL MISSION DATA</div> -->
          <!-- <circle-progress [percent]="statePercent" [radius]="60"  [outerStrokeWidth]="15" [innerStrokeWidth]="10"
  [outerStrokeColor]="'#3777BE'" [innerStrokeColor]="'#7f9fc1'" [animation]="true" [animationDuration]="300" style="
  width: 20px; margin-left: 75%;"
></circle-progress> -->
<div class="row ">
                       
  <div class="col-sm-8" style="
  padding-left: 243px;
  padding-top: -8px;
  margin-top: -25px;
">
    <google-chart [data]="geoChart" (chartSelect)='select($event)'  *ngIf=mapReady></google-chart>
    
  </div>
  <div class="col-sm-4">
    <div echarts class="demo-chart" [ngClass]="{'graph-border' : typeOfVacinationChart}"
[options]="typeOfVacinationChart"></div>
    
  </div>
 
</div>
 
</div>



   


         

      