<app-headerone></app-headerone>
<div class="vh-50 d-flex justify-content-center align-items-center">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-8 col-lg-8">
        <div class="card bg-white ">
          <div class="card-body p-3 ">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
              <h3 class="fw-bold  ">Change Password</h3>
              <div *ngIf="credentialdata" style="font-size: 80%;color: #dc3545; margin-bottom: 5px;" >{{credentialdata}} </div>
              <!--  <div class="form-group mb-4">
                                  <div style="font-size: 80%;
                                  color: #dc3545; margin-bottom: 5px;" >{{credentialdata}} </div>
                                  <p>User Name</p>
                                  <input id="inputUsename" [(ngModel)]="userName" formControlName="usename" type="hidden" >
                                  <input  type="text" value="{{userName}}"  [disabled]="isDisabled"   placeholder="Usename " required="" autofocus="" class="form-control  px-4 ">
                                  <div *ngIf="submitted && f.usename.errors" class="invalid-feedback">
                                      <div *ngIf="f.usename.errors.required">User Name is required</div>
                                    

                                   </div>
                              </div> -->
              <div class="form-group mb-4">
                <p>Old Password</p>
                <input id="inputPassword" [type]="show ? 'text' : 'password'" formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Old Password" required=""
                  class="form-control  px-4 text-primary">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Old password is Required</div>
                </div>
              </div>

              <div class="form-group mb-4">
                <p>New Password</p>
                <input id="inputPassword" [type]="show ? 'text' : 'password'" formControlName="newpassword"
                  [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }" placeholder="New Password" required=""
                  class="form-control  px-4 text-primary">
				  <input  type="checkbox" (click)="password()"> {{show ? 'Hide Password' : 'Show Password'}}
                <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
                  <div *ngIf="f.newpassword.errors.required">New password is Required</div>
                </div>
              </div>

              <div class="d-grid gap-2 col-6 mx-auto">
                <button type="submit" class="btn btn-primary" style="background-color: #273f78;">Save</button>
              </div>
              <div class="text-center d-flex justify-content-between mt-4"></div>
            </form>
            <div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>