import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

///TODO - 111

@Injectable({ providedIn: 'root' })
export class DataService {

    constructor(private http: HttpClient) { }

    /// TODO : new file

    npddImport(fileName: string) {
        return this.http.get<any>(`${environment.apiURL}
        02npdd/import/${fileName}`);
    }

    npddCreate(data: any) {
        return this.http.post(`${environment.apiURL}npdd/create`, data);
    }

    didfCreate(data: any) {
        return this.http.post(`${environment.apiURL}didf/create`, data);
    }

    
    didfFundingPatternCreate(data: any) {
        return this.http.post(`${environment.apiURL}didfFundingPattern/create`, data);
    }


    rgmCreate(data: any) {
        return this.http.post(`${environment.apiURL}rgm/create`, data);
    }

    mvuCreate(data: any) {
        return this.http.post(`${environment.apiURL}mvu/create`, data);
    }

    sdcfpoCreate(noofmilk: string, workingcapital: string, interestsubvention: string) {
        return this.http.post(`${environment.apiURL}sdcfpo/create`, {
            cooperatives: noofmilk,
            capitalLoan: workingcapital,
            inSubvention: interestsubvention
        });
    }

    changePassword(oldPwd: string, password: string) {
        return this.http.post<any>(`${environment.apiURL}change-password`, { oldPassword: oldPwd, password: password });
    }
nlmCreate(data: any) {
        return this.http.post(`${environment.apiURL}nlm/create`, data);
    }
	 nlmsubsidyCreate(subsidyamount: string) {
        return this.http.post(`${environment.apiURL}nlmsubsidy/create`, {
            subsidyamt: subsidyamount
        });
    }

}
