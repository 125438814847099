<app-header></app-header>
 <div class="btn-float-left">&nbsp;&nbsp; Last Updated:{{lastDateFromArray}}</div>
      <div class="heding-middle-text">Dairy Processing and Infrastructure Development Fund (DIDF)</div>
      <div class="container" style="max-width: 1205px;">
       
        <div class="row" >
          <div class="col-sm-3">
            <div class="card" style="background-color: #005588; color: white; border-radius: 10px;" >
              <div class="card-header border-bottom bg-transparent" style="font-weight: 700; text-align: center;" >
               SUMMARY
              </div>
              <div class="card-body" style="padding: 60px; min-height: 497px;" >
             
                <!-- <div class="container"> -->
  
                <div class="row">
                 
                    <div class="col-sm" >
                          <div class="row" >
                            <div class="col-sm mt-2" style="text-align: center;">
							<br><br><br>
                              <p>No. of Projects</p> 
                            <h5 class="card-title mt-3 top-five-states"> {{noofProjects}}</h5>
                          </div>
                         
                       
                            </div>
                         <hr>

                           
                                <div class="row" >
                                    <div class="col-sm mt-4" style="text-align: center;">
                                      <p>No. of States/UTs</p> 
                                    <h5 class="card-title mt-2 top-five-states">  {{stateLength}}</h5>
                                  </div>
                                 
                                 
                                    </div>
                        
                      </div>
                 

           
                </div>
                

                
                <!-- </div> -->
              </div>
            </div>
          </div>
    
      
    
    
          <div class="col-sm-6" style="padding: 0px;">
            <div class="card" style="border-radius: 10px;">
              <div class="card-body">
               <p class="box-heading" style="text-align: center;"> FUNDING PATTERN AND SOURCE OF FUND</p >
                <!-- <div class="container"> -->
                    <div class="row">
                        <div class="col-sm-3">
             <p class="card-text-one">Scheme Outlay</p>
                        
                        </div>
                        <div class="col-sm-3 text-right">
                            <p class="card-text-one">Loan Component<br> (from NABARD/NDDB)</p>
                            
                        </div>
                        <div class="col-sm-3 text-right">
                            <p class="card-text-one"> End Borrower Contribution</p>
                           
                        </div>
                        <div class="col-sm-3 text-right">
                            <p class="card-text-one">  Interest Subvention  &nbsp;</p>
                            <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                          
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-3">
                          <h5 class="card-title mt-3 total-livestock-count-blue" > {{SchemeOutlay}} Cr</h5>
                        </div>
                        <div class="col-sm-3 text-right">
                          
                            <h5 class="card-title mt-3 total-livestock-count-green" > {{LoanComponentfromNABARDNDDB}} Cr</h5>
                        </div>
                        <div class="col-sm-3 text-right">
                          
                            <h5 class="card-title mt-3 total-livestock-count-green"> {{EndBorrowerContribution}} Cr</h5>
                        </div>
                        <div class="col-sm-3 text-right">
                            
                            <h5 class="card-title mt-3 total-livestock-count-green" > {{InterestSubvention}} Cr</h5>
                        </div>
                      </div>
              </div>
            </div>
          </div>
    

          
    
          <div class="col-sm-3">
            <div class="card" style="background-color: #005588; color: white; border-radius: 10px;" >
              <div class="card-header border-bottom bg-transparent" style="font-weight: 700; text-align: center;" >
                TOP FIVE  STATES <br>(Loan Sanctioned)
              </div>
              <div class="card-body">
           
                <!-- <div class="container"> -->
                    <div class="row mt-1" >
                       
                        <div class="col-sm-12" style="text-align: center; min-height: 65px;"  >
                          <h6 class="top-five-box-heading mb-1"> {{topFistState}}</h6>
                          
                          <h5>{{topFistTotalLoanApprovedState}} Cr</h5>                         
                        </div>
                      
                       
                      </div>
                      <hr>            
                      <div class="row " >
                       
                        <div class="col-sm-12 mt-2 " style="text-align: center; min-height: 65px;"  >
                          <h6 class="top-five-box-heading mb-1">{{topSecandState}} </h6>
                        
                          <h5>{{topSencandTotalLoanApprovedState}} Cr</h5>                         
                        </div>
                      
                       
                      </div>
                      <hr>    
                      <div class="row " >
                       
                        <div class="col-sm-12 mt-2" style="text-align: center; min-height: 65px;"  >
                          <h6 class="top-five-box-heading mb-1">{{topTirdState}}</h6>
                          
                          <h5>{{topThirdTotalLoanApprovedState}} Cr</h5>                         
                        </div>
                      
                       
                      </div>
                      <hr>    
                      <div class="row " >
                       
                        <div class="col-sm-12 mt-2" style="text-align: center;"  >
                          <h6 class="top-five-box-heading mb-1">{{topFourthState}} </h6>
                         
                          <h5>{{topFourthTotalLoanApprovedState}} Cr</h5>                         
                        </div>
                      
                       
                      </div>
                      <hr>    
                      <div class="row " >
                       
                        <div class="col-sm-12 mt" style="text-align: center; "  >
                          <h6 class="top-five-box-heading mb-1">{{topFiveState}}</h6>
                         
                          <h5>{{topFiveTotalLoanApprovedState}} Cr</h5>                         
                        </div>
                      
                       
                      </div>
                     
              </div>
            </div>
          </div>
        </div>
        <div class="heding-middle-two">
            <div class="row">
                <div class="col-sm-12" style="padding: 0px;">
                  <div class="card" style="border-radius: 10px;" >
                    <div class="card" style="border-radius: 10px;">
                        <div class="card-body">
                         <p class="box-heading " style="text-align: center;"> FINANCIAL PROGRESS</p>
                          <!-- <div class="container"> -->
                              <div class="row">
                                  <div class="col-sm-4">
                       <p class="card-text-one">Total Project Outlay Approved</p>
                             
                                  </div>
                                  <div class="col-sm-4 text-right">
                                      <p class="card-text-one">Total Loan Approved </p>
                                   
                                  </div>
                                  <div class="col-sm-4 text-right">
                                      <p class="card-text-one">Total Loan Disbursed</p>
                                   
                                  </div>
                                  
                                </div>

                                <div class="row">
                                  <div class="col-sm-4">
                     
                                 <h5 class="card-title total-livestock-count-blue mt-4 ">{{totalProjectOutlayApproved}} Cr</h5>
                                  </div>
                                  <div class="col-sm-4 text-right">
                                    
                                 <h5 class="card-title total-livestock-count-green mt-4">{{totalLoanApproved}} Cr</h5>
                                  </div>
                                  <div class="col-sm-4 text-right">
                                     
                                      <h5 class="card-title total-livestock-count-green mt-4">{{tloanDisbured}} Cr</h5>
                                  </div>
                                  
                                </div>

                        </div>
                      </div>
                  </div>
                </div>
          
              </div>
  
        </div>

          
        <div class="heding-middle-three">
            <div class="row">
                <div class="col-sm-12" style="padding: 0px;">
                  <div class="card" style="border-radius: 10px;" >
                    <div class="card" style="border-radius: 10px;">
                        <div class="card-body">
                         <p class="box-heading" style="text-align: center;"> PHYSICAL PROGRESS</p>
                          <!-- <div class="container"> -->
                        
      
                            <div class="row">
                       
                              <div class="col-sm-3" style="width: 117px;">
                                <p class="card-text-one">Milk Processing Capacity<br> (In LLPD)</p>      
                              
                              </div>
                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                <p class="card-text-one">Value added and Product Capacity (In LLPD)</p>      
                              
                              </div>
                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                <p class="card-text-one">Drying Capacity<br> (In MTPD)</p>      
                               
                            </div>
                            <div class="col-sm-2 text-right" style=" width: 117px;">
                                <p class="card-text-one">No. of Dairy Plant Refurbished/<br>Strengthened</p>      
                              
                            </div>
                            <div class="col-sm-3 text-right" style="width: 113px;" >
                                <p class="card-text-one">Producer Members Covered</p>      
                               
                            </div>
                            </div>


                            <div class="row">
                       
                              <div class="col-sm-3" style=" width: 117px;">
                                   
                                <h5 class="card-title  total-livestock-count-blue">{{milkProcessingCapacityInLLPD}} </h5>
                                     
                              </div>
                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                     
                                <h5 class="card-title total-livestock-count-green" >{{valueaddedandProductCapacityInLLPD}}</h5>
                              </div>
                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                   
                                <h5 class="card-title total-livestock-count-green" >{{dryingCapacityInMTPD}}</h5>
                            </div>
                            <div class="col-sm-2 text-right" style=" width: 117px;">
                                     
                                <h5 class="card-title total-livestock-count-green" >{{noofDairyPlantRefurbishedStrengthened}}</h5>
                            </div>
                            <div class="col-sm-3 text-right" style="width: 113px;" >
                                   
                                <h5 class="card-title  total-livestock-count-green" >{{producerMemberCovered}}</h5>
                            </div>
                            </div>
      
                        </div>
                      </div>
                  </div>
                </div>
          
              </div>
  
        </div>
        <div style="
        padding: 10px 225px;
        margin-top: -60px; visibility: hidden;
        ">
        
            <google-chart [data]="geoChart" *ngIf=mapReady></google-chart>
        </div>
     
<div class="bg-img">
    <div class="row" style="margin-top:35px" >
        
        <span class="box-heading-one">SUCCESS STORIES</span>
        <owl-carousel-o [options]="customOptions">
   
            <ng-container *ngFor="let slide of slides">

              <ng-template carouselSlide [id]="slide.id">
                <div class="col" style="
                margin-top: 5px;
            ">
                   <div class="card" style="border-radius: 20px;">
                  
                     <div class="card-body" style="height: 135px; ">
                        
                         <!-- <div class="container"> -->
                      <div class="row" *ngIf="slide.id == '1'">
                          
                            <div class="col-sm-2">
                             
                               <div  class="icon-box"style=" margin-top: 15px;">
                                 <span  class="registrations-today-icon"><img  src="/assets/imgs/background/04.png" style="height: 70px;" alt=""></span></div>
                             
                            </div>
                            <div class="col-sm-10 text-right" >
                             <span class="box-heading-two">Ropar District Cooperative Milk Producers’ Union Prospers Under DIDF</span>
                              <p style="font-size: 14px;">A new state-of-the-art fermented product plant was established (capacity 3.70 LLPD). It is paying a better price to its producer members - Rs. 39/kg (2021-22) compared to Rs. 33.71/kg (2016-17)</p>
                            </div>
                          </div>
                          <div class="row" *ngIf="slide.id == '2'">
                           
                           <div class="col-sm-2">
                            
                              <div  class="icon-box"style=" margin-top: 15px;">
                                <span  class="registrations-today-icon"><img  src="/assets/imgs/background/06.png" style="height: 70px;" alt=""></span></div>
                            
                           </div>
                           <div class="col-sm-10 text-right" >
                            <span class="box-heading-two">Panchmahal Milk Union Prospers Under DIDF</span>
                             <p style="font-size: 14px;">State-of-the-art Liquid Milk (6 LLPD) & Product Plant of 7.50 LLPD was established. The Union paid Rs. 741/kg fat (2021-22) to its producers, compared to Rs. 718/kg fat (2020-21).</p>
                           </div>
                         </div>
                         <!-- </div> -->
                       </div>               
        </div>
      </div>
    </ng-template>
</ng-container>
   
</owl-carousel-o>
    


    </div>
</div>


</div>

   


         

      