import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { User } from './user';
import { environment } from 'src/environments/environment';
///TODO - 111
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    public refreshTokenTimeout: any;
    public storageName: string = "_data";

    constructor(private router: Router, private http: HttpClient, @Inject('LOCALSTORAGE') private localStorage: Storage) {
        const userData = this.getUserData();
        this.userSubject = new BehaviorSubject<User>(userData);
        this.user = this.userSubject.asObservable();
    }
    public get userValue(): User {
        return this.userSubject.value;
    }
    getUserData() {
        const userData = localStorage.getItem(this.storageName) || sessionStorage.getItem(this.storageName);
        return JSON.parse(userData!)
    }
    saveUserData(user: any) {

        const rememberMe = this.localStorage.getItem('rememberMe') || 'no';
        if (rememberMe === 'yes') {
            this.localStorage.setItem(this.storageName, JSON.stringify(user));
        } else {
            sessionStorage.setItem(this.storageName, JSON.stringify(user));
        }
    }
    public getRememberMe() {
        return this.localStorage.getItem('rememberMe') || 'no';
    }

    logout(): void {
        this.localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/']);
    }
    login(email: string, password: string, rememberMe: boolean) {
        return this.http.post<any>(`${environment.apiURL}login`, { email, password }, { withCredentials: true })
            .pipe(map(output => {
                var user = new User({
                    id: output.data.userId,
                    fullName: output.data.email,
                    authData: output.data.authData,
                    role: output.data.role,
                });
                this.localStorage.setItem('rememberMe', rememberMe ? 'yes' : 'no');
                this.saveUserData(user);
                this.userSubject.next(user);
                return user;
            }));
    }


    getCurrentUser(): any {
        const userData = this.getUserData();
        if (userData !== null) {
            return {
                token: userData.authData,
                id: userData.id,
                role: userData.role,
                fullName: userData.fullName
            };
        }
    }

}
