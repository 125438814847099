import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { DataService } from '../core/data.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  userLoginArray: any = new Array()
  loginUserData: any = new Array()
  credentialdata: any
  count: any
  adminRole: boolean = false;
  // csvUploadTime: any;
  userLogin: any;
  userName: any;
  isDisabled: boolean = true;
  constructor(private formBuilder: FormBuilder, private router: Router, private dataService: DataService) { }
  registerForm: any = FormGroup;
  submitted = false;
  show: boolean = false;


  //Add user form actions
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    console.log("hello")
    // this.router.navigateByUrl("/mainpage");
    if (this.submitted) {
      this.userLogin = '';

      console.log(this.registerForm['value'])


      const OldPassword = this.registerForm.value.password;
      const newPassword = this.registerForm.value.newpassword;
      this.dataService.changePassword(OldPassword, newPassword).pipe(first()).subscribe({
        next: (data) => {
          this.router.navigateByUrl("/dashboard");
        },
        error: (err) => {
          console.log("err", err.error);
          this.credentialdata = err.error.message;
        }
      });




    }

  }


  ngOnInit(): void {
    //login form
    //Add User form validations

    this.userName = sessionStorage.getItem('userName')
    console.log(this.userName)
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
    });

  }
 password() {
    this.show = !this.show;
}


}
