import { Component, OnInit } from '@angular/core';
 ///TODO - 111
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
///TODO - 111
import { first } from 'rxjs';
import { AuthenticationService } from '../core/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userLoginArray: any = new Array()
  loginUserData: any = new Array()
  credentialdata: any
  count: any
  adminRole: boolean = false;
  // csvUploadTime: any;
  userLogin: any;
  constructor(private formBuilder: FormBuilder, private router: Router, public authService: AuthenticationService) { }
  registerForm: any = FormGroup;
  submitted = false;

  //Add user form actions
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    if (this.submitted) {
      this.userLogin = '';

      ///TODO - 111
      const username = this.registerForm.value.usename;
      const password = this.registerForm.value.password;
      this.authService.login(username, password, false).pipe(first()).subscribe({
        next: (data) => {
          if (data.role === "superadmin") {
		  this.router.navigateByUrl("/dashboard");
            //this.router.navigateByUrl("/mainpage");
          }
          else if (data.role === "lhdcp") {
            this.router.navigateByUrl("/lhdcpentryform");
          }
          else if (data.role === "rgm") {
            this.router.navigateByUrl("/rgmentryform");
          }
          else if (data.role === "didf") {
            this.router.navigateByUrl("/didfentryform");
          }
          else if (data.role === "npdd") {
            this.router.navigateByUrl("/npddentryform");
          }
          else if (data.role === "sdcfpo") {
            this.router.navigateByUrl("/sdcfpoentryform");
          }
		  else if (data.role === "nlm") {
            this.router.navigateByUrl("/nlmentryform");
          }
        },
        error: (err) => {
          this.credentialdata = "The username or password you entered isn't correct."
        }
      });



    }

  }

  ///TODO - 111
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      usename: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }


}
