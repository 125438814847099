import { delay, map } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';

///TODO - 111
export class User {
    id?: number;
    fullName?: string;
    authData?: string;
    role?: string;
    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.fullName = data.fullName;
        this.authData =data.authData;
        this.role =data.role;
    }
}