<app-header></app-header>

      <div class="container" style="max-width: 1205px;">
       
        <div class="row" >
           
           

<div class="col-sm-6">
          <p style=" font-weight: 700;font-size: 14px; margin-bottom: 2px; margin-left: 5px; ">State-wise Information for National Programme For Dairy Development</p> 
          <!-- <p-dropdown [options]="districtsName" [(ngModel)]="selectedCity1" (onChange)="OnChangeDistrict($event)" autoWidth="false" [style]="{'width':'100%', 'border-radius': '1px'}"  [filter]="true" filterBy="name"
          [showClear]="false" placeholder="Please Select Districts" styleClass="my-dropdown">
          <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="selectedCity1">
              <div></div>
            </div>
          </ng-template>
          <ng-template let-usertype pTemplate="item">
            <div class="country-item">
              <div></div>
            </div>
          </ng-template>
        </p-dropdown> -->
    </div> 
        

    <div class="col-sm-6">
        <div class="card" style="background-color: #005588; color: white; border-radius: 10px;" >
           
          <div class="card-body" >
         
            <!-- <div class="container"> -->
            <div class="row">
              <div class="col-sm-6" >
                <div class="row" >
                    <div class="col-sm-8" > 
                        <p class="" style="font-size: 11px; ">Number of Projects Sanctioned</p>
                        <!-- <p class="" style="font-size: 11px; ">Farmers Benefited</p> -->
                    </div> 
                    <div class="col-sm-4"> 
                        <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm">  {{allTotalNoofprojectssanctioned}}</h5>
                        <!-- <h5 _ngcontent-tda-c67="" class="total-livestock-count-dark-blue-nlm"> {{totalNoOfFarmersBenefittedInNaipIII}}</h5> -->
                    </div> 
                 
                </div>
             </div>        
            </div>  
            <!-- </div> -->
          </div>
        </div>
      </div>


        </div>




        <div class="heding-middle">
            <div class="row">
           
          
              <div class="col">
                <div class="card" style="border-radius: 10px;">
                  <div class="card-body">
                   <p class="box-heading">SUCCESS STORIES</p>
                                           
                    <!-- <div class="container"> -->
                        <div class="row" style="
                        height: 278px;
                    ">
                          <div class="col-sm-12">
                                   
        <owl-carousel-o [options]="customOptions">
   
            <ng-container *ngFor="let slide of slides">

              <ng-template carouselSlide [id]="slide.id">
                <div class="col">
                   <div class="card" style="
                   border: 0px;
               " >
                  
                     <div class="card-body" >
                        
                         <!-- <div class="container"> -->
                               <div class="row" *ngIf="slide.id == '1'">
                          
                            <div class="col-sm-2">
                             
                               <div  class="icon-box"style=" margin-top: 15px;">
                                 <span  class="registrations-today-icon"><img  src="/assets/imgs/background/01.png" style="height: 70px;" alt=""></span></div>
                             
                            </div>
                            <div class="col-sm-10 text-right" >
                             <span class="box-heading-two">Gujarat</span>
                              <p style="font-size: 14px;">The Ahmedabad District Co-operative Milk Producers’ Union Ltd. has established 7 Bulk Milk Cooling Units having capacity of 15000 LPD. This has led to a rise in milk procurement, increase in milk quality, and increase in milk pourer members.</p>
                            </div>
                          </div>
                          <div class="row" *ngIf="slide.id == '2'">
                           
                           <div class="col-sm-2">
                            
                              <div  class="icon-box"style=" margin-top: 15px;">
                                <span  class="registrations-today-icon"><img  src="/assets/imgs/background/02.png" style="height: 70px;" alt=""></span></div>
                            
                           </div>
                           <div class="col-sm-10 text-right" >
                            <span class="box-heading-two">Kerala</span>
                             <p style="font-size: 14px;">Installation of Bulk Milk Cooling Units at societies under Pattambi P&I unit has improved microbial quality of raw milk from 80 minutes to over 180 minutes</p>
                           </div>
                         </div>
                         <!-- </div> -->
                       </div>               
        </div>
      </div>
    </ng-template>
</ng-container>
   
</owl-carousel-o>
                          </div>
                          
                          </div>
                  </div>
                </div>
              </div>
              </div>

        </div>


<div style="margin-top: -386px;">
        <div class="row " >
            <div class="col-sm-12">
                <div class="col-sm-6">
                    <div class="card" style="border-radius: 10px;">
                      <div class="card-body">
                       <p class="box-heading" style="text-align: center;"> FINANCIAL PROGRESS</p>
                        <!-- <div class="container"> -->
                       <div class="row">
                                <div class="col-sm-3" >
                                 <p class="card-text-one">Total Approved Outlay</p>
                                </div>
                                <div class="col-sm-3 text-right" >
                                    <p class="card-text-one">Central Share</p>
                                </div>
                                <div class="col-sm-3 text-right" >
                                    <p class="card-text-one">Funds Released</p>
                                </div>
                                <div class="col-sm-3 text-right" >
                                    <p class="card-text-one">Funds Utilised</p>
                                </div>
                               
                              
                             </div>
        
                      <div class="row">
                        <div class="col-sm-3" >
                          <p class="total-livestock-count-blue">{{allTotalApprovedOutlay}} L</p>
                        </div>
                        <div class="col-sm-3 text-right" >
                            <p class="total-livestock-count-green ">{{allTotalCentralShare}} L</p>
                        </div>
                        <div class="col-sm-3 text-right" >
                            <p class="total-livestock-count-green">{{allTotalFundsReleased}} L</p>
                        </div>
                        <div class="col-sm-3 text-right">
                            <p class="total-livestock-count-green ">{{allTotalFundsUtilised}} L</p>
                        </div>
                       
              </div>
        
        
                    </div>
                  </div>
                </div>
                    </div>



              <div class="row " >
                <div class="col-sm-6" style="padding: 8px;width: 51.3%;">
                    <div class="card" style="border-radius: 10px;" >
                      <div class="card" style="border-radius: 10px;">
                          <div class="card-body">
                           <p class="box-heading" style="text-align: center;"> PHYSICAL PROGRESS</p>
                            <!-- <div class="container"> -->
                              <div class="row">
                   
                                <div class="col-sm-3" style="border-right: 1px solid;">
                                  <p  class="top-five-box-font-heading">Increase in Average Daily Milk Procurement </p>      
                                 
                                </div>
                                <div class="col-sm-3 text-right" style="border-right: 1px solid;" >
                                  <p class="top-five-box-font-heading">Bulk Milk Cooler Installed  </p>      
                                    <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                  
                                </div>
                                <div class="col-sm-3 text-right" style="border-right: 1px solid;" >
                                  <p  class="top-five-box-font-heading">AMCU/DPMCU Installed</p>    
                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                
                              </div>
                            
                              <div class="col-sm-3 text-right"  >
                                  <p  class="top-five-box-font-heading">Milk Adulteration Testing Machines Installed</p>    
                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                              
                              </div>
                              </div>
        
        
                        <div class="row">
                         
                          <div class="col-sm-3" style="border-right: 1px solid;" >
                           
                            <p class="total-livestock-count-blue">{{allTotalAverageDailyMilkProcurementAch}}</p>
                              <p class="card-text-one">TKgPD <br> (Thousand Kilograms Per Day)</p>   
                          </div>
                          <div class="col-sm-3 text-right" style="margin-top: -30px; border-right: 1px solid;" >
                            <p class="card-text-one"> Number</p>
                            <p class="total-livestock-count-blue">{{allTotalBulkMilkCoolerAch}}</p> 
                            <p class="card-text-one">Capacity<br> (In Thousand Liters)</p>     
                            <p class="total-livestock-count-blue">{{allTotalBulkMilkCoolerCapacity}}</p>
                            <!-- <div class="row">
                              <div class="col-sm-6">
                                <p class="total-livestock-count-blue">4755 KL</p>
                              </div>
                              <div class="col-sm-6">
                                <p class="total-livestock-count-blue">4755 KL</p>
                              </div>
                            </div> -->
                          </div>
                          <div class="col-sm-3 text-right"  style="border-right: 1px solid;">
                             
                            <p class="total-livestock-count-blue">{{allTotalAutomaticMilkCollectionAch}}</p>
                        </div>
                      
                        <div class="col-sm-3 text-right" >
                               
                            <p class="total-livestock-count-blue">{{allTotalMilkAdulterationTestingMachinesAch}}</p>
                        </div>
                        </div>
                          </div>
                        </div>
                    </div>
                  </div>
                        </div>
      

                                </div>                     
          <!-- <div class="heding-middle-one">STATE WISE RASHTRIYA GOKUL MISSION DATA</div> -->
          <!-- <circle-progress [percent]="statePercent" [radius]="60"  [outerStrokeWidth]="15" [innerStrokeWidth]="10"
  [outerStrokeColor]="'#3777BE'" [innerStrokeColor]="'#7f9fc1'" [animation]="true" [animationDuration]="300" style="
  width: 20px; margin-left: 75%;"
></circle-progress> -->
<div class="row ">
                       
  <div class="col-sm-8" style="
  padding-left: 243px;
  padding-top: -8px;
  margin-top: -25px;
">
    <google-chart [data]="geoChart"   *ngIf=mapReady></google-chart>
    
  </div>
  <!-- <div class="col-sm-4">
    <div echarts class="demo-chart" [ngClass]="{'graph-border' : typeOfVacinationChart}"
[options]="typeOfVacinationChart"></div>
    
  </div> -->
 
</div>
 
</div>



   


         

      