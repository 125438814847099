import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { NpddschemeService } from './npddscheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { count } from 'rxjs';
@Component({
  selector: 'app-npddscheme',
  templateUrl: './npddscheme.component.html',
  styleUrls: ['./npddscheme.component.scss']
})
export class NpddschemeComponent implements OnInit {
  
  yearWiseEnroleMentChart:any;
  states_data = [['State','COVID-Confirmed Cases']];
  result:  any = new Array()
  response:  any = new Array()
  npddAllData:  any = new Array()
  totalSateWiseFundsUtilised:  any = new Array()
  totalFundsUtilisedStateWise:  any = new Array()
  topFiveFundsUtilisedArray:  any = new Array()
  allTotalApprovedOutlay:any
  allTotalCentralShare:any
  allTotalFundsReleased:any
  allTotalFundsUtilised:any
  allTotalBulkMilkCoolerAch:any
  allTotalBulkMilkCoolerCapacity:any
  allTotalAverageDailyMilkProcurementAch:any
  allTotalAutomaticMilkCollectionAch:any
  allTotalMilkAdulterationTestingMachinesAch:any
  allTotalNoofprojectssanctioned:any
  topFistState:any
  topFistFundsUtilisedState:any
  topSecandState:any
  topSencandFundsUtilisedState:any
  topTirdState:any
  topThirdFundsUtilisedState:any
  topFourthState:any
  topFourthFundsUtilisedState:any
  topFiveState:any
  topFiveFundsUtilisedState:any
  stateLength:any
  mapReady=false;
  valueInFormate:any
  lastDateFromArray: any;
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  noday:any;
  curentDate: any;
  constructor(public serv: NpddschemeService) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'},
    {id: '3'}
  ];
  ngOnInit(): void {
    this.NpddDashboardData();
    this.showEnrolementGraph();
    this.serv.getData().subscribe((res)=>{
      console.log(res)
      this.result=res;
     
     // this.result.splice(0,1);

      for(let state of this.result){
        let temp = [state.state,Number(state.confirmed)];
        if( state.state=="Odisha"){
          temp = ['IN-OR',Number(state.confirmed)];
        }
        this.states_data.push(temp);
      }
      this.mapReady=true
    },
    (err)=>{
      console.log(err)
    }
  );

  }

  NpddDashboardData()
  {
    this.serv.getNpddData().subscribe((res)=>{
    this.npddAllData=res;
    console.log("all  npdd dataaaaaaaaa")
    console.log(this.npddAllData)
    console.log(this.npddAllData.length)
    this.stateLength=this.npddAllData.length
	
     this.lastDateFromArray = this.npddAllData[this.npddAllData.length - 1];
   console.log("last date--------------")

   this.dateres = this.lastDateFromArray['created_at'].split("T");
   console.log( this.dateres[0])
   this.dateresone=this.dateres[0].split("-")
   console.log( this.dateresone)
   this.year=this.dateresone[0]
   this.month=this.dateresone[1]
   this.day=this.dateresone[2]
   this.noday=Number(this.day)
   this.noday+=1
   console.log(this.noday)
   this.curentDate=this.noday+'/'+this.month+'/'+this.year;
    const sumallTotalApprovedOutlay = this.npddAllData.map((item:any) => Number(item['Total_Approved_Outlay'])).reduce((prev:any, curr:any) => prev + curr, 0);
    const sumallCentralShare = this.npddAllData.map((item:any) => Number(item['Central_Share'])).reduce((prev:any, curr:any) => prev + curr, 0);
    const sumallFundsReleased = this.npddAllData.map((item:any) => Number(item['Funds_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
    const sumallFundsUtilised = this.npddAllData.map((item:any) => Number(item['Funds_Utilised'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allTotalAverageDailyMilkProcurementAch = this.npddAllData.map((item:any) => Number(item['Average_Daily_Milk_Procurement_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0).toFixed(2);
    this.allTotalBulkMilkCoolerAch = this.npddAllData.map((item:any) => Number(item['Bulk_Milk_Cooler_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allTotalBulkMilkCoolerCapacity = this.npddAllData.map((item:any) => Number(item['Bulk_Milk_Cooler_Capacity'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allTotalAutomaticMilkCollectionAch = this.npddAllData.map((item:any) => Number(item['Automatic_Milk_Collection_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allTotalMilkAdulterationTestingMachinesAch = this.npddAllData.map((item:any) => Number(item['Milk_Adulteration_Testing_Machines_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allTotalNoofprojectssanctioned = this.npddAllData.map((item:any) => Number(item['No_of_projects_sanctioned'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allTotalApprovedOutlay=this.numDifferentiationss(sumallTotalApprovedOutlay);
    this.allTotalCentralShare=this.numDifferentiationss(sumallCentralShare);
    this.allTotalFundsReleased=this.numDifferentiationss(sumallFundsReleased);
    this.allTotalFundsUtilised=this.numDifferentiationss(sumallFundsUtilised);


this.totalSateWiseFundsUtilised=[];
var groupByFundsUtilised = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalStateData=groupByFundsUtilised(this.npddAllData, 'State_name')
this.totalSateWiseFundsUtilised = Object.entries(totalStateData)

this.totalFundsUtilisedStateWise=[];
this.totalSateWiseFundsUtilised.forEach((y:any) =>
{
const sumallFundsUtilised = y[1].map((item:any) => Number(item['Funds_Utilised'])).reduce((prev:any, curr:any) => prev + curr, 0);

var finalObj1 = Object.assign({"State": y[0]}, {"sumallFundsUtilised": sumallFundsUtilised});
this.totalFundsUtilisedStateWise.push(finalObj1)
});
console.log("current State all data----")

    console.log( this.totalFundsUtilisedStateWise)
    var topValues = this.totalFundsUtilisedStateWise.sort((a:any,b:any) => b['sumallFundsUtilised']-a['sumallFundsUtilised']).slice(0,5);
console.log("top states")
console.log(topValues)

this.topFiveFundsUtilisedArray=[];

topValues.forEach((y:any) =>
{
var finalObj1 = Object.assign({"State": y['State']}, {"SumallFundsUtilised": this.numDifferentiationss(y['sumallFundsUtilised'])});
this.topFiveFundsUtilisedArray.push(finalObj1);
});
console.log("top five state data")
console.log(this.topFiveFundsUtilisedArray)
this.topFistState=this.topFiveFundsUtilisedArray[0]['State']
this.topFistFundsUtilisedState=this.topFiveFundsUtilisedArray[0]['SumallFundsUtilised']
this.topSecandState=this.topFiveFundsUtilisedArray[1]['State']
this.topSencandFundsUtilisedState=this.topFiveFundsUtilisedArray[1]['SumallFundsUtilised']
this.topTirdState=this.topFiveFundsUtilisedArray[2]['State']
this.topThirdFundsUtilisedState=this.topFiveFundsUtilisedArray[2]['SumallFundsUtilised']
this.topFourthState=this.topFiveFundsUtilisedArray[3]['State']
this.topFourthFundsUtilisedState=this.topFiveFundsUtilisedArray[3]['SumallFundsUtilised']
this.topFiveState=this.topFiveFundsUtilisedArray[4]['State']
this.topFiveFundsUtilisedState=this.topFiveFundsUtilisedArray[4]['SumallFundsUtilised']

    },
    (err)=>{
      console.log(err)
    }
  );
  }
    numDifferentiationss(value:any) {

    var val = Math.abs(value)
    
      this.valueInFormate = (val / 100).toFixed(2) + ' Cr';
   
    return this.valueInFormate;
  }
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }
  public geoChart: GoogleChartInterface = {
    chartType: 'GeoChart',
    dataTable: this.states_data,
    options: {
      domain:'IN',
      region: 'IN',
      colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
      resolution: 'provinces',
      zoom: 6,
     // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
  disableDefaultUI: true,
  //displayMode: 'text',
  defaultColor:'#40e0d0',
  backgroundColor: 'transparent',
  tooltip: {
    isHtml: true
},
legend: 'none',
datalessRegionColor: 'transparent',
      //'height': 600,
      'width': 1,
     

      
    }
  };


  showEnrolementGraph()
  {
      
      this.yearWiseEnroleMentChart =  {
        title: {
               show: false,
               left: 'center',
               text: 'COURSE  ENROLMENT STATICS GRAPH  ',
             },
        tooltip: {
               trigger: 'axis'
             },
      
            axisLabel: {
              interval:0,
              rotate: 65,
          },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value',
          axisLine:{                 //Coordinate axis
            show:true,             //Show Axis axis or not
            onZero:true,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
        },
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            color:'#5470c6'
  
          //  color: '#8E24AA'
          }
        ]
      };
  
    }
    
}
