import { Component, OnInit } from '@angular/core';
import { SdcfposchemeService } from './sdcfposcheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-sdcfposcheme',
  templateUrl: './sdcfposcheme.component.html',
  styleUrls: ['./sdcfposcheme.component.scss']
})
export class SdcfposchemeComponent implements OnInit {
	SdcfpoAllData:  any = new Array()
	InterestSubvention: any
	MilkCooperatives: any
	WorkingCapitalLoanAmount:any
    lastDateFromArray: any;
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  curentDate: any;
  noday: any;

  constructor(public serv: SdcfposchemeService) { }
customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'}
  ];
  ngOnInit(): void {
    this.SdcfoDashboardData();

  }
SdcfoDashboardData(){
this.serv.getSdcfpoData().subscribe((res)=>{
      this.SdcfpoAllData=res;
	      this.dateres = this.SdcfpoAllData[0]['created_at'].split("T");
    console.log( this.dateres[0])
    this.dateresone=this.dateres[0].split("-")
    console.log( this.dateresone)
    this.year=this.dateresone[0]
    this.month=this.dateresone[1]
    this.day=this.dateresone[2]
  this.noday=Number(this.day)
  this.noday+=1
  console.log(this.noday)
  this.curentDate=this.noday+'/'+this.month+'/'+this.year;
	  this.InterestSubvention=this.SdcfpoAllData[0]['Interest_Subvention_Disbursed']
	  this.MilkCooperatives=this.SdcfpoAllData[0]['Milk_Cooperatives_FPOs_Aassisted']
	  this.WorkingCapitalLoanAmount=this.SdcfpoAllData[0]['Working_Capital_Loan_Amount']
console.log(this.SdcfpoAllData)
    },
    (err)=>{
      console.log(err)
    }
  );


}
}

