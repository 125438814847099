import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { NlmschemeService } from './nlmscheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { max } from 'lodash';
@Component({
  selector: 'app-nlmscheme',
  templateUrl: './nlmscheme.component.html',
  styleUrls: ['./nlmscheme.component.scss']
})
export class NlmschemeComponent implements OnInit {
  
  yearWiseEnroleMentChart:any;
  typeOfDAHDChart:any;
  typeOfDAHDCostChart:any;
  states_data = [['State Code','State','Approved Projects by DAHD','Project Cost(In Cr)']];
  result:  any = new Array()
  response:  any = new Array()
  allNlmData:  any = new Array()
  totalSchemeWiseData:  any = new Array()
  totalSchemeData:  any = new Array()
  totalStateSchemeWiseData:  any = new Array()
  totalStateSchemeWiseDAHDData:  any = new Array()
  totalTopFiveStateWiseData:  any = new Array()
  totalSchemeDataStateWise:  any = new Array()
  nlmDashboardKpiData:  any = new Array()
  nlmDashboardstatewisekpiData:  any = new Array()
  nlmDashboardsubsidyReleasedKpiData:  any = new Array()
  

 projectCostInArray:  any = new Array()
 totalNoOfProjectsInArray:  any = new Array()
 applicationsEligiblebyStateInArray:  any = new Array()
 approvedProjectsbyDAHDInArray:  any = new Array()
 totalAggregateStateDAHDProjectCountData:  any = new Array()
 totalAggregateStateDAHDProjectCostData:  any = new Array()
  statePercent=0
  mapReady=false;
  showPieChartpageload = true;
  showPieChartMapclick= false;
  selectState: any;
  projectSchemeCost: any;
  projectSchemeLoanAmount: any;
  valueInFormate: any;
  sheepAndGoatFarmingNumberOfProjects: any;
  sheepAndGoatFarmingloanAmount: any;
  sheepAndGoatFarmingprojectCost: any;
  poggeryEntereprenuerNumberOfProjects: any;
  poggeryEntereprenuerloanAmount: any;
  poggeryEntereprenuerprojectCost: any;
  ruralPoultryNumberOfProjects: any;
  ruralPoultryloanAmount: any;
  ruralPoultryprojectCost: any;
  projectStateWiseSchemeCost: any;
  topFirstPerfomanceState: any;
  topFirstPerformingnoOfProjectsDone: any;
  topFirstPerformingprojectCost: any;
  topSecondPerfomanceState: any;
  topSecondPerformingnoOfProjectsDone: any;
  topSecondPerformingprojectCost: any;
  topThirdPerfomanceState: any;
  topThirdPerformingnoOfProjectsDone: any;
  topThirdPerformingprojectCost: any;
  topFourthPerfomanceState: any;
  topFourthPerformingnoOfProjectsDone: any;
  topFourthPerformingprojectCost: any;
  topFifthPerfomanceState: any;
  topFifthPerformingnoOfProjectsDone: any;
  topFifthPerformingprojectCost: any;
  noOfEntrepreneursRegisteredCount: any;
  numberOfEntrepreneursRegisteredProjectCost: any;
  projectsEligibleByStateCount: any;
  projectsEligibleByStateProjectCostt: any;
  projetcsSanctionedByBankCount: any;
  projetcsSanctionedByBankProjectCostt: any;
  approvedProjectsForSubsidyCount: any;
  approvedProjectsForSubsidyProjectCostt: any;
  approvedProjectsForDAHDProjectCostt: any;
  subsidyAmountReleasedCount: any;
  subsidyAmountReleasedd: any;
  NLMSubsidyReleasedtoSIDBI: any;

  projectUnderImplementationCount: any;
  projectUnderImplementationCost: any;
  projectCompletedCount: any;
  projectCompletedCost: any;



  feedFoderNumberOfProjects: any;
  feedFoderloanAmount: any;
  feedFoderprojectCost: any;
  totalNoOfProjectsInNlm: any;
  projectCostWithoutCr: any;
  projectSchemeCostInCr: any;
  nlmProjectCost: any;
  nlmProjectCostWithoutNum: any;
  lastDateFromArray: any;
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  noday:any;
  curentDate: any;

  
  State_AndhraPradesh :any
  AndhraPradesh_noOfProjects :any
  AndhraPradesh_ProjectCost :any
  AndhraPradesh_colorcode :any
  State_ArunachalPradesh :any
  ArunachalPradesh_noOfProjects :any
  ArunachalPradesh_ProjectCost :any
  ArunachalPradesh_colorcode :any
  State_Assam :any
  Assam_noOfProjects :any 
  Assam_ProjectCost :any
  Assam_colorcode :any
  State_Bihar :any
  Bihar_noOfProjects :any
  Bihar_ProjectCost :any
  Bihar_colorcode :any
  State_Chhattisgarh :any
  Chhattisgarh_noOfProjects :any
  Chhattisgarh_ProjectCost :any
  Chhattisgarh_colorcode :any
  State_Goa :any
  Goa_noOfProjects :any
  Goa_ProjectCost :any
  Goa_colorcode :any
  State_Gujarat :any
  Gujarat_noOfProjects :any
  Gujarat_ProjectCost :any
  Gujarat_colorcode :any
  State_Haryana :any
  Haryana_noOfProjects :any
  Haryana_ProjectCost :any
  Haryana_colorcode :any
  State_HimachalPradesh :any
  HimachalPradesh_noOfProjects :any
  HimachalPradesh_ProjectCost :any
  HimachalPradesh_colorcode :any
  State_Jharkhand :any
  Jharkhand_noOfProjects :any
  Jharkhand_ProjectCost :any
  Jharkhand_colorcode :any
  State_Karnataka :any
  Karnataka_noOfProjects :any
  Karnataka_ProjectCost :any
  Karnataka_colorcode :any
  State_Kerala :any
  Kerala_noOfProjects :any
  Kerala_ProjectCost :any
  Kerala_colorcode :any
  State_MadhyaPradesh :any
  MadhyaPradesh_noOfProjects :any
  MadhyaPradesh_ProjectCost :any
  MadhyaPradesh_colorcode :any
  State_Maharashtra :any
  Maharashtra_noOfProjects :any
  Maharashtra_ProjectCost :any
  Maharashtra_colorcode:any
  State_Manipur :any
  Manipur_noOfProjects :any
  Manipur_ProjectCost :any
  Manipur_colorcode :any
  State_Meghalaya :any
  Meghalaya_noOfProjects :any
  Meghalaya_ProjectCost :any
  Meghalaya_colorcode :any
  State_Mizoram :any
  Mizoram_noOfProjects :any
  Mizoram_ProjectCost :any
  Mizoram_colorcode:any
  State_Nagaland :any
  Nagaland_noOfProjects :any
  Nagaland_ProjectCost :any
  Nagaland_colorcode :any
  State_Odisha :any
  Odisha_noOfProjects :any
  Odisha_ProjectCost :any
  Odisha_colorcode :any
  State_Punjab :any
  Punjab_noOfProjects :any
  Punjab_ProjectCost :any
  Punjab_colorcode :any
  State_Rajasthan :any
  Rajasthan_noOfProjects :any
  Rajasthan_ProjectCost :any
  Rajasthan_colorcode :any
  State_Sikkim :any
  Sikkim_noOfProjects :any
  Sikkim_ProjectCost :any
  Sikkim_colorcode :any
  State_TamilNadu :any
  TamilNadu_noOfProjects :any
  TamilNadu_ProjectCost :any
  TamilNadu_colorcode :any
  State_Telangana :any
  Telangana_noOfProjects :any
  Telangana_ProjectCost :any
  Telangana_colorcode :any
  State_Tripura :any
  Tripura_noOfProjects :any
  Tripura_ProjectCost :any
  Tripura_colorcode :any
  State_Uttarakhand :any
  Uttarakhand_noOfProjects :any
  Uttarakhand_ProjectCost :any
  Uttarakhand_colorcode :any
  State_UttarPradesh :any
  UttarPradesh_noOfProjects :any
  UttarPradesh_ProjectCost :any
  UttarPradesh_colorcode :any
  State_WestBengal :any
  WestBengal_noOfProjects :any
  WestBengal_ProjectCost :any
  WestBengal_colorcode :any
  State_Andaman :any
  Andaman_noOfProjects :any
  Andaman_ProjectCost :any
  Andaman_colorcode :any
  State_Chandigarh :any
  Chandigarh_noOfProjects :any
  Chandigarh_ProjectCost :any
  Chandigarh_colorcode :any
  State_Dadra :any
  Dadra_noOfProjects :any
  Dadra_ProjectCost :any
  Dadra_colorcode :any
  State_Delhi :any
  Delhi_noOfProjects :any
  Delhi_ProjectCost :any
  Delhi_colorcode :any
  State_Jammu :any
  Jammu_noOfProjects :any
  Jammu_ProjectCost :any
  Jammu_colorcode :any
  State_Ladakh :any
  Ladakh_noOfProjects :any
  Ladakh_ProjectCost :any
  Ladakh_colorcode :any
  State_Lakshadweep :any
  Lakshadweep_noOfProjects :any
  Lakshadweep_ProjectCost :any
  Lakshadweep_colorcode :any
  State_Puducherry :any
  Puducherry_noOfProjects :any
  Puducherry_ProjectCost :any
  Puducherry_colorcode :any
  colorcode :any
  maxvalue:any
  minvalue:any

  constructor(public serv: NlmschemeService) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'},
    {id: '3'}
  ];
  ngOnInit(): void {
    this.nlmDashboardData();
    this.nlmDashboardKpi();
	this.nlmDashboardstatewiseKpi();
	this.nlmDashboardsubsidyReleasedKpi();

  }



  nlmDashboardData()
  {
    this.allNlmData=[];
    this.totalSchemeWiseData=[];
    this.serv.getDashboardData().subscribe((res)=>{
      console.log(res)
      this.allNlmData=res;
	  
   this.lastDateFromArray = this.allNlmData[this.allNlmData.length - 1];
   console.log("last date--------------")

   this.dateres = this.lastDateFromArray['created_at'].split("T");
   console.log( this.dateres[0])
   this.dateresone=this.dateres[0].split("-")
   console.log( this.dateresone)
   this.year=this.dateresone[0]
   this.month=this.dateresone[1]
   this.day=this.dateresone[2]
   this.noday=Number(this.day)
   this.noday+=1
   console.log(this.noday)
   this.curentDate=this.noday+'/'+this.month+'/'+this.year;
	  
      var groupByScheme = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };


//this.subsidyAmountReleasedCount = this.allNlmData.map((item:any) => Number(item['subsidy_Amount_Released_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
//const subsidy_Amount_Released = this.allNlmData.map((item:any) => Number(item['subsidy_Amount_Released'])).reduce((prev:any, curr:any) => prev + curr, 0);
//this.subsidyAmountReleasedd=this.numDifferentiation(subsidy_Amount_Released);

var totalStateData=groupByScheme(this.allNlmData, 'description')
this.totalSchemeWiseData = Object.entries(totalStateData)
console.log(this.totalSchemeWiseData)
this.totalSchemeData=[];
this.totalSchemeWiseData.forEach((y:any) =>
{
const noOfProjects = y[1].map((item:any) => Number(item['no_Of_Project'])).reduce((prev:any, curr:any) => prev + curr, 0);
const projectCost = y[1].map((item:any) => Number(item['project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
const loanAmount = y[1].map((item:any) => Number(item['loan_Amount'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.projectSchemeCost=this.numDifferentiation(projectCost);
this.projectSchemeLoanAmount=this.numDifferentiation(loanAmount);
var finalObj1 = Object.assign({"Scheme": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"loanAmount": this.projectSchemeLoanAmount});
this.totalSchemeData.push(finalObj1)

});
console.log("total scheme wise data--------")
console.log(this.totalSchemeData)
this.sheepAndGoatFarmingNumberOfProjects=this.totalSchemeData[0]['noOfProjects'];
this.sheepAndGoatFarmingloanAmount=this.totalSchemeData[0]['loanAmount'];
this.sheepAndGoatFarmingprojectCost=this.totalSchemeData[0]['projectCost'];

this.poggeryEntereprenuerNumberOfProjects=this.totalSchemeData[2]['noOfProjects'];
this.poggeryEntereprenuerloanAmount=this.totalSchemeData[2]['loanAmount'];
this.poggeryEntereprenuerprojectCost=this.totalSchemeData[2]['projectCost'];

this.ruralPoultryNumberOfProjects=this.totalSchemeData[1]['noOfProjects'];
this.ruralPoultryloanAmount=this.totalSchemeData[1]['loanAmount'];
this.ruralPoultryprojectCost=this.totalSchemeData[1]['projectCost'];

this.feedFoderNumberOfProjects=this.totalSchemeData[3]['noOfProjects'];
this.feedFoderloanAmount=this.totalSchemeData[3]['loanAmount'];
this.feedFoderprojectCost=this.totalSchemeData[3]['projectCost'];
// this.totalStateSchemeWiseData=[];
// var groupByStateName = function(xs:any, key:any) {
//   return xs.reduce(function(rv:any, x:any) {
//     (rv[x[key]] = rv[x[key]] || []).push(x);
//     return rv;
//   }, {});
// };
// var totalStateWiseData=groupByStateName(this.allNlmData, 'stateName')
// this.totalStateSchemeWiseData = Object.entries(totalStateWiseData)
// this.totalTopFiveStateWiseData=[];
// this.totalSchemeDataStateWise=[];
// this.totalStateSchemeWiseData.forEach((y:any) =>
// {
//   const noOfProjects = y[1].map((item:any) => Number(item['no_Of_Project'])).reduce((prev:any, curr:any) => prev + curr, 0);
//   const projectCost = y[1].map((item:any) => Number(item['project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
//   this.projectStateWiseSchemeCost=this.numDifferentiation(projectCost);

//   var finalObj2 = Object.assign({"StateName": y[0]}, {"noOfProjects": noOfProjects},{"projectCost":this.numDifferentiations(projectCost)});
//   this.totalSchemeDataStateWise.push(finalObj2)
// });

// console.log(this.totalSchemeDataStateWise)
// this. totalNoOfProjectsInNlm = this.totalSchemeDataStateWise.map((item:any) => Number(item['noOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);

// this.projectCostWithoutCr = this.totalSchemeDataStateWise.map((item:any) => Number(item['projectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);



// for(let state of this.totalSchemeDataStateWise){
 
//   let temp = [state.StateNameName,Number(state.noOfProjects),Number(state.projectCost)];
//   if( state.StateNameName=="ODISHA"){
//     temp = ['Orissa',Number(state.noOfProjects),Number(state.projectCost)];
//   }
//  // this.states_data.push(delhitemp);
//   this.states_data.push(temp);
// }
// this.states_data.push(['IN-UT','0','0'],['GOA','0','0']);
// //this.states_data.push(['DELHI','0','0'],['Jammu and Kashmir','0','0'],['HIMACHAL PRADESH','0','0'],['IN-UT','0','0'],['ASSAM','0','0'],['JHARKHAND','0','0'],['TRIPURA','0','0'],['MEGHALAYA','0','0'],['Ladakh','0','0'],['GOA','0','0'],['PUDUCHERRY','0','0'],['ARUNACHAL PRADESH','0','0'],['CHANDIGARH','0','0'],['MANIPUR','0','0'],['NAGALAND','0','0'],['SIKKIM','0','0'],['THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU','0','0'],['IN-AN','0','0'],['MIZORAM','0','0'],['IN-LD','0','0']);
// console.log("states data------------")
// console.log(this.states_data)
//  this.mapReady=true


    },
    (err)=>{
      console.log(err)
    }
  );
  }
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }
  numDifferentiations(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) ;
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) ;
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) ;
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }



  nlmDashboardKpi()
  {
    this.nlmDashboardKpiData=[];
    this.serv.getDashboardKpiData().subscribe((res)=>{
      console.log("nlmkpidata------------")
      console.log(res)
      this.nlmDashboardKpiData=res


      this.noOfEntrepreneursRegisteredCount=0
      this.numberOfEntrepreneursRegisteredProjectCost=0
     // this.projectsEligibleByStateCount=0
      // this.projectsEligibleByStateProjectCostt=0
      this.projetcsSanctionedByBankCount=0
      this.projetcsSanctionedByBankProjectCostt=0
      this.approvedProjectsForSubsidyCount=0
      this.approvedProjectsForSubsidyProjectCostt=0
      this.approvedProjectsForDAHDProjectCostt=0


      this.projectUnderImplementationCount = 0;
      this.projectUnderImplementationCost = 0;
      this.projectCompletedCount = 0;
      this.projectCompletedCost = 0;

      this.projectCostInArray=[];
      this.totalNoOfProjectsInArray=[];
      console.log("project  cost----------------")

      this.noOfEntrepreneursRegisteredCount = this.nlmDashboardKpiData.map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
      const noOfEntrepreneursRegisteredProjectCost = this.nlmDashboardKpiData.map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.nlmProjectCostWithoutNum = noOfEntrepreneursRegisteredProjectCost;
      this.nlmProjectCost=this.numDifferentiations(noOfEntrepreneursRegisteredProjectCost)
      this.numberOfEntrepreneursRegisteredProjectCost=this.numDifferentiation(noOfEntrepreneursRegisteredProjectCost);
      this.projectCostInArray.push(this.nlmProjectCost);
      this.totalNoOfProjectsInArray.push(this.noOfEntrepreneursRegisteredCount)
      console.log(this.projectCostInArray)
      console.log(this.totalNoOfProjectsInArray)


     // this.projectsEligibleByStateCount = this.nlmDashboardKpiData.map((item:any) => Number(item['projects_Eligible_By_State_Count'])).reduce((prev:any, curr:any) => prev + curr, 0); 
     // const projectsEligibleByStateProjectCost = this.nlmDashboardKpiData.map((item:any) => Number(item['projects_Eligible_By_State_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
     // this.projectsEligibleByStateProjectCostt=this.numDifferentiation(projectsEligibleByStateProjectCost);

      this.projetcsSanctionedByBankCount = this.nlmDashboardKpiData.map((item:any) => Number(item['projetcs_Sanctioned_By_Bank_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
      const projetcs_Sanctioned_By_Bank_ProjectCost = this.nlmDashboardKpiData.map((item:any) => Number(item['projetcs_Sanctioned_By_Bank_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.projetcsSanctionedByBankProjectCostt=this.numDifferentiation(projetcs_Sanctioned_By_Bank_ProjectCost);
    
      this.approvedProjectsbyDAHDInArray = [];
      this.approvedProjectsForSubsidyCount = this.nlmDashboardKpiData.map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.approvedProjectsbyDAHDInArray.push(this.approvedProjectsForSubsidyCount)
      const approvedProjectsForSubsidyProjectCost = this.nlmDashboardKpiData.map((item:any) => Number(item['approved_projects_by_dahd_projectcost'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.approvedProjectsForDAHDProjectCostt = approvedProjectsForSubsidyProjectCost;
      if(approvedProjectsForSubsidyProjectCost != 0)
	  {
      this.approvedProjectsForSubsidyProjectCostt=this.numDifferentiation(approvedProjectsForSubsidyProjectCost);
      }


      // this.projectUnderImplementationCount = 0;
      // this.projectUnderImplementationCost = 0;
      // this.projectCompletedCount = 0;
      // this.projectCompletedCost = 0;

      this.projectUnderImplementationCount = this.nlmDashboardKpiData.map((item:any) => Number(item['project_Under_Implementation_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);  
      const projectUnderImplementationCost = this.nlmDashboardKpiData.map((item:any) => Number(item['project_Under_Implementation_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
      if(projectUnderImplementationCost != 0)
	    {
      this.projectUnderImplementationCost=this.numDifferentiation(projectUnderImplementationCost);
      }
      this.projectCompletedCount = this.nlmDashboardKpiData.map((item:any) => Number(item['project_Completed_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);  
      const projectCompletedCost = this.nlmDashboardKpiData.map((item:any) => Number(item['project_Completed_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
      if(projectCompletedCost != 0)
	    {
      this.projectCompletedCost=this.numDifferentiation(projectCompletedCost);
      }



      this.totalStateSchemeWiseData=[];
      this.totalStateSchemeWiseDAHDData=[];
      var groupByStateName = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      var totalStateWiseData=groupByStateName(this.nlmDashboardKpiData, 'stateName')
      this.totalStateSchemeWiseDAHDData = Object.entries(totalStateWiseData)
      this.totalTopFiveStateWiseData=[];
      this.totalSchemeDataStateWise=[];
      this.totalStateSchemeWiseDAHDData.forEach((y:any) =>
      {
        const noOfProjects = y[1].map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);
        const projectCost = y[1].map((item:any) => Number(item['approved_projects_by_dahd_projectcost'])).reduce((prev:any, curr:any) => prev + curr, 0);
        const noOfProjectsFormap = y[1].map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
        const projectCostFormap = y[1].map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
        this.projectStateWiseSchemeCost=this.numDifferentiation(projectCost);
      

        var finalObj1 = Object.assign({"State_name": y[0]},{"approved_projects_by_dahd_count": noOfProjects},{"approved_projects_by_dahd_projectcost": this.projectStateWiseSchemeCost});
        this.totalTopFiveStateWiseData.push(finalObj1)

       //  var finalObj2 = Object.assign({"StateName": y[0]}, {"noOfProjects": noOfProjects},{"projectCost":this.numDifferentiations(projectCost)});
       // this.totalSchemeDataStateWise.push(finalObj2)
       var finalObj2 = Object.assign({"StateName": y[0]}, {"noOfProjects": noOfProjectsFormap},{"projectCost":this.numDifferentiations(projectCostFormap)});
       this.totalSchemeDataStateWise.push(finalObj2)
      });
      
      console.log(this.totalSchemeDataStateWise)


      var topValues = this.totalTopFiveStateWiseData.sort((a:any,b:any) => b['approved_projects_by_dahd_count']-a['approved_projects_by_dahd_count']).slice(0,5);
      console.log("top states new")
      console.log(topValues)     
      
      this.topFirstPerfomanceState=topValues[0]['State_name'];
      this.topFirstPerformingnoOfProjectsDone=topValues[0]['approved_projects_by_dahd_count'];
      this.topFirstPerformingprojectCost=topValues[0]['approved_projects_by_dahd_projectcost'];
      
      this.topSecondPerfomanceState=topValues[1]['State_name'];
      this.topSecondPerformingnoOfProjectsDone=topValues[1]['approved_projects_by_dahd_count'];
      this.topSecondPerformingprojectCost=topValues[1]['approved_projects_by_dahd_projectcost'];
      
      this.topThirdPerfomanceState=topValues[2]['State_name'];
      this.topThirdPerformingnoOfProjectsDone=topValues[2]['approved_projects_by_dahd_count'];
      this.topThirdPerformingprojectCost=topValues[2]['approved_projects_by_dahd_projectcost'];
      
      this.topFourthPerfomanceState=topValues[3]['State_name'];
      this.topFourthPerformingnoOfProjectsDone=topValues[3]['approved_projects_by_dahd_count'];
      this.topFourthPerformingprojectCost=topValues[3]['approved_projects_by_dahd_projectcost'];
      
      this.topFifthPerfomanceState=topValues[4]['State_name'];
      this.topFifthPerformingnoOfProjectsDone=topValues[4]['approved_projects_by_dahd_count'];
      this.topFifthPerformingprojectCost=topValues[4]['approved_projects_by_dahd_projectcost'];





      this. totalNoOfProjectsInNlm = this.totalSchemeDataStateWise.map((item:any) => Number(item['noOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
      
      this.projectCostWithoutCr = this.totalSchemeDataStateWise.map((item:any) => Number(item['projectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);

      //const max = Math.max(this.totalSchemeDataStateWise);

      var items =  this.totalSchemeDataStateWise.map(function(item:any) { return parseInt(item['noOfProjects']); })
      this.maxvalue= Math.max.apply(Math, items);      
//value=v first color=if(v<0.26*max)?color1:IF(v>=0.25*max and v<0.51*max),"Color 2",IF(v>=0.51*max,v<0.76*max),"Color 3",=IF(v>0.75*max,"Color 4")))
       for(let state of this.totalSchemeDataStateWise){
       

        let temp = [state.StateName,state.StateName,Number(state.noOfProjects),Number(state.projectCost)];
        // if(state.StateName=="ANDAMAN & NICOBAR ISLANDS"){
        //   temp = ['IN-AN','ANDAMAN & NICOBAR ISLANDS',Number(state.noOfProjects),Number(state.projectCost)];
        // }
        // else if( state.StateName=="LAKSHADWEEP"){
        //   temp = ['IN-LD','LAKSHADWEEP',Number(state.noOfProjects),Number(state.projectCost)];
        // }
        // else if( state.StateName=="ODISHA"){
        //   temp = ['IN-OR','ODISHA',Number(state.noOfProjects),Number(state.projectCost)];
        // }
        // else if( state.StateName=="LADAKH"){
        //   temp = ['IN-LA','LADAKH',Number(state.noOfProjects),Number(state.projectCost)];
        // }
        // else if( state.StateName=="UTTARAKHAND"){
        //   temp = ['IN-UT','UTTARAKHAND',Number(state.noOfProjects),Number(state.projectCost)];
        // }
        // else if(state.StateName=="JAMMU & KASHMIR"){
        //   temp = ['IN-JK','JAMMU & KASHMIR',Number(state.noOfProjects),Number(state.projectCost)];
        // }
           
        this.State_Goa = "GOA";
        this.Goa_noOfProjects = 0;  
        this.Goa_ProjectCost = 0;  
        this.Goa_colorcode ="ZeroColor";    
        this.State_Lakshadweep = "LAKSHADWEEP";
        this.Lakshadweep_noOfProjects = 0;
        this.Lakshadweep_ProjectCost = 0;
        this.Lakshadweep_colorcode ="ZeroColor";
        this.State_Ladakh = "LADAKH";
        this.Ladakh_noOfProjects = 0;
        this.Ladakh_ProjectCost = 0;
        this.Ladakh_colorcode ="ZeroColor";
        if( state.StateName=="UTTAR PRADESH"){    
          this.State_UttarPradesh = state.StateName;
          this.UttarPradesh_noOfProjects = Number(state.noOfProjects);
          this.UttarPradesh_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.UttarPradesh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.UttarPradesh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.UttarPradesh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.UttarPradesh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.UttarPradesh_colorcode ="FourthColor";
          }
//this.UttarPradesh_colorcode = "redflag";
        }
        else if( state.StateName=="RAJASTHAN"){    
          this.State_Rajasthan = state.StateName;
          this.Rajasthan_noOfProjects = Number(state.noOfProjects);
          this.Rajasthan_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Rajasthan_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Rajasthan_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Rajasthan_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Rajasthan_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Rajasthan_colorcode ="FourthColor";
          }
        } 
        else if( state.StateName=="BIHAR"){    
          this.State_Bihar = state.StateName;
          this.Bihar_noOfProjects = Number(state.noOfProjects);
          this.Bihar_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Bihar_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Bihar_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Bihar_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Bihar_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Bihar_colorcode ="FourthColor";
          }
        } 
        else if( state.StateName=="HIMACHAL PRADESH"){    
          this.State_HimachalPradesh = state.StateName;
          this.HimachalPradesh_noOfProjects = Number(state.noOfProjects);
          this.HimachalPradesh_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.HimachalPradesh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.HimachalPradesh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.HimachalPradesh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.HimachalPradesh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.HimachalPradesh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="CHANDIGARH"){    
          this.State_Chandigarh = state.StateName;
          this.Chandigarh_noOfProjects = Number(state.noOfProjects);
          this.Chandigarh_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Chandigarh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Chandigarh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Chandigarh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Chandigarh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Chandigarh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="JAMMU AND KASHMIR"){    
          this.State_Jammu = state.StateName;
          this.Jammu_noOfProjects = Number(state.noOfProjects);
          this.Jammu_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Jammu_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Jammu_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Jammu_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Jammu_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Jammu_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="HARYANA"){    
          this.State_Haryana = state.StateName;
          this.Haryana_noOfProjects = Number(state.noOfProjects);
          this.Haryana_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Haryana_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Haryana_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Haryana_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Haryana_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Haryana_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="UTTARAKHAND"){    
          this.State_Uttarakhand = state.StateName;
          this.Uttarakhand_noOfProjects = Number(state.noOfProjects);
          this.Uttarakhand_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Uttarakhand_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Uttarakhand_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Uttarakhand_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Uttarakhand_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Uttarakhand_colorcode ="FourthColor";
          }
        }  
        else if( state.StateName=="MAHARASHTRA"){    
          this.State_Maharashtra = state.StateName;
          this.Maharashtra_noOfProjects = Number(state.noOfProjects);
          this.Maharashtra_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Maharashtra_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Maharashtra_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Maharashtra_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Maharashtra_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Maharashtra_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="ANDHRA PRADESH"){    
          this.State_AndhraPradesh = state.StateName;
          this.AndhraPradesh_noOfProjects = Number(state.noOfProjects);
          this.AndhraPradesh_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.AndhraPradesh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.AndhraPradesh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.AndhraPradesh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.AndhraPradesh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.AndhraPradesh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="GUJARAT"){    
          this.State_Gujarat = state.StateName;
          this.Gujarat_noOfProjects = Number(state.noOfProjects);
          this.Gujarat_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Gujarat_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Gujarat_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Gujarat_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Gujarat_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Gujarat_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="MADHYA PRADESH"){    
          this.State_MadhyaPradesh = state.StateName;
          this.MadhyaPradesh_noOfProjects = Number(state.noOfProjects);
          this.MadhyaPradesh_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.MadhyaPradesh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.MadhyaPradesh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.MadhyaPradesh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.MadhyaPradesh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.MadhyaPradesh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU"){    
          this.State_Dadra = state.StateName;
          this.Dadra_noOfProjects = Number(state.noOfProjects);
          this.Dadra_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Dadra_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Dadra_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Dadra_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Dadra_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Dadra_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="TAMIL NADU"){    
          this.State_TamilNadu = state.StateName;
          this.TamilNadu_noOfProjects = Number(state.noOfProjects);
          this.TamilNadu_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.TamilNadu_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.TamilNadu_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.TamilNadu_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.TamilNadu_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.TamilNadu_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="PUDUCHERRY"){    
          this.State_Puducherry = state.StateName;
          this.Puducherry_noOfProjects = Number(state.noOfProjects);
          this.Puducherry_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Puducherry_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Puducherry_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Puducherry_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Puducherry_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Puducherry_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="TELANGANA"){    
          this.State_Telangana = state.StateName;
          this.Telangana_noOfProjects = Number(state.noOfProjects);
          this.Telangana_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Telangana_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Telangana_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Telangana_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Telangana_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Telangana_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="LADAKH"){    
          this.State_Ladakh = state.StateName;
          this.Ladakh_noOfProjects = Number(state.noOfProjects);
          this.Ladakh_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Ladakh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Ladakh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Ladakh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Ladakh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Ladakh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="KARNATAKA"){    
          this.State_Karnataka = state.StateName;
          this.Karnataka_noOfProjects = Number(state.noOfProjects);
          this.Karnataka_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Karnataka_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Karnataka_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Karnataka_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Karnataka_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Karnataka_colorcode ="FourthColor";
          }
        } 
        // else if( state.StateName=="GOA"){    
        //   this.State_Goa = state.StateName;
        //   this.Goa_noOfProjects = Number(state.noOfProjects);
        // }
        else if( state.StateName=="MEGHALAYA"){    
          this.State_Meghalaya = state.StateName;
          this.Meghalaya_noOfProjects = Number(state.noOfProjects);
          this.Meghalaya_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Meghalaya_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Meghalaya_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Meghalaya_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Meghalaya_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Meghalaya_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="ASSAM"){    
          this.State_Assam = state.StateName;
          this.Assam_noOfProjects = Number(state.noOfProjects);
          this.Assam_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Assam_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Assam_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Assam_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Assam_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Assam_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="TRIPURA"){    
          this.State_Tripura = state.StateName;
          this.Tripura_noOfProjects = Number(state.noOfProjects);
          this.Tripura_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Tripura_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Tripura_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Tripura_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Tripura_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Tripura_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="WEST BENGAL"){    
          this.State_WestBengal = state.StateName;
          this.WestBengal_noOfProjects = Number(state.noOfProjects);
          this.WestBengal_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.WestBengal_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.WestBengal_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.WestBengal_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.WestBengal_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.WestBengal_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="ARUNACHAL PRADESH"){    
          this.State_ArunachalPradesh = state.StateName;
          this.ArunachalPradesh_noOfProjects = Number(state.noOfProjects);
          this.ArunachalPradesh_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.ArunachalPradesh_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.ArunachalPradesh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.ArunachalPradesh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.ArunachalPradesh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.ArunachalPradesh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="SIKKIM"){    
          this.State_Sikkim = state.StateName;
          this.Sikkim_noOfProjects = Number(state.noOfProjects);
          this.Sikkim_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Sikkim_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Sikkim_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Sikkim_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Sikkim_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Sikkim_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="MANIPUR"){    
          this.State_Manipur = state.StateName;
          this.Manipur_noOfProjects = Number(state.noOfProjects);
          this.Manipur_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Manipur_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Manipur_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Manipur_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Manipur_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Manipur_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="MIZORAM"){    
          this.State_Mizoram = state.StateName;
          this.Mizoram_noOfProjects = Number(state.noOfProjects);
          this.Mizoram_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Mizoram_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Mizoram_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Mizoram_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Mizoram_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Mizoram_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="NAGALAND"){    
          this.State_Nagaland = state.StateName;
          this.Nagaland_noOfProjects = Number(state.noOfProjects);
          this.Nagaland_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Nagaland_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Nagaland_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Nagaland_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Nagaland_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Nagaland_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="CHHATTISGARH"){    
          this.State_Chhattisgarh = state.StateName;
          this.Chhattisgarh_noOfProjects = Number(state.noOfProjects);
          this.Chhattisgarh_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Rajasthan_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Chhattisgarh_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Chhattisgarh_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Chhattisgarh_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Chhattisgarh_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="JHARKHAND"){    
          this.State_Jharkhand = state.StateName;
          this.Jharkhand_noOfProjects = Number(state.noOfProjects);
          this.Jharkhand_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Jharkhand_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Jharkhand_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Jharkhand_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Jharkhand_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Jharkhand_colorcode ="FourthColor";
          }
        }
        else if( state.StateName=="ODISHA"){    
          this.State_Odisha = state.StateName;
          this.Odisha_noOfProjects = Number(state.noOfProjects);
          this.Odisha_ProjectCost = Number(state.projectCost);
           if(state.noOfProjects ==0)
          {
            this.Odisha_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Odisha_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Odisha_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Odisha_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Odisha_colorcode ="FourthColor";
          }
        }
      
       else  if( state.StateName=="ANDAMAN AND NICOBAR ISLANDS"){
         temp = ['IN-AN','ANDAMAN AND NICOBAR ISLANDS',Number(state.noOfProjects)];
         this.State_Andaman = state.StateName;
         this.Andaman_noOfProjects = Number(state.noOfProjects);
         this.Andaman_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Andaman_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Andaman_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Andaman_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Andaman_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Andaman_colorcode ="FourthColor";
          }
       }
       else if( state.StateName=="DELHI"){    
         this.State_Delhi = state.StateName;
         this.Delhi_noOfProjects = Number(state.noOfProjects);
         this.Delhi_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Delhi_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Delhi_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Delhi_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Delhi_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Delhi_colorcode ="FourthColor";
          }
       }
       else if( state.StateName=="PUNJAB"){    
         this.State_Punjab = state.StateName;
         this.Punjab_noOfProjects = Number(state.noOfProjects);
         this.Punjab_ProjectCost = Number(state.projectCost);
          if(state.noOfProjects ==0)
          {
            this.Punjab_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Punjab_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Punjab_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Punjab_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Punjab_colorcode ="FourthColor";
          }
       }
         else if( state.StateName=="KERALA"){    
           this.State_Kerala = state.StateName;
           this.Kerala_noOfProjects = Number(state.noOfProjects);
           this.Kerala_ProjectCost = Number(state.projectCost);
            if(state.noOfProjects ==0)
          {
            this.Kerala_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Kerala_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Kerala_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Kerala_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Kerala_colorcode ="FourthColor";
          }
         }
         else if( state.StateName=="LAKSHADWEEP"){    
           this.State_Lakshadweep = state.StateName;
           this.Lakshadweep_noOfProjects = Number(state.noOfProjects);
           this.Lakshadweep_ProjectCost = Number(state.projectCost);
            if(state.noOfProjects ==0)
          {
            this.Lakshadweep_colorcode ="ZeroColor";
          }
          if(state.noOfProjects <0.26*this.maxvalue && state.noOfProjects >0)
          {
            this.Lakshadweep_colorcode ="FirstColor";
          }
          if(state.noOfProjects >=0.25*this.maxvalue && state.noOfProjects < 0.51*this.maxvalue)
          {
            this.Lakshadweep_colorcode ="SecondColor";
          }
          if(state.noOfProjects >=0.51*this.maxvalue && state.noOfProjects <0.76*this.maxvalue)
          {
            this.Lakshadweep_colorcode ="ThirdColor";
          }
          if(state.noOfProjects > 0.75*this.maxvalue)
          {
            this.Lakshadweep_colorcode ="FourthColor";
          }
         }

       // this.states_data.push(delhitemp);
        this.states_data.push(temp);
       // this.states_data.push(['IN-LD','LAKSHADWEEP','0','0'],['GOA','GOA','0','0']);
      }
   
      console.log("states data------------")
      console.log(this.states_data)
       this.mapReady=true
      


      this.showEnrolementGraph();
      this.showchartonpageload();
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
  
  nlmDashboardstatewiseKpi()
  {
    this.nlmDashboardstatewisekpiData=[];
    this.serv.getDashboardstatewisekpiData().subscribe((res)=>{
      console.log("nlmstatewisekpiData by ab------------")
      console.log(res)
      this.nlmDashboardstatewisekpiData=res
      
       this.totalStateSchemeWiseData=[];
      var groupByStateName = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      var totalStateWiseData=groupByStateName(this.nlmDashboardstatewisekpiData, 'State_name')
      this.totalStateSchemeWiseData = Object.entries(totalStateWiseData)
      this.totalTopFiveStateWiseData=[];      
      this.totalStateSchemeWiseData.forEach((y:any) =>
      {
        const noOfProjects = y[1].map((item:any) => Number(item['Applications_Eligible'])).reduce((prev:any, curr:any) => prev + curr, 0);
        const projectCost = y[1].map((item:any) => Number(item['Project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
        this.projectStateWiseSchemeCost=this.numDifferentiation(projectCost);
      
        var finalObj1 = Object.assign({"State_name": y[0]},{"Applications_Eligible": noOfProjects},{"Project_Cost": this.projectStateWiseSchemeCost});
        this.totalTopFiveStateWiseData.push(finalObj1)
      });
      
      // var topValues = this.totalTopFiveStateWiseData.sort((a:any,b:any) => b['Applications_Eligible']-a['Applications_Eligible']).slice(0,5);
      // console.log("top states new")
      // console.log(topValues)     
      
      // this.topFirstPerfomanceState=topValues[0]['State_name'];
      // this.topFirstPerformingnoOfProjectsDone=topValues[0]['Applications_Eligible'];
      // this.topFirstPerformingprojectCost=topValues[0]['Project_Cost'];
      
      // this.topSecondPerfomanceState=topValues[1]['State_name'];
      // this.topSecondPerformingnoOfProjectsDone=topValues[1]['Applications_Eligible'];
      // this.topSecondPerformingprojectCost=topValues[1]['Project_Cost'];
      
      // this.topThirdPerfomanceState=topValues[2]['State_name'];
      // this.topThirdPerformingnoOfProjectsDone=topValues[2]['Applications_Eligible'];
      // this.topThirdPerformingprojectCost=topValues[2]['Project_Cost'];
      
      // this.topFourthPerfomanceState=topValues[3]['State_name'];
      // this.topFourthPerformingnoOfProjectsDone=topValues[3]['Applications_Eligible'];
      // this.topFourthPerformingprojectCost=topValues[3]['Project_Cost'];
      
      // this.topFifthPerfomanceState=topValues[4]['State_name'];
      // this.topFifthPerformingnoOfProjectsDone=topValues[4]['Applications_Eligible'];
      // this.topFifthPerformingprojectCost=topValues[4]['Project_Cost'];
      this.applicationsEligiblebyStateInArray = [];
        this.projectsEligibleByStateCount = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['Applications_Eligible'])).reduce((prev:any, curr:any) => prev + curr, 0);
        this.applicationsEligiblebyStateInArray.push(this.projectsEligibleByStateCount)
        
        const projectsEligibleByStateProjectCost = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['Project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
        this.projectsEligibleByStateProjectCostt=this.numDifferentiation(projectsEligibleByStateProjectCost);
      
        this.subsidyAmountReleasedCount = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['No_of_projects_received_inst'])).reduce((prev:any, curr:any) => prev + curr, 0);
      // // this.subsidyAmountReleasedd = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['Cumulative_amt_of_subsidy_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
         const subsidy_Amount_Released = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['Cumulative_amt_of_subsidy_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
        this.subsidyAmountReleasedd=this.numDifferentiation(subsidy_Amount_Released);
    //   //console.log(this.subsidyAmountReleasedCount)
    this.showEnrolementGraph();

    },
    (err)=>{
      console.log(err)
    }
  ); 
  }



  nlmDashboardsubsidyReleasedKpi()
  {
    this.nlmDashboardsubsidyReleasedKpiData=[];
    this.serv.getDashboardsubsidykpiData().subscribe((res)=>{
      console.log("nlmDashboardsubsidyReleasedKpiData by ab------------ getDashboardsubsidyReleasedKpiData")
      console.log(res)
      this.nlmDashboardsubsidyReleasedKpiData=res
      
    
      
       // this.subsidyAmountReleasedCount = this.nlmDashboardsubsidyReleasedKpiData.map((item:any) => Number(item['No_of_projects_received_inst'])).reduce((prev:any, curr:any) => prev + curr, 0);
      // // this.subsidyAmountReleasedd = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['Cumulative_amt_of_subsidy_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
         const subsidy_Amount_Released = this.nlmDashboardsubsidyReleasedKpiData.map((item:any) => Number(item['subsidyamt'])).reduce((prev:any, curr:any) => prev + curr, 0);
        this.NLMSubsidyReleasedtoSIDBI=this.numDifferentiation(subsidy_Amount_Released);
   

    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
  
  public geoChart: GoogleChartInterface = {
    chartType: 'GeoChart',
    dataTable: this.states_data,
    options: {
      domain:'IN',
      region: 'IN',
      sizeAxis: { minValue: 0, maxValue: 1 },
      colorAxis: {colors: ['#fff','#008832']},
      // colorAxis: {colors: ['#67cdcd','#67cdcd']},
      resolution: 'provinces',
      zoom: 6,
     // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
  disableDefaultUI: true,
  //displayMode: 'text',
  defaultColor:'#40e0d0',
  backgroundColor: 'transparent',
  //tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
      //'height': 600,
      'width': 850,
     

      
    }
  };
  showEnrolementGraph()
  {
    console.log("graph data")
    // console.log(this.totalNoOfProjectsInArray) 
    // console.log(this.projectCostInArray)
    console.log(this.applicationsEligiblebyStateInArray) 
    console.log(this.approvedProjectsbyDAHDInArray)
    
      this.yearWiseEnroleMentChart =   {
        
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // data: ['No. of Applications Registered', 'Project Cost (In Cr)']
          data: ['Applications Eligible by State', 'Approved Projects by DAHD']
        },
        toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          
          {
            type: 'category',
            axisTick: { show: true },
            axisLabel: {
              interval: 0,
              rotate: 0 //If the label names are too long you can manage this by rotating the label.
            },
            data:['Applications']
          },
        
        ],
        yAxis: [
          {
            splitLine: {
              show: false
           },
            axisLine:{                 //Coordinate axis
              show:true, 
                        //Show Axis axis or not        //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
          },
            type: 'value'
          }
        ],
        series: [
          {
            // name: 'No. of Applications Registered',
            name: 'Applications Eligible by State',
            type: 'bar',
            barGap: 0,
          //  label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data:this.applicationsEligiblebyStateInArray
          },
          {
            // name: 'Project Cost (In Cr)',
            name: 'Approved Projects by DAHD',
            type: 'bar',
           // label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data:this.approvedProjectsbyDAHDInArray
          }
         
        ]
      };
  
    }
    // public select(event: ChartSelectEvent) {
    // console.log(event.selectedRowValues[0])
    // if(event.selectedRowValues[0]=='Rajasthan')
    // {
    //   this.statePercent=40;
    // }
    // else if(event.selectedRowValues[0]=='Uttar Pradesh'){
    //   this.statePercent=70;
    // }
    // } 

    public select(event: any) {
      console.log("event");
      this.showPieChartpageload = false;
      this.showPieChartMapclick= true;
      console.log(event)
      console.log(this.totalStateSchemeWiseDAHDData)

      this.totalStateSchemeWiseDAHDData.forEach((y:any) =>
      {
     if(y[0]==event)
     {
       this.selectState=y[1]
     }
     
      });
      var groupByTypeDAHD = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalTypeDAHDStateData=groupByTypeDAHD(this.selectState, 'stateName')
  var totalTypeDAHDStateDataCount = Object.entries(totalTypeDAHDStateData)
  console.log(totalTypeDAHDStateDataCount)
  this.totalAggregateStateDAHDProjectCountData=[];
  this.totalAggregateStateDAHDProjectCostData=[];
  var StateName = null;
  var StateName1 = null;
if(event.message != "deselect")
{
  totalTypeDAHDStateDataCount.forEach((y:any) =>
  {
  //  var finalObj = Object.assign({"name": "Total Projects"}, {"value": this.approvedProjectsForSubsidyCount}); 
    var finalObj = Object.assign({"name": "Total Projects"}, {"value": this.noOfEntrepreneursRegisteredCount}); 
    
    this.totalAggregateStateDAHDProjectCountData.push(finalObj);
   
    //  var totalProjectCount =  y[1].map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);  
    var totalProjectCount =  y[1].map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);      
      //var finalObj = Object.assign({"name": y[0]}, {"value": totalProjectCount});
      finalObj = Object.assign({"name": "Projects"}, {"value": totalProjectCount});
      this.totalAggregateStateDAHDProjectCountData.push(finalObj);     
      StateName = event;

  });
  totalTypeDAHDStateDataCount.forEach((y:any) =>
  {   
    //var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.approvedProjectsForDAHDProjectCostt});
    var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.nlmProjectCostWithoutNum});      
    this.totalAggregateStateDAHDProjectCostData.push(finalObj1);      
    //  var totalProjectCost =  y[1].map((item:any) => Number(item['approved_projects_by_dahd_projectcost'])).reduce((prev:any, curr:any) => prev + curr, 0);      
    var totalProjectCost =  y[1].map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);  
    finalObj1 = Object.assign({"name": "Project Cost"}, {"value": totalProjectCost});
      this.totalAggregateStateDAHDProjectCostData.push(finalObj1);
      StateName1 = event;
  });
}
else{
 // var finalObj = Object.assign({"name": "Projects"}, {"value": this.approvedProjectsForSubsidyCount}); 
  var finalObj = Object.assign({"name": "Projects"}, {"value": this.noOfEntrepreneursRegisteredCount}); 
  this.totalAggregateStateDAHDProjectCountData.push(finalObj);
 // var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.approvedProjectsForDAHDProjectCostt});
 var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.nlmProjectCostWithoutNum});  
  this.totalAggregateStateDAHDProjectCostData.push(finalObj1);

  StateName = "Total Projects Submitted in Portal";
  StateName1 = "Total Cost of Projects Submitted in Portal";
}
  console.log("this.totalAggregateStateDAHDProjectCountData")
console.log(this.totalAggregateStateDAHDProjectCountData)
console.log(this.totalAggregateStateDAHDProjectCostData)

      this.typeOfDAHDChart = {
        title: {
          show:true,
          text: StateName,
          // text: event.selectedRowValues[0],
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          show:false
        },
        series: [
          {
           // name: 'Access From',
            type: 'pie',
            radius: '44%',
            // radius: '53%',
          //  radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateDAHDProjectCountData
          }
        ]
      }
      this.typeOfDAHDCostChart = {
        title: {
          show:true,
          text: StateName1,
          // text: event.selectedRowValues[0],
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          show:false
        },
        series: [
          {
           // name: 'Access From',
            type: 'pie',
            radius: '44%',
            // radius: '53%',
          //  radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateDAHDProjectCostData
          }
        ]
      }
  };

  public showchartonpageload() {
    this.showPieChartpageload = true;
    this.showPieChartMapclick= false;
    this.totalAggregateStateDAHDProjectCountData=[];
    this.totalAggregateStateDAHDProjectCostData=[];
  var StateName = null;
  var StateName1 = null;
  
  // var finalObj = Object.assign({"name": "Projects"}, {"value": this.approvedProjectsForSubsidyCount}); 
  var finalObj = Object.assign({"name": "Projects"}, {"value": this.noOfEntrepreneursRegisteredCount}); 
  this.totalAggregateStateDAHDProjectCountData.push(finalObj);
 // var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.approvedProjectsForDAHDProjectCostt}); 
  var finalObj1 = Object.assign({"name": "Total Cost"}, {"value": this.nlmProjectCostWithoutNum}); 
  this.totalAggregateStateDAHDProjectCostData.push(finalObj1);
  StateName = "Total Projects Submitted in Portal";
  StateName1 = "Total Cost of Projects Submitted in Portal";
          console.log("Total AI Done")
  console.log(this.totalAggregateStateDAHDProjectCountData)
  
  
      this.typeOfDAHDChart = {
        title: {
          show:true,
          // text: event.selectedRowValues[0],
          text: StateName,
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'right'
        // },
        series: [
          {
           // name: 'Access From',
           type: 'pie',
            radius: '44%',
           // radius: ['40%', '70%'],
            avoidLabelOverlap: false,
           
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '80%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateDAHDProjectCountData
          }
        ]
      };
      this.typeOfDAHDCostChart = {
        title: {
          show:true,
          // text: event.selectedRowValues[0],
          text: StateName1,
          textStyle: {fontSize: 10},
          left: 'center'
         
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'right'
        // },
        series: [
          {
           // name: 'Access From',
           type: 'pie',
            radius: '44%',
           // radius: ['40%', '70%'],
            avoidLabelOverlap: false,
           
            labelLine: {
              show: true
          },
            label: {
              formatter: '{b|{b}：}{c}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#796e70',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#796e70',
                  width: '80%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#796e70',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#796e70',
                  backgroundColor: 'black',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
           
            data: this.totalAggregateStateDAHDProjectCostData
          }
        ]
      };
  
  } 
//     public select(event: ChartSelectEvent) {
//       this.showPieChartpageload = false;
//       this.showPieChartMapclick= true;
//       console.log(event.selectedRowValues[0])
//       console.log(this.totalStateSchemeWiseDAHDData)

//       this.totalStateSchemeWiseDAHDData.forEach((y:any) =>
//       {
//      if(y[0]==event.selectedRowValues[0])
//      {
//        this.selectState=y[1]
//      }
     
//       });
//       var groupByTypeDAHD = function(xs:any, key:any) {
//         return xs.reduce(function(rv:any, x:any) {
//           (rv[x[key]] = rv[x[key]] || []).push(x);
//           return rv;
//         }, {});
//       };
//   var totalTypeDAHDStateData=groupByTypeDAHD(this.selectState, 'stateName')
//   var totalTypeDAHDStateDataCount = Object.entries(totalTypeDAHDStateData)
//   console.log(totalTypeDAHDStateDataCount)
//   this.totalAggregateStateDAHDData=[];
//   var StateName = null;
// if(event.message != "deselect")
// {
//   totalTypeDAHDStateDataCount.forEach((y:any) =>
//   {
//       var totalProjectCount =  y[1].map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);
//       var totalProjectCost =  y[1].map((item:any) => Number(item['approved_projects_by_dahd_projectcost'])).reduce((prev:any, curr:any) => prev + curr, 0);

//       //var finalObj = Object.assign({"name": y[0]}, {"value": totalProjectCount});
//       var finalObj = Object.assign({"name": "Projects"}, {"value": totalProjectCount});
//       this.totalAggregateStateDAHDData.push(finalObj);
//       finalObj = Object.assign({"name": "Projects Cost"}, {"value": totalProjectCost});
//       this.totalAggregateStateDAHDData.push(finalObj);
//       StateName = event.selectedRowValues[0];

//   });
// }
// else{
//   var finalObj = Object.assign({"name": "Projects"}, {"value": this.approvedProjectsForSubsidyCount}); 
//   this.totalAggregateStateDAHDData.push(finalObj);
//   finalObj = Object.assign({"name": "Projects Cost"}, {"value": this.approvedProjectsForSubsidyProjectCostt});
//   this.totalAggregateStateDAHDData.push(finalObj);

//   StateName = "Total Approved Projects And Cost by DAHD";
// }
//   console.log("this.totalAggregateStateDAHDData")
// console.log(this.totalAggregateStateDAHDData)


//       this.typeOfDAHDChart = {
//         title: {
//           show:true,
//           text: StateName,
//           // text: event.selectedRowValues[0],
//           textStyle: {fontSize: 10},
//           left: 'center'
         
//         },
//         tooltip: {
//           trigger: 'item'
//         },
//         legend: {
//           orient: 'vertical',
//           left: 'right',
//           show:false
//         },
//         series: [
//           {
//            // name: 'Access From',
//             type: 'pie',
//             radius: '44%',
//             // radius: '53%',
//           //  radius: ['40%', '70%'],
//             avoidLabelOverlap: true,
//             labelLine: {
//               show: true
//           },
//             label: {
//               formatter: '{b|{b}：}{c}  ',
//               backgroundColor: '#F6F8FC',
//               borderColor: '#8C8D8E',
//               borderWidth: 1,
//               borderRadius: 4,
//               rich: {
//                 a: {
//                   color: '#796e70',
//                   lineHeight: 22,
//                   align: 'center'
//                 },
//                 hr: {
//                   borderColor: '#796e70',
//                   width: '100%',
//                   borderWidth: 1,
//                   height: 0
//                 },
//                 b: {
//                   color: '#796e70',
//                   fontSize: 14,
//                   fontWeight: 'bold',
//                   lineHeight: 33
//                 },
//                 per: {
//                   color: '#796e70',
//                   backgroundColor: 'black',
//                   padding: [3, 4],
//                   borderRadius: 4
//                 }
//               }
//             },
//             emphasis: {
//               label: {
//                 show: true,
//                 fontSize: '15',
//                 fontWeight: 'bold'
//               }
//             },
           
//             data: this.totalAggregateStateDAHDData
//           }
//         ]
//       };



//   } 
}
