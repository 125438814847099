import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class SdcfposchemeService {

  constructor(public http: HttpClient) { }
  getSdcfpoData() {
    // return this.http.get('/assets/finalsud25012022.json');
   return this.http.get('https://dahddashboard.ndlm.co.in/api/sdcfpo');
    //return this.http.get('/assets/sdcfpo.json');
     }
}
