import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonToCSVService {

  //constructor() { }
  downloadFile(data:any, filename='data') {
    let csvData = null;
    if(filename=='lhdcp')
    {
        csvData = this.ConvertToCSV(data, ['State_code','States_Uts','MVUs_sanctioned', 'MVUs_operational'],'lhdcp');
    }
    else if(filename=='DIDF')
    {
        csvData = this.ConvertToCSV(data, ['State_Code','State_name','No_of_Projects', 'Total_Project_Outlay_Approved','Total_Loan_Approved','Total_Loan_disbursed','Milk_Processing_Capacity_In_LLPD','Value_added_and_Product_Capacity_In_LLPD','Drying_Capacity_In_MTPD','No_of_Dairy_Plant_Refurbished_Strengthened','Producer_Member_Covered'],'DIDF');
    }
    else if(filename=='RGM')
    {
        csvData = this.ConvertToCSV(data, ['State_Code','State_Name','IVF', 'MAITRIs_Trained_Inducted','Sex_Sorted_Semen_Doses_Produced','Sex_Sorted_Semen_Doses_Sold','Sex_Sorted_Semen_Doses_AI_Done','Breed_Multiplication_Farms_Sanctioned'],'RGM');
    }
    else if(filename=='NPDD')
    {
        csvData = this.ConvertToCSV(data, ['State_Code','State_name','No_of_projects_sanctioned','Total_Approved_Outlay','Central_Share','Funds_released','Funds_Utilised','Average_Daily_Milk_Procurement_Ach','Bulk_Milk_Cooler_Ach','Bulk_Milk_Cooler_Capacity','Automatic_Milk_Collection_Ach','Milk_Adulteration_Testing_Machines_Ach'],'NPDD');
    }
    else if(filename=='NLM')
    {
        csvData = this.ConvertToCSV(data, ['State_Code','State_name','Applications_Eligible', 'Project_Cost','No_of_projects_received_inst','No_of_projects_received_both_inst','Cumulative_amt_of_subsidy_released'],'NLM');
    }
    else if(filename=='SDCFPO')
    {
        csvData = this.ConvertToCSV(data, ['Milk_Cooperatives_FPOs_Aassisted','Working_Capital_Loan_Amount','Interest_Subvention_Disbursed'],'SDCFPO');
    } 
	else if(filename=='DidfFundingPattern')
    {
        csvData = this.ConvertToCSV(data, ['Scheme_Outlay','Loan_Component_from_NABARD_NDDB','End_Borrower_Contribution','Interest_Subvention'],'DidfFundingPattern');
    } 	
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray:any, headerList:any,CSVType:any) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
    /* let row = 'S.No,';
     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1); */
     let row =null;
    // let row = 'S. No.,State_Code,State_name,Sanctioned MVUs,Operational MVUs'; // Static Header By Abhishek
    if(CSVType=='lhdcp')
    {
        row = 'S. No.,State_Code,State_name,Sanctioned MVUs,Operational MVUs'; 
    }
    else if(CSVType=='DIDF')
    {
        row = 'S. No.,State_Code,State_name,No. of Projects,Total Project Outlay Approved,Total Loan Approved,Total Loan disbursed,Milk Processing Capacity(In LLPD),Value added and Product Capacity(In LLPD),Drying Capacity(In MTPD),No. of Dairy Plant Refurbished/Strengthened,Producer Member Covered'; 
    }
    else if(CSVType=='RGM')
    {
        row = 'S. No.,State_Code,State_name,In Vitro Fertilization Pregnancies,MAITRIs Trained,Sex Sorted Semen Doses Produced,Sex Sorted Semen Doses Sold,Sex Sorted Semen Doses AI Done,Breed Multiplication Farms Sanctioned'; 
    }
    else if(CSVType=='NPDD')
    {
        row = 'S. No.,State_Code,State_name,No._of_projects_sanctioned,Total_Approved_Outlay,Central_Share,Funds_released,Funds_Utilised,Average_Daily_Milk_Procurement_Ach,Bulk_Milk_Cooler_Ach,Bulk_Milk_Cooler_Capacity,Automatic_Milk_Collection_Ach,Milk_Adulteration_Testing_Machines_Ach'; 
    }
    else if(CSVType=='NLM')
    {
        row = 'S. No.,State_Code,State_name,Applications Eligible by State,Project Cost,No of projects received installment (atleast 1st) of subsidy,No of projects received both installment of Subsidy,Cumulative amount of subsidy released'; 
    }
    else if(CSVType=='SDCFPO')
    {
        row = 'S. No.,Number of Milk Cooperatives/FPOs Aassisted,Working Capital Loan Amount Considered for Interest Subvention (In Cr),Interest Subvention Disbursed (In Cr)'; 
    } 
	else if(CSVType=='DidfFundingPattern')
    {
        row = 'S. No.,Scheme Outlay,Loan Component,End Borrower Contribution,Interest Subvention'; 
    }  
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }
}
