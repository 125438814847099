<app-headerone></app-headerone>
<div class="heding-middle-text">Dairy Processing and Infrastructure Development Fund (DIDF)</div>


<div class="container">
  <!-- <div class="row">
  <div class="col-sm-2 " style="
  margin-left: 87%;
" >
   
    <button type="button" class="btn btn-success" >Upload File</button>
  </div>
</div> -->
<div class="row">
  <div class="col-sm-0" style="text-align: right;">  
    <button (click)="download()" class="btn-float btn btn-primary"> Download Current Data</button>
  </div>
</div>
  <div class="row"
    style="border: 1px solid #b9b9b4; margin-top: 16px; border-radius: 9px;max-width: 100%;margin-left: 0px;">

    <div class="col-sm-12 ">
      <div class="title_container mt-1">
      </div>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="row g-3">
          <div class="col-sm-3">
            <label for="description" class="form-label"> Scheme Outlay</label>
            <div class="input-group has-validation">
              <input required="required" type="text" class="form-control" id="so" formControlName="so" placeholder=""
                value="">
            </div>
            <span class="error-message" *ngIf="error('so', 'pattern')">
              Invalid number
            </span>
          </div>



          <div class="col-sm-3">
            <label for="description" class="form-label">Loan Component</label>
            <div class="input-group has-validation">
              <input required="required" type="text" class="form-control" id="lc" formControlName="lc" placeholder=""
                value="">
            </div>
            <span class="error-message" *ngIf="error('lc', 'pattern')">
              Invalid number
            </span>
          </div>



          <div class="col-sm-3">
            <label for="description" class="form-label">End Borrower Contribution</label>
            <div class="input-group has-validation">
              <input required="required" required="required" type="text" class="form-control" id="ebc"
                formControlName="ebc" placeholder="" value="">
            </div>
            <span class="error-message" *ngIf="error('ebc', 'pattern')">
              Invalid number
            </span>
          </div>

          <div class="col-sm-3">
            <label for="description" class="form-label">Interest Subvention</label>
            <div class="input-group has-validation">
              <input required="required" type="text" class="form-control" id="is" formControlName="is" placeholder=""
                value="">
            </div>
            <span class="error-message" *ngIf="error('is', 'pattern')">
              Invalid number
            </span>
          </div>

        </div>
        <hr class="my-4">
        <div class="col-12">

          <div class="title_container mt-1">
            <button type="submit" class="btn btn-primary btn-block text-uppercase mb-2 w-25 shadow-sm" style="
                    background-color: #2a4075; margin-left:40%">Add</button>
          </div>

        </div>
      </form>
    </div>

  </div>



  <div class="row mt-3">
    <div class="col-sm-12">
      <div class="card">
        <h5 class="card-header" style="border-color: skyblue;background-color: #2db8f10f;"> DETAILS </h5>

        <div class="card-body" *ngIf="npddDetailInJson.length>0">
          <p-table #dt id="myTable" [value]="npddDetailInJson" styleClass="noHeader" [columns]="colsUsers"
            [paginator]="true" [rows]="37" [style]="{'background-color':'#cecece'}" [resizableColumns]="true">

            <ng-template pTemplate="caption">
              <div class="ui small left icon input align-left">
                <input pInputText type="text" class="search-boxx"
                  (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search keyword" />
                <button type="button"  (click)="onUpload()"
                  style="float:right; width: 85px; margin-left:15px;overflow: unset;box-shadow: 0 0 0px 0 #9f9b9b, 0 0 2px 0 #878682, 0 0 0px 0 #b1aeae inset;"
                  class="btn btn-primary">Save
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" pResizableColumn [style]="{'background-color':'#e5f4fe'}"
                  [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>

                <th style="background-color:#e5f4fe">Action</th>
              </tr>
              <tr>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-npddDetailInJson let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" class="ui-resizable-column">
                  {{npddDetailInJson[col.field]}}

                </td>

                <td>
                  <button type="button" class="btn btn-danger"
                    (click)="deleteRow(npddDetailInJson.state)">Delete</button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>


  </div>


</div>