import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class StatedashboardService {

  constructor(public http: HttpClient) { }
getData() {
  // return this.http.get('/assets/finalsud25012022.json');
  return this.http.get('/assets/geo.json');
   }
   
getLhdcData() {
	return this.http.get('https://dahddashboard.ndlm.co.in/api/nadcp');
    //return this.http.get('/assets/nadcp.json');
}
getMvuData()
{
	return this.http.get('https://dahddashboard.ndlm.co.in/api/mvu');
    //return this.http.get('/assets/mvu.json');
}
}
