<app-header></app-header>
<div class="heding-middle-text">Supporting Dairy Cooperatives & Farmer Producer Organizations (SDCFPO)<div class="btn-float-left">Last Updated:{{curentDate}}</div></div>
<div class="container" style="max-width: 1228px;">
    <div style="background-color: #f0f1f1;  padding: 16px 12px 18px 15px; border-radius: 10px; ">
      <div class="row">
        <div class="col-sm-4 mt-4" style="text-align: center;">
          <div class="card" style="border-radius: 10px;" >
            <div class="card-body">
              <div class="row">
                <div class="mt-3 col-sm-12">               
               </div>
               <div class="mt-3 col-sm-12 text-right heading-one">
                <p>Number of Milk Cooperatives/ <br>FPOs Aassisted</p> 
                       
               </div>
               <div class="col-sm-12 text-right heading-one" style="padding: 0px 118px;">
               
              <p class="card-title mt-3 total-livestock-count-blue" > {{MilkCooperatives}}</p>    
                               <!--<<p class="card-title mt-3 total-livestock-count-blue" > 60 </p> -->
               </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
  
        <div class="col-sm-4 mt-4" style="text-align: center;">
            <div class="card" style="border-radius: 10px;" >
              <div class="card-body">
                <div class="row">
                  <div class="mt-3 col-sm-12">               
                 </div>
                 <div class="mt-3 col-sm-12 text-right heading-one">
                  <p>Working Capital Loan Amount<br>Considered for Interest Subvention</p> 
                         
                 </div>
                 <div class="col-sm-12 text-right heading-one" style="padding: 0px 118px;">
                 
                    <p class="card-title mt-3 total-livestock-count-blue" >{{WorkingCapitalLoanAmount}} Cr</p>  
				                 <!--< <p class="card-title mt-3 total-livestock-count-blue" >29235 Cr</p>-->          
                 </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
  
          <div class="col-sm-4 mt-4" style="text-align: center;">
            <div class="card" style="border-radius: 10px;" >
              <div class="card-body">
                <div class="row">
                  <div class="mt-3 col-sm-12">               
                 </div>
                 <div class="mt-3 col-sm-12 text-right heading-one">
                  <p>Interest Subvention <br>Disbursed</p> 
                         
                 </div>
                 <div class="col-sm-12 text-right heading-one" style="padding: 0px 118px;">
                 
                 <p class="card-title mt-3 total-livestock-count-blue" >{{InterestSubvention}} Cr</p> 
				  <!--  <p class="card-title mt-3 total-livestock-count-blue" >313 Cr</p>-->          
                 </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
      </div>
                    

  
  
     
    </div> 
 <div class="bg-img">
    <div class="row" style="margin-top:35px" >
        
        <span class="box-heading-one">SUCCESS STORIES</span>
        <owl-carousel-o [options]="customOptions">
   
            <ng-container *ngFor="let slide of slides">

              <ng-template carouselSlide [id]="slide.id">
                <div class="col" style="
                margin-top: 5px;
            ">
                   <div class="card" style="border-radius: 20px;">
                  
                     <div class="card-body" style="height: 170px; ">
                        
                         <!-- <div class="container"> -->
                      <div class="row" *ngIf="slide.id == '1'">
                          
                            <div class="col-sm-2">
                             
                               <div  class="icon-box"style=" margin-top: 15px;">
                                 <span  class="registrations-today-icon"><img  src="/assets/imgs/background/SDCFPO2.png" style="height: 70px;" alt=""></span></div>
                             
                            </div>
                            <div class="col-sm-10 text-right" >
                             <span class="box-heading-two">Banaskantha Milk Union</span>
                              <p style="font-size: 14px;">Banaskantha Milk Union (Gujarat) availed loans of Rs. 920 Crore during 2020-21 and Rs. 1720 Crore during 2021-22 from banks under this scheme, which played a significant role in the Union’s ability to continue procuring milk and making regular payments to its dairy farmers despite the challenges posed by the COVID-19 crisis.</p>
                            </div>
                          </div>
                          <div class="row" *ngIf="slide.id == '2'">
                           
                           <div class="col-sm-2">
                            
                              <div  class="icon-box"style=" margin-top: 15px;">
                                <span  class="registrations-today-icon"><img  src="/assets/imgs/background/SDCFPO1.png" style="height: 70px;" alt=""></span></div>
                            
                           </div>
                           <div class="col-sm-10 text-right" >
                            <span class="box-heading-two">Bangalore Milk Union</span>
                             <p style="font-size: 14px;">In 2020, milk procurement volumes went up and the Bangalore Milk Union was able to make regular payments to dairy farmers despite decrease in milk sales and other challenges posed by Covid lockdown. Loans of Rs. 200 Cr from commercial banks during 2020-22 from Govt of India under this scheme allowed the Union to make timely payments to milk producer members during the pandemic years.</p>
                           </div>
                         </div>
                         <!-- </div> -->
                       </div>               
        </div>
      </div>
    </ng-template>
</ng-container>
   
</owl-carousel-o>
    

    </div>
    </div>	
   </div>