import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { DidfschemeService } from './didfscheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-didfscheme',
  templateUrl: './didfscheme.component.html',
  styleUrls: ['./didfscheme.component.scss']
})
export class DidfschemeComponent implements OnInit {
  
  yearWiseEnroleMentChart:any;
  states_data = [['State','COVID-Confirmed Cases']];
  result:  any = new Array()
  response:  any = new Array()
  totalSateWiseFundsUtilised:  any = new Array()
  totalLoanApprovedStateWise:  any = new Array()
  topFiveFundsUtilisedArray:  any = new Array()
  didfAllData:  any = new Array()
  fundingPattern:  any = new Array()
  totalProjectOutlayApproved:any;
  dryingCapacityInMTPD:any;
  noofProjects:any
  stateLength:any
  valueInFormate:any
  lastDateFromArray: any;
  totalLoanApproved:any;
  totalLoanDisbursed:any;
  milkProcessingCapacityInLLPD:any
  valueaddedandProductCapacityInLLPD:any
  noofDairyPlantRefurbishedStrengthened:any
  producerMemberCovered:any
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  curentDate: any;
  tloanDisbured:any
  mapReady=false;
  noday: any;
  topFistState: any;
  topFistTotalLoanApprovedState: any;
  topSecandState: any;
  topSencandTotalLoanApprovedState: any;
  topTirdState: any;
  topThirdTotalLoanApprovedState: any;
  topFourthState: any;
  topFourthTotalLoanApprovedState: any;
  topFiveState: any;
  topFiveTotalLoanApprovedState: any;
  SchemeOutlay: any;
  LoanComponentfromNABARDNDDB: any;
  EndBorrowerContribution: any;
  InterestSubvention: any;
  constructor(public serv: DidfschemeService) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'},
    {id: '3'}
  ];
  ngOnInit(): void {
    this.showEnrolementGraph();

   


    this.serv.getData().subscribe((res)=>{
      console.log(res)
      this.result=res;
     
     // this.result.splice(0,1);

      for(let state of this.result){
        let temp = [state.state,Number(state.confirmed)];
        if( state.state=="Odisha"){
          temp = ['IN-OR',Number(state.confirmed)];
        }
        this.states_data.push(temp);
      }
      this.mapReady=true
    },
    (err)=>{
      console.log(err)
    }
  );
this.DidfDashboardData();
this.getDidfFundingPatterns();
  }

  DidfDashboardData()
  {
    this.didfAllData=[];
    this.serv.getDidfData().subscribe((res)=>{
    this.didfAllData=res;
    console.log("all  didf dataaaaaaaaa")
    console.log(res)
    console.log(this.didfAllData)
    console.log(this.didfAllData.length)
    this.stateLength=this.didfAllData.length
console.log("state lengthhh")
    console.log(this.stateLength)
  

 this.lastDateFromArray = this.didfAllData[this.didfAllData.length - 1];
    console.log("last date--------------")
    console.log(this.lastDateFromArray)
    this.dateres = this.lastDateFromArray['created_at'].split("T");
    console.log( this.dateres[0])
    this.dateresone=this.dateres[0].split("-")
    console.log( this.dateresone)
    this.year=this.dateresone[0]
    this.month=this.dateresone[1]
    this.day=this.dateresone[2]
    this.noday=Number(this.day)
    this.noday+=1
    console.log(this.noday)
    this.curentDate=this.noday+'/'+this.month+'/'+this.year;
    this.lastDateFromArray =this.curentDate



    this.noofProjects = this.didfAllData.map((item:any) => Number(item['No_of_Projects'])).reduce((prev:any, curr:any) => prev + curr, 0);
    


 this.totalProjectOutlayApproved = this.didfAllData.map((item:any) => Number(item['Total_Project_Outlay_Approved'])).reduce((prev:any, curr:any) => prev + curr, 0).toFixed(2);
//this.totalProjectOutlayApproved =this.numDifferentiations(alltotalProjectOutlayApproved);
console.log("project outlay")
console.log(this.totalProjectOutlayApproved)
 
  this.totalLoanApproved = this.didfAllData.map((item:any) => Number(item['Total_Loan_Approved'])).reduce((prev:any, curr:any) => prev + curr, 0).toFixed(2);
 const totalLoanApprovedatt = this.didfAllData.map((item:any) => Number(item['Total_Loan_Approved'])).reduce((prev:any, curr:any) => prev + curr, 0);
// this.totalLoanApproved =this.numDifferentiations(this.totalLoanApproved);
 //this.totalLoanApproved =this.numDifferentiations(alltotalLoanApproved);
 console.log("Total_Loan_Approved")
 console.log(this.totalLoanApproved )
this.totalLoanDisbursed = 0;
 this.totalLoanDisbursed = this.didfAllData.map((item:any) => Number(item['Total_Loan_disbursed'])).reduce((prev:any, curr:any) => prev + curr, 0);
  const sumtotalLoanDisbursed = this.didfAllData.map((item:any) => Number(item['Total_Loan_disbursed'])).reduce((prev:any, curr:any) => prev + curr, 0);

// this.totalLoanDisbursed =this.numDifferentiations(this.totalLoanDisbursed);
 //this.totalLoanDisbursed =this.numDifferentiations(alltotalLoanDisbursed);
const num1 = sumtotalLoanDisbursed.toFixed(2);
 console.log("Total_Loan_disbursed")
 console.log(this.totalLoanDisbursed)
 console.log(this.didfAllData)
 console.log(num1)
 this.tloanDisbured=num1;
 
 const allmilkProcessingCapacityInLLPD = this.didfAllData.map((item:any) => Number(item['Milk_Processing_Capacity_In_LLPD'])).reduce((prev:any, curr:any) => prev + curr, 0);
 this.milkProcessingCapacityInLLPD =this.numDifferentiation(allmilkProcessingCapacityInLLPD);
 

 console.log("Milk_Processing_Capacity_In_LLPD")
 console.log(allmilkProcessingCapacityInLLPD)
 const allvalueaddedandProductCapacityInLLPD = this.didfAllData.map((item:any) => Number(item['Value_added_and_Product_Capacity_In_LLPD'])).reduce((prev:any, curr:any) => prev + curr, 0);

 this.valueaddedandProductCapacityInLLPD =this.numDifferentiation(allvalueaddedandProductCapacityInLLPD);
 
 
 const alldryingCapacityInMTPD = this.didfAllData.map((item:any) => Number(item['Drying_Capacity_In_MTPD'])).reduce((prev:any, curr:any) => prev + curr, 0);

 this.dryingCapacityInMTPD =this.numDifferentiation(alldryingCapacityInMTPD);
 

 const allnoofDairyPlantRefurbishedStrengthened = this.didfAllData.map((item:any) => Number(item['No_of_Dairy_Plant_Refurbished_Strengthened'])).reduce((prev:any, curr:any) => prev + curr, 0);

 this.noofDairyPlantRefurbishedStrengthened =this.numDifferentiation(allnoofDairyPlantRefurbishedStrengthened);

 const allproducerMemberCovered = this.didfAllData.map((item:any) => Number(item['Producer_Member_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
 this.producerMemberCovered =this.numDifferentiation(allproducerMemberCovered);

 this.totalSateWiseFundsUtilised=[];
 var groupByFundsUtilised = function(xs:any, key:any) {
   return xs.reduce(function(rv:any, x:any) {
     (rv[x[key]] = rv[x[key]] || []).push(x);
     return rv;
   }, {});
 };
 var totalStateData=groupByFundsUtilised(this.didfAllData, 'State_name')
 this.totalSateWiseFundsUtilised = Object.entries(totalStateData)



 this.totalLoanApprovedStateWise=[];
 this.totalSateWiseFundsUtilised.forEach((y:any) =>
 {
 const sumallTotalLoanApproved = y[1].map((item:any) => Number(item['Total_Loan_Approved'])).reduce((prev:any, curr:any) => prev + curr, 0);
 
 var finalObj1 = Object.assign({"State": y[0]}, {"sumallTotalLoanApproved": sumallTotalLoanApproved});
 this.totalLoanApprovedStateWise.push(finalObj1)
 });
 console.log("current State all data----")
 
     console.log( this.totalLoanApprovedStateWise)
     var topValues = this.totalLoanApprovedStateWise.sort((a:any,b:any) => b['sumallTotalLoanApproved']-a['sumallTotalLoanApproved']).slice(0,5);
 console.log("top states")
 console.log(topValues)
 
 this.topFiveFundsUtilisedArray=[];
 
 topValues.forEach((y:any) =>
 {
 var finalObj1 = Object.assign({"State": y['State']}, {"sumallTotalLoanApproved": y['sumallTotalLoanApproved']});
 this.topFiveFundsUtilisedArray.push(finalObj1);
 });

 console.log("top five state data")
console.log(this.topFiveFundsUtilisedArray)

console.log(this.topFiveFundsUtilisedArray)
this.topFistState=this.topFiveFundsUtilisedArray[0]['State']
this.topFistTotalLoanApprovedState=this.topFiveFundsUtilisedArray[0]['sumallTotalLoanApproved']
this.topSecandState=this.topFiveFundsUtilisedArray[1]['State']
this.topSencandTotalLoanApprovedState=this.topFiveFundsUtilisedArray[1]['sumallTotalLoanApproved']
this.topTirdState=this.topFiveFundsUtilisedArray[2]['State']
this.topThirdTotalLoanApprovedState=this.topFiveFundsUtilisedArray[2]['sumallTotalLoanApproved']
this.topFourthState=this.topFiveFundsUtilisedArray[3]['State']
this.topFourthTotalLoanApprovedState=this.topFiveFundsUtilisedArray[3]['sumallTotalLoanApproved']
this.topFiveState=this.topFiveFundsUtilisedArray[4]['State']
this.topFiveTotalLoanApprovedState=this.topFiveFundsUtilisedArray[4]['sumallTotalLoanApproved']
    },
    (err)=>{
      console.log(err)
    }
  );
  }
  getDidfFundingPatterns()
  {
    this.fundingPattern=[];
    this.serv.getDidfFundingPattern().subscribe((res)=>{
      console.log("didf datatatatata")
      this.fundingPattern=res;
      this.SchemeOutlay=this.fundingPattern[0]['Scheme_Outlay']
      this.LoanComponentfromNABARDNDDB=this.fundingPattern[0]['Loan_Component_from_NABARD_NDDB']
      this.EndBorrowerContribution=this.fundingPattern[0]['End_Borrower_Contribution']
      this.InterestSubvention=this.fundingPattern[0]['Interest_Subvention']
    console.log(res);
    });
  }

  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }else if (val >= 10) {
      this.valueInFormate    = val ;
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }

  numDifferentiations(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) ;
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) ;
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2);
    }else if (val >= 10) {
      this.valueInFormate    = val ;
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }

  public geoChart: GoogleChartInterface = {
    chartType: 'GeoChart',
    dataTable: this.states_data,
    options: {
      domain:'IN',
      region: 'IN',
      colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
      resolution: 'provinces',
      zoom: 6,
     // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
  disableDefaultUI: true,
  //displayMode: 'text',
  defaultColor:'#40e0d0',
  backgroundColor: 'transparent',
  tooltip: {
    isHtml: true
},
legend: 'none',
datalessRegionColor: 'transparent',
      //'height': 600,
      'width': 1,
     

      
    }
  };


  showEnrolementGraph()
  {
      
      this.yearWiseEnroleMentChart =  {
        title: {
               show: false,
               left: 'center',
               text: 'COURSE  ENROLMENT STATICS GRAPH  ',
             },
        tooltip: {
               trigger: 'axis'
             },
      
            axisLabel: {
              interval:0,
              rotate: 65,
          },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value',
          axisLine:{                 //Coordinate axis
            show:true,             //Show Axis axis or not
            onZero:true,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
        },
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            color:'#5470c6'
  
          //  color: '#8E24AA'
          }
        ]
      };
  
    }
    
}
