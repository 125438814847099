import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class NpddschemeService {
  constructor(public http: HttpClient) { }
  getData() {
    // return this.http.get('/assets/finalsud25012022.json');
    return this.http.get('/assets/geo.json');
     }
     getNpddData()
     {
     return this.http.get('https://dahddashboard.ndlm.co.in/api/npdd');
	  // return this.http.get('/assets/npdd.json');
     }
}
