import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http';
/// TODO
import { DataService } from '../core/data.service';
@Component({
  selector: 'app-sdcfpoentryform',
  templateUrl: './sdcfpoentryform.component.html',
  styleUrls: ['./sdcfpoentryform.component.scss']
})
export class SdcfpoentryformComponent implements OnInit {
  registerForm: any = FormGroup;
  submitted = false;
  /// TODO
  intRegex = /((\d+)((\.\d{1,2})?))$/;
  constructor(private formBuilder: FormBuilder, private router: Router,
    /// TODO  : add this service
    private dataService: DataService,private JsonToCSVService:JsonToCSVService,public http: HttpClient) { }
  get f() { return this.registerForm.controls; }
  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //True if all the fields are filled
    if (this.submitted) {
      console.log(this.registerForm['value']);
    }

    /// TODO
    const data = this.registerForm.value;
    this.dataService.sdcfpoCreate(data.noofmilk, data.workingcapital, data.interestsubvention).subscribe((result: any) => {
      //alert(result.message);
	  alert("Added Successfully ");
      this.registerForm.patchValue({
        noofmilk: '',
        workingcapital: '',
        interestsubvention: '',
      })
    })


  }
  ngOnInit(): void {
    /// TODO
    this.registerForm = this.formBuilder.group({
      noofmilk: new FormControl('', [Validators.pattern(this.intRegex), Validators.maxLength(256)]),
      workingcapital: new FormControl('', [Validators.pattern(this.intRegex), Validators.maxLength(256)]),
      interestsubvention: new FormControl('', [Validators.pattern(this.intRegex), Validators.maxLength(256)]),
    });
  }
  // -- Add by Abhishek for JSON To CSV
allSDCFPOData:any
download(){
  this.getSDCFPODashboardData();  
}
getSDCFPOData() {
  return this.http.get('https://dahddashboard.ndlm.co.in/api/sdcfpo');
//return this.http.get('/assets/didf.json');
 }

getSDCFPODashboardData()
{
this.getSDCFPOData().subscribe((res)=>{
  console.log("SDCFPO Data Result");
  console.log(res);
  this.allSDCFPOData=res;
  this.JsonToCSVService.downloadFile(this.allSDCFPOData, 'SDCFPO');
},
(err)=>{
console.log(err)
}
); 
}
// ---- End Abhi Code -----
}
