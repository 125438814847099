<app-header></app-header>

      <div class="container" style="max-width: 1205px;">
       
        <div class="row" >
           
           

<div class="col-sm-6">
          <p style=" font-weight: 700;font-size: 14px; margin-bottom: 2px; margin-left: 5px; ">State-wise Information for Dairy Processing and Infrastructure Development Fund (DIDF)</p> 
          <!-- <p-dropdown [options]="districtsName" [(ngModel)]="selectedCity1" (onChange)="OnChangeDistrict($event)" autoWidth="false" [style]="{'width':'100%', 'border-radius': '1px'}"  [filter]="true" filterBy="name"
          [showClear]="false" placeholder="Please Select Districts" styleClass="my-dropdown">
          <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="selectedCity1">
              <div></div>
            </div>
          </ng-template>
          <ng-template let-usertype pTemplate="item">
            <div class="country-item">
              <div></div>
            </div>
          </ng-template>
        </p-dropdown> -->
    </div> 
        

    <div class="col-sm-6">
        <div class="card" style="background-color: #005588; color: white; border-radius: 10px;" >
           
          <div class="card-body" >
         
            <!-- <div class="container"> -->
            <div class="row">
              <div class="col-sm-6" >
                <div class="row" >
                    <div class="col-sm-8" > 
                        <p class="" style="font-size: 11px; ">Number of Projects Sanctioned</p>
                        <!-- <p class="" style="font-size: 11px; ">Farmers Benefited</p> -->
                    </div> 
                    <div class="col-sm-4"> 
                        <h5 _ngcontent-tda-c67="" class="total-livestock-count-blue-nlm"> {{noofProjects}}</h5>
                        <!-- <h5 _ngcontent-tda-c67="" class="total-livestock-count-dark-blue-nlm"> {{totalNoOfFarmersBenefittedInNaipIII}}</h5> -->
                    </div> 
                 
                </div>
             </div>        
            </div>  
            <!-- </div> -->
          </div>
        </div>
      </div>


        </div>




        <div class="heding-middle">
            <div class="row">
           
          
              <div class="col">
                <div class="card" style="border-radius: 10px;">
                  <div class="card-body">
                   <p class="box-heading">SUCCESS STORIES</p>
                                           
                    <!-- <div class="container"> -->
                        <div class="row" style="
                        height: 278px;
                    ">
                          <div class="col-sm-12">
                                   
        <owl-carousel-o [options]="customOptions">
   
            <ng-container *ngFor="let slide of slides">

              <ng-template carouselSlide [id]="slide.id">
                <div class="col">
                   <div class="card" style="
                   border: 0px;
               " >
                  
                     <div class="card-body" >
                        
                         <!-- <div class="container"> -->
                          <div class="row" *ngIf="slide.id == '1'">
                          
                            <div class="col-sm-2">
                             
                               <div  class="icon-box"style=" margin-top: 15px;">
                                 <span  class="registrations-today-icon"><img  src="/assets/imgs/background/04.png" style="height: 70px;" alt=""></span></div>
                             
                            </div>
                            <div class="col-sm-10 text-right" >
                             <span class="box-heading-two">Ropar District Cooperative Milk Producers’ Union Prospers Under DIDF</span>
                              <p style="font-size: 14px;">A new state-of-the-art fermented product plant was established (capacity 3.70 LLPD). It is paying a better price to its producer members - Rs. 39/kg (2021-22) compared to Rs. 33.71/kg (2016-17)</p>
                            </div>
                          </div>
                          <div class="row" *ngIf="slide.id == '2'">
                           
                           <div class="col-sm-2">
                            
                              <div  class="icon-box"style=" margin-top: 15px;">
                                <span  class="registrations-today-icon"><img  src="/assets/imgs/background/06.png" style="height: 70px;" alt=""></span></div>
                            
                           </div>
                           <div class="col-sm-10 text-right" >
                            <span class="box-heading-two">Panchmahal Milk Union Prospers Under DIDF</span>
                             <p style="font-size: 14px;">State-of-the-art Liquid Milk (6 LLPD) & Product Plant of 7.50 LLPD was established. The Union paid Rs. 741/kg fat (2021-22) to its producers, compared to Rs. 718/kg fat (2020-21).</p>
                           </div>
                         </div>
                         <!-- </div> -->
                       </div>               
        </div>
      </div>
    </ng-template>
</ng-container>
   
</owl-carousel-o>
                          </div>
                          
                          </div>
                  </div>
                </div>
              </div>
              </div>

        </div>


<div style="margin-top: -398px;">
        <div class="row" >
            <!-- <div class="col-sm-12">
                <div class="col-sm-6" >
                    <div class="card" style="border-radius: 10px;">
                      <div class="card-body">
                       <p class="box-heading" style="text-align: center;"> FUNDING PATTERN AND SOURCE OF FUND</p >
                        
                            <div class="row">
                                <div class="col-sm-3">
                     <p class="card-text-one">Scheme Outlay</p>
                                
                                </div>
                                <div class="col-sm-3 text-right">
                                    <p class="card-text-one">Loan Component<br> (from NABARD/NDDB)</p>
                                    
                                </div>
                                <div class="col-sm-3 text-right">
                                    <p class="card-text-one"> End Borrower Contribution</p>
                                   
                                </div>
                                <div class="col-sm-3 text-right">
                                    <p class="card-text-one">  Interest Subvention  &nbsp;</p>
                                 
                                  
                                </div>
                              </div>
        
        
                              <div class="row">
                                <div class="col-sm-3">
                                  <h5 class="card-title mt-3 total-livestock-count-blue" > 11,184 Cr</h5>
                                </div>
                                <div class="col-sm-3 text-right">
                                  
                                    <h5 class="card-title mt-3 total-livestock-count-green" > 8,004 Cr</h5>
                                </div>
                                <div class="col-sm-3 text-right">
                                  
                                    <h5 class="card-title mt-3 total-livestock-count-green"> 2,001Cr</h5>
                                </div>
                                <div class="col-sm-3 text-right">
                                    
                                    <h5 class="card-title mt-3 total-livestock-count-green" > 1,167 </h5>
                                </div>
                              </div>
                      </div>
                    </div>
                  </div>
                    </div> -->



              <div class="row mt-3" >
                <div class="col-sm-6" >
                    <div class="card" style="border-radius: 10px;" >
                      <div class="card" style="border-radius: 10px; width: 102%;">
                          <div class="card-body">
                           <p class="box-heading " style="text-align: center;"> FINANCIAL PROGRESS</p>
                            <!-- <div class="container"> -->
                                <div class="row">
                                    <div class="col-sm-4">
                         <p class="card-text-one">Total Project Outlay Approved</p>
                               
                                    </div>
                                    <div class="col-sm-4 text-right">
                                        <p class="card-text-one">Total Loan Approved </p>
                                     
                                    </div>
                                    <div class="col-sm-4 text-right">
                                        <p class="card-text-one">Total Loan disbursed</p>
                                     
                                    </div>
                                    
                                  </div>
  
                                  <div class="row">
                                    <div class="col-sm-4">
                       
                                   <h5 class="card-title total-livestock-count-blue mt-4 ">{{totalProjectOutlayApproved}} Cr</h5>
                                    </div>
                                    <div class="col-sm-4 text-right">
                                      
                                   <h5 class="card-title total-livestock-count-green mt-4">{{totalLoanApproved}} Cr</h5>
                                    </div>
                                    <div class="col-sm-4 text-right">
                                       
                                        <h5 class="card-title total-livestock-count-green mt-4">{{totalLoanDisbursed}} Cr</h5>
                                    </div>
                                    
                                  </div>
  
                          </div>
                        </div>
                    </div>
                  </div>





                  
                        </div>
      



                        <div class="heding-middle-three">
                            <div class="row mt-3">
                                <div class="col-sm-6" >
                                  <div class="card" style="border-radius: 10px;" >
                                    <div class="card" style="border-radius: 10px; width: 100.5%;">
                                        <div class="card-body">
                                         <p class="box-heading" style="text-align: center;"> PHYSICAL PROGRESS</p>
                                          <!-- <div class="container"> -->
                                        
                      
                                            <div class="row">
                                       
                                              <div class="col-sm-3" style="width: 117px;">
                                                <p class="card-text-one">Milk Processing Capacity<br> (In LLPD)</p>      
                                              
                                              </div>
                                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                                <p class="card-text-one">Value added and Product Capacity (In LLPD)</p>      
                                              
                                              </div>
                                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                                <p class="card-text-one">Drying Capacity<br> (In MTPD)</p>      
                                               
                                            </div>
                                            <div class="col-sm-2 text-right" style=" width: 117px;">
                                                <p class="card-text-one">No. of Dairy Plant Refurbished/<br>Strengthened</p>      
                                              
                                            </div>
                                            <div class="col-sm-3 text-right" style="width: 113px;" >
                                                <p class="card-text-one">Producer Member Covered</p>      
                                               
                                            </div>
                                            </div>
                
                
                                            <div class="row">
                                       
                                              <div class="col-sm-3" style=" width: 117px;">
                                                   
                                                <h5 class="card-title  total-livestock-count-blue">{{milkProcessingCapacityInLLPD}}</h5>
                                                     
                                              </div>
                                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                                     
                                                <h5 class="card-title total-livestock-count-green" >{{valueaddedandProductCapacityInLLPD}}</h5>
                                              </div>
                                              <div class="col-sm-2 text-right" style=" width: 117px;">
                                                   
                                                <h5 class="card-title total-livestock-count-green" >{{dryingCapacityInMTPD}}</h5>
                                            </div>
                                            <div class="col-sm-2 text-right" style=" width: 117px;">
                                                     
                                                <h5 class="card-title total-livestock-count-green" >{{noofDairyPlantRefurbishedStrengthened}}</h5>
                                            </div>
                                            <div class="col-sm-3 text-right" style="width: 113px;" >
                                                   
                                                <h5 class="card-title  total-livestock-count-green" >{{producerMemberCovered}}</h5>
                                            </div>
                                            </div>
                      
                                        </div>
                                      </div>
                                  </div>
                                </div>
                          
                              </div>
                  
                        </div>

                                </div>                     
          <!-- <div class="heding-middle-one">STATE WISE RASHTRIYA GOKUL MISSION DATA</div> -->
          <!-- <circle-progress [percent]="statePercent" [radius]="60"  [outerStrokeWidth]="15" [innerStrokeWidth]="10"
  [outerStrokeColor]="'#3777BE'" [innerStrokeColor]="'#7f9fc1'" [animation]="true" [animationDuration]="300" style="
  width: 20px; margin-left: 75%;"
></circle-progress> -->
<div class="row ">
                       
  <div class="col-sm-8" style="
  padding-left: 243px;
  padding-top: -8px;
  margin-top: -25px;
">
    <google-chart [data]="geoChart"   *ngIf=mapReady></google-chart>
    
  </div>
  <!-- <div class="col-sm-4">
    <div echarts class="demo-chart" [ngClass]="{'graph-border' : typeOfVacinationChart}"
[options]="typeOfVacinationChart"></div>
    
  </div> -->
 
</div>
 
</div>



   


         

      