import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../core/data.service';
import { Globals } from '../globals';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http' 

@Component({
  selector: 'app-nlmentryform',
  templateUrl: './nlmentryform.component.html',
  styleUrls: ['./nlmentryform.component.scss']
})
export class NlmentryformComponent implements OnInit {

  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  registerForm: any = FormGroup;
  rgmDetailInJson: any = new Array()
  submitted = false;
  colsUsers: any;
  globals!: Globals;
  csvData: any;
  constructor(private formBuilder: FormBuilder, private router: Router, globals: Globals, private dataService: DataService, 
    private JsonToCSVService:JsonToCSVService,public http: HttpClient) {
    this.globals = globals;
  }
  get f() { return this.registerForm.controls; }
  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if (this.submitted) {
		var state = this.globals.indianState.find(c => c.code ===this.registerForm.value.state );

	if(state === undefined || state === null){
	  
        console.log("your key or value exists!");
      }
      else {
        var finalObj = Object.assign({ "state": state.name }, { "stateCode": state.code }, { "aebs": this.registerForm['value']['aebs'] }, { "pc": this.registerForm['value']['pc'] }, { "npcis": this.registerForm['value']['npcis'] }, { "npris": this.registerForm['value']['npris'] }, { "casr": this.registerForm['value']['casr'] });
        this.rgmDetailInJson.push(finalObj);
      }
    }
    console.log("form submmit value")
    console.log(this.rgmDetailInJson)
  }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      state: new FormControl('', [Validators.required]),
      aebs: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      pc: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      npcis: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      npris: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      casr: new FormControl('', [Validators.pattern(this.globals.intRegex)]),     
    });
    this.initializeColumns();
  }
  initializeColumns() {
    this.colsUsers = [
      { field: 'state', header: 'State' },
      { field: 'aebs', header: 'Applications Eligible by State' },
      { field: 'pc', header: 'Project Cost (in crore)' },
      { field: 'npcis', header: 'No of projects received installment (atleast 1st) of subsidy' },
      { field: 'npris', header: 'No of projects received both installment  of Subsidy' },
      { field: 'casr', header: 'Cumulative amount of subsidy released' },      

    ];
  }
  getEventValue($event: any): string {
    return $event.target.value;
  }

  uploadListener($event: any): void {
    let files = $event.srcElement.files;
    if (this.globals.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        this.csvData = reader.result;
        let csvRecordsArray = (this.csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };
      reader.onerror = function () {
        console.log('error is occurred while reading file!');
      };
    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord = new NlmCsvData();
        csvRecord.stateCode = curruntRecord[1].trim();
        csvRecord.state = curruntRecord[2].trim();

        csvRecord.aebs = this.globals.setDefault(curruntRecord[3]).trim();
        csvRecord.pc = this.globals.setDefault(curruntRecord[4]).trim();
        csvRecord.npcis = this.globals.setDefault(curruntRecord[5]).trim();
        csvRecord.npris = this.globals.setDefault(curruntRecord[6]).trim();
        csvRecord.casr = this.globals.setDefault(curruntRecord[7]).trim();       

        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  getJsonData() {
    this.rgmDetailInJson = [];
    this.rgmDetailInJson = this.records;

  }


  deleteRow(state: any) {
    for (let [i, user] of this.rgmDetailInJson.entries()) {
      if (user.state === state) {
        this.rgmDetailInJson.splice(i, 1); // Tim is now removed from "users"
      }
    }
    console.log(this.rgmDetailInJson)
  }

  onUpload() {

    const data = this.rgmDetailInJson;
    if (data.length > 0) {
      this.dataService.nlmCreate(data).subscribe((result: any) => {
        //alert(result.message);
        alert("added sucessfully");
        this.rgmDetailInJson = [];
      });
    }

  }

// -- Add by Abhishek for JSON To CSV
allNLMData:any
download(){
  this.getNLMDashboardData();  
}
getNlmData() {
  return this.http.get('https://dahddashboard.ndlm.co.in/api/nlmstatewisekpi');
//return this.http.get('/assets/didf.json');
 }

getNLMDashboardData()
{
this.getNlmData().subscribe((res)=>{
  console.log("NLM Data Result");
  console.log(res);
  this.allNLMData=res;
  this.JsonToCSVService.downloadFile(this.allNLMData, 'NLM');
},
(err)=>{
console.log(err)
}
); 
}
// ---- End Abhi Code -----

}

export class NlmCsvData {
  public stateCode: any;
  public state: any;
  public aebs: any;
  public pc: any;
  public npcis: any;
  public npris: any;
  public casr: any; 
}