<app-header></app-header>
      <div class="heding-middle-text">National Programme For Dairy Development<div class="btn-float-left">Last Updated:{{curentDate}}</div> </div>
      <div class="container" style="max-width: 1205px;">
       
        <div class="row" >
          <div class="col-sm-3">
            <div class="card" style="background-color: #005588; color: white; border-radius: 10px; " >
              <div class="card-body">
             
                <!-- <div class="container"> -->
  
                <div class="row mt-3">
                 
                    <div class="col-sm" >
                          <div class="row" >
                            <div class="col-sm mt-5" style="text-align: center;">
                              <p>Number of Projects <br> Sanctioned</p> 
                            <h5 class="card-title mt-5 mb-4 top-five-states">{{allTotalNoofprojectssanctioned}}</h5>
                          </div>      
                            </div>
                         <hr>

                            <div class="row mt-4" >
                                <div class="col-sm mt-5" style="text-align: center;">
                                  <p>States/UTs Covered</p> 
                                <h5 class="card-title mt-5 mb-4 top-five-states">{{stateLength}}</h5>
                              </div>                         
                                </div>                       
                      </div>
                </div>  
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="col-sm-6" style="padding: 0px;">
            <div class="card" style="border-radius: 10px;">
              <div class="card-body">
               <p class="box-heading" style="text-align: center;"> FINANCIAL PROGRESS</p>
                <!-- <div class="container"> -->
               <div class="row">
                        <div class="col-sm-3" >
                         <p class="card-text-one">Total Approved Outlay</p>
                        </div>
                        <div class="col-sm-3 text-right" >
                            <p class="card-text-one">Central Share</p>
                        </div>
                        <div class="col-sm-3 text-right" >
                            <p class="card-text-one">Funds Released</p>
                        </div>
                        <div class="col-sm-3 text-right" >
                            <p class="card-text-one">Funds Utilised</p>
                        </div>
                       
                      
                     </div>

              <div class="row">
                <div class="col-sm-3" >
                  <p class="total-livestock-count-blue">{{allTotalApprovedOutlay}}</p>
                </div>
                <div class="col-sm-3 text-right" >
                    <p class="total-livestock-count-green ">{{allTotalCentralShare}}</p>
                </div>
                <div class="col-sm-3 text-right" >
                    <p class="total-livestock-count-green">{{allTotalFundsReleased}}</p>
                </div>
                <div class="col-sm-3 text-right">
                    <p class="total-livestock-count-green ">{{allTotalFundsUtilised}}</p>
                </div>
               
      </div>


            </div>
          </div>
        </div>
    
     
        <div class="col-sm-3">
          <div class="card" style="background-color: #005588; color: white; border-radius: 10px;"  >
              <div class="card-header border-bottom bg-transparent" style="font-weight: 700;text-align: center;">TOP FIVE PERFORMING STATES (Fund Utilization)
                </div>
            <div class="card-body">
           
              <!-- <div class="container"> -->
                  <div class="row mt-1" >
                     
                      <div class="col-sm-12" style="text-align: center; "  >
                        <h6 class="top-five-box-heading mb-1"> {{topFistState}}</h6>
                         
                        <h5>{{topFistFundsUtilisedState}}</h5>                         
                      </div>
                    
                     
                    </div>
                   <hr style="margin-top:6px;">             
                    <div class="row " >
                     
                      <div class="col-sm-12 mt-2 " style="text-align: center; "  >
                        <h6 class="top-five-box-heading mb-1">{{topSecandState}} </h6>
                       
                        <h5>{{topSencandFundsUtilisedState}}</h5>                         
                      </div>
                    
                     
                    </div>
                   <hr style="margin-top:6px;">    
                    <div class="row " >
                     
                      <div class="col-sm-12 mt-2" style="text-align: center;"  >
                        <h6 class="top-five-box-heading mb-1">{{topTirdState}}</h6>
                       
                        <h5>{{topThirdFundsUtilisedState}}</h5>                         
                      </div>
                    
                     
                    </div>
                   <hr style="margin-top:6px;">   
                    <div class="row " >
                     
                      <div class="col-sm-12 mt-2" style="text-align: center; "  >
                        <h6 class="top-five-box-heading mb-1">{{topFourthState}}</h6>
                      
                        <h5>{{topFourthFundsUtilisedState}}</h5>                         
                      </div>
                    
                     
                    </div>
                   <hr style="margin-top:5px;">     
                    <div class="row" >
                     
                      <div class="col-sm-12 mt" style="text-align: center; "  >
                        <h6 class="top-five-box-heading mb-1">{{topFiveState}}</h6>
                       
                        <h5>{{topFiveFundsUtilisedState}}</h5>                         
                      </div>
                    
                     
                    </div>
                   
            </div>
          </div>
        </div>
        


          
        <div class="heding-middle-two">
            <div class="row">
                <div class="col-sm-12" style="padding: 0px;">
                  <div class="card" style="border-radius: 10px;" >
                    <div class="card" style="border-radius: 10px;">
                        <div class="card-body">
                         <p class="box-heading" style="text-align: center;"> PHYSICAL PROGRESS</p>
                          <!-- <div class="container"> -->
                            <div class="row">
                 
                              <div class="col-sm-3" style="border-right: 1px solid;">
                                <p  class="top-five-box-font-heading">Increase in Average Daily Milk Procurement </p>      
                               
                              </div>
                              <div class="col-sm-3 text-right" style="border-right: 1px solid;" >
                                <p class="top-five-box-font-heading">Bulk Milk Cooler Installed  </p>      
                                  <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                                
                              </div>
                              <div class="col-sm-3 text-right" style="border-right: 1px solid;" >
                                <p  class="top-five-box-font-heading">AMCU/DPMCU Installed</p>    
                                <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                              
                            </div>
                          
                            <div class="col-sm-3 text-right"  >
                                <p  class="top-five-box-font-heading">Milk Adulteration Testing Machines Installed</p>    
                                <!-- <i class="fab fa-accessible-icon mt-2" style="font-size:50px; "></i> -->
                            
                            </div>
                            </div>
      
      
                      <div class="row">
                       
                        <div class="col-sm-3" style="border-right: 1px solid;" >
                         
                          <p class="total-livestock-count-blue">{{allTotalAverageDailyMilkProcurementAch}}</p>
						  <!-- <p class="total-livestock-count-blue">3764.67</p> -->
                            <p class="card-text-one">TKgPD <br> (Thousand Kilograms Per Day)</p>   
                        </div>
                        <div class="col-sm-3 text-right" style="margin-top: -30px; border-right: 1px solid;" >
                          <p class="card-text-one"> Number</p>
                          <p class="total-livestock-count-blue">{{allTotalBulkMilkCoolerAch}}</p> 
                          <p class="card-text-one">Capacity<br> (In Thousand Liters)</p>     
                          <p class="total-livestock-count-blue">{{allTotalBulkMilkCoolerCapacity}}</p>
                          <!-- <div class="row">
                            <div class="col-sm-6">
                              <p class="total-livestock-count-blue">4755 KL</p>
                            </div>
                            <div class="col-sm-6">
                              <p class="total-livestock-count-blue">4755 KL</p>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-sm-3 text-right"  style="border-right: 1px solid;">
                           
                          <p class="total-livestock-count-blue">{{allTotalAutomaticMilkCollectionAch}}</p>
                      </div>
                    
                      <div class="col-sm-3 text-right" >
                             
                          <p class="total-livestock-count-blue">{{allTotalMilkAdulterationTestingMachinesAch}}</p>
                      </div>
                      </div>
                        </div>
                      </div>
                  </div>
                </div>
          
              </div>
  
        </div>
      
     

<div class="bg-img">
    <div class="row" >
        
        <span class="box-heading-one">SUCCESS STORIES</span>
        <owl-carousel-o [options]="customOptions">
   
            <ng-container *ngFor="let slide of slides">

              <ng-template carouselSlide [id]="slide.id">
                <div class="col" style="
                margin-top: 5px;
            ">
                   <div class="card" style="border-radius: 20px;">
                  
                     <div class="card-body" style="height: 135px; ">
                        
                         <!-- <div class="container"> -->
                                   <div class="row" *ngIf="slide.id == '1'">
                          
                            <div class="col-sm-2">
                             
                               <div  class="icon-box"style=" margin-top: 15px;">
                                 <span  class="registrations-today-icon"><img  src="/assets/imgs/background/01.png" style="height: 70px;" alt=""></span></div>
                             
                            </div>
                            <div class="col-sm-10 text-right" >
                             <span class="box-heading-two">Gujarat</span>
                              <p style="font-size: 14px;">The Ahmedabad District Co-operative Milk Producers’ Union Ltd. has established 7 Bulk Milk Cooling Units having capacity of 15000 LPD. This has led to a rise in milk procurement, increase in milk quality, and increase in milk pourer members.</p>
                            </div>
                          </div>
                          <div class="row" *ngIf="slide.id == '2'">
                           
                           <div class="col-sm-2">
                            
                              <div  class="icon-box"style=" margin-top: 15px;">
                                <span  class="registrations-today-icon"><img  src="/assets/imgs/background/02.png" style="height: 70px;" alt=""></span></div>
                            
                           </div>
                           <div class="col-sm-10 text-right" >
                            <span class="box-heading-two">Kerala</span>
                             <p style="font-size: 14px;">Installation of Bulk Milk Cooling Units at societies under Pattambi P&I unit has improved microbial quality of raw milk from 80 minutes to over 180 minutes</p>
                           </div>
                         </div>
                         <!-- </div> -->
                       </div>               
        </div>
      </div>
    </ng-template>
</ng-container>
   
</owl-carousel-o>
    


    </div>
</div>


</div>

   


         

      