import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';
import { NpddschemeService } from '../npddscheme/npddscheme.service';

@Component({
  selector: 'app-statewise-npddscheme',
  templateUrl: './statewise-npddscheme.component.html',
  styleUrls: ['./statewise-npddscheme.component.scss']
})

export class StatewiseNpddschemeComponent implements OnInit {
  selectedCity1 :  any = new Array()
  states_data = [['State','Total AI Done']];
  mapReady=false;
  stateWisestateWiseNpddAllData: any = new Array()
  totalNpddSateWiseTypeDataCount: any = new Array()
  selectStateData: any = new Array()
  valueInFormate:any;

  totalNaipAiDoneStateWise: any = new Array()


  selectStateName: any;
  selectStateCode:any;
  allTotalApprovedOutlay: any;
  allTotalCentralShare: any;
  allTotalFundsReleased: any;
  allTotalFundsUtilised:any;
  allTotalAverageDailyMilkProcurementAch: any;
  allTotalBulkMilkCoolerAch: any;
  allTotalBulkMilkCoolerCapacity: any;
  allTotalAutomaticMilkCollectionAch: any;
  allTotalMilkAdulterationTestingMachinesAch: any;
  allTotalNoofprojectssanctioned: any;
 
  constructor(public serv: NpddschemeService,private route: ActivatedRoute) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'}
  ];
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {  
      this.selectStateName=params['State'];
	   console.log("state name for state code")
      console.log(params['State'])
      const myArray = params['State'].split("(");
      this.selectStateName=myArray[0];
      const myArray1 = myArray[1].split(")");
      console.log(myArray1)
      this.selectStateCode=myArray1[0];

    });
  this.StateWiseNpddDashboardData();
  
    //console.log(this.route.snapshot.queryParams.name); // book
  
  }
  StateWiseNpddDashboardData()
  {
    this.stateWisestateWiseNpddAllData=[];
    this.serv.getNpddData().subscribe((res)=>{
      this.stateWisestateWiseNpddAllData=res;
      console.log("total npdd dataaaaaaaa")
      console.log(this.stateWisestateWiseNpddAllData)
      this.totalNpddSateWiseTypeDataCount=[];
      var groupByTypeState = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalLhdcStateData=groupByTypeState(this.stateWisestateWiseNpddAllData, 'State_Code')
  this.totalNpddSateWiseTypeDataCount = Object.entries(totalLhdcStateData)

  this.selectStateData=[];
  this.totalNpddSateWiseTypeDataCount.forEach((y:any) =>
  {
  if(y[0]==this.selectStateCode)
  {
   this.selectStateData=y[1]
  }
});
  console.log("select state dataaaaaaaaa------")
  console.log(this.selectStateData)
  this.allTotalAverageDailyMilkProcurementAch=0
  this.allTotalBulkMilkCoolerAch=0
  this.allTotalBulkMilkCoolerCapacity=0
  this.allTotalAutomaticMilkCollectionAch=0
  this.allTotalMilkAdulterationTestingMachinesAch=0
  this.allTotalNoofprojectssanctioned=0
  this.allTotalApprovedOutlay=0
  this.allTotalCentralShare=0
  this.allTotalFundsReleased=0
  this.allTotalFundsUtilised=0
      const sumallTotalApprovedOutlay = this.selectStateData.map((item:any) => Number(item['Total_Approved_Outlay'])).reduce((prev:any, curr:any) => prev + curr, 0);
      const sumallCentralShare = this.selectStateData.map((item:any) => Number(item['Central_Share'])).reduce((prev:any, curr:any) => prev + curr, 0);
      const sumallFundsReleased = this.selectStateData.map((item:any) => Number(item['Funds_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
      const sumallFundsUtilised = this.selectStateData.map((item:any) => Number(item['Funds_Utilised'])).reduce((prev:any, curr:any) => prev + curr, 0);
      const TotalAverageDailyMilkProcurementAch = this.selectStateData.map((item:any) => Number(item['Average_Daily_Milk_Procurement_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.allTotalAverageDailyMilkProcurementAch=TotalAverageDailyMilkProcurementAch.toFixed(2)
      this.allTotalBulkMilkCoolerAch = this.selectStateData.map((item:any) => Number(item['Bulk_Milk_Cooler_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.allTotalBulkMilkCoolerCapacity = this.selectStateData.map((item:any) => Number(item['Bulk_Milk_Cooler_Capacity'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.allTotalAutomaticMilkCollectionAch = this.selectStateData.map((item:any) => Number(item['Automatic_Milk_Collection_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.allTotalMilkAdulterationTestingMachinesAch = this.selectStateData.map((item:any) => Number(item['Milk_Adulteration_Testing_Machines_Ach'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.allTotalNoofprojectssanctioned = this.selectStateData.map((item:any) => Number(item['No_of_projects_sanctioned'])).reduce((prev:any, curr:any) => prev + curr, 0);
      this.allTotalApprovedOutlay=sumallTotalApprovedOutlay;
      this.allTotalCentralShare=sumallCentralShare;
      this.allTotalFundsReleased=sumallFundsReleased;
      this.allTotalFundsUtilised=sumallFundsUtilised;
     
      console.log("project senctioneddddddddd")
      console.log(this.allTotalNoofprojectssanctioned)
for(let state of this.totalNaipAiDoneStateWise){
  let temp = [state.statName,Number(state.totalNoOfAiDone)];
  if( state.statName=="ANDAMAN & NICOBAR ISLANDS"){
    temp = ['IN-AN',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="LAKSHADWEEP"){
    temp = ['IN-LD',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="ODISHA"){
    temp = ['IN-OR',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="LADAKH"){
    temp = ['Ladakh',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="UTTARANCHAL"){
    temp = ['IN-UT',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="JAMMU & KASHMIR"){
    temp = ['Jammu and Kashmir',Number(state.totalNoOfAiDone)];
  }
  this.states_data.push(temp);
}
console.log("sdsdsdsds")
console.log(this.states_data)
   this.mapReady=true
  
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
 
 
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 100) {
      this.valueInFormate    = val ;
    }
    return this.valueInFormate;
  }

public geoChart: GoogleChartInterface = {
  chartType: 'GeoChart',
  dataTable: this.states_data,
  options: {
    domain:'IN',
    region: 'IN',
    colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
    resolution: 'provinces',
    zoom: 6,
   // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
disableDefaultUI: true,
//displayMode: 'text',
defaultColor:'#40e0d0',
backgroundColor: 'transparent',
enableRegionInteractivity: true,
//tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
    'height': 3,
    //'width': 850,
   

    
  }
  
};


  // OnChangeDistrict(event:any)
  // {
 
  // }                     
  
}
