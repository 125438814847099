import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class DidfschemeService {

  constructor(public http: HttpClient) { }
  getData() {
    // return this.http.get('/assets/finalsud25012022.json');
    return this.http.get('/assets/geo.json');
     }
     getDidfData() {
       return this.http.get('https://dahddashboard.ndlm.co.in/api/didf');
      //return this.http.get('/assets/didf.json');
       }
	    getDidfFundingPattern() {
        // return this.http.get('/assets/finalsud25012022.json');
        return this.http.get(' https://dahddashboard.ndlm.co.in/api/didfFundingPattern');
         }
}
