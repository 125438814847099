 <div class=" container-fluid">
        <div class="row">
            <div class=" col-12 col-md-6">
                <div class="img-responsive"> <img src="https://dahddashboard.ndlm.co.in/assets/imgs/background/login.jpg" class="dn" alt=""> </div>
            </div><!-- End -->

            <!-- The content half -->
            <div class=" col-12 col-md-6">
                <div class="img-responsive"> <img src="https://dahddashboard.ndlm.co.in/assets/imgs/background/national-abulum.jpg" class="mt-3" alt=""></div>
                <h2 class="mt-5"> Dashboard Sign In</h2>

                <br />
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="form-group mb-4">
                        <div style="font-size: 80%;
                        color: #dc3545; margin-bottom: 5px;">{{credentialdata}} </div>
                        <p>Email address</p>

                        <input id="inputUsename" type="text" formControlName="usename"
                            [ngClass]="{ 'is-invalid': submitted && f.usename.errors }" placeholder="Username "
                            required="" autofocus="" class="form-control  px-4">
                        <div *ngIf="submitted && f.usename.errors" class="invalid-feedback">
                            <div *ngIf="f.usename.errors.required">Email is required</div>
                            <div *ngIf="f.usename.errors.email">Email must be a valid </div>

                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <p>Password</p>
                        <input id="inputPassword" type="password" formControlName="password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password"
                            required="" class="form-control  px-4 text-primary">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>

                    <div class="d-grid gap-2 col-12 mx-auto">
                        <button type="submit" class="btn btn-primary">Sign in</button>
                    </div>
                    <div class="text-center d-flex justify-content-between mt-4"></div>
                </form>

            </div>
            <!-- End -->

        </div>
    </div>