import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';
import { StatewiseAhidfschemeService } from './statewise-ahidfscheme.service';

@Component({
  selector: 'app-statewise-ahidfscheme',
  templateUrl: './statewise-ahidfscheme.component.html',
  styleUrls: ['./statewise-ahidfscheme.component.scss']
})

export class StatewiseAhidfschemeComponent implements OnInit {
  selectedCity1 :  any = new Array()
  states_data = [['State','Total AI Done']];
  stateWiseAhidfAllData: any = new Array()
  totalAhidfSateWiseTypeNaipDataCount: any = new Array()
  totalAhidfAiDoneStateWise: any = new Array()
  totalAllSchemeDoneArray: any = new Array()
  totalStateTypeAiDataCount: any = new Array()
  farmersBenifitedCountArray: any = new Array()
  totalAllAiSchemeDoneArray: any = new Array()
  topFiveNaipStateArray: any = new Array()
  totalAhidfDistrictWiseTypeNaipDataCount: any = new Array()
  totalNaipDistrictWise: any = new Array()
  topFiveDistrictNaipArray: any = new Array()
  totalAllNaipIISchemeDoneArray: any = new Array()
  totalAllNaipISchemeDoneArray: any = new Array()
  distrricts: any = new Array()
  districtsName: any = new Array()
  totalAggregateStateAiData: any = new Array()
  totalDistrictTypeAHidfDataCount: any = new Array()
  farmersDistrictBenifitedCountArray: any = new Array()
  totalDistrictWiseAiSchemeDoneArray: any = new Array()
  totalRgmDistrictWiseTypeNaipIIDataCount: any = new Array()
  totalRgmDistrictWiseTypeNaipIDataCount: any = new Array()
  MaitriStateWiseAllData: any = new Array()

 totalNaipIIDistrictTypeAiDataCount: any = new Array()
 totalNaipIDistrictTypeAiDataCount: any = new Array()
 totalDistrictWiseNaipIIAiSchemeDoneArray: any = new Array()
 totalDistrictWiseNaipIAiSchemeDoneArray: any = new Array()
 totalMatriStatewiseArray: any = new Array()
  currentDateData: any = new Array()
  SchemeCountArray: any = new Array()
  totalAhidfSateWiseTypeDataCount: any = new Array()
  totalAhidfSchemeStateWiseData: any = new Array()
  selectStateAhidfData: any = new Array()
  totalAhidfSchemeDistrictWiseData: any = new Array()
  selectStateName: any;
  selectStateData: any;
  selectStateCode:any;
  mapReady=false;
  valueInFormate: any;
  totalNoOfAiDoneCount: any;
  totalNoOffarmersBenifited: any;
  totalNoOfAiAllSchemeDone: any;
  totalFarmersBenifitedAllSchemeDone: any;
  totalNoOfAiDoneInNaipI: any;
  totalNoOfAnimalCoverdDoneInNaipI: any;
  totalNoOfFarmersBenefittedInNaipI: any;
  totalNoOfAiDoneInNaipII: any;
  totalNoOfAnimalCoverdDoneInNaipII: any;
  totalNoOfFarmersBenefittedInNaipII: any;
  totalNoOfAiDoneInNaipIII: any;
  totalNoOfAnimalCoverdDoneInNaipIII: any;
  totalNoOfFarmersBenefittedInNaipIII: any;
  topFirstPerfomanceDistrict: any;
  topFirstPerformingDistrictAiDone: any;
  topSecondPerfomanceDistrict: any;
  topSecondPerformingDistrictAiDone: any;
  topThirdPerfomanceDistrict: any;
  topThirdPerformingDistrictAiDone: any;
  topFourthPerfomanceDistrict: any;
  topFourthPerformingDistrictAiDone: any;
  topFifthPerfomanceDistrict: any;
  topFifthPerformingDistrictAiDone: any;
  typeOfVacinationChart:any;
  selectState: any;
  selectDistrict: any;
  currentNaipIIData: any;
  currentNaipIData: any;
  selectNaipIIDistrict: any;
  selectNaipIDistrict: any;
  MAITRIsTrainedInducted: any;
  SexSortedSemenDosesProduced: any;
  IVF: any;
  SexSortedSemenDosesAIDone: any;
  SexSortedSemenDosesSold: any;
  projectSchemeCost: any;
  projectSchemeLoanAmount: any;
  animalFeedPlantSchemeName: any;
  animalFeedPlantloanAmount: any;
  animalFeedPlantprojectCost: any;
  dairyProcessingValueAdditionSchemeName: any;
  dairyProcessingValueAdditionloanAmount: any;
  dairyProcessingValueAdditionprojectCost: any;
  meatProcessingValueAdditionSchemeName: any;
  meatProcessingValueAdditionloanAmount: any;
  meatProcessingValueAdditionprojectCost: any;
  breedimprovementTechnologyandBreedMultiplicationfarmSchemeName: any;
  breedimprovementTechnologyandBreedMultiplicationfarmloanAmount: any;
  breedimprovementTechnologyandBreedMultiplicationfarmprojectCost: any;
  AnimalWastetoWealthManagementSchemeName: any;
  AnimalWastetoWealthManagementloanAmount: any;
  AnimalWastetoWealthManagementprojectCost: any;
  allNoOfEntrepreneursRegistered: any;
  allProjectCostEntrepreneursRegistered: any;
  allProjectsEligibleByDepartment: any;
  allProjectsCostOfEligible: any;
  allProjectsSanctionedByBank: any;
  allProjectCostOfSanctionedByBank: any;
  allApprovedForInterestSubvention: any;
  allInterestSubventionAmount: any;
  constructor(public serv: StatewiseAhidfschemeService,private route: ActivatedRoute) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'}
  ];
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {  
      this.selectStateName=params['State'];
	   console.log("state name for state code")
      console.log(params['State'])
      const myArray = params['State'].split("(");
      this.selectStateName=myArray[0];
      const myArray1 = myArray[1].split(")");
      console.log(myArray1)
      this.selectStateCode=myArray1[0];

    });
  this.StateWiseRgmDashboardData();
  this.AhidfKpiDashboardData();
    //console.log(this.route.snapshot.queryParams.name); // book
  
  }
  StateWiseRgmDashboardData()
  {
    this.stateWiseAhidfAllData=[];
    this.serv.getAhidfDashboardData().subscribe((res)=>{
      this.stateWiseAhidfAllData=res;
      console.log("all  ahidf  data-----")
      console.log(this.stateWiseAhidfAllData)
   //////////////////////////////////////Group By State Data/////////////////////////////////////////   
      this.totalAhidfSateWiseTypeNaipDataCount=[];
      var groupByTypeState = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalLhdcStateData=groupByTypeState(this.stateWiseAhidfAllData, 'StateName')
  this.totalAhidfSateWiseTypeNaipDataCount = Object.entries(totalLhdcStateData)
  this.totalAhidfAiDoneStateWise=[];
  this.totalAllSchemeDoneArray=[];
  this.selectStateData=[];
  this.totalAhidfSateWiseTypeNaipDataCount.forEach((y:any) =>
  {
  if(y[0]==this.selectStateName)
  {
   this.selectStateData=y[1]
  }
 
 
  var  NoOfProjects = y['1'].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);

var allSchemeData = Object.assign({"statName": y[0]}, {"NoOfProjects": NoOfProjects});
this.totalAhidfAiDoneStateWise.push(allSchemeData);
  });
  console.log(this.totalAhidfSateWiseTypeNaipDataCount);
  console.log("select state dataaaaaaaa")
  console.log(this.selectStateData)
  console.log(this.selectStateData.length)
  if(this.selectStateData.length!=0)
  {
 this.totalAhidfSateWiseTypeDataCount=[];
 var groupBySchemes = function(xs:any, key:any) {
   return xs.reduce(function(rv:any, x:any) {
     (rv[x[key]] = rv[x[key]] || []).push(x);
     return rv;
   }, {});
 };
var totalSchemeWiseData=groupBySchemes(this.selectStateData, 'Schemes')
this.totalAhidfSateWiseTypeDataCount = Object.entries(totalSchemeWiseData)

console.log("select scheme wise data")
 console.log(this.totalAhidfSateWiseTypeDataCount)

 this.totalAhidfSchemeStateWiseData=[];
 this.totalAhidfSateWiseTypeDataCount.forEach((y:any) =>
 {
 const noOfProjects = y[1].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
 const projectCost = y[1].map((item:any) => Number(item['ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
 const loanAmount = y[1].map((item:any) => Number(item['LoanAmount'])).reduce((prev:any, curr:any) => prev + curr, 0);
 this.projectSchemeCost=this.numDifferentiation(projectCost);
 this.projectSchemeLoanAmount=this.numDifferentiation(loanAmount);
 
 var finalObj1 = Object.assign({"Scheme": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"loanAmount": this.projectSchemeLoanAmount});
 this.totalAhidfSchemeStateWiseData.push(finalObj1)
 
});

console.log("alllllll ahidf  dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
console.log(this.totalAhidfSchemeStateWiseData)
this.dairyProcessingValueAdditionSchemeName=0
this.dairyProcessingValueAdditionloanAmount=0
this.dairyProcessingValueAdditionprojectCost=0
this.meatProcessingValueAdditionSchemeName=0
this.meatProcessingValueAdditionloanAmount=0
this.meatProcessingValueAdditionprojectCost=0
this.breedimprovementTechnologyandBreedMultiplicationfarmSchemeName=0
this.breedimprovementTechnologyandBreedMultiplicationfarmloanAmount=0
this.breedimprovementTechnologyandBreedMultiplicationfarmprojectCost=0
this.AnimalWastetoWealthManagementSchemeName=0
this.AnimalWastetoWealthManagementloanAmount=0
this.AnimalWastetoWealthManagementprojectCost=0
this.animalFeedPlantSchemeName=0
this.animalFeedPlantloanAmount=0
this.animalFeedPlantprojectCost=0
this.totalAhidfSchemeStateWiseData.forEach((y:any) =>
 {
  console.log("all scheme -----------------")
  console.log(y.Scheme)
  if(y.Scheme=='Dairy Processing & Value Addition')
  {
 this.dairyProcessingValueAdditionSchemeName=y.noOfProjects;
 this.dairyProcessingValueAdditionloanAmount=y.loanAmount
 this.dairyProcessingValueAdditionprojectCost=y.projectCost
  }
  if(y.Scheme=='Meat Processing & Value Addition')
  {
 this.meatProcessingValueAdditionSchemeName=y.noOfProjects;
 this.meatProcessingValueAdditionloanAmount=y.loanAmount
 this.meatProcessingValueAdditionprojectCost=y.projectCost
  }
  if(y.Scheme=='Animal Feed Plant')
  {
    this.animalFeedPlantSchemeName=y.noOfProjects;
    this.animalFeedPlantloanAmount=y.loanAmount
    this.animalFeedPlantprojectCost=y.projectCost
  }
  if(y.Scheme=='Breed improvement Technology and Breed Multiplication farm')
  {
    this.breedimprovementTechnologyandBreedMultiplicationfarmSchemeName=y.noOfProjects;
 this.breedimprovementTechnologyandBreedMultiplicationfarmloanAmount=y.loanAmount
 this.breedimprovementTechnologyandBreedMultiplicationfarmprojectCost=y.projectCost
 
  }
  if(y.Scheme=='Animal Waste to Wealth Management (Agri waste management)')
  {
    this.AnimalWastetoWealthManagementSchemeName=y.noOfProjects;
    this.AnimalWastetoWealthManagementloanAmount=y.loanAmount
    this.AnimalWastetoWealthManagementprojectCost=y.projectCost
  }
 
});




this.totalAhidfDistrictWiseTypeNaipDataCount=[];
var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalAhidfcDistrictData=groupByTypeDistrict(this.selectStateData, 'DistrictName')
this.totalAhidfDistrictWiseTypeNaipDataCount = Object.entries(totalAhidfcDistrictData)
console.log("dididididiid")
console.log(this.totalAhidfDistrictWiseTypeNaipDataCount)
 var keys = Object.keys(totalAhidfcDistrictData);
 console.log("all keys")
 console.log(keys)
console.log(this.totalAhidfDistrictWiseTypeNaipDataCount);
this.totalNaipDistrictWise=[];
this.topFiveDistrictNaipArray=[];
this.distrricts=[];
this.districtsName=[];
this.totalAhidfDistrictWiseTypeNaipDataCount.forEach((y:any) =>
{
const sumallDistrictAiDone = y[1].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
var finalObj1 = Object.assign({"District": y[0]}, {"NoOfProjects": sumallDistrictAiDone});
this.totalNaipDistrictWise.push(finalObj1)
var finalObj = Object.assign({"name": y[0].toUpperCase()}, {"code":  y[0].toUpperCase()});
this.distrricts.push(finalObj)
this.districtsName= this.distrricts.sort(function(a:any,b:any){ return a.name.localeCompare(b.name); });
});
//------- variable use for defaul select------------------------------------------------------
//this.selectedCity1 = {name: 'ANAND',code: 'ANAND'};
console.log("District_Name")
console.log(this.districtsName)
var topValues = this.totalNaipDistrictWise.sort((a:any,b:any) => b['NoOfProjects']-a['NoOfProjects']).slice(0,5);
console.log(this.totalNaipDistrictWise)
console.log(topValues);
topValues.forEach((y:any) =>
{
var finalObj1 = Object.assign({"District": y['District']}, {"NoOfProjects": y['NoOfProjects']});
this.topFiveDistrictNaipArray.push(finalObj1);
});
console.log("top five vacination District")
console.log(this.topFiveDistrictNaipArray)
this.topFirstPerfomanceDistrict=this.topFiveDistrictNaipArray[0]['District'];
this.topFirstPerformingDistrictAiDone=this.topFiveDistrictNaipArray[0]['NoOfProjects'];

this.topSecondPerfomanceDistrict=this.topFiveDistrictNaipArray[1]['District'];
this.topSecondPerformingDistrictAiDone=this.topFiveDistrictNaipArray[1]['NoOfProjects'];

this.topThirdPerfomanceDistrict=this.topFiveDistrictNaipArray[2]['District'];
this.topThirdPerformingDistrictAiDone=this.topFiveDistrictNaipArray[2]['NoOfProjects'];

this.topFourthPerfomanceDistrict=this.topFiveDistrictNaipArray[3]['District'];
this.topFourthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[3]['NoOfProjects'];

this.topFifthPerfomanceDistrict=this.topFiveDistrictNaipArray[4]['District'];
this.topFifthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[4]['NoOfProjects'];
for(let state of this.totalAhidfAiDoneStateWise){
  let temp = [state.statName,Number(state.NoOfProjects)];
  if( state.statName=="ANDAMAN & NICOBAR ISLANDS"){
    temp = ['IN-AN',Number(state.NoOfProjects)];
  }
  else if( state.statName=="LAKSHADWEEP"){
    temp = ['IN-LD',Number(state.NoOfProjects)];
  }
  else if( state.statName=="ODISHA"){
    temp = ['IN-OR',Number(state.NoOfProjects)];
  }
  else if( state.statName=="LADAKH"){
    temp = ['Ladakh',Number(state.NoOfProjects)];
  }
  else if( state.statName=="UTTARANCHAL"){
    temp = ['IN-UT',Number(state.NoOfProjects)];
  }
  else if( state.statName=="JAMMU & KASHMIR"){
    temp = ['Jammu and Kashmir',Number(state.NoOfProjects)];
  }
  this.states_data.push(temp);
}
console.log("sdsdsdsds")
console.log(this.states_data)
   this.mapReady=true
}
else
{
  console.log("not dataaa")
  this.dairyProcessingValueAdditionSchemeName=0
  this.dairyProcessingValueAdditionloanAmount=0
  this.dairyProcessingValueAdditionprojectCost=0
  this.meatProcessingValueAdditionSchemeName=0
  this.meatProcessingValueAdditionloanAmount=0
  this.meatProcessingValueAdditionprojectCost=0
  this.breedimprovementTechnologyandBreedMultiplicationfarmSchemeName=0
  this.breedimprovementTechnologyandBreedMultiplicationfarmloanAmount=0
  this.breedimprovementTechnologyandBreedMultiplicationfarmprojectCost=0
  this.AnimalWastetoWealthManagementSchemeName=0
  this.AnimalWastetoWealthManagementloanAmount=0
  this.AnimalWastetoWealthManagementprojectCost=0
  this.animalFeedPlantSchemeName=0
  this.animalFeedPlantloanAmount=0
  this.animalFeedPlantprojectCost=0
}
  
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
 
  AhidfKpiDashboardData()
  {
    this.MaitriStateWiseAllData=[];
	this.allNoOfEntrepreneursRegistered=0
    this.allProjectCostEntrepreneursRegistered=0
    this.allProjectsEligibleByDepartment=0
    this.allProjectsCostOfEligible=0
    this.allProjectsSanctionedByBank=0
    this.allProjectCostOfSanctionedByBank=0
    this.allApprovedForInterestSubvention=0
    this.allInterestSubventionAmount=0
    this.serv.getKpiData().subscribe((res)=>{
      this.MaitriStateWiseAllData=res;
   
    this.totalMatriStatewiseArray=[];
    var groupByMvuData = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalMatristaewiseData=groupByMvuData( this.MaitriStateWiseAllData, 'StateCoveredLGDCode')
    console.log(this.MaitriStateWiseAllData)
    this.totalMatriStatewiseArray = Object.entries(totalMatristaewiseData)
    this.selectStateAhidfData=[];
    this.totalMatriStatewiseArray.forEach((y:any) =>
    {
     
    if((y[0]==this.selectStateCode))
    {
      
  this.selectStateAhidfData['0']=y[1];
  
   } 
   });
   console.log("ahidf kpi data--------")
console.log( this.selectStateAhidfData)
   this.selectStateAhidfData.forEach((y:any) =>
{
const NoOfEntrepreneursRegistered = y.map((item:any) => Number(item['NoOfEntrepreneursRegistered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allNoOfEntrepreneursRegistered=NoOfEntrepreneursRegistered

const ProjectCostEntrepreneursRegistered = y.map((item:any) => Number(item['ProjectCostEntrepreneursRegistered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectCostEntrepreneursRegistered=this.numDifferentiation(ProjectCostEntrepreneursRegistered)

const ProjectsEligibleByDepartment = y.map((item:any) => Number(item['ProjectsEligibleByDepartment'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsEligibleByDepartment=ProjectsEligibleByDepartment


const ProjectsCostOfEligible = y.map((item:any) => Number(item['ProjectsCostOfEligible'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsCostOfEligible=this.numDifferentiation(ProjectsCostOfEligible)


const ProjectsSanctionedByBank = y.map((item:any) => Number(item['ProjectsSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsSanctionedByBank=ProjectsSanctionedByBank

const ProjectCostOfSanctionedByBank = y.map((item:any) => Number(item['ProjectCostOfSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectCostOfSanctionedByBank=this.numDifferentiation(ProjectCostOfSanctionedByBank)

const ApprovedForInterestSubvention = y.map((item:any) => Number(item['ApprovedForInterestSubvention'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allApprovedForInterestSubvention=ApprovedForInterestSubvention

const InterestSubventionAmount = y.map((item:any) => Number(item['ProjectCostInterestSubvention'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allInterestSubventionAmount=InterestSubventionAmount

});

    },
    (err)=>{
      console.log(err)
    }
  );
  }
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 100) {
      this.valueInFormate    = val ;
    }
    return this.valueInFormate;
  }
  public select(event: ChartSelectEvent) {
    console.log(event.selectedRowValues[0])
    this.totalAhidfSateWiseTypeNaipDataCount.forEach((y:any) =>
    {
   if(y[0]==event.selectedRowValues[0])
   {
     this.selectState=y[1]
   }
   
    });
    var groupByTypeVacination = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
var totalTypeVacinationStateData=groupByTypeVacination(this.selectState, 'Scheme')
var totalTypeVacinationStateDataCount = Object.entries(totalTypeVacinationStateData)
this.totalAggregateStateAiData=[];
  totalTypeVacinationStateDataCount.forEach((y:any) =>
  {
      var totalVaccination =  y[1].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);

      var finalObj = Object.assign({"name": y[0]}, {"value": totalVaccination});
      this.totalAggregateStateAiData.push(finalObj)

  });
console.log(this.totalAggregateStateAiData)
this.typeOfVacinationChart = {
  title: {
    show:true,
    text: event.selectedRowValues[0],
    left: 'center'
   
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'right'
  },
  series: [
    {
     // name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '15',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: this.totalAggregateStateAiData
    }
  ]
};
}
public geoChart: GoogleChartInterface = {
  chartType: 'GeoChart',
  dataTable: this.states_data,
  options: {
    domain:'IN',
    region: 'IN',
    colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
    resolution: 'provinces',
    zoom: 6,
   // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
disableDefaultUI: true,
//displayMode: 'text',
defaultColor:'#40e0d0',
backgroundColor: 'transparent',
enableRegionInteractivity: true,
//tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
    'height': 3,
    //'width': 850,
   

    
  }
  
};


  OnChangeDistrict(event:any)
  {
    console.log(event.value.name)
    console.log(this.totalAhidfDistrictWiseTypeNaipDataCount)
    this.totalAhidfDistrictWiseTypeNaipDataCount.forEach((y:any) =>
    {
   if(y[0].toUpperCase()==event.value.name)
   {
     this.selectDistrict=y[1]
   }   
   });

this.totalDistrictTypeAHidfDataCount=[];
this.totalNaipIIDistrictTypeAiDataCount=[];
this.totalNaipIDistrictTypeAiDataCount=[];
if(this.selectDistrict)
{
var groupByDistrictTotalVaccinatioTypeData = function(xs:any, key:any) {
return xs.reduce(function(rv:any, x:any) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});
};
var totalVaccinationTypeData=groupByDistrictTotalVaccinatioTypeData(this.selectDistrict, 'Schemes')
this.totalDistrictTypeAHidfDataCount = Object.entries(totalVaccinationTypeData)

this.totalAhidfSchemeDistrictWiseData=[];
this.totalDistrictTypeAHidfDataCount.forEach((y:any) =>
{
const noOfProjects = y[1].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);
const projectCost = y[1].map((item:any) => Number(item['ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
const loanAmount = y[1].map((item:any) => Number(item['LoanAmount'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.projectSchemeCost=this.numDifferentiation(projectCost);
this.projectSchemeLoanAmount=this.numDifferentiation(loanAmount);

var finalObj1 = Object.assign({"Scheme": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"loanAmount": this.projectSchemeLoanAmount});
this.totalAhidfSchemeDistrictWiseData.push(finalObj1)

});
console.log("district   wise data----------")
console.log(this.totalAhidfSchemeDistrictWiseData)


this.dairyProcessingValueAdditionSchemeName=0
this.dairyProcessingValueAdditionloanAmount=0
this.dairyProcessingValueAdditionprojectCost=0
this.meatProcessingValueAdditionSchemeName=0
this.meatProcessingValueAdditionloanAmount=0
this.meatProcessingValueAdditionprojectCost=0
this.breedimprovementTechnologyandBreedMultiplicationfarmSchemeName=0
this.breedimprovementTechnologyandBreedMultiplicationfarmloanAmount=0
this.breedimprovementTechnologyandBreedMultiplicationfarmprojectCost=0
this.AnimalWastetoWealthManagementSchemeName=0
this.AnimalWastetoWealthManagementloanAmount=0
this.AnimalWastetoWealthManagementprojectCost=0
this.animalFeedPlantSchemeName=0
this.animalFeedPlantloanAmount=0
this.animalFeedPlantprojectCost=0
this.totalAhidfSchemeDistrictWiseData.forEach((y:any) =>
 {
  console.log("all scheme -----------------")
  console.log(y.Scheme)
  if(y.Scheme=='Dairy Processing & Value Addition')
  {
 this.dairyProcessingValueAdditionSchemeName=y.noOfProjects;
 this.dairyProcessingValueAdditionloanAmount=y.loanAmount
 this.dairyProcessingValueAdditionprojectCost=y.projectCost
  }
  if(y.Scheme=='Meat Processing & Value Addition')
  {
 this.meatProcessingValueAdditionSchemeName=y.noOfProjects;
 this.meatProcessingValueAdditionloanAmount=y.loanAmount
 this.meatProcessingValueAdditionprojectCost=y.projectCost
  }
  if(y.Scheme=='Animal Feed Plant')
  {
    this.animalFeedPlantSchemeName=y.noOfProjects;
    this.animalFeedPlantloanAmount=y.loanAmount
    this.animalFeedPlantprojectCost=y.projectCost
  }
  if(y.Scheme=='Breed improvement Technology and Breed Multiplication farm')
  {
    this.breedimprovementTechnologyandBreedMultiplicationfarmSchemeName=y.noOfProjects;
 this.breedimprovementTechnologyandBreedMultiplicationfarmloanAmount=y.loanAmount
 this.breedimprovementTechnologyandBreedMultiplicationfarmprojectCost=y.projectCost
 
  }
  if(y.Scheme=='Animal Waste to Wealth Management (Agri waste management)')
  {
    this.AnimalWastetoWealthManagementSchemeName=y.noOfProjects;
    this.AnimalWastetoWealthManagementloanAmount=y.loanAmount
    this.AnimalWastetoWealthManagementprojectCost=y.projectCost
  }
 
});

this.AhidfDistrictKpiDashboardData(event.value.name)

}
   
  }  
  
  

  AhidfDistrictKpiDashboardData(districtName:any)
  {
    this.MaitriStateWiseAllData=[];
    this.serv.getKpiData().subscribe((res)=>{
      this.MaitriStateWiseAllData=res;
   
    this.totalMatriStatewiseArray=[];
    var groupByMvuData = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalMatristaewiseData=groupByMvuData( this.MaitriStateWiseAllData, 'DistrictName')
    console.log(this.MaitriStateWiseAllData)
    this.totalMatriStatewiseArray = Object.entries(totalMatristaewiseData)
    this.selectStateAhidfData=[];
    this.totalMatriStatewiseArray.forEach((y:any) =>
    {
     
    if((y[0]==districtName))
    {
      
  this.selectStateAhidfData['0']=y[1];
  
   } 
   });

   this.selectStateAhidfData.forEach((y:any) =>
{
const NoOfEntrepreneursRegistered = y.map((item:any) => Number(item['NoOfEntrepreneursRegistered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allNoOfEntrepreneursRegistered=NoOfEntrepreneursRegistered

const ProjectCostEntrepreneursRegistered = y.map((item:any) => Number(item['ProjectCostEntrepreneursRegistered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectCostEntrepreneursRegistered=this.numDifferentiation(ProjectCostEntrepreneursRegistered)

const ProjectsEligibleByDepartment = y.map((item:any) => Number(item['ProjectsEligibleByDepartment'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsEligibleByDepartment=ProjectsEligibleByDepartment


const ProjectsCostOfEligible = y.map((item:any) => Number(item['ProjectsCostOfEligible'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsCostOfEligible=this.numDifferentiation(ProjectsCostOfEligible)


const ProjectsSanctionedByBank = y.map((item:any) => Number(item['ProjectsSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsSanctionedByBank=ProjectsSanctionedByBank

const ProjectCostOfSanctionedByBank = y.map((item:any) => Number(item['ProjectCostOfSanctionedByBank'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectCostOfSanctionedByBank=this.numDifferentiation(ProjectCostOfSanctionedByBank)

const ApprovedForInterestSubvention = y.map((item:any) => Number(item['ApprovedForInterestSubvention'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allApprovedForInterestSubvention=ApprovedForInterestSubvention

const InterestSubventionAmount = y.map((item:any) => Number(item['ProjectCostInterestSubvention'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allInterestSubventionAmount=InterestSubventionAmount

});

    },
    (err)=>{
      console.log(err)
    }
  );
  }
  
}
