import { Component, OnInit } from '@angular/core';
import { RgmschemeService } from '../rgmscheme/rgmscheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';

@Component({
  selector: 'app-statewise-rgmscheme',
  templateUrl: './statewise-rgmscheme.component.html',
  styleUrls: ['./statewise-rgmscheme.component.scss']
})

export class StatewiseRgmschemeComponent implements OnInit {
  selectedCity1 :  any = new Array()
  states_data = [['State','Total AI Done']];
  stateWiseRgmAllData: any = new Array()
  totalRgmSateWiseTypeNaipDataCount: any = new Array()
  totalNaipAiDoneStateWise: any = new Array()
  totalAllSchemeDoneArray: any = new Array()
  totalStateTypeAiDataCount: any = new Array()
  farmersBenifitedCountArray: any = new Array()
  totalAllAiSchemeDoneArray: any = new Array()
  topFiveNaipStateArray: any = new Array()
  totalRgmDistrictWiseTypeNaipIVDataCount: any = new Array()
  totalNaipDistrictWise: any = new Array()
  topFiveDistrictNaipArray: any = new Array()
  totalAllNaipIISchemeDoneArray: any = new Array()
  totalAllNaipISchemeDoneArray: any = new Array()
  distrricts: any = new Array()
  districtsName: any = new Array()
  totalAggregateStateAiData: any = new Array()
  totalDistrictTypeAiDataCount: any = new Array()
  farmersDistrictBenifitedCountArray: any = new Array()
  totalDistrictWiseAiSchemeDoneArray: any = new Array()
  totalRgmDistrictWiseTypeNaipIIDataCount: any = new Array()
  totalRgmDistrictWiseTypeNaipIDataCount: any = new Array()
  MaitriStateWiseAllData: any = new Array()

 totalNaipIIDistrictTypeAiDataCount: any = new Array()
 totalNaipIDistrictTypeAiDataCount: any = new Array()
 totalDistrictWiseNaipIIAiSchemeDoneArray: any = new Array()
 totalDistrictWiseNaipIAiSchemeDoneArray: any = new Array()
 totalRgmDistrictWiseTypeNaipIIIDataCount: any = new Array()
 totalNaipIIIDistrictTypeAiDataCount: any = new Array()
 totalDistrictWiseNaipIIIAiSchemeDoneArray: any = new Array()
 totalNaipIVStateTypeAiDataCount: any = new Array()
totalAllNaipIVAiSchemeDoneArray: any = new Array()
 totalMatriStatewiseArray: any = new Array()
  currentDateData: any = new Array()
  SchemeCountArray: any = new Array()
  selectStateName: any;
  selectStateData: any;
  selectStateCode:any;
  mapReady=false;
  valueInFormate: any;
  totalNoOfAiDoneCount: any;
  totalNoOffarmersBenifited: any;
  totalNoOfAiAllSchemeDone: any;
  totalFarmersBenifitedAllSchemeDone: any;
  totalNoOfAiDoneInNaipI: any;
  totalNoOfAnimalCoverdDoneInNaipI: any;
  totalNoOfFarmersBenefittedInNaipI: any;
  totalNoOfAiDoneInNaipII: any;
  totalNoOfAnimalCoverdDoneInNaipII: any;
  totalNoOfFarmersBenefittedInNaipII: any;
  totalNoOfAiDoneInNaipIII: any;
  totalNoOfAnimalCoverdDoneInNaipIII: any;
  totalNoOfFarmersBenefittedInNaipIII: any;
  topFirstPerfomanceDistrict: any;
  topFirstPerformingDistrictAiDone: any;
  topSecondPerfomanceDistrict: any;
  topSecondPerformingDistrictAiDone: any;
  topThirdPerfomanceDistrict: any;
  topThirdPerformingDistrictAiDone: any;
  topFourthPerfomanceDistrict: any;
  topFourthPerformingDistrictAiDone: any;
  topFifthPerfomanceDistrict: any;
  topFifthPerformingDistrictAiDone: any;
  typeOfVacinationChart:any;
  selectState: any;
  selectDistrict: any;
  currentNaipIIData: any;
  currentNaipIData: any;
  selectNaipIIDistrict: any;
  selectNaipIDistrict: any;
  MAITRIsTrainedInducted: any;
  SexSortedSemenDosesProduced: any;
  IVF: any;
  SexSortedSemenDosesAIDone: any;
  SexSortedSemenDosesSold: any;
  totalStateWiseNaipIAndNaipIIAiDone: any;
  totalStateWiseNaipIAndNaipIIAnimalsCoverd: any;
  totalStateWiseNaipIAndNaipIIFarmersBenifited: any;
  sumallNaipIAiDone: any;
  sumallNaipIIAiDone: any;
  sumallNaipIAnimalsCoverd: any;
  sumallNaipIIAnimalsCoverd: any;
  sumallNaipIFarmersBenifitted: any;
  sumallNaipIIFarmersBenifitted: any;
  Breed_Multiplication_Farms_Sanctioned: any;
  sumallNaipDistrictAiDone: any;
  sumallNaipDistrictAnimalsCoverd: any;
  sumallNaipDistrictFarmersBenifitted: any;
  sumallNaipIIDistrictAiDone: any;
  sumallNaipIIDistrictAnimalsCoverd: any;
  sumallNaipIIDistrictFarmersBenifitted: any;
  sumallNaipIDistrictAiDone: any;
  sumallNaipIDistrictAnimalsCoverd: any;
  sumallNaipIDistrictFarmersBenifitted: any;
  currentNaipIIIData: any;
  totalNaipIVNoOfAiAllSchemeDone: any;
  totalNaipIvFarmersBenifitedAllSchemeDone: any;
  totalNoOfAiDoneInNaipIV: any;
  totalNoOfAnimalCoverdDoneInNaipIV: any;
  totalNoOfFarmersBenefittedInNaipIV: any;
  selectNaipIIIDistrict: any;
  sumallNaipIIINaipDistrictAiDone: any;
  sumallNaipIIIDistrictAnimalsCoverd: any;
  sumallNaipIIIDistrictFarmersBenifitted: any;
  constructor(public serv: RgmschemeService,private route: ActivatedRoute) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'}
  ];
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {  
      this.selectStateName=params['State'];
	   console.log("state name for state code")
      console.log(params['State'])
      const myArray = params['State'].split("(");
      this.selectStateName=myArray[0];
      const myArray1 = myArray[1].split(")");
      console.log(myArray1)
      this.selectStateCode=myArray1[0];

    });
  this.StateWiseRgmDashboardData();
  this.MaitriDashboardData();
    //console.log(this.route.snapshot.queryParams.name); // book
  
  }
  StateWiseRgmDashboardData()
  {
    this.stateWiseRgmAllData=[];
	this.totalNoOfAiDoneInNaipIV=0
    this.totalNoOfAnimalCoverdDoneInNaipIV=0
    this.totalNoOfFarmersBenefittedInNaipIV=0
    this.totalNoOfAiDoneInNaipIII=0
    this.totalNoOfAnimalCoverdDoneInNaipIII=0
    this.totalNoOfFarmersBenefittedInNaipIII=0
    this.totalNoOfAiDoneInNaipII=0
    this.totalNoOfAnimalCoverdDoneInNaipII=0
    this.totalNoOfFarmersBenefittedInNaipII=0
    this.totalNoOfAiDoneInNaipI=0
    this.totalNoOfAnimalCoverdDoneInNaipI=0
    this.totalNoOfFarmersBenefittedInNaipI=0
    this.totalStateWiseNaipIAndNaipIIAiDone=0
    this.totalStateWiseNaipIAndNaipIIAnimalsCoverd=0
    this.totalStateWiseNaipIAndNaipIIFarmersBenifited=0
    this.sumallNaipIAiDone=0
    this.sumallNaipIIAiDone=0
    this.sumallNaipIAnimalsCoverd=0
    this.sumallNaipIIAnimalsCoverd=0
    this.sumallNaipIFarmersBenifitted=0
    this.sumallNaipIIFarmersBenifitted=0

    this.serv.getNaipData().subscribe((res)=>{
      this.stateWiseRgmAllData=res;
      console.log(this.stateWiseRgmAllData)
   //////////////////////////////////////Group By State Data/////////////////////////////////////////   
      this.totalRgmSateWiseTypeNaipDataCount=[];
      var groupByTypeState = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalLhdcStateData=groupByTypeState(this.stateWiseRgmAllData, 'State_Name')
  this.totalRgmSateWiseTypeNaipDataCount = Object.entries(totalLhdcStateData)
  this.totalNaipAiDoneStateWise=[];
  this.totalAllSchemeDoneArray=[];
  this.totalRgmSateWiseTypeNaipDataCount.forEach((y:any) =>
  {
  if(y[0]==this.selectStateName)
  {
   this.selectStateData=y[1]
  }
  var  sumallAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);

var allSchemeData = Object.assign({"statName": y[0]}, {"totalNoOfAiDone": sumallAiDone});
this.totalNaipAiDoneStateWise.push(allSchemeData);
  });
  console.log(this.totalNaipAiDoneStateWise);

  var topValues = this.totalNaipAiDoneStateWise.sort((a:any,b:any) => b['totalNoOfAiDone']-a['totalNoOfAiDone']).slice(0,5);
console.log("top states")
console.log(topValues)
this.topFiveNaipStateArray=[];

topValues.forEach((y:any) =>
{
//this.numDifferentiation(y['VacinationDone']);
var finalObj1 = Object.assign({"State": y['statName']}, {"totalNoOfAiDone": this.numDifferentiation(y['totalNoOfAiDone'])});
this.topFiveNaipStateArray.push(finalObj1);
});
console.log(this.topFiveNaipStateArray)






/////////////////////////////////////Group By On Date////////////////////////////////////

var groupByScheme = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalAllNaipSchemeData=groupByScheme(this.selectStateData, 'Scheme')
var totalAllNaipSchemeDataCount = Object.entries(totalAllNaipSchemeData)

console.log("total  naip  data----------")
console.log(totalAllNaipSchemeDataCount)
var groupByDataDate = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalCurrentDateData=groupByDataDate(this.selectStateData, 'DataDate')
var totalCurrentDateDataCount = Object.entries(totalCurrentDateData)
console.log("current date data")
console.log(totalCurrentDateDataCount)
this.currentDateData=[];
this.currentDateData.push(totalCurrentDateDataCount[totalCurrentDateDataCount.length-1]['1']);
totalCurrentDateDataCount.forEach((y:any) =>
{
if(y[0]=='01/08/2021')
{
  this.currentNaipIIData=y[1];
}
if(y[0]=='01/06/2020')
{
  this.currentNaipIData=y[1];
}
if(y[0]=='02/11/2022')
{
  this.currentNaipIIIData=y[1];
}
});
console.log("curentdate")
console.log(this.currentNaipIIIData)


/////////////////////////Group By Naip IV Done Type///////////////////////////////////////////////////

if(this.currentDateData[0])
{
this.totalNaipIVStateTypeAiDataCount=[];
var groupByTotalAiTypeData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalAiTypeData=groupByTotalAiTypeData(this.currentDateData[0], 'Scheme')
this.totalNaipIVStateTypeAiDataCount = Object.entries(totalAiTypeData)
console.log("currrent data for naipIIIIIIIIIIIIIIIII")
console.log(this.totalNaipIVStateTypeAiDataCount)
this.totalAllNaipIVAiSchemeDoneArray=[];
this.totalNaipIVStateTypeAiDataCount.forEach((y:any) =>
{
  var  sumallAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var  sumallAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var  sumallFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(sumallAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(sumallAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(sumallFarmersBenifitted)});
  this.totalAllNaipIVAiSchemeDoneArray.push(allSchemeData);
});

this.totalNaipIVNoOfAiAllSchemeDone=this.numDifferentiation(this.totalNoOfAiDoneCount);
this.totalNaipIvFarmersBenifitedAllSchemeDone=this.numDifferentiation(this.totalNoOffarmersBenifited);

this.totalNoOfAiDoneInNaipIV=  this.totalAllNaipIVAiSchemeDoneArray[0]['totalNoOfAiDone'];
this.totalNoOfAnimalCoverdDoneInNaipIV =  this.totalAllNaipIVAiSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
this.totalNoOfFarmersBenefittedInNaipIV=this.totalAllNaipIVAiSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];
console.log(this.totalAllNaipIVAiSchemeDoneArray)

}

/////////////////////////////////////Group By On Naip IIIDone Type//////////////////////////////////////
if(this.currentNaipIIIData)
{
this.totalStateTypeAiDataCount=[];
var groupByTotalAiTypeData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalAiTypeData=groupByTotalAiTypeData(this.currentNaipIIIData, 'Scheme')
this.totalStateTypeAiDataCount = Object.entries(totalAiTypeData)
console.log("currrent data for naipIIIIIIIIIIIIIIIII")
console.log(this.totalStateTypeAiDataCount)
this.totalAllAiSchemeDoneArray=[];
this.totalStateTypeAiDataCount.forEach((y:any) =>
{
  var  sumallAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var  sumallAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var  sumallFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(sumallAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(sumallAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(sumallFarmersBenifitted)});
  this.totalAllAiSchemeDoneArray.push(allSchemeData);
});

this.totalNoOfAiAllSchemeDone=this.numDifferentiation(this.totalNoOfAiDoneCount);
this.totalFarmersBenifitedAllSchemeDone=this.numDifferentiation(this.totalNoOffarmersBenifited);

this.totalNoOfAiDoneInNaipIII=  this.totalAllAiSchemeDoneArray[0]['totalNoOfAiDone'];
this.totalNoOfAnimalCoverdDoneInNaipIII =  this.totalAllAiSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
this.totalNoOfFarmersBenefittedInNaipIII=this.totalAllAiSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];
console.log(this.totalAllAiSchemeDoneArray)
this.totalRgmDistrictWiseTypeNaipIIIDataCount=[];
var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalRgmNaipIIIDistrictsData=groupByTypeDistrict(this.currentNaipIIIData, 'District_Name')
this.totalRgmDistrictWiseTypeNaipIIIDataCount = Object.entries(totalRgmNaipIIIDistrictsData)

}

/////////////////////////////////////Group By On Naip IIDone Type//////////////////////////////////////
if(this.currentNaipIIData)
{
this.totalStateTypeAiDataCount=[];
var groupByTotalAiTypeData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalAiTypeData=groupByTotalAiTypeData(this.currentNaipIIData, 'Scheme')
this.totalStateTypeAiDataCount = Object.entries(totalAiTypeData)
//console.log("NAI II DAttttttttttttttttttt")
console.log(this.totalStateTypeAiDataCount)
this.totalAllNaipIISchemeDoneArray=[];
this.SchemeCountArray=[];
this.totalStateTypeAiDataCount.forEach((y:any) =>
{
  this.sumallNaipIIAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIIAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIIFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
 
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIIAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIIAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIIFarmersBenifitted)});
  this.totalAllNaipIISchemeDoneArray.push(allSchemeData);
});

this.totalNoOfAiAllSchemeDone=this.numDifferentiation(this.totalNoOfAiDoneCount);
this.totalFarmersBenifitedAllSchemeDone=this.numDifferentiation(this.totalNoOffarmersBenifited);

this.totalNoOfAiDoneInNaipII=  this.totalAllNaipIISchemeDoneArray[0]['totalNoOfAiDone'];
this.totalNoOfAnimalCoverdDoneInNaipII =  this.totalAllNaipIISchemeDoneArray[0]['totalNoOfAnimalCoverd'];
this.totalNoOfFarmersBenefittedInNaipII=this.totalAllNaipIISchemeDoneArray[0]['totalNoOfFarmersBenefitted'];

this.totalRgmDistrictWiseTypeNaipIIDataCount=[];
var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalRgmNaipIIDistrictsData=groupByTypeDistrict(this.currentNaipIIData, 'District_Name')
this.totalRgmDistrictWiseTypeNaipIIDataCount = Object.entries(totalRgmNaipIIDistrictsData)
}

/////////////////////////////////////Group By On Naip IDone Type//////////////////////////////////////

if(this.currentNaipIData)
{

this.totalStateTypeAiDataCount=[];
var groupByTotalAiTypeData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalAiTypeData=groupByTotalAiTypeData(this.currentNaipIData, 'Scheme')
this.totalStateTypeAiDataCount = Object.entries(totalAiTypeData)
this.totalAllNaipISchemeDoneArray=[];

this.totalStateTypeAiDataCount.forEach((y:any) =>
{
    this.sumallNaipIAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.sumallNaipIAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.sumallNaipIFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
 
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIFarmersBenifitted)});
  this.totalAllNaipISchemeDoneArray.push(allSchemeData);
});


this.totalNoOfAiAllSchemeDone=this.numDifferentiation(this.totalNoOfAiDoneCount);
this.totalFarmersBenifitedAllSchemeDone=this.numDifferentiation(this.totalNoOffarmersBenifited);

this.totalNoOfAiDoneInNaipI=  this.totalAllNaipISchemeDoneArray[0]['totalNoOfAiDone'];
this.totalNoOfAnimalCoverdDoneInNaipI =  this.totalAllNaipISchemeDoneArray[0]['totalNoOfAnimalCoverd'];
this.totalNoOfFarmersBenefittedInNaipI=this.totalAllNaipISchemeDoneArray[0]['totalNoOfFarmersBenefitted'];


this.totalRgmDistrictWiseTypeNaipIDataCount=[];
var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalRgmNaipIDistrictsData=groupByTypeDistrict(this.currentNaipIData, 'District_Name')
this.totalRgmDistrictWiseTypeNaipIDataCount = Object.entries(totalRgmNaipIDistrictsData)
    }
console.log("--------------naipI  and NaipII  data--------------------")
console.log(this.sumallNaipIAiDone)
console.log(this.sumallNaipIIAiDone)
this.totalStateWiseNaipIAndNaipIIAiDone=this.numDifferentiation(this.sumallNaipIAiDone+this.sumallNaipIIAiDone);
this.totalStateWiseNaipIAndNaipIIAnimalsCoverd=this.numDifferentiation(this.sumallNaipIAnimalsCoverd+this.sumallNaipIIAnimalsCoverd);
this.totalStateWiseNaipIAndNaipIIFarmersBenifited=this.numDifferentiation(this.sumallNaipIFarmersBenifitted+this.sumallNaipIIFarmersBenifitted);


  /////////////////////////////////////Group By On all scheme Districts//////////////////////////////////////
 

this.totalRgmDistrictWiseTypeNaipIVDataCount=[];
var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalRgmcDistrictData=groupByTypeDistrict(this.currentDateData[0], 'District_Name')
this.totalRgmDistrictWiseTypeNaipIVDataCount = Object.entries(totalRgmcDistrictData)
console.log(this.currentDateData[0])
console.log("dididididiid")
console.log(this.totalRgmDistrictWiseTypeNaipIVDataCount)
 var keys = Object.keys(totalRgmcDistrictData);
 console.log("all keys")
 console.log(keys)
console.log(this.totalRgmDistrictWiseTypeNaipIVDataCount);
this.totalNaipDistrictWise=[];
this.topFiveDistrictNaipArray=[];
this.distrricts=[];
this.districtsName=[];
this.totalRgmDistrictWiseTypeNaipIVDataCount.forEach((y:any) =>
{
const sumallDistrictAiDone = y[1].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
var finalObj1 = Object.assign({"District": y[0]}, {"naipaiDone": sumallDistrictAiDone});
this.totalNaipDistrictWise.push(finalObj1)
var finalObj = Object.assign({"name": y[0].toUpperCase()}, {"code":  y[0].toUpperCase()});
this.distrricts.push(finalObj)
this.districtsName= this.distrricts.sort(function(a:any,b:any){ return a.name.localeCompare(b.name); });
});
//------- variable use for defaul select------------------------------------------------------
//this.selectedCity1 = {name: 'ANAND',code: 'ANAND'};
console.log("District_Name")
console.log(this.districtsName)
var topValues = this.totalNaipDistrictWise.sort((a:any,b:any) => b['naipaiDone']-a['naipaiDone']).slice(0,5);
console.log(this.totalNaipDistrictWise)
console.log(topValues);
topValues.forEach((y:any) =>
{
var finalObj1 = Object.assign({"District": y['District']}, {"naipaiDone": this.numDifferentiation(y['naipaiDone'])});
this.topFiveDistrictNaipArray.push(finalObj1);
});
console.log("top five vacination District")
console.log(this.topFiveDistrictNaipArray)
this.topFirstPerfomanceDistrict=this.topFiveDistrictNaipArray[0]['District'];
this.topFirstPerformingDistrictAiDone=this.topFiveDistrictNaipArray[0]['naipaiDone'];

this.topSecondPerfomanceDistrict=this.topFiveDistrictNaipArray[1]['District'];
this.topSecondPerformingDistrictAiDone=this.topFiveDistrictNaipArray[1]['naipaiDone'];

this.topThirdPerfomanceDistrict=this.topFiveDistrictNaipArray[2]['District'];
this.topThirdPerformingDistrictAiDone=this.topFiveDistrictNaipArray[2]['naipaiDone'];

this.topFourthPerfomanceDistrict=this.topFiveDistrictNaipArray[3]['District'];
this.topFourthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[3]['naipaiDone'];

this.topFifthPerfomanceDistrict=this.topFiveDistrictNaipArray[4]['District'];
this.topFifthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[4]['naipaiDone'];
for(let state of this.totalNaipAiDoneStateWise){
  let temp = [state.statName,Number(state.totalNoOfAiDone)];
  if( state.statName=="ANDAMAN & NICOBAR ISLANDS"){
    temp = ['IN-AN',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="LAKSHADWEEP"){
    temp = ['IN-LD',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="ODISHA"){
    temp = ['IN-OR',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="LADAKH"){
    temp = ['Ladakh',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="UTTARANCHAL"){
    temp = ['IN-UT',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="JAMMU & KASHMIR"){
    temp = ['Jammu and Kashmir',Number(state.totalNoOfAiDone)];
  }
  this.states_data.push(temp);
}
console.log("sdsdsdsds")
console.log(this.states_data)
   this.mapReady=true
  
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
 
  MaitriDashboardData()
  {
    this.MaitriStateWiseAllData=[];
    this.MAITRIsTrainedInducted=0
    this.SexSortedSemenDosesProduced=0
    this.SexSortedSemenDosesAIDone=0
    this.SexSortedSemenDosesSold=0
    this.Breed_Multiplication_Farms_Sanctioned=0
    this.IVF=0
    this.serv.getMaitriData().subscribe((res)=>{
      this.MaitriStateWiseAllData=res;
    console.log("Maitri data state wise--------------")
    this.totalMatriStatewiseArray=[];
    var groupByMvuData = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalMatristaewiseData=groupByMvuData( this.MaitriStateWiseAllData, 'State_Code')
    console.log(this.MaitriStateWiseAllData)
    this.totalMatriStatewiseArray = Object.entries(totalMatristaewiseData)
    this.totalMatriStatewiseArray.forEach((y:any) =>
    {
      console.log(y[0])
    if((y[0]==this.selectStateCode))
    {
  console.log("rgm infoooooooo")
  console.log(y[1][0])
  this.MAITRIsTrainedInducted=y[1][0]['MAITRIs_Trained_Inducted'];
  this.SexSortedSemenDosesProduced=y[1][0]['Sex_Sorted_Semen_Doses_Produced'];
  this.Breed_Multiplication_Farms_Sanctioned=y[1][0]['Breed_Multiplication_Farms_Sanctioned'];
  this.IVF=y[1][0]['IVF'];
  this.SexSortedSemenDosesAIDone=y[1][0]['Sex_Sorted_Semen_Doses_AI_Done'];
  this.SexSortedSemenDosesSold=y[1][0]['Sex_Sorted_Semen_Doses_Sold'];
   } 
   });
    },
    (err)=>{
      console.log(err)
    }
  );
  }
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 100) {
      this.valueInFormate    = val ;
    }
    return this.valueInFormate;
  }
  public select(event: ChartSelectEvent) {
    console.log(event.selectedRowValues[0])
    this.totalRgmSateWiseTypeNaipDataCount.forEach((y:any) =>
    {
   if(y[0]==event.selectedRowValues[0])
   {
     this.selectState=y[1]
   }
   
    });
    var groupByTypeVacination = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
var totalTypeVacinationStateData=groupByTypeVacination(this.selectState, 'Scheme')
var totalTypeVacinationStateDataCount = Object.entries(totalTypeVacinationStateData)
this.totalAggregateStateAiData=[];
  totalTypeVacinationStateDataCount.forEach((y:any) =>
  {
      var totalVaccination =  y[1].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);

      var finalObj = Object.assign({"name": y[0]}, {"value": totalVaccination});
      this.totalAggregateStateAiData.push(finalObj)

  });
console.log(this.totalAggregateStateAiData)
this.typeOfVacinationChart = {
  title: {
    show:true,
    text: event.selectedRowValues[0],
    left: 'center'
   
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'right'
  },
  series: [
    {
     // name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '15',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: this.totalAggregateStateAiData
    }
  ]
};
}
public geoChart: GoogleChartInterface = {
  chartType: 'GeoChart',
  dataTable: this.states_data,
  options: {
    domain:'IN',
    region: 'IN',
    colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
    resolution: 'provinces',
    zoom: 6,
   // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
disableDefaultUI: true,
//displayMode: 'text',
defaultColor:'#40e0d0',
backgroundColor: 'transparent',
enableRegionInteractivity: true,
//tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
    'height': 3,
    //'width': 850,
   

    
  }
  
};


  OnChangeDistrict(event:any)
  {
    console.log(event.value.name)
    console.log(this.totalRgmDistrictWiseTypeNaipIVDataCount)
    this.totalStateWiseNaipIAndNaipIIAiDone=0
    this.totalStateWiseNaipIAndNaipIIAnimalsCoverd=0
    this.totalStateWiseNaipIAndNaipIIFarmersBenifited=0
    this.sumallNaipIDistrictAiDone=0
    this.sumallNaipIIDistrictAiDone=0
    this.sumallNaipIDistrictAnimalsCoverd=0
    this.sumallNaipIIDistrictAnimalsCoverd=0
    this.sumallNaipIDistrictFarmersBenifitted=0
    this.sumallNaipIIDistrictFarmersBenifitted=0
    this.totalRgmDistrictWiseTypeNaipIVDataCount.forEach((y:any) =>
    {
   if(y[0].toUpperCase()==event.value.name)
   {
     this.selectDistrict=y[1]
   }   
   });
   this.totalRgmDistrictWiseTypeNaipIIIDataCount.forEach((y:any) =>
   {
  if(y[0].toUpperCase()==event.value.name)
  {
    this.selectNaipIIIDistrict=y[1]
  }   
  });

   this.totalRgmDistrictWiseTypeNaipIIDataCount.forEach((y:any) =>
   {
  if(y[0].toUpperCase()==event.value.name)
  {
    this.selectNaipIIDistrict=y[1]
  }   
  });

  this.totalRgmDistrictWiseTypeNaipIDataCount.forEach((y:any) =>
  {
 if(y[0].toUpperCase()==event.value.name)
 {
  this.selectNaipIDistrict=y[1]
 }   
 });
 this.totalDistrictTypeAiDataCount=[];
this.totalNaipIIIDistrictTypeAiDataCount=[];
this.totalNaipIIDistrictTypeAiDataCount=[];
this.totalNaipIDistrictTypeAiDataCount=[];
if(this.selectNaipIIIDistrict)
{
var groupByDistrictTotalVaccinatioTypeData = function(xs:any, key:any) {
return xs.reduce(function(rv:any, x:any) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});
};
var totalVaccinationTypeData=groupByDistrictTotalVaccinatioTypeData(this.selectDistrict, 'Scheme')
this.totalDistrictTypeAiDataCount = Object.entries(totalVaccinationTypeData)
}


if(this.selectNaipIIIDistrict)
{
var groupByDistrictNaipIIITotalVaccinatioTypeData = function(xs:any, key:any) {
return xs.reduce(function(rv:any, x:any) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});
};
var totalNaipIIIVaccinationTypeData=groupByDistrictNaipIIITotalVaccinatioTypeData(this.selectNaipIIIDistrict, 'Scheme')
this.totalNaipIIIDistrictTypeAiDataCount = Object.entries(totalNaipIIIVaccinationTypeData)
}
if(this.selectNaipIIDistrict)
{
var groupByDistrictNaipIITotalVaccinatioTypeData = function(xs:any, key:any) {
return xs.reduce(function(rv:any, x:any) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});
};


var totalNaipIIVaccinationTypeData=groupByDistrictNaipIITotalVaccinatioTypeData(this.selectNaipIIDistrict, 'Scheme')
this.totalNaipIIDistrictTypeAiDataCount = Object.entries(totalNaipIIVaccinationTypeData)
}
if(this.selectNaipIDistrict)
{
var groupByDistrictNaipITotalVaccinatioTypeData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  };
  var totalNaipIVaccinationTypeData=groupByDistrictNaipITotalVaccinatioTypeData(this.selectNaipIDistrict, 'Scheme')
  this.totalNaipIDistrictTypeAiDataCount = Object.entries(totalNaipIVaccinationTypeData)
}

console.log(this.totalDistrictTypeAiDataCount)
this.totalDistrictWiseAiSchemeDoneArray=[];
this.totalDistrictWiseNaipIIIAiSchemeDoneArray=[];
this.totalDistrictWiseNaipIIAiSchemeDoneArray=[];
this.totalDistrictWiseNaipIAiSchemeDoneArray=[];
this.totalDistrictTypeAiDataCount.forEach((y:any) =>
{
  this.sumallNaipDistrictAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipDistrictAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipDistrictFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipDistrictAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipDistrictAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipDistrictFarmersBenifitted)});
  this.totalDistrictWiseAiSchemeDoneArray.push(allSchemeData);
});
this.totalNaipIIIDistrictTypeAiDataCount.forEach((y:any) =>
{
  this.sumallNaipIIINaipDistrictAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIIIDistrictAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIIIDistrictFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIIINaipDistrictAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIIIDistrictAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIIIDistrictFarmersBenifitted)});
  this.totalDistrictWiseNaipIIIAiSchemeDoneArray.push(allSchemeData);
});

this.totalNaipIIDistrictTypeAiDataCount.forEach((y:any) =>
{
  this.sumallNaipIIDistrictAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIIDistrictAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIIDistrictFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIIDistrictAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIIDistrictAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIIDistrictFarmersBenifitted)});
  this.totalDistrictWiseNaipIIAiSchemeDoneArray.push(allSchemeData);
});

this.totalNaipIDistrictTypeAiDataCount.forEach((y:any) =>
{
  this.sumallNaipIDistrictAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIDistrictAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.sumallNaipIDistrictFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIDistrictAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIDistrictAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIDistrictFarmersBenifitted)});
  this.totalDistrictWiseNaipIAiSchemeDoneArray.push(allSchemeData);
});
console.log("district wise dataaa")
console.log(this.totalDistrictWiseAiSchemeDoneArray)

   this.totalNoOfAiDoneInNaipIV=  this.totalDistrictWiseAiSchemeDoneArray[0]['totalNoOfAiDone'];
   this.totalNoOfAnimalCoverdDoneInNaipIV =  this.totalDistrictWiseAiSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
   this.totalNoOfFarmersBenefittedInNaipIV=this.totalDistrictWiseAiSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];

   this.totalNoOfAiDoneInNaipIII=  this.totalDistrictWiseNaipIIIAiSchemeDoneArray[0]['totalNoOfAiDone'];
   this.totalNoOfAnimalCoverdDoneInNaipIII =  this.totalDistrictWiseNaipIIIAiSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
   this.totalNoOfFarmersBenefittedInNaipIII=this.totalDistrictWiseNaipIIIAiSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];

   this.totalNoOfAiDoneInNaipII=  this.totalDistrictWiseNaipIIAiSchemeDoneArray[0]['totalNoOfAiDone'];
   this.totalNoOfAnimalCoverdDoneInNaipII =  this.totalDistrictWiseNaipIIAiSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
   this.totalNoOfFarmersBenefittedInNaipII=this.totalDistrictWiseNaipIIAiSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];

   this.totalNoOfAiDoneInNaipI=  this.totalDistrictWiseNaipIAiSchemeDoneArray[0]['totalNoOfAiDone'];
   this.totalNoOfAnimalCoverdDoneInNaipI =  this.totalDistrictWiseNaipIAiSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
   this.totalNoOfFarmersBenefittedInNaipI=this.totalDistrictWiseNaipIAiSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];


   this.totalStateWiseNaipIAndNaipIIAiDone=this.numDifferentiation(this.sumallNaipIDistrictAiDone+this.sumallNaipIIDistrictAiDone);
   this.totalStateWiseNaipIAndNaipIIAnimalsCoverd=this.numDifferentiation(this.sumallNaipIDistrictAnimalsCoverd+this.sumallNaipIIDistrictAnimalsCoverd);
   this.totalStateWiseNaipIAndNaipIIFarmersBenifited=this.numDifferentiation(this.sumallNaipIDistrictFarmersBenifitted+this.sumallNaipIIDistrictFarmersBenifitted);
   

  }                     
  
}
