import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatedashboardComponent } from './statedashboard/statedashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { TableModule } from 'primeng/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NlmschemeComponent } from './nlmscheme/nlmscheme.component';
import { AhidfschemeComponent } from './ahidfscheme/ahidfscheme.component';
import { NpddschemeComponent } from './npddscheme/npddscheme.component';
import { RgmschemeComponent } from './rgmscheme/rgmscheme.component';
import { SdcfposchemeComponent } from './sdcfposcheme/sdcfposcheme.component';
import { HeaderComponent } from './header/header.component';
import { DidfschemeComponent } from './didfscheme/didfscheme.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { StatewiseLhdcschemeComponent } from './statewise-lhdcscheme/statewise-lhdcscheme.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LogoutComponent } from './logout/logout.component';
import { SdcfpoentryformComponent } from './sdcfpoentryform/sdcfpoentryform.component';
import { RgmentryformComponent } from './rgmentryform/rgmentryform.component';
import { NpddentryformComponent } from './npddentryform/npddentryform.component';
import { DidfentryformComponent } from './didfentryform/didfentryform.component';
import { HeaderoneComponent } from './headerone/headerone.component';
import { LhdcpentryformComponent } from './lhdcpentryform/lhdcpentryform.component';
import { AuthguradServiceService } from './authgurad-service.service';
import { StatewiseNlmschemeComponent } from './statewise-nlmscheme/statewise-nlmscheme.component';
import { StatewiseRgmschemeComponent } from './statewise-rgmscheme/statewise-rgmscheme.component';
import { StatewiseAhidfschemeComponent } from './statewise-ahidfscheme/statewise-ahidfscheme.component';
import { StatewiseNpddschemeComponent } from './statewise-npddscheme/statewise-npddscheme.component';
import { StatewiseDidfschemeComponent } from './statewise-didfscheme/statewise-didfscheme.component';
import { DidffundingpatternentryformComponent } from './didffundingpatternentryform/didffundingpatternentryform.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AuthInterceptor } from './core/auth.interceptor';
import { AuthGuard } from './core/auth.guard';
import { NlmentryformComponent } from './nlmentryform/nlmentryform.component';
import { NlmsubsidyentryformComponent } from './nlmsubsidyentryform/nlmsubsidyentryform.component';
import { IndexComponent } from './index/index.component';
//import { ToastrModule } from 'ngx-toastr';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainpageComponent,
    DashboardComponent,
    StatedashboardComponent,
    NlmschemeComponent,
    AhidfschemeComponent,
    NpddschemeComponent,
    RgmschemeComponent,
    SdcfposchemeComponent,
	SdcfpoentryformComponent,
	RgmentryformComponent,
	DidfentryformComponent,
	NpddentryformComponent,
	HeaderoneComponent,
	LhdcpentryformComponent,
    HeaderComponent,
    DidfschemeComponent,
    StatewiseLhdcschemeComponent,
    LogoutComponent,
    StatewiseNlmschemeComponent,
    StatewiseRgmschemeComponent,
	StatewiseAhidfschemeComponent,
	StatewiseNpddschemeComponent,
	StatewiseDidfschemeComponent,
	DidffundingpatternentryformComponent,
	ChangepasswordComponent,
 NlmentryformComponent,
 NlmsubsidyentryformComponent,
 IndexComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
	TableModule,
    Ng2GoogleChartsModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    DropdownModule,
    ReactiveFormsModule,
    AppRoutingModule,
	//ToastrModule.forRoot(),
    NgCircleProgressModule.forRoot({
      "radius": 60,
      "space": -10
    }),

    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [
    //AuthguradServiceService,
     ///TODO - 111
    AuthGuard,
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
