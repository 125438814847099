import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class AhidfschemeService {

  constructor(public http: HttpClient) { }
  getData() {
    // return this.http.get('/assets/finalsud25012022.json');
    return this.http.get('/assets/geo.json');
     }
     getAhidfDashboardData() {
      // return this.http.get('/assets/finalsud25012022.json');
      //return this.http.get('/assets/ahidfdashboard.json');
	     return this.http.get('https://dahddashboard.ndlm.co.in/api/ahidfdashboard');
       }
     getAhidfData() {
      // return this.http.get('/assets/ahidfkpi.json');
	   // return this.http.get('https://dahddashboard.ndlm.co.in/api/ahidfdashboard');
      return this.http.get('https://dahddashboard.ndlm.co.in/api/ahidfkpi');
       }
}
