import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { RgmschemeService } from './rgmscheme.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-rgmscheme',
  templateUrl: './rgmscheme.component.html',
  styleUrls: ['./rgmscheme.component.scss']
})
export class RgmschemeComponent implements OnInit {
  
  yearWiseEnroleMentChart:any;
  states_data = [['State Code','State','Total AI Done']];
  NaipAllData:  any = new Array()
  MaitriAllData:  any = new Array()
  valueInFormate:any
  totalAllSchemeDoneArray:  any = new Array()
  SchemeCountArray:  any = new Array()
  farmersBenifitedCountArray:  any = new Array()
  clavesBornArray:  any = new Array()
  totalSateWiseTypeNaipDataCount:  any = new Array()
  totalNaipStateWise:  any = new Array()
  topFiveNaipStateArray:  any = new Array()
  totalAggregateStateNaipData:  any = new Array()
  totalDateWiseNaipIIIAiDoneArray:  any = new Array()
  naipIIIAiDate:  any = new Array()
  totalNoOfNaipIIIAiDoneArray:  any = new Array()
  NaipIIAiDate:  any = new Array()
  totalNoOfNaipIIAiDoneArray:  any = new Array()
  totalDateWiseNaipIIAiDoneArray:  any = new Array()
  result:  any = new Array()
  response:  any = new Array()
  currentDateData:  any = new Array()
  totalAllNaipIISchemeDoneArray:  any = new Array()
  totalAllNaipISchemeDoneArray:  any = new Array()
  totalAllNaipIVSchemeDoneArray:  any = new Array()
  totalSateWiseTypeNaipIVDataCount:  any = new Array()
  topFiveNaipIVStateArray:  any = new Array()
  naipIVAiDate:  any = new Array()
  totalNoOfNaipIVAiDoneArray:  any = new Array()
  totalDateWiseNaipIVDataCount:  any = new Array()
  totalDateWiseNaipIVAiDoneArray:  any = new Array()
  currentNaipIVDateData:  any = new Array()
  naipITypeAiData:  any = new Array()
  totalDateWiseNaipIAiDataCount:  any = new Array()
  totalDateWiseNaipIAiDoneArray:  any = new Array()
  NaipIAiDate:  any = new Array()
  totalNoOfNaipIAiDoneArray:  any = new Array()
  mapReady=false;
  myCSSclass=true;
  myCSSclass1=false;
  myCSSclass2=false;
  myCSSclass3=false;
  showChartNaipIIchart=false;
  showChartNaipIchart=false;
  showChartNaipIVchart=true
  showChartNaipIIIchart=false;
  showPieChartpageload = true;
  showPieChartMapclick= false;
  totalNoOfAiDoneCount: any;
  totalNoOfAiAllSchemeDone: any;
  totalNoOffarmersBenifited: any;
  totalFarmersBenifitedAllSchemeDone: any;
  totalNoOfAiDoneInNaipI: any;
  totalNoOfAnimalCoverdDoneInNaipI: any;
  totalNoOfFarmersBenefittedInNaipI: any;
  totalNoOfAiDoneInNaipII: any;
  totalNoOfAnimalCoverdDoneInNaipII: any;
  totalNoOfFarmersBenefittedInNaipII: any;
  totalNoOfAiDoneInNaipIII: any;
  totalNoOfAnimalCoverdDoneInNaipIII: any;
  totalNoOfFarmersBenefittedInNaipIII: any;
  allSexSortedSemenDosesAIDone:any;
  totalDateWiseNaipIIIataCount:any;
  clavesBornArrayCount: any;
  allSexSortedSemenDosesSold:any;
  totalNoOfClavisBornDone: any;
  topFirstPerfomanceState: any;
  topFirstPerformingNaipDone: any;
  topSecondPerfomanceState: any;
  topSecondPerformingNaipDone: any;
  topThirdPerfomanceState: any;
  topThirdPerformingNaipDone: any;
  topFourthPerfomanceState: any;
  topFourthPerformingNaipDone: any;
  topFifthPerfomanceState: any;
  topFifthPerformingNaipDone: any;
  typeOfVacinationChart:any;
  allSexSortedSemenDosesProduced:any
  selectState: any;
  schemeTypeNaipIIIData: any;
  showNaipIIEnrolementChart:any;
  naipIITypeAiData: any;
  sumallIVF:any;
  sumallMAITRIsTrainedInducted:any;
  totalDateWiseNaipIIAiDataCount: any;
  currentNaipIIData: any;
  currentNaipIData: any;
   lastDateFromArray: any;
  totalNoOfAiDoneInNaipIV: any;
  totalNoOfAiDoneInNaipIVWithoutNumDif: any;
  totalNoOfAnimalCoverdDoneInNaipIV: any;
  totalNoOfFarmersBenefittedInNaipIV: any;
  totalNaipIAndNaipIIAiDone: any;
  sumallNaipIIAiDone: any;
  sumallNaipIAiDone: any;
  totalNaipIAndNaipIIAnimalsCoverd: any;
  sumallNaipIIAnimalsCoverd: any;
  sumallNaipIAnimalsCoverd: any;
  totalNaipIAndNaipIIFarmersBenifited: any;
  sumallNaipIIFarmersBenifitted: any;
  sumallNaipIFarmersBenifitted: any;
  yearWiseEnroleMentChartNaipI:any;
  showNaipIIEnrolementChartNaipII:any;
  yearWiseEnroleMentChartNaipIV:any;
  yearWiseEnroleMentChartNaipIII:any;
  dateres: any;
  dateresone: any;
  year: any;
  month: any;
  day: any;
  noday:any;
  curentDate: any;
  stateLength: any;
  Breed_Multiplication_Farms_Sanctioned: any;
  
  

  State_AndhraPradesh :any
  AndhraPradesh_TotalAIDone :any
  State_ArunachalPradesh :any
  ArunachalPradesh_TotalAIDone :any
  State_Assam :any
  Assam_TotalAIDone :any 
  State_Bihar :any
  Bihar_TotalAIDone :any
  State_Chhattisgarh :any
  Chhattisgarh_TotalAIDone :any
  State_Goa :any
  Goa_TotalAIDone :any
  State_Gujarat :any
  Gujarat_TotalAIDone :any
  State_Haryana :any
  Haryana_TotalAIDone :any
  State_HimachalPradesh :any
  HimachalPradesh_TotalAIDone :any
  State_Jharkhand :any
  Jharkhand_TotalAIDone :any
  State_Karnataka :any
  Karnataka_TotalAIDone :any
  State_Kerala :any
  Kerala_TotalAIDone :any
  State_MadhyaPradesh :any
  MadhyaPradesh_TotalAIDone :any
  State_Maharashtra :any
  Maharashtra_TotalAIDone :any
  State_Manipur :any
  Manipur_TotalAIDone :any
  State_Meghalaya :any
  Meghalaya_TotalAIDone :any
  State_Mizoram :any
  Mizoram_TotalAIDone :any
  State_Nagaland :any
  Nagaland_TotalAIDone :any
  State_Odisha :any
  Odisha_TotalAIDone :any
  State_Punjab :any
  Punjab_TotalAIDone :any
  State_Rajasthan :any
  Rajasthan_TotalAIDone :any
  State_Sikkim :any
  Sikkim_TotalAIDone :any
  State_TamilNadu :any
  TamilNadu_TotalAIDone :any
  State_Telangana :any
  Telangana_TotalAIDone :any
  State_Tripura :any
  Tripura_TotalAIDone :any
  State_Uttarakhand :any
  Uttarakhand_TotalAIDone :any
  State_UttarPradesh :any
  UttarPradesh_TotalAIDone :any
  State_WestBengal :any
  WestBengal_TotalAIDone :any
  State_Andaman :any
  Andaman_TotalAIDone :any
  State_Chandigarh :any
  Chandigarh_TotalAIDone :any
  State_Dadra :any
  Dadra_TotalAIDone :any
  State_Delhi :any
  Delhi_TotalAIDone :any
  State_Jammu :any
  Jammu_TotalAIDone :any
  State_Ladakh :any
  Ladakh_TotalAIDone :any
  State_Lakshadweep :any
  Lakshadweep_TotalAIDone :any
  State_Puducherry :any
  Puducherry_TotalAIDone :any


  
  constructor(public serv: RgmschemeService) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 2,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'},
    {id: '3'}
  ];
  ngOnInit(): void {
    this.RgmDashboardData();
    this.MaitriDashboardData();
    this.showEnrolementGraph();
   // this.showchartonpageload();
  // const event = new CustomEvent('build', { detail: ChartSelectEvent });
  }


  RgmDashboardData()
  {
    this.serv.getNaipData().subscribe((res)=>{
      this.NaipAllData=res;
 console.log(this.NaipAllData)

 this.stateLength=this.NaipAllData.length
	
 this.lastDateFromArray = this.NaipAllData[this.NaipAllData.length - 1];
console.log("last date--------------")
this.dateres = this.lastDateFromArray['created_at'].split("T");
console.log( this.dateres[0])
this.dateresone=this.dateres[0].split("-")
console.log( this.dateresone)
this.year=this.dateresone[0]
this.month=this.dateresone[1]
this.day=this.dateresone[2]
this.noday=Number(this.day)
this.noday+=1
console.log(this.noday)
this.curentDate=this.noday+'/'+this.month+'/'+this.year;
 var groupByScheme = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalAllNaipSchemeData=groupByScheme(this.NaipAllData, 'Scheme')
var totalAllNaipSchemeDataCount = Object.entries(totalAllNaipSchemeData)

console.log("total  naip  data----------")
console.log(totalAllNaipSchemeDataCount)
/////////////////////////////////////Group By On Date////////////////////////////////////
 var groupByDataDate = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalCurrentDateData=groupByDataDate(this.NaipAllData, 'DataDate')
var totalCurrentDateDataCount = Object.entries(totalCurrentDateData)
console.log("current date data")
console.log(totalCurrentDateDataCount)
this.currentDateData=[];
//this.currentDateData.push(totalCurrentDateDataCount[totalCurrentDateDataCount.length-1]['1']);
totalCurrentDateDataCount.forEach((y:any) =>
{
if(y[0]=='01/08/2021')
{
  this.currentNaipIIData=y[1];
}
if(y[0]=='01/06/2020')
{
  this.currentNaipIData=y[1];
}
if(y[0]=='02/11/2022')
{
  this.currentDateData=y[1];
}
});
console.log("curentdate")
console.log(this.currentDateData)

 /////////////////////////////////////Group By On  NAIPIII DATA Scheme////////////////////////////////////
 var groupByScheme = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalSchemeData=groupByScheme(this.currentDateData, 'Scheme')
var totalSchemeDataCount = Object.entries(totalSchemeData)
console.log("groupb y total schemess")
console.log(totalSchemeDataCount)
this.totalAllSchemeDoneArray=[];
this.clavesBornArray=[];
this.farmersBenifitedCountArray=[];
this.SchemeCountArray=[];
totalSchemeDataCount.forEach((y:any) =>
{
var  sumallAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
var  sumallAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
var  sumallClavesBornDone = y['1'].map((item:any) => Number(item['Total_calves_born'])).reduce((prev:any, curr:any) => prev + curr, 0);
var  sumallFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
this.SchemeCountArray.push(sumallAiDone)
this.farmersBenifitedCountArray.push(sumallFarmersBenifitted)
this.clavesBornArray.push(sumallClavesBornDone)
var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(sumallAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(sumallAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(sumallFarmersBenifitted)});
this.totalAllSchemeDoneArray.push(allSchemeData);
});
console.log("all scheme current data")
console.log(this.clavesBornArray);
console.log(this.totalAllSchemeDoneArray)
this.totalNoOfAiDoneCount = this.SchemeCountArray.reduce(function(a:any,b:any) {
  return (+a)+(+b);
});
this.totalNoOffarmersBenifited = this.farmersBenifitedCountArray.reduce(function(a:any,b:any) {
  return (+a)+(+b);
});

this.totalNoOfClavisBornDone=this.numDifferentiation(this.clavesBornArray);

this.totalNoOfAiAllSchemeDone=this.numDifferentiation(this.totalNoOfAiDoneCount);
this.totalFarmersBenifitedAllSchemeDone=this.numDifferentiation(this.totalNoOfAiDoneCount);
console.log("total all schemeeeeeeeee")
console.log(this.totalNoOfClavisBornDone)
console.log(this.totalAllSchemeDoneArray)

////////////    changes by Abhishek on 14-12-2022
// this.totalNoOfAiDoneInNaipIII=  this.totalAllSchemeDoneArray[0]['totalNoOfAiDone'];
// this.totalNoOfAnimalCoverdDoneInNaipIII =  this.totalAllSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
// this.totalNoOfFarmersBenefittedInNaipIII=this.totalAllSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];


 this.totalNoOfAiDoneInNaipIII=  this.totalAllSchemeDoneArray[1]['totalNoOfAiDone'];
 this.totalNoOfAnimalCoverdDoneInNaipIII =  this.totalAllSchemeDoneArray[1]['totalNoOfAnimalCoverd'];
 this.totalNoOfFarmersBenefittedInNaipIII=this.totalAllSchemeDoneArray[1]['totalNoOfFarmersBenefitted'];


/////////////////////////////////////Group By On  NAIPII DATA Scheme////////////////////////////////////


//this.SchemeNaipIICountArray=[];
console.log('NAIPII DATTTTTTTTTTTTTTTTTTTTT')
console.log(this.currentNaipIIData)
var groupByScheme = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalSchemeNaipIIData=groupByScheme(this.currentNaipIIData, 'Scheme')
var totalNaipSchemeDataCount = Object.entries(totalSchemeNaipIIData)
this.totalAllNaipIISchemeDoneArray=[];
totalNaipSchemeDataCount.forEach((y:any) =>
{
this.sumallNaipIIAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.sumallNaipIIAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.sumallNaipIIFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);

var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIIAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIIAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIIFarmersBenifitted)});
this.totalAllNaipIISchemeDoneArray.push(allSchemeData);
});

this.totalNoOfAiDoneInNaipII=  this.totalAllNaipIISchemeDoneArray[0]['totalNoOfAiDone'];
this.totalNoOfAnimalCoverdDoneInNaipII =  this.totalAllNaipIISchemeDoneArray[0]['totalNoOfAnimalCoverd'];
this.totalNoOfFarmersBenefittedInNaipII=this.totalAllNaipIISchemeDoneArray[0]['totalNoOfFarmersBenefitted'];



/////////////////////////////////////Group By On  NAIPI DATA Scheme////////////////////////////////////


//this.SchemeNaipIICountArray=[];
console.log('NAIPII DATTTTTTTTTTTTTTTTTTTTT')
console.log(this.currentNaipIData)
var groupByScheme = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var totalSchemeNaipIData=groupByScheme(this.currentNaipIData, 'Scheme')
var totalNaipISchemeDataCount = Object.entries(totalSchemeNaipIData)
this.totalAllNaipISchemeDoneArray=[];
totalNaipISchemeDataCount.forEach((y:any) =>
{
this.sumallNaipIAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.sumallNaipIAnimalsCoverd = y['1'].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.sumallNaipIFarmersBenifitted = y['1'].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);

var allSchemeData = Object.assign({"schemeName": y[0]}, {"totalNoOfAiDone": this.numDifferentiation(this.sumallNaipIAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(this.sumallNaipIAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(this.sumallNaipIFarmersBenifitted)});
this.totalAllNaipISchemeDoneArray.push(allSchemeData);
});

this.totalNoOfAiDoneInNaipI=  this.totalAllNaipISchemeDoneArray[0]['totalNoOfAiDone'];
this.totalNoOfAnimalCoverdDoneInNaipI =  this.totalAllNaipISchemeDoneArray[0]['totalNoOfAnimalCoverd'];
this.totalNoOfFarmersBenefittedInNaipI=this.totalAllNaipISchemeDoneArray[0]['totalNoOfFarmersBenefitted'];


this.totalNaipIAndNaipIIAiDone=this.numDifferentiation(this.sumallNaipIIAiDone+this.sumallNaipIAiDone);
this.totalNaipIAndNaipIIAnimalsCoverd=this.numDifferentiation(this.sumallNaipIIAnimalsCoverd+this.sumallNaipIAnimalsCoverd);
this.totalNaipIAndNaipIIFarmersBenifited=this.numDifferentiation(this.sumallNaipIIFarmersBenifitted+this.sumallNaipIFarmersBenifitted);


this.totalAllNaipIVSchemeDoneArray=[];
totalAllNaipSchemeDataCount.forEach((y:any) =>
{
if(y[0]=="NAIP III")
{
  console.log('fmd data')
  console.log(y[1])
 this.schemeTypeNaipIIIData=y[1];

 var groupByDateWiseFMDData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalDateWiseFMDData=groupByDateWiseFMDData(this.schemeTypeNaipIIIData, 'DataDate')
this.totalDateWiseNaipIIIataCount = Object.entries(totalDateWiseFMDData)
console.log("date Wise Data ")
console.log(this.totalDateWiseNaipIIIataCount)
this.totalDateWiseNaipIIIataCount.forEach((y:any) =>
{
const sumallTotalAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
var allNaipIIIData = Object.assign({"date": y[0]}, {"totalNoOfNaipIIIAiDone": sumallTotalAiDone});
this.totalDateWiseNaipIIIAiDoneArray.push(allNaipIIIData)
});
console.log("NaipIII data datewise")

console.log(this.totalDateWiseNaipIIIAiDoneArray)
this.naipIIIAiDate=[];
this.totalNoOfNaipIIIAiDoneArray=[];
this.totalDateWiseNaipIIIAiDoneArray.forEach((y:any) =>
  {
this.naipIIIAiDate.push(y['date']);
this.totalNoOfNaipIIIAiDoneArray.push(y['totalNoOfNaipIIIAiDone']);
  });
  // this.lastDateFromArray = this.naipIIIAiDate.pop();
 
}
console.log("NaipIII Data")
console.log(this.naipIIIAiDate)
console.log(this.totalNoOfNaipIIIAiDoneArray)




if(y[0]=="NAIP")
{
 this.naipITypeAiData=y[1];
 this.totalDateWiseNaipIAiDataCount=[];
 this.totalDateWiseNaipIAiDoneArray=[];
 var groupByDateWiseDateWiseData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalDateWiseNaipIAiData=groupByDateWiseDateWiseData(this.naipITypeAiData, 'DataDate')
this.totalDateWiseNaipIAiDataCount = Object.entries(totalDateWiseNaipIAiData)
console.log("----naip i  naip i naip i  naip i  nail i  nailp i-----")
console.log(this.totalDateWiseNaipIAiDataCount)
this.totalDateWiseNaipIAiDataCount.forEach((y:any) =>
{
 
const sumallNaipIDateWiseVaccination = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);


var vaccinationData = Object.assign({"date": y[0]}, {"totalNoOfNaipIIAiDone": sumallNaipIDateWiseVaccination});
this.totalDateWiseNaipIAiDoneArray.push(vaccinationData)
});


console.log(this.totalDateWiseNaipIAiDoneArray)
this.NaipIAiDate=[];
this.totalNoOfNaipIAiDoneArray=[];
this.totalDateWiseNaipIAiDoneArray.forEach((y:any) =>
  {
this.NaipIAiDate.push(y['date']);
this.totalNoOfNaipIAiDoneArray.push(y['totalNoOfNaipIIAiDone']);
  });


console.log("---naip  I  data---")
console.log(this.NaipIAiDate)
console.log(this.totalNoOfNaipIAiDoneArray)




}











if(y[0]=="NAIP II")
{
  console.log(y[1])
 this.naipIITypeAiData=y[1];

 var groupByDateWiseBrucellosisData = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalDateWiseAiData=groupByDateWiseBrucellosisData(this.naipIITypeAiData, 'DataDate')
this.totalDateWiseNaipIIAiDataCount = Object.entries(totalDateWiseAiData)
this.totalDateWiseNaipIIAiDoneArray=[];
this.totalDateWiseNaipIIAiDataCount.forEach((y:any) =>
{
const sumallDateWiseVaccination = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
console.log(sumallDateWiseVaccination)

var vaccinationData = Object.assign({"date": y[0]}, {"totalNoOfNaipIIAiDone": sumallDateWiseVaccination});
this.totalDateWiseNaipIIAiDoneArray.push(vaccinationData)
});
this.NaipIIAiDate=[];
this.totalNoOfNaipIIAiDoneArray=[];
this.totalDateWiseNaipIIAiDoneArray.forEach((y:any) =>
  {
this.NaipIIAiDate.push(y['date']);
this.totalNoOfNaipIIAiDoneArray.push(y['totalNoOfNaipIIAiDone']);
  });
}
if(y[0]=="NAIP IV")
{
  var groupByNaipIVDataDate = function(xs:any, key:any) {
    return xs.reduce(function(rv:any, x:any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  
  var totalNaipIVCurrentDateData=groupByNaipIVDataDate(y['1'], 'DataDate')
  var totalNaipIVCurrentDateDataCount = Object.entries(totalNaipIVCurrentDateData)
  console.log("current Naip IV date data")
  console.log(totalNaipIVCurrentDateDataCount)
  this.currentNaipIVDateData=[];
  this.currentNaipIVDateData.push(totalNaipIVCurrentDateDataCount[totalNaipIVCurrentDateDataCount.length-1]['1']);

  console.log("currentdata-------------------")
  console.log( this.currentNaipIVDateData)
  var  sumallNaipIVAiDone =this.currentNaipIVDateData[0].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var  sumallNaipIVAnimalsCoverd = this.currentNaipIVDateData[0].map((item:any) => Number(item['No_of_Animal_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var  sumallNaipIVFarmersBenifitted = this.currentNaipIVDateData[0].map((item:any) => Number(item["Total_no_of_farmers_benefitted"])).reduce((prev:any, curr:any) => prev + curr, 0);
  
  var allSchemeData = Object.assign({"schemeName": y[0]},{"totalNoOfAiDoneWithoutNumdifference": sumallNaipIVAiDone}, {"totalNoOfAiDone": this.numDifferentiation(sumallNaipIVAiDone)},{"totalNoOfAnimalCoverd": this.numDifferentiation(sumallNaipIVAnimalsCoverd)}, {"totalNoOfFarmersBenefitted": this.numDifferentiation(sumallNaipIVFarmersBenifitted)});
  this.totalAllNaipIVSchemeDoneArray.push(allSchemeData);
 
  this.totalNoOfAiDoneInNaipIVWithoutNumDif = this.totalAllNaipIVSchemeDoneArray[0]['totalNoOfAiDoneWithoutNumdifference'];
  this.totalNoOfAiDoneInNaipIV=  this.totalAllNaipIVSchemeDoneArray[0]['totalNoOfAiDone'];
  this.totalNoOfAnimalCoverdDoneInNaipIV =  this.totalAllNaipIVSchemeDoneArray[0]['totalNoOfAnimalCoverd'];
  this.totalNoOfFarmersBenefittedInNaipIV=this.totalAllNaipIVSchemeDoneArray[0]['totalNoOfFarmersBenefitted'];


  this.totalSateWiseTypeNaipIVDataCount=[];
  var groupByNaipIVTypeState = function(xs:any, key:any) {
    return xs.reduce(function(rv:any, x:any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  var totalStateData=groupByNaipIVTypeState(this.currentNaipIVDateData[0], 'State_Name')
  this.totalSateWiseTypeNaipIVDataCount = Object.entries(totalStateData)
console.log("NAIP IV DAtaaaaaaaaa-")
console.log(this.totalSateWiseTypeNaipIVDataCount)
this.topFiveNaipIVStateArray=[];
  this.totalSateWiseTypeNaipIVDataCount.forEach((y:any) =>
{
const sumallStateNaip = y[1].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
var finalObj1 = Object.assign({"State": y[0]}, {"totalNoOfNaipStateWise": sumallStateNaip});
this.topFiveNaipIVStateArray.push(finalObj1)
});
  var topValues = this.topFiveNaipIVStateArray.sort((a:any,b:any) => b['totalNoOfNaipStateWise']-a['totalNoOfNaipStateWise']).slice(0,5);
  console.log("top states")
  console.log(this.topFiveNaipIVStateArray)
  
  topValues.forEach((y:any) =>
  {
  //this.numDifferentiation(y['VacinationDone']);
  var finalObj1 = Object.assign({"State": y['State']}, {"totalNoOfNaipStateWise": this.numDifferentiation(y['totalNoOfNaipStateWise'])});
  this.topFiveNaipStateArray.push(finalObj1);
  });
  console.log("top five vacination state")
  console.log(this.topFiveNaipStateArray)
  this.topFirstPerfomanceState=this.topFiveNaipStateArray[0]['State'];
  this.topFirstPerformingNaipDone=this.topFiveNaipStateArray[0]['totalNoOfNaipStateWise'];
  
  this.topSecondPerfomanceState=this.topFiveNaipStateArray[1]['State'];
  this.topSecondPerformingNaipDone=this.topFiveNaipStateArray[1]['totalNoOfNaipStateWise'];
  
  this.topThirdPerfomanceState=this.topFiveNaipStateArray[2]['State'];
  this.topThirdPerformingNaipDone=this.topFiveNaipStateArray[2]['totalNoOfNaipStateWise'];
  
  this.topFourthPerfomanceState=this.topFiveNaipStateArray[3]['State'];
  this.topFourthPerformingNaipDone=this.topFiveNaipStateArray[3]['totalNoOfNaipStateWise'];
  
  this.topFifthPerfomanceState=this.topFiveNaipStateArray[4]['State'];
  this.topFifthPerformingNaipDone=this.topFiveNaipStateArray[4]['totalNoOfNaipStateWise'];





this.totalDateWiseNaipIVDataCount=[];
this.totalDateWiseNaipIVAiDoneArray=[];
  var groupByDateWiseNaipIVData = function(xs:any, key:any) {
    return xs.reduce(function(rv:any, x:any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  var totalDateWiseFMDData=groupByDateWiseNaipIVData(y['1'], 'DataDate')
  this.totalDateWiseNaipIVDataCount = Object.entries(totalDateWiseFMDData)
  console.log("date Wise Data ")
  console.log(this.totalDateWiseNaipIVDataCount)
  this.totalDateWiseNaipIVDataCount = this.totalDateWiseNaipIVDataCount.slice(1);
  this.totalDateWiseNaipIVDataCount.forEach((y:any) =>
  {
  const sumallTotalAiDone = y['1'].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
  var allNaipIIIData = Object.assign({"date": y[0]}, {"totalNoOfNaipIVAiDone": sumallTotalAiDone});
  this.totalDateWiseNaipIVAiDoneArray.push(allNaipIIIData)
  });
  console.log("NaipIV data datewise")
  
  console.log(this.totalDateWiseNaipIVAiDoneArray)
  this.naipIVAiDate=[];
  this.totalNoOfNaipIVAiDoneArray=[];
  this.totalDateWiseNaipIVAiDoneArray.forEach((y:any) =>
    {
  this.naipIVAiDate.push(y['date']);
  this.totalNoOfNaipIVAiDoneArray.push(y['totalNoOfNaipIVAiDone']);
    });

console.log(this.naipIVAiDate)

this.showEnrolementGraph();

}
});
console.log("naipIV dataaaaaaaa")
console.log(this.totalNoOfAiDoneInNaipIV)
console.log( this.totalNoOfAnimalCoverdDoneInNaipIV)
console.log(this.totalNoOfFarmersBenefittedInNaipIV)
//////////////////////////////////////Group By State Data/////////////////////////////////////////




for(let state of this.topFiveNaipIVStateArray){
 
  let temp = [state.State,state.State,Number(state.totalNoOfNaipStateWise)];
  this.State_Andaman = "ANDAMAN AND NICOBAR ISLANDS";
  this.Andaman_TotalAIDone = 0;    
  this.State_Delhi = "DELHI";
  this.Delhi_TotalAIDone = 0;
  this.State_Punjab = "PUNJAB";
  this.Punjab_TotalAIDone = 0; 
  this.State_Kerala = "KERALA";
  this.Kerala_TotalAIDone = 0;
  this.State_Lakshadweep = "LAKSHADWEEP";
  this.Lakshadweep_TotalAIDone = 0;
  if( state.State=="UTTAR PRADESH"){    
    this.State_UttarPradesh = state.State;
    this.UttarPradesh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="RAJASTHAN"){    
    this.State_Rajasthan = state.State;
    this.Rajasthan_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  } 
  else if( state.State=="BIHAR"){    
    this.State_Bihar = state.State;
    this.Bihar_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  } 
  else if( state.State=="HIMACHAL PRADESH"){    
    this.State_HimachalPradesh = state.State;
    this.HimachalPradesh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="CHANDIGARH"){    
    this.State_Chandigarh = state.State;
    this.Chandigarh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="JAMMU AND KASHMIR"){    
    this.State_Jammu = state.State;
    this.Jammu_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="HARYANA"){    
    this.State_Haryana = state.State;
    this.Haryana_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="UTTARAKHAND"){    
    this.State_Uttarakhand = state.State;
    this.Uttarakhand_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }  
  else if( state.State=="MAHARASHTRA"){    
    this.State_Maharashtra = state.State;
    this.Maharashtra_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="ANDHRA PRADESH"){    
    this.State_AndhraPradesh = state.State;
    this.AndhraPradesh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="GUJARAT"){    
    this.State_Gujarat = state.State;
    this.Gujarat_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="MADHYA PRADESH"){    
    this.State_MadhyaPradesh = state.State;
    this.MadhyaPradesh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU"){    
    this.State_Dadra = state.State;
    this.Dadra_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="TAMIL NADU"){    
    this.State_TamilNadu = state.State;
    this.TamilNadu_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="PUDUCHERRY"){    
    this.State_Puducherry = state.State;
    this.Puducherry_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="TELANGANA"){    
    this.State_Telangana = state.State;
    this.Telangana_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="LADAKH"){    
    this.State_Ladakh = state.State;
    this.Ladakh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="KARNATAKA"){    
    this.State_Karnataka = state.State;
    this.Karnataka_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  } 
  else if( state.State=="GOA"){    
    this.State_Goa = state.State;
    this.Goa_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="MEGHALAYA"){    
    this.State_Meghalaya = state.State;
    this.Meghalaya_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="ASSAM"){    
    this.State_Assam = state.State;
    this.Assam_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="TRIPURA"){    
    this.State_Tripura = state.State;
    this.Tripura_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="WEST BENGAL"){    
    this.State_WestBengal = state.State;
    this.WestBengal_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="ARUNACHAL PRADESH"){    
    this.State_ArunachalPradesh = state.State;
    this.ArunachalPradesh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="SIKKIM"){    
    this.State_Sikkim = state.State;
    this.Sikkim_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="MANIPUR"){    
    this.State_Manipur = state.State;
    this.Manipur_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="MIZORAM"){    
    this.State_Mizoram = state.State;
    this.Mizoram_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="NAGALAND"){    
    this.State_Nagaland = state.State;
    this.Nagaland_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="CHHATTISGARH"){    
    this.State_Chhattisgarh = state.State;
    this.Chhattisgarh_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="JHARKHAND"){    
    this.State_Jharkhand = state.State;
    this.Jharkhand_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }
  else if( state.State=="ODISHA"){    
    this.State_Odisha = state.State;
    this.Odisha_TotalAIDone = Number(state.totalNoOfNaipStateWise);
  }

// else  if( state.State=="ANDAMAN AND NICOBAR ISLANDS"){
//   temp = ['IN-AN','ANDAMAN AND NICOBAR ISLANDS',Number(state.totalNoOfNaipStateWise)];
//   this.State_Andaman = state.State;
//   this.Andaman_TotalAIDone = Number(state.totalNoOfNaipStateWise);
// }
// else if( state.State=="DELHI"){    
//   this.State_Delhi = state.State;
//   this.Delhi_TotalAIDone = Number(state.totalNoOfNaipStateWise);
// }
// else if( state.State=="PUNJAB"){    
//   this.State_Punjab = state.State;
//   this.Punjab_TotalAIDone = Number(state.totalNoOfNaipStateWise);
// }
//   else if( state.State=="KERALA"){    
//     this.State_Kerala = state.State;
//     this.Kerala_TotalAIDone = Number(state.totalNoOfNaipStateWise);
//   }
//   else if( state.State=="LAKSHADWEEP"){    
//     this.State_Lakshadweep = state.State;
//     this.Lakshadweep_TotalAIDone = Number(state.totalNoOfNaipStateWise);
//   }





 // this.states_data.push(delhitemp);
  this.states_data.push(temp);
}
console.log("states data------------")
console.log(this.states_data)
 this.mapReady=true
  

 this.showchartonpageload();
    },
    (err)=>{
      console.log(err)
    }
  ); 
  
  }
  
  
 
    MaitriDashboardData()
  {
    this.MaitriAllData=[];
    this.serv.getMaitriData().subscribe((res)=>{
      this.MaitriAllData=res;
    console.log("Maitri data--------------")
    this.sumallIVF = this.MaitriAllData.map((item:any) => Number(item['IVF'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.sumallMAITRIsTrainedInducted = this.MaitriAllData.map((item:any) => Number(item['MAITRIs_Trained_Inducted'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.Breed_Multiplication_Farms_Sanctioned =this.MaitriAllData.map((item:any) => Number(item['Breed_Multiplication_Farms_Sanctioned'])).reduce((prev:any, curr:any) => prev + curr, 0);
    // this.allSexSortedSemenDosesProduced = this.MaitriAllData.map((item:any) => Number(item['Sex_Sorted_Semen_Doses_Produced'])).reduce((prev:any, curr:any) => prev + curr, 0);

    // this.allSexSortedSemenDosesSold = this.MaitriAllData.map((item:any) => Number(item['Sex_Sorted_Semen_Doses_Sold'])).reduce((prev:any, curr:any) => prev + curr, 0);

    // this.allSexSortedSemenDosesAIDone = this.MaitriAllData.map((item:any) => Number(item['Sex_Sorted_Semen_Doses_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allSexSortedSemenDosesProduced = this.MaitriAllData.map((item:any) => Number(item['Sex_Sorted_Semen_Doses_Produced'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.allSexSortedSemenDosesProduced=this.numDifferentiation(this.allSexSortedSemenDosesProduced);
    this.allSexSortedSemenDosesSold = this.MaitriAllData.map((item:any) => Number(item['Sex_Sorted_Semen_Doses_Sold'])).reduce((prev:any, curr:any) => prev + curr, 0);    
    this.allSexSortedSemenDosesSold=this.numDifferentiation(this.allSexSortedSemenDosesSold);
    this.allSexSortedSemenDosesAIDone = this.MaitriAllData.map((item:any) => Number(item['Sex_Sorted_Semen_Doses_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);    
    this.allSexSortedSemenDosesAIDone=this.numDifferentiation(this.allSexSortedSemenDosesAIDone);



    console.log(this.MaitriAllData)
    },
    (err)=>{
      console.log(err)
    }
  );
  }
  
  
  
 
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }
  chartButtonClick()
  {
    console.log("hello")
    this.showChartNaipIchart=false;
    this.showChartNaipIIchart=true;
    this.showChartNaipIVchart=false
    this.showChartNaipIIIchart=false
    this.myCSSclass2=true
    this.myCSSclass=false
    this.myCSSclass1=false
    this.myCSSclass3=false
    this.showNaipIIEnrolementChartNaipII=
    {
          title: {
                 show: false,
                 left: 'center',
                 text: 'COURSE  ENROLMENT STATICS GRAPH  ',
               },
          tooltip: {
                 trigger: 'axis'
               },
               grid: { containLabel: true },
              axisLabel: {
                interval:0,
                rotate: 65,
            },
            
          xAxis: {
            type: 'category',
          
            data: this.NaipIIAiDate,
           
          },
          yAxis: {
            type: 'value',
            axisLine:{                 //Coordinate axis
              show:false,             //Show Axis axis or not
             // onZero:false,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
          },
		   splitLine: {

            show: false

         },
          },
          height: 140,
          series: [
            {
              data:this.totalNoOfNaipIIAiDoneArray,
              type: 'bar',
              color:'#8dd9cc'
    
            //  color: '#8E24AA'
            }
          ]
        };
  }

  


  showEnrolementGraphNaipI()
  {
    this.showChartNaipIchart=true
    this.showChartNaipIVchart=false
    this.showChartNaipIIchart=false
    this.showChartNaipIIIchart=false
    this.myCSSclass3=true
    this.myCSSclass=false
    this.myCSSclass1=false
    this.myCSSclass2=false
      this.yearWiseEnroleMentChartNaipI =  {
        title: {
               show: false,
               left: 'center',
               text: 'COURSE  ENROLMENT STATICS GRAPH  ',
             },
        tooltip: {
               trigger: 'axis'
             },
      
            axisLabel: {
              interval:0,
              rotate: 65,
          },
        xAxis: {
          type: 'category',
          data:this.NaipIAiDate
        },
        yAxis: {
          type: 'value',
          axisLine:{                 //Coordinate axis
            show:true,             //Show Axis axis or not
            onZero:true,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
        },
		 splitLine: {

            show: false

         },
        },
        height: 110,
        series: [
          {
            data: this.totalNoOfNaipIAiDoneArray,
            type: 'bar',
            color:'#8dd9cc'
  
          //  color: '#8E24AA'
          }
        ]
      };
  
    }






  showEnrolementGraph()
  {
    console.log("garaph  dataa------------------")
    console.log(this.naipIVAiDate)
    this.showChartNaipIVchart=true
    this.showChartNaipIchart=false
    this.showChartNaipIIchart=false
    this.showChartNaipIIIchart=false
    this.myCSSclass=true
    this.myCSSclass3=false
    this.myCSSclass1=false
    this.myCSSclass2=false
      this.yearWiseEnroleMentChartNaipIV =  {
        title: {
               show: false,
               left: 'center',
               text: 'COURSE  ENROLMENT STATICS GRAPH  ',
             },
        tooltip: {
               trigger: 'axis'
             },
      
            axisLabel: {
              interval:0,
              rotate: 65,
          },
        xAxis: {
          type: 'category',
          data:this.naipIVAiDate
        },
        yAxis: {
          type: 'value',
          axisLine:{                 //Coordinate axis
            show:true,             //Show Axis axis or not
            onZero:true,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
        },
		 splitLine: {

            show: false

         },
        },
        height: 110,
        series: [
          {
            data: this.totalNoOfNaipIVAiDoneArray,
            type: 'bar',
            color:'#8dd9cc'
  
          //  color: '#8E24AA'
          }
        ]
      };
  
    }

    showEnrolementGraphNaipIII()
    {
      console.log("garaph  dataa------------------")
      console.log(this.naipIIIAiDate)
      this.showChartNaipIVchart=false
      this.showChartNaipIchart=false
      this.showChartNaipIIchart=false
      this.showChartNaipIIIchart=true
      this.myCSSclass=false
      this.myCSSclass3=false
      this.myCSSclass1=true
      this.myCSSclass2=false
        this.yearWiseEnroleMentChartNaipIII =  {
          title: {
                 show: false,
                 left: 'center',
                 text: 'COURSE  ENROLMENT STATICS GRAPH  ',
               },
          tooltip: {
                 trigger: 'axis'
               },
        
              axisLabel: {
                interval:0,
                rotate: 65,
            },
          xAxis: {
            type: 'category',
            data:this.naipIIIAiDate
          },
          yAxis: {
            type: 'value',
            axisLine:{                 //Coordinate axis
              show:true,             //Show Axis axis or not
              onZero:true,           //Whether the axis of X-axis or Y-axis is on the 0 scale of another axis is valid only when the other axis is a numerical axis and contains the 0 scale
          },
       splitLine: {
  
              show: false
  
           },
          },
          height: 110,
          series: [
            {
              data: this.totalNoOfNaipIIIAiDoneArray,
              type: 'bar',
              color:'#8dd9cc'
    
            //  color: '#8E24AA'
            }
          ]
        };
    
      }
  public select(event: any) {
  this.showPieChartpageload = false;
  this.showPieChartMapclick= true;
    console.log(event)
    console.log(event)
    console.log(this.totalSateWiseTypeNaipIVDataCount)

    this.totalSateWiseTypeNaipIVDataCount.forEach((y:any) =>
    {
   if(y[0]==event)
   {
    console.log(y[0])
     this.selectState=y[1]
   }
   
    });
    // if(event.selectedRowValues[0]=='KERALA')
// {
  // this.selectState='';
// }
// if(event.selectedRowValues[0]=='PUNJAB')
// {
  // this.selectState='';
// }
// if(event.selectedRowValues[0]=='DELHI')
// {
  // this.selectState='';
// }

    var groupByTypeVacination = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalTypeVacinationStateData=null;
   
  
      totalTypeVacinationStateData=groupByTypeVacination(this.selectState, 'Scheme')
      
//var totalTypeVacinationStateData=groupByTypeVacination(this.selectState, 'Scheme')
var totalTypeVacinationStateDataCount = Object.entries(totalTypeVacinationStateData)
console.log(totalTypeVacinationStateDataCount)
this.totalAggregateStateNaipData=[];
var StateName = null;
if(event.message != "deselect")
{
totalTypeVacinationStateDataCount.forEach((y:any) =>
{
  var finalObj = Object.assign({"name": "Total AI Done"}, {"value": this.totalNoOfAiDoneInNaipIVWithoutNumDif}); 
  this.totalAggregateStateNaipData.push(finalObj);

    var totalVaccination =  y[1].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);
    finalObj = Object.assign({"name": y[0]}, {"value": totalVaccination});
    this.totalAggregateStateNaipData.push(finalObj);
    StateName = event;
    
});

}
else
{
  var finalObj = Object.assign({"name": "NAIP IV"}, {"value": this.totalNoOfAiDoneInNaipIVWithoutNumDif}); 
  this.totalAggregateStateNaipData.push(finalObj);
  StateName = "Total AI Done";
        // var today = new Date();    
        // var pipe = new DatePipe('en-US');      
        // let ChangedFormat = pipe.transform(today, 'dd/MM/YYYY');       
}
console.log("Abhi")
console.log(this.totalAggregateStateNaipData)


    this.typeOfVacinationChart = {
      title: {
        show:true,
        // text: event.selectedRowValues[0],
        text: StateName,
        textStyle: {fontSize: 10},
        left: 'center'
       
      },
      tooltip: {
        trigger: 'item'
      },
      // legend: {
      //   orient: 'vertical',
      //   left: 'right'
      // },
      series: [
        {
         // name: 'Access From',
         type: 'pie',
          radius: '44%',
         // radius: ['40%', '70%'],
          avoidLabelOverlap: false,
         
          labelLine: {
            show: true
        },
          label: {
            formatter: '{b|{b}：}{c}  ',
            backgroundColor: '#F6F8FC',
            borderColor: '#8C8D8E',
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              a: {
                color: '#796e70',
                lineHeight: 22,
                align: 'center'
              },
              hr: {
                borderColor: '#796e70',
                width: '80%',
                borderWidth: 1,
                height: 0
              },
              b: {
                color: '#796e70',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 33
              },
              per: {
                color: '#796e70',
                backgroundColor: 'black',
                padding: [3, 4],
                borderRadius: 4
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '15',
              fontWeight: 'bold'
            }
          },
         
          data: this.totalAggregateStateNaipData
        }
      ]
    };



} 
  public geoChart: GoogleChartInterface = {
    chartType: 'GeoChart',
    dataTable: this.states_data,
    options: {
      domain:'IN',
      region: 'IN',
      colorAxis: {colors: ['#67cdcd','#67cdcd']},
      resolution: 'provinces',
      zoom: 6,
     // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
  disableDefaultUI: true,
  //displayMode: 'text',
  defaultColor:'#40e0d0',
  backgroundColor: 'transparent',
 
legend: 'none',
datalessRegionColor: 'transparent',
      //'height': 600,
      'width': 850,
     

      
    }
  };



  public showchartonpageload() {
  this.showPieChartpageload = true;
  this.showPieChartMapclick= false;
this.totalAggregateStateNaipData=[];
var StateName = null;

  var finalObj = Object.assign({"name": "NAIP IV"}, {"value": this.totalNoOfAiDoneInNaipIVWithoutNumDif}); 
  this.totalAggregateStateNaipData.push(finalObj);
  StateName = "Total AI Done";
        // var today = new Date();    
        // var pipe = new DatePipe('en-US');      
        // let ChangedFormat = pipe.transform(today, 'dd/MM/YYYY');       
        console.log("Total AI Done")
console.log(this.totalAggregateStateNaipData)


    this.typeOfVacinationChart = {
      title: {
        show:true,
        // text: event.selectedRowValues[0],
        text: StateName,
        textStyle: {fontSize: 10},
        left: 'center'
       
      },
      tooltip: {
        trigger: 'item'
      },
      // legend: {
      //   orient: 'vertical',
      //   left: 'right'
      // },
      series: [
        {
         // name: 'Access From',
         type: 'pie',
          radius: '44%',
         // radius: ['40%', '70%'],
          avoidLabelOverlap: false,
         
          labelLine: {
            show: true
        },
          label: {
            formatter: '{b|{b}：}{c}  ',
            backgroundColor: '#F6F8FC',
            borderColor: '#8C8D8E',
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              a: {
                color: '#796e70',
                lineHeight: 22,
                align: 'center'
              },
              hr: {
                borderColor: '#796e70',
                width: '80%',
                borderWidth: 1,
                height: 0
              },
              b: {
                color: '#796e70',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 33
              },
              per: {
                color: '#796e70',
                backgroundColor: 'black',
                padding: [3, 4],
                borderRadius: 4
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '15',
              fontWeight: 'bold'
            }
          },
         
          data: this.totalAggregateStateNaipData
        }
      ]
    };



} 
    
}
