<div class="container-fluid"> 
    <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
          
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
           <div class="bg-img1 bg-img1-mobile"> </div>
            <div class="text-left text-left-mobile">
          <h3><b>Department of Animal Husbandry and Dairying</b></h3>   
          
          <a routerLink="/dashboard"><button type="button" class="btn btn-success btn-layout">Click Here</button></a>
       
        </div>  
          </div> 
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
            <div class="bg-img2 bg-img2-mobile"> </div>
             <div class="text-right text-right-mobile">
           <h3><b>Department of Fisheries</b></h3>   
           <!--<button type="button"class="btn btn-primary btn-layout" style="margin-top: 62px;">Click Here</button>-->
          <a href="https://pmmsymis.dof.gov.in/"><button type="button" class="btn btn-primary btn-layout" style="margin-top: 62px;">Click Here</button></a>
         </div>  
           </div> 
    </div> 
  </div>