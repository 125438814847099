import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Globals {
  intRegex = /((\d+)((\.\d{1,2})?))$/;
  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
  setDefault(value: string) {
    return value === '' ? "0" : value;
  }
  indianState = [
    { name: "ANDAMAN & NICOBAR", code: "35" },
    { name: "ANDHRA PRADESH", code: "28" },
    { name: "ARUNACHAL PRADESH", code: "12" },
    { name: "ASSAM", code: "18" },
    { name: "BIHAR", code: "10" },
    { name: "CHANDIGARH", code: "4" },
    { name: "CHHATTISGARH", code: "22" },
    { name: "DELHI", code: "7" },
    { name: "D&N HAVELI", code: "state" },
    { name: "GOA", code: "30" },
    { name: "GUJARAT", code: "24" },
    { name: "HARYANA", code: "6" },
    { name: "HIMACHAL PRADESH", code: "2" },
    { name: "JAMMU & KASHMIR", code: "1" },
    { name: "JHARKHAND", code: "20" },
    { name: "KARNATAKA", code: "29" },
    { name: "KERALA", code: "32" },
    { name: "LADAKH", code: "37" },
    { name: "LAKSHADWEEP", code: "32" },
    { name: "MADHYA PRADESH", code: "23" },
    { name: "MAHARASHTRA", code: "27" },
    { name: "MANIPUR", code: "14" },
    { name: "MEGHALAYA", code: "17" },
    { name: "MIZORAM", code: "15" },
    { name: "NAGALAND", code: "13" },
    { name: "ODISHA", code: "21" },
    { name: "PUDUCHERRY", code: "34" },
    { name: "PUNJAB", code: "3" },
    { name: "RAJASTHAN", code: "8" },
    { name: "SIKKIM", code: "11" },
    { name: "TAMIL NADU", code: "33" },
    { name: "TELANGANA", code: "36" },
    { name: "TRIPURA", code: "16" },
    { name: "UTTAR PRADESH", code: "9" },
    { name: "UTTARAKHAND", code: "5" },
    { name: "WEST BENGAL", code: "19" }

  ]


}