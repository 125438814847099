import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';
import { DidfschemeService } from '../didfscheme/didfscheme.service';


@Component({
  selector: 'app-statewise-didfscheme',
  templateUrl: './statewise-didfscheme.component.html',
  styleUrls: ['./statewise-didfscheme.component.scss']
})

export class StatewiseDidfschemeComponent implements OnInit {
  selectedCity1 :  any = new Array()
  states_data = [['State','Total AI Done']];
  mapReady=false;
  stateWiseDidfAllData: any = new Array()
  totalNpddSateWiseTypeDataCount: any = new Array()
  selectStateData: any = new Array()
  valueInFormate:any;

  totalNaipAiDoneStateWise: any = new Array()


  selectStateName: any;
  selectStateCode:any;
  allTotalApprovedOutlay: any;
  allTotalCentralShare: any;
  allTotalFundsReleased: any;
  allTotalFundsUtilised:any;
  allTotalAverageDailyMilkProcurementAch: any;
  allTotalBulkMilkCoolerAch: any;
  allTotalBulkMilkCoolerCapacity: any;
  allTotalAutomaticMilkCollectionAch: any;
  allTotalMilkAdulterationTestingMachinesAch: any;
  allTotalNoofprojectssanctioned: any;
  noofProjects: any;
  totalProjectOutlayApproved: any;
  totalLoanApproved: any;
  totalLoanDisbursed: any;
  milkProcessingCapacityInLLPD: any;
  valueaddedandProductCapacityInLLPD: any;
  dryingCapacityInMTPD: any;
  noofDairyPlantRefurbishedStrengthened: any;
  producerMemberCovered: any;
 
  constructor(public serv: DidfschemeService,private route: ActivatedRoute) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
   {id: '1'},
    {id: '2'}
  ];
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {  
      this.selectStateName=params['State'];
	   console.log("state name for state code")
      console.log(params['State'])
      const myArray = params['State'].split("(");
      this.selectStateName=myArray[0];
      const myArray1 = myArray[1].split(")");
      console.log(myArray1)
      this.selectStateCode=myArray1[0];

    });
  this.StateWiseNpddDashboardData();
  
    //console.log(this.route.snapshot.queryParams.name); // book
  
  }
  StateWiseNpddDashboardData()
  {
    this.stateWiseDidfAllData=[];
    this.serv.getDidfData().subscribe((res)=>{
      this.stateWiseDidfAllData=res;
      console.log("total didf dataaaaaaaa")
      console.log(this.stateWiseDidfAllData)
      this.totalNpddSateWiseTypeDataCount=[];
      var groupByTypeState = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalLhdcStateData=groupByTypeState(this.stateWiseDidfAllData, 'State_Code')
  this.totalNpddSateWiseTypeDataCount = Object.entries(totalLhdcStateData)

  this.selectStateData=[];
  this.totalNpddSateWiseTypeDataCount.forEach((y:any) =>
  {
  if(y[0]==this.selectStateCode)
  {
   this.selectStateData=y[1]
  }
});
console.log("selected  state  data-------------------")
console.log( this.selectStateData)
this.noofProjects=0
this.totalProjectOutlayApproved=0
this.totalLoanApproved=0
this.totalLoanDisbursed=0
this.milkProcessingCapacityInLLPD=0
this.valueaddedandProductCapacityInLLPD=0
this.dryingCapacityInMTPD=0
this.noofDairyPlantRefurbishedStrengthened=0
this.producerMemberCovered=0
this.noofProjects = this.selectStateData.map((item:any) => Number(item['No_of_Projects'])).reduce((prev:any, curr:any) => prev + curr, 0);
    


const alltotalProjectOutlayApproved = this.selectStateData.map((item:any) => Number(item['Total_Project_Outlay_Approved'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.totalProjectOutlayApproved =this.numDifferentiations(alltotalProjectOutlayApproved);
console.log("project outlay")
console.log(alltotalProjectOutlayApproved)

const alltotalLoanApproved = this.selectStateData.map((item:any) => Number(item['Total_Loan_Approved'])).reduce((prev:any, curr:any) => prev + curr, 0);

this.totalLoanApproved =this.numDifferentiations(alltotalLoanApproved);
console.log("Total_Loan_Approved")
console.log(alltotalLoanApproved)

const alltotalLoanDisbursed = this.selectStateData.map((item:any) => Number(item['Total_Loan_disbursed'])).reduce((prev:any, curr:any) => prev + curr, 0);


this.totalLoanDisbursed =this.numDifferentiations(alltotalLoanDisbursed);

console.log("Total_Loan_disbursed")
console.log(alltotalLoanDisbursed)

this.milkProcessingCapacityInLLPD = this.selectStateData.map((item:any) => Number(item['Milk_Processing_Capacity_In_LLPD'])).reduce((prev:any, curr:any) => prev + curr, 0);
//this.milkProcessingCapacityInLLPD =this.numDifferentiation(allmilkProcessingCapacityInLLPD);

console.log("Milk_Processing_Capacity_In_LLPD")
console.log(this.milkProcessingCapacityInLLPD)
this.valueaddedandProductCapacityInLLPD = this.selectStateData.map((item:any) => Number(item['Value_added_and_Product_Capacity_In_LLPD'])).reduce((prev:any, curr:any) => prev + curr, 0);

//this.valueaddedandProductCapacityInLLPD =this.numDifferentiation(allvalueaddedandProductCapacityInLLPD);


this.dryingCapacityInMTPD = this.selectStateData.map((item:any) => Number(item['Drying_Capacity_In_MTPD'])).reduce((prev:any, curr:any) => prev + curr, 0);

//this.dryingCapacityInMTPD =this.numDifferentiation(alldryingCapacityInMTPD);


this.noofDairyPlantRefurbishedStrengthened = this.selectStateData.map((item:any) => Number(item['No_of_Dairy_Plant_Refurbished_Strengthened'])).reduce((prev:any, curr:any) => prev + curr, 0);

//this.noofDairyPlantRefurbishedStrengthened =this.numDifferentiation(allnoofDairyPlantRefurbishedStrengthened);

const allproducerMemberCovered = this.selectStateData.map((item:any) => Number(item['Producer_Member_Covered'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.producerMemberCovered =this.numDifferentiation(allproducerMemberCovered);
     
	
	 
	  console.log("didf  data milk  procurementttttt------")
	  console.log(this.dryingCapacityInMTPD)
	   console.log(this.noofDairyPlantRefurbishedStrengthened)
	    
	 
      console.log("project senctioneddddddddd")
      console.log(this.allTotalNoofprojectssanctioned)
for(let state of this.totalNaipAiDoneStateWise){
  let temp = [state.statName,Number(state.totalNoOfAiDone)];
  if( state.statName=="ANDAMAN & NICOBAR ISLANDS"){
    temp = ['IN-AN',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="LAKSHADWEEP"){
    temp = ['IN-LD',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="ODISHA"){
    temp = ['IN-OR',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="LADAKH"){
    temp = ['Ladakh',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="UTTARANCHAL"){
    temp = ['IN-UT',Number(state.totalNoOfAiDone)];
  }
  else if( state.statName=="JAMMU & KASHMIR"){
    temp = ['Jammu and Kashmir',Number(state.totalNoOfAiDone)];
  }
  this.states_data.push(temp);
}
console.log("sdsdsdsds")
console.log(this.states_data)
   this.mapReady=true
  
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
 
 
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 100) {
      this.valueInFormate    = val ;
    }
    return this.valueInFormate;
  }
  numDifferentiations(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) ;
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) ;
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2);
    }else if (val >= 10) {
      this.valueInFormate    = val ;
    }
    else if (val >= 0) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' ';
    }
    return this.valueInFormate;
  }
public geoChart: GoogleChartInterface = {
  chartType: 'GeoChart',
  dataTable: this.states_data,
  options: {
    domain:'IN',
    region: 'IN',
    colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
    resolution: 'provinces',
    zoom: 6,
   // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
disableDefaultUI: true,
//displayMode: 'text',
defaultColor:'#40e0d0',
backgroundColor: 'transparent',
enableRegionInteractivity: true,
//tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
    'height': 3,
    //'width': 850,
   

    
  }
  
};


  // OnChangeDistrict(event:any)
  // {
 
  // }                     
  
}
