import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class StatewiseAhidfschemeService {

  constructor(public http: HttpClient) { }

  getAhidfDashboardData() {
  
    return this.http.get('https://dahddashboard.ndlm.co.in/api/ahidfdashboard');
     }
    getKpiData()
    {
      return this.http.get('https://dahddashboard.ndlm.co.in/api/ahidfkpi');
    }
}
