import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
/// TODO  : import DataService
import { DataService } from '../core/data.service';
import { Globals } from '../globals';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http';
/// TODO  : import UploadService
//import { UploadService } from '../upload.service';
export class CsvData {
  //public State_Code: any;
  public stateCode: any;
  public state: any;
  public nops: any;
  public iadmp: any;
  public bmci: any;
  public adi: any;
  public mat: any;
  public bmcc: any;
  public tao: any;
  public cs: any;
  public fr: any;
  public fu: any;
}
@Component({
  selector: 'app-npddentryform',
  templateUrl: './npddentryform.component.html',
  styleUrls: ['./npddentryform.component.scss']
})
export class NpddentryformComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  registerForm: any = FormGroup;
  npddDetailInJson: any = new Array()
  submitted = false;
  colsUsers: any;
  csvData: any;
  globals!: Globals;
  uploadListener($event: any): void {
    let files = $event.srcElement.files;
    if (this.globals.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        this.csvData = reader.result;
        let csvRecordsArray = (this.csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };
      reader.onerror = function () {
        console.log('error is occurred while reading file!');
      };
    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord = new CsvData();
        //csvRecord.State_Code = curruntRecord[0].trim();
        csvRecord.stateCode = curruntRecord[1].trim();
        csvRecord.state = curruntRecord[2].trim();
        csvRecord.nops = this.globals.setDefault(curruntRecord[3]).trim();
        csvRecord.tao = this.globals.setDefault(curruntRecord[4]).trim();
        csvRecord.cs = this.globals.setDefault(curruntRecord[5]).trim();
        csvRecord.fr = this.globals.setDefault(curruntRecord[6]).trim();
        csvRecord.fu = this.globals.setDefault(curruntRecord[7]).trim();
        csvRecord.iadmp = this.globals.setDefault(curruntRecord[8]).trim();
        csvRecord.bmci = this.globals.setDefault(curruntRecord[9]).trim();
        csvRecord.bmcc = this.globals.setDefault(curruntRecord[10]).trim();
        csvRecord.adi = this.globals.setDefault(curruntRecord[11]).trim();
        csvRecord.mat = this.globals.setDefault(curruntRecord[12]).trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }


  getHeaderArray(csvRecordsArr: any) {
    let headers = (csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  getJsonData() {
    this.npddDetailInJson = [];
    this.npddDetailInJson = this.records;
	console.log("csv  file  value---")
	console.log(this.npddDetailInJson)

  }
  constructor(private formBuilder: FormBuilder, globals: Globals, private router: Router, private dataService: DataService,private JsonToCSVService:JsonToCSVService,public http: HttpClient
  ) {
    this.globals = globals;
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //this.npddDetailInJson = [];
    //True if all the fields are filled
    if (this.submitted) {
     var state = this.globals.indianState.find(c => c.code ===this.registerForm.value.state );
  console.log("upload  data---")
console.log(state)
	if(state === undefined || state === null){
        console.log("your key or value exists!");
      }
      else {
        var finalObj = Object.assign({ "state": state.name }, { "stateCode": state.code }, { "nops": this.registerForm['value']['nops'] }, { "iadmp": this.registerForm['value']['iadmp'] }, { "bmci": this.registerForm['value']['bmci'] }, { "adi": this.registerForm['value']['adi'] }, { "mat": this.registerForm['value']['mat'] }, { "bmcc": this.registerForm['value']['bmcc'] }, { "tao": this.registerForm['value']['tao'] }, { "cs": this.registerForm['value']['cs'] }, { "fr": this.registerForm['value']['fr'] }, { "fu": this.registerForm['value']['fu'] });
        this.npddDetailInJson.push(finalObj);
      }
    }

    console.log("form submit value")
    console.log(this.npddDetailInJson)
  }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
     // stateCode: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      state: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      nops: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      iadmp: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      bmci: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      adi: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      mat: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      bmcc: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      tao: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      cs: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      fr: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
      fu: new FormControl('', [Validators.pattern(this.globals.intRegex), Validators.maxLength(256)]),
    });
    this.initializeColumns();
  }
  initializeColumns() {
    this.colsUsers = [
     // { field: 'stateCode', header: 'State Code' },
      { field: 'state', header: 'State Name' },
      { field: 'nops', header: 'Number of Projects Sanctioned' },
      { field: 'iadmp', header: 'Average Daily Milk Procurement' },
      { field: 'bmci', header: 'Bulk Milk Cooler Installed' },
      { field: 'adi', header: 'AMCU/DPMCU Installed' },
      { field: 'mat', header: 'Milk Adulteration Testing Machines Installed' },
      { field: 'bmcc', header: 'Bulk Milk Cooler Capacity' },
      { field: 'tao', header: 'Total Approved Outlay' },
      { field: 'cs', header: 'Central Share' },
      { field: 'fr', header: 'Funds Released' },
      { field: 'fu', header: 'Funds Utilised' },
    ];
  }

  getEventValue($event: any): string {
    return $event.target.value;
  }
  deleteRow(state: any) {
    for (let [i, user] of this.npddDetailInJson.entries()) {
      if (user.state === state) {
        this.npddDetailInJson.splice(i, 1); // Trim is now removed from "users"
      }
    }
    console.log(this.npddDetailInJson)
  }

  onUpload() {
  console.log("upload  data---")
console.log(this.npddDetailInJson)
    const data = this.npddDetailInJson;
    if (data.length > 0) {
      this.dataService.npddCreate(data).subscribe((result: any) => {
        //alert(result.message);
        alert("Added Successfully ");
        this.npddDetailInJson = [];
      });
    }

  }
  // -- Add by Abhishek for JSON To CSV
  allNPDDData:any
download(){
  this.getNPDDDashboardData();  
}
getNpddData() {
  return this.http.get('https://dahddashboard.ndlm.co.in/api/npdd');
//return this.http.get('/assets/didf.json');
 }

getNPDDDashboardData()
{
this.getNpddData().subscribe((res)=>{
  console.log("NPDD Data Result");
  console.log(res);
  this.allNPDDData=res;
  this.JsonToCSVService.downloadFile(this.allNPDDData, 'NPDD');
},
(err)=>{
console.log(err)
}
); 
}
// ---- End Abhi Code -----
}
