import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http' 
@Injectable({
  providedIn: 'root'
})
export class RgmschemeService {

  constructor(public http: HttpClient) { }
  getNaipData() {
    // return this.http.get('/assets/finalsud25012022.json');
   return this.http.get('https://dahddashboard.ndlm.co.in/api/rgm');
    //return this.http.get('/assets/rgm.json');
     }
	 
	getMaitriData()  {
      return this.http.get('https://dahddashboard.ndlm.co.in/api/maitri');
	    //return this.http.get('/assets/maitri.json');
    }
}
