import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';
import { NlmschemeService } from '../nlmscheme/nlmscheme.service';


@Component({
  selector: 'app-statewise-nlmscheme',
  templateUrl: './statewise-nlmscheme.component.html',
  styleUrls: ['./statewise-nlmscheme.component.scss']
})

export class StatewiseNlmschemeComponent implements OnInit {
  selectedCity1 :  any = new Array()
  states_data = [['State','Total AI Done']];
  stateWiseNlmAllData: any = new Array()
  totalNlmSateWiseTypeNlmDataCount: any = new Array()
  totalNlmStateWise: any = new Array()
  totalAllSchemeDoneArray: any = new Array()
  totalStateTypeAiDataCount: any = new Array()
  farmersBenifitedCountArray: any = new Array()
  totalAllAiSchemeDoneArray: any = new Array()
  topFiveNaipStateArray: any = new Array()
  totalNlmDistrictWiseTypeNlmDataCount: any = new Array()
  totalNaipDistrictWise: any = new Array()
  topFiveDistrictNaipArray: any = new Array()
  totalAllNaipIISchemeDoneArray: any = new Array()
  totalAllNaipISchemeDoneArray: any = new Array()
  distrricts: any = new Array()
  districtsName: any = new Array()
  totalAggregateStateAiData: any = new Array()
  totalDistrictTypeNlmDataCount: any = new Array()
  farmersDistrictBenifitedCountArray: any = new Array()
  totalDistrictWiseAiSchemeDoneArray: any = new Array()
  totalRgmDistrictWiseTypeNaipIIDataCount: any = new Array()
  totalRgmDistrictWiseTypeNaipIDataCount: any = new Array()
  nlmDashboardKpiData: any = new Array()
  nlmDashboardstatewisekpiData:  any = new Array()

 totalNaipIIDistrictTypeAiDataCount: any = new Array()
 totalNaipIDistrictTypeAiDataCount: any = new Array()
 totalDistrictWiseNaipIIAiSchemeDoneArray: any = new Array()
 totalDistrictWiseNaipIAiSchemeDoneArray: any = new Array()
 totalMatriStatewiseArray: any = new Array()
  currentDateData: any = new Array()
  SchemeCountArray: any = new Array()
  totalNlmSateWiseTypeDataCount: any = new Array()
  totalNlmSchemeStateWiseData: any = new Array()
  selectStateNlmData: any = new Array()
  totalAhidfSchemeDistrictWiseData: any = new Array()
  selectStateName: any;
  selectStateData: any;
  selectStateCode:any;
  mapReady=false;
  valueInFormate: any;
  totalNoOfAiDoneCount: any;
  totalNoOffarmersBenifited: any;
  totalNoOfAiAllSchemeDone: any;
  totalFarmersBenifitedAllSchemeDone: any;
  totalNoOfAiDoneInNaipI: any;
  totalNoOfAnimalCoverdDoneInNaipI: any;
  totalNoOfFarmersBenefittedInNaipI: any;
  totalNoOfAiDoneInNaipII: any;
  totalNoOfAnimalCoverdDoneInNaipII: any;
  totalNoOfFarmersBenefittedInNaipII: any;
  totalNoOfAiDoneInNaipIII: any;
  totalNoOfAnimalCoverdDoneInNaipIII: any;
  totalNoOfFarmersBenefittedInNaipIII: any;
  topFirstPerfomanceDistrict: any;
  topFirstPerformingDistrictAiDone: any;
  topSecondPerfomanceDistrict: any;
  topSecondPerformingDistrictAiDone: any;
  topThirdPerfomanceDistrict: any;
  topThirdPerformingDistrictAiDone: any;
  topFourthPerfomanceDistrict: any;
  topFourthPerformingDistrictAiDone: any;
  topFifthPerfomanceDistrict: any;
  topFifthPerformingDistrictAiDone: any;
  typeOfVacinationChart:any;
  selectState: any;
  selectDistrict: any;
  currentNaipIIData: any;
  currentNaipIData: any;
  selectNaipIIDistrict: any;
  selectNaipIDistrict: any;
  MAITRIsTrainedInducted: any;
  SexSortedSemenDosesProduced: any;
  IVF: any;
  SexSortedSemenDosesAIDone: any;
  SexSortedSemenDosesSold: any;
  projectSchemeCost: any;
  projectSchemeLoanAmount: any;
  piggeryEnterepreneurSchemeName: any;
  piggeryEnterepreneurloanAmount: any;
  piggeryEnterepreneurprojectCost: any;
  sheepAndGoatFarmingSchemeName: any;
  sheepAndGoatFarmingloanAmount: any;
  sheepAndGoatFarmingprojectCost: any;
  ruralPoultrySchemeName: any;
  ruralPoultryloanAmount: any;
  ruralPoultryprojectCost: any;
  feedAndFodderSchemeName: any;
  feedAndFodderloanAmount: any;
  feedAndFodderprojectCost: any;
  allNoOfEntrepreneursRegistered: any;
  allProjectCostEntrepreneursRegistered: any;
  allProjectsEligibleByDepartment: any;
  allProjectsCostOfEligible: any;
  allProjectsSanctionedByBank: any;
  allProjectCostOfSanctionedByBank: any;
  allApprovedForInterestSubvention: any;
  allInterestSubventionAmount: any;
  subsidyAmountReleasedCount: any;
  subsidyAmountReleasedd: any;
  constructor(public serv: NlmschemeService,private route: ActivatedRoute) { }
  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    dots: true,
    navSpeed: 600,
    
  }
  slides = [
    {id: '1'},
    {id: '2'}
  ];
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {  
      this.selectStateName=params['State'];
	   console.log("state name for state code")
      console.log(params['State'])
      const myArray = params['State'].split("(");
      this.selectStateName=myArray[0];
      const myArray1 = myArray[1].split(")");
      console.log(myArray1)
      this.selectStateCode=myArray1[0];

    });
  this.StateWiseNlmDashboardData();
  this.AhidfKpiDashboardData();
  this.nlmDashboardstatewiseKpi();
    //console.log(this.route.snapshot.queryParams.name); // book
  
  }
  StateWiseNlmDashboardData()
  {
    this.stateWiseNlmAllData=[];
    this.serv.getDashboardData().subscribe((res)=>{
      this.stateWiseNlmAllData=res;
      console.log("all  Nlm  data-----")
      console.log(this.stateWiseNlmAllData)
   //////////////////////////////////////Group By State Data/////////////////////////////////////////   
      this.totalNlmSateWiseTypeNlmDataCount=[];
      var groupByTypeState = function(xs:any, key:any) {
        return xs.reduce(function(rv:any, x:any) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  var totalLhdcStateData=groupByTypeState(this.stateWiseNlmAllData, 'stateName')
  this.totalNlmSateWiseTypeNlmDataCount = Object.entries(totalLhdcStateData)
  this.totalNlmStateWise=[];
  this.totalAllSchemeDoneArray=[];
  this.selectStateData=[];
  this.totalNlmSateWiseTypeNlmDataCount.forEach((y:any) =>
  {
  if(y[0]==this.selectStateName)
  {
   this.selectStateData=y[1]
  }
 
 
  var  NoOfProjects = y['1'].map((item:any) => Number(item['NoOfProjects'])).reduce((prev:any, curr:any) => prev + curr, 0);

var allSchemeData = Object.assign({"statName": y[0]}, {"NoOfProjects": NoOfProjects});
this.totalNlmStateWise.push(allSchemeData);
  });
  console.log("susydy ammount--------eeeeeeeeeeeee--------")
  console.log(this.selectStateData)
  // this.subsidyAmountReleasedCount=0;
  // this.subsidyAmountReleasedd=0;
  // this.subsidyAmountReleasedCount = this.selectStateData.map((item:any) => Number(item['subsidy_Amount_Released_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
  // const subsidy_Amount_Released = this.selectStateData.map((item:any) => Number(item['subsidy_Amount_Released'])).reduce((prev:any, curr:any) => prev + curr, 0);
  // if(subsidy_Amount_Released>0)
  // {
  //   this.subsidyAmountReleasedd=this.numDifferentiation(subsidy_Amount_Released);
  // }
  // else
  // {
  //   this.subsidyAmountReleasedd=0+" Cr"
  // }
 
// console.log("subsidy  ammount------------")
// console.log(this.subsidyAmountReleasedCount)
// console.log(this.subsidyAmountReleasedd)
  if(this.selectStateData.length!=0)
  {
 this.totalNlmSateWiseTypeDataCount=[];
 var groupBySchemes = function(xs:any, key:any) {
   return xs.reduce(function(rv:any, x:any) {
     (rv[x[key]] = rv[x[key]] || []).push(x);
     return rv;
   }, {});
 };
var totalSchemeWiseData=groupBySchemes(this.selectStateData, 'description')
this.totalNlmSateWiseTypeDataCount = Object.entries(totalSchemeWiseData)

console.log("select scheme wise data")
 console.log(this.totalNlmSateWiseTypeDataCount)

 this.totalNlmSchemeStateWiseData=[];
 this.totalNlmSateWiseTypeDataCount.forEach((y:any) =>
 {
 const noOfProjects = y[1].map((item:any) => Number(item['no_Of_Project'])).reduce((prev:any, curr:any) => prev + curr, 0);
 const projectCost = y[1].map((item:any) => Number(item['project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
 const loanAmount = y[1].map((item:any) => Number(item['loan_Amount'])).reduce((prev:any, curr:any) => prev + curr, 0);
 this.projectSchemeCost=this.numDifferentiation(projectCost);
 this.projectSchemeLoanAmount=this.numDifferentiation(loanAmount);
 
 var finalObj1 = Object.assign({"Scheme": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"loanAmount": this.projectSchemeLoanAmount});
 this.totalNlmSchemeStateWiseData.push(finalObj1)
 
});

console.log("alllllll Nlm  dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
console.log(this.totalNlmSchemeStateWiseData)
this.sheepAndGoatFarmingSchemeName=0
this.sheepAndGoatFarmingloanAmount=0
this.sheepAndGoatFarmingprojectCost=0
this.ruralPoultrySchemeName=0
this.ruralPoultryloanAmount=0
this.ruralPoultryprojectCost=0
this.feedAndFodderSchemeName=0
this.feedAndFodderloanAmount=0
this.feedAndFodderprojectCost=0
this.piggeryEnterepreneurSchemeName=0
this.piggeryEnterepreneurloanAmount=0
this.piggeryEnterepreneurprojectCost=0
this.totalNlmSchemeStateWiseData.forEach((y:any) =>
 {
  console.log("all scheme -----------------")
  console.log(y.Scheme)
  if(y.Scheme=='Establishment of Entrepreneur for breed Development in small ruminant sector (sheep & goat Farming)')
  {
 this.sheepAndGoatFarmingSchemeName=y.noOfProjects;
 this.sheepAndGoatFarmingloanAmount=y.loanAmount
 this.sheepAndGoatFarmingprojectCost=y.projectCost
  }
  if(y.Scheme=='Establishment of entrepreneurship for breed development of Rural Poultry')
  {
 this.ruralPoultrySchemeName=y.noOfProjects;
 this.ruralPoultryloanAmount=y.loanAmount
 this.ruralPoultryprojectCost=y.projectCost
  }
  if(y.Scheme=='Promotion of Piggery Entrepreneur')
  {
    this.piggeryEnterepreneurSchemeName=y.noOfProjects;
    this.piggeryEnterepreneurloanAmount=y.loanAmount
    this.piggeryEnterepreneurprojectCost=y.projectCost
  }
  if(y.Scheme=='Entrepreneurial activities in feed and fodder')
  {
    this.feedAndFodderSchemeName=y.noOfProjects;
 this.feedAndFodderloanAmount=y.loanAmount
 this.feedAndFodderprojectCost=y.projectCost
 
  }
 
});




this.totalNlmDistrictWiseTypeNlmDataCount=[];
var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalNlmcDistrictData=groupByTypeDistrict(this.selectStateData, 'districtName')
this.totalNlmDistrictWiseTypeNlmDataCount = Object.entries(totalNlmcDistrictData)
console.log("dididididiid")
console.log(this.totalNlmDistrictWiseTypeNlmDataCount)
 var keys = Object.keys(totalNlmcDistrictData);
 console.log("all keys")
 console.log(keys)
console.log(this.totalNlmDistrictWiseTypeNlmDataCount);
this.totalNaipDistrictWise=[];
this.topFiveDistrictNaipArray=[];
this.distrricts=[];
this.districtsName=[];
// Comment By Abhi
// this.totalNlmDistrictWiseTypeNlmDataCount.forEach((y:any) =>
// {
// const sumallDistrictAiDone = y[1].map((item:any) => Number(item['no_Of_Project'])).reduce((prev:any, curr:any) => prev + curr, 0);
// var finalObj1 = Object.assign({"District": y[0]}, {"NoOfProjects": sumallDistrictAiDone});
// this.totalNaipDistrictWise.push(finalObj1)
// var finalObj = Object.assign({"name": y[0]}, {"code":  y[0]});
// this.distrricts.push(finalObj)
// this.districtsName= this.distrricts.sort(function(a:any,b:any){ return a.name.localeCompare(b.name); });
// });

// End Comment by Abhi
//------- variable use for defaul select------------------------------------------------------
//this.selectedCity1 = {name: 'ANAND',code: 'ANAND'};

// Comment by Abhi again
// console.log("District_Name")
// console.log(this.districtsName)
// var topValues = this.totalNaipDistrictWise.sort((a:any,b:any) => b['NoOfProjects']-a['NoOfProjects']).slice(0,5);
// console.log(this.totalNaipDistrictWise)
// console.log(topValues);
// topValues.forEach((y:any) =>
// {
// var finalObj1 = Object.assign({"District": y['District']}, {"NoOfProjects": y['NoOfProjects']});
// this.topFiveDistrictNaipArray.push(finalObj1);
// });
// console.log("top five vacination District")
// console.log(this.topFiveDistrictNaipArray)
// this.topFirstPerfomanceDistrict=this.topFiveDistrictNaipArray[0]['District'];
// this.topFirstPerformingDistrictAiDone=this.topFiveDistrictNaipArray[0]['NoOfProjects'];

// this.topSecondPerfomanceDistrict=this.topFiveDistrictNaipArray[1]['District'];
// this.topSecondPerformingDistrictAiDone=this.topFiveDistrictNaipArray[1]['NoOfProjects'];

// this.topThirdPerfomanceDistrict=this.topFiveDistrictNaipArray[2]['District'];
// this.topThirdPerformingDistrictAiDone=this.topFiveDistrictNaipArray[2]['NoOfProjects'];

// this.topFourthPerfomanceDistrict=this.topFiveDistrictNaipArray[3]['District'];
// this.topFourthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[3]['NoOfProjects'];

// this.topFifthPerfomanceDistrict=this.topFiveDistrictNaipArray[4]['District'];
// this.topFifthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[4]['NoOfProjects'];

// End Comment by Abhi
for(let state of this.totalNlmStateWise){
  let temp = [state.statName,Number(state.NoOfProjects)];
  if( state.statName=="ANDAMAN & NICOBAR ISLANDS"){
    temp = ['IN-AN',Number(state.NoOfProjects)];
  }
  else if( state.statName=="LAKSHADWEEP"){
    temp = ['IN-LD',Number(state.NoOfProjects)];
  }
  else if( state.statName=="ODISHA"){
    temp = ['IN-OR',Number(state.NoOfProjects)];
  }
  else if( state.statName=="LADAKH"){
    temp = ['Ladakh',Number(state.NoOfProjects)];
  }
  else if( state.statName=="UTTARANCHAL"){
    temp = ['IN-UT',Number(state.NoOfProjects)];
  }
  else if( state.statName=="JAMMU & KASHMIR"){
    temp = ['Jammu and Kashmir',Number(state.NoOfProjects)];
  }
  this.states_data.push(temp);
}
console.log("sdsdsdsds")
console.log(this.states_data)
   this.mapReady=true
}
else
{
  console.log("not dataaa")
  this.sheepAndGoatFarmingSchemeName=0
  this.sheepAndGoatFarmingloanAmount=0
  this.sheepAndGoatFarmingprojectCost=0
  this.ruralPoultrySchemeName=0
  this.ruralPoultryloanAmount=0
  this.ruralPoultryprojectCost=0
  this.feedAndFodderSchemeName=0
  this.feedAndFodderloanAmount=0
  this.feedAndFodderprojectCost=0
  this.piggeryEnterepreneurSchemeName=0
  this.piggeryEnterepreneurloanAmount=0
  this.piggeryEnterepreneurprojectCost=0
}
  
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }
 
  AhidfKpiDashboardData()
  {
    this.nlmDashboardKpiData=[];
    this.allNoOfEntrepreneursRegistered=0
    this.allProjectCostEntrepreneursRegistered=0
    //this.allProjectsEligibleByDepartment=0
    //this.allProjectsCostOfEligible=0
    this.allProjectsSanctionedByBank=0
    this.allProjectCostOfSanctionedByBank=0
    this.allApprovedForInterestSubvention=0
    this.allInterestSubventionAmount=0
    this.totalNaipDistrictWise=[];  // Add by Abhi    
this.topFiveDistrictNaipArray=[];
this.distrricts=[];
this.districtsName=[];
this.totalNlmDistrictWiseTypeNlmDataCount=[];
    this.serv.getDashboardKpiData().subscribe((res)=>{
      this.nlmDashboardKpiData=res;
   
    this.totalMatriStatewiseArray=[];
    var groupByMvuData = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalMatristaewiseData=groupByMvuData( this.nlmDashboardKpiData, 'stateCodeId')
    console.log(this.nlmDashboardKpiData)
    this.totalMatriStatewiseArray = Object.entries(totalMatristaewiseData)
    this.selectStateNlmData=[];
    this.selectStateData=[];
    this.totalMatriStatewiseArray.forEach((y:any) =>
    {   
      if((y[0]==this.selectStateCode))
      {
        
      this.selectStateNlmData['0']=y[1];
      this.selectStateData=y[1]
      }    

  
 });
// Add by Abhi again
 var groupByTypeDistrict = function(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var totalNlmcDistrictData=groupByTypeDistrict(this.selectStateData, 'districtName')
this.totalNlmDistrictWiseTypeNlmDataCount = Object.entries(totalNlmcDistrictData)

console.log("District_Name")
console.log(this.districtsName)

 this.totalNlmDistrictWiseTypeNlmDataCount.forEach((y:any) =>
 {
 const sumallDistrictAiDone = y[1].map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);
 var finalObj1 = Object.assign({"District": y[0]}, {"NoOfProjects": sumallDistrictAiDone});
 this.totalNaipDistrictWise.push(finalObj1)
 var finalObj = Object.assign({"name": y[0].toUpperCase()}, {"code":  y[0].toUpperCase()});
 this.distrricts.push(finalObj)
 this.districtsName= this.distrricts.sort(function(a:any,b:any){ return a.name.localeCompare(b.name); });
 });


var topValues = this.totalNaipDistrictWise.sort((a:any,b:any) => b['NoOfProjects']-a['NoOfProjects']).slice(0,5);
console.log(this.totalNaipDistrictWise)
console.log(topValues);
topValues.forEach((y:any) =>
{
var finalObj1 = Object.assign({"District": y['District']}, {"NoOfProjects": y['NoOfProjects']});
this.topFiveDistrictNaipArray.push(finalObj1);
});
console.log("top five vacination District")
console.log(this.topFiveDistrictNaipArray)
this.topFirstPerfomanceDistrict=this.topFiveDistrictNaipArray[0]['District'];
if(this.topFiveDistrictNaipArray[0]['NoOfProjects']!= 0)
{
  this.topFirstPerformingDistrictAiDone=this.topFiveDistrictNaipArray[0]['NoOfProjects'];
}
else
{
  this.topFirstPerformingDistrictAiDone="-";
}
this.topSecondPerfomanceDistrict=this.topFiveDistrictNaipArray[1]['District'];
if(this.topFiveDistrictNaipArray[1]['NoOfProjects']!= 0)
{
this.topSecondPerformingDistrictAiDone=this.topFiveDistrictNaipArray[1]['NoOfProjects'];
}
else
{
  this.topSecondPerformingDistrictAiDone="-";
}
this.topThirdPerfomanceDistrict=this.topFiveDistrictNaipArray[2]['District'];
if(this.topFiveDistrictNaipArray[2]['NoOfProjects']!= 0)
{
this.topThirdPerformingDistrictAiDone=this.topFiveDistrictNaipArray[2]['NoOfProjects'];
}
else
{
  this.topThirdPerformingDistrictAiDone="-";
}
this.topFourthPerfomanceDistrict=this.topFiveDistrictNaipArray[3]['District'];
if(this.topFiveDistrictNaipArray[3]['NoOfProjects']!= 0)
{
this.topFourthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[3]['NoOfProjects'];
}
else
{
  this.topFourthPerformingDistrictAiDone="-";
}
this.topFifthPerfomanceDistrict=this.topFiveDistrictNaipArray[4]['District'];
if(this.topFiveDistrictNaipArray[4]['NoOfProjects']!= 0)
{
this.topFifthPerformingDistrictAiDone=this.topFiveDistrictNaipArray[4]['NoOfProjects'];
}
else
{
  this.topFifthPerformingDistrictAiDone="-";
}
// End by Abhi




   console.log("ahidf kpi data--------")
console.log( this.selectStateNlmData)
   this.selectStateNlmData.forEach((y:any) =>
{
const NoOfEntrepreneursRegistered = y.map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allNoOfEntrepreneursRegistered=NoOfEntrepreneursRegistered

const ProjectCostEntrepreneursRegistered = y.map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectCostEntrepreneursRegistered=this.numDifferentiation(ProjectCostEntrepreneursRegistered)

//const ProjectsEligibleByDepartment = y.map((item:any) => Number(item['projects_Eligible_By_State_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
//this.allProjectsEligibleByDepartment=ProjectsEligibleByDepartment


// const ProjectsCostOfEligible = y.map((item:any) => Number(item['projects_Eligible_By_State_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
// this.allProjectsCostOfEligible=this.numDifferentiation(ProjectsCostOfEligible)


const ProjectsSanctionedByBank = y.map((item:any) => Number(item['projetcs_Sanctioned_By_Bank_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allProjectsSanctionedByBank=ProjectsSanctionedByBank

const ProjectCostOfSanctionedByBank = y.map((item:any) => Number(item['projetcs_Sanctioned_By_Bank_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
if(ProjectCostOfSanctionedByBank !=0)
{
  this.allProjectCostOfSanctionedByBank=this.numDifferentiation(ProjectCostOfSanctionedByBank)
}

const ApprovedForInterestSubvention = y.map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.allApprovedForInterestSubvention=ApprovedForInterestSubvention

const InterestSubventionAmount = y.map((item:any) => Number(item['approved_projects_by_dahd_projectcost'])).reduce((prev:any, curr:any) => prev + curr, 0);
if(InterestSubventionAmount != 0)
 { 
this.allInterestSubventionAmount=this.numDifferentiation(InterestSubventionAmount)
}
});

    },
    (err)=>{
      console.log(err)
    }
  );
  }
  numDifferentiation(value:any) {

    var val = Math.abs(value)
    if (val >= 10000000) {
      this.valueInFormate = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      this.valueInFormate    = (val / 100000).toFixed(2) + ' L';
    } else if (val >= 1000) {
      this.valueInFormate    = (val / 1000).toFixed(2) + ' K';
    }
    else if (val >= 100) {
      this.valueInFormate    = val ;
    }
    return this.valueInFormate;
  }
  public select(event: ChartSelectEvent) {
    console.log(event.selectedRowValues[0])
    this.totalNlmSateWiseTypeNlmDataCount.forEach((y:any) =>
    {
   if(y[0]==event.selectedRowValues[0])
   {
     this.selectState=y[1]
   }
   
    });
    var groupByTypeVacination = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
var totalTypeVacinationStateData=groupByTypeVacination(this.selectState, 'Scheme')
var totalTypeVacinationStateDataCount = Object.entries(totalTypeVacinationStateData)
this.totalAggregateStateAiData=[];
  totalTypeVacinationStateDataCount.forEach((y:any) =>
  {
      var totalVaccination =  y[1].map((item:any) => Number(item['Total_no_of_AI_Done'])).reduce((prev:any, curr:any) => prev + curr, 0);

      var finalObj = Object.assign({"name": y[0]}, {"value": totalVaccination});
      this.totalAggregateStateAiData.push(finalObj)

  });
console.log(this.totalAggregateStateAiData)
this.typeOfVacinationChart = {
  title: {
    show:true,
    text: event.selectedRowValues[0],
    left: 'center'
   
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'right'
  },
  series: [
    {
     // name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '15',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: this.totalAggregateStateAiData
    }
  ]
};
}
public geoChart: GoogleChartInterface = {
  chartType: 'GeoChart',
  dataTable: this.states_data,
  options: {
    domain:'IN',
    region: 'IN',
    colorAxis: {colors: ['#40e0d0','#40e0d0','#40e0d0','40e0d0','40e0d0']},
    resolution: 'provinces',
    zoom: 6,
   // defaultColor:{colors: ['#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5','#A5ECF5']},
disableDefaultUI: true,
//displayMode: 'text',
defaultColor:'#40e0d0',
backgroundColor: 'transparent',
enableRegionInteractivity: true,
//tooltip: { trigger: 'none'},
legend: 'none',
datalessRegionColor: 'transparent',
    'height': 3,
    //'width': 850,
   

    
  }
  
};


  OnChangeDistrict(event:any)
  {
    console.log(event.value.name)
    console.log(this.totalNlmDistrictWiseTypeNlmDataCount)
    this.totalNlmDistrictWiseTypeNlmDataCount.forEach((y:any) =>
    {
   if(y[0].toUpperCase()==event.value.name)
   {
     this.selectDistrict=y[1]
   }   
   });

this.totalDistrictTypeNlmDataCount=[];
if(this.selectDistrict)
{
var groupByDistrictTotalVaccinatioTypeData = function(xs:any, key:any) {
return xs.reduce(function(rv:any, x:any) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});
};
var totalVaccinationTypeData=groupByDistrictTotalVaccinatioTypeData(this.selectDistrict, 'description')
this.totalDistrictTypeNlmDataCount = Object.entries(totalVaccinationTypeData)

this.totalAhidfSchemeDistrictWiseData=[];
this.totalDistrictTypeNlmDataCount.forEach((y:any) =>
{
const noOfProjects = y[1].map((item:any) => Number(item['no_Of_Project'])).reduce((prev:any, curr:any) => prev + curr, 0);
const projectCost = y[1].map((item:any) => Number(item['project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
const loanAmount = y[1].map((item:any) => Number(item['loan_Amount'])).reduce((prev:any, curr:any) => prev + curr, 0);
this.projectSchemeCost=this.numDifferentiation(projectCost);
this.projectSchemeLoanAmount=this.numDifferentiation(loanAmount);

var finalObj1 = Object.assign({"Scheme": y[0]}, {"noOfProjects": noOfProjects},{"projectCost": this.projectSchemeCost},{"loanAmount": this.projectSchemeLoanAmount});
this.totalAhidfSchemeDistrictWiseData.push(finalObj1)

});
console.log("district   wise data----------")
console.log(this.totalAhidfSchemeDistrictWiseData)


this.sheepAndGoatFarmingSchemeName=0
this.sheepAndGoatFarmingloanAmount=0
this.sheepAndGoatFarmingprojectCost=0
this.ruralPoultrySchemeName=0
this.ruralPoultryloanAmount=0
this.ruralPoultryprojectCost=0
this.feedAndFodderSchemeName=0
this.feedAndFodderloanAmount=0
this.feedAndFodderprojectCost=0

this.piggeryEnterepreneurSchemeName=0
this.piggeryEnterepreneurloanAmount=0
this.piggeryEnterepreneurprojectCost=0
this.totalAhidfSchemeDistrictWiseData.forEach((y:any) =>
 {
  console.log("all scheme -----------------")
  console.log(y.Scheme)
  if(y.Scheme=='Establishment of Entrepreneur for breed Development in small ruminant sector (sheep & goat Farming)')
  {
 this.sheepAndGoatFarmingSchemeName=y.noOfProjects;
 this.sheepAndGoatFarmingloanAmount=y.loanAmount
 this.sheepAndGoatFarmingprojectCost=y.projectCost
  }
  if(y.Scheme=='Establishment of entrepreneurship for breed development of Rural Poultry')
  {
 this.ruralPoultrySchemeName=y.noOfProjects;
 this.ruralPoultryloanAmount=y.loanAmount
 this.ruralPoultryprojectCost=y.projectCost
  }
  if(y.Scheme=='Promotion of Piggery Entrepreneur')
  {
    this.piggeryEnterepreneurSchemeName=y.noOfProjects;
    this.piggeryEnterepreneurloanAmount=y.loanAmount
    this.piggeryEnterepreneurprojectCost=y.projectCost
  }
  if(y.Scheme=='Entrepreneurial activities in feed and fodder')
  {
    this.feedAndFodderSchemeName=y.noOfProjects;
 this.feedAndFodderloanAmount=y.loanAmount
 this.feedAndFodderprojectCost=y.projectCost
 
  }
  
 
});

this.AhidfDistrictKpiDashboardData(event.value.name)

}
   
  }  
  
  

  AhidfDistrictKpiDashboardData(districtName:any)
  {
    this.nlmDashboardKpiData=[];
    this.allNoOfEntrepreneursRegistered=0
    this.allProjectCostEntrepreneursRegistered=0
   // this.allProjectsEligibleByDepartment=0
   // this.allProjectsCostOfEligible=0
    this.allProjectsSanctionedByBank=0
    this.allProjectCostOfSanctionedByBank=0
    this.allApprovedForInterestSubvention=0
    this.allInterestSubventionAmount=0
    this.serv.getDashboardKpiData().subscribe((res)=>{
      this.nlmDashboardKpiData=res;
   
    this.totalMatriStatewiseArray=[];
    var groupByMvuData = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalMatristaewiseData=groupByMvuData( this.nlmDashboardKpiData, 'districtName')
    console.log(this.nlmDashboardKpiData)
    this.totalMatriStatewiseArray = Object.entries(totalMatristaewiseData)
    this.selectStateNlmData=[];
    

    this.totalMatriStatewiseArray.forEach((y:any) =>
    {
     
    if((y[0]==districtName))
    {
      
  this.selectStateNlmData['0']=y[1];
  
   } 

   });


   this.selectStateNlmData.forEach((y:any) =>
{
  const NoOfEntrepreneursRegistered = y.map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.allNoOfEntrepreneursRegistered=NoOfEntrepreneursRegistered
  
  const ProjectCostEntrepreneursRegistered = y.map((item:any) => Number(item['no_Of_Entrepreneurs_Registered_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.allProjectCostEntrepreneursRegistered=this.numDifferentiation(ProjectCostEntrepreneursRegistered)
  
  //const ProjectsEligibleByDepartment = y.map((item:any) => Number(item['projects_Eligible_By_State_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
 // this.allProjectsEligibleByDepartment=ProjectsEligibleByDepartment
  
  
  // const ProjectsCostOfEligible = y.map((item:any) => Number(item['projects_Eligible_By_State_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
  // this.allProjectsCostOfEligible=this.numDifferentiation(ProjectsCostOfEligible)
  
  
  const ProjectsSanctionedByBank = y.map((item:any) => Number(item['projetcs_Sanctioned_By_Bank_Count'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.allProjectsSanctionedByBank=ProjectsSanctionedByBank
  
  const ProjectCostOfSanctionedByBank = y.map((item:any) => Number(item['projetcs_Sanctioned_By_Bank_ProjectCost'])).reduce((prev:any, curr:any) => prev + curr, 0);
  if(ProjectCostOfSanctionedByBank !=0)
{
  this.allProjectCostOfSanctionedByBank=this.numDifferentiation(ProjectCostOfSanctionedByBank)
}
 
  const ApprovedForInterestSubvention = y.map((item:any) => Number(item['approved_projects_by_dahd_count'])).reduce((prev:any, curr:any) => prev + curr, 0);
  this.allApprovedForInterestSubvention=ApprovedForInterestSubvention
  
  const InterestSubventionAmount = y.map((item:any) => Number(item['approved_projects_by_dahd_projectcost'])).reduce((prev:any, curr:any) => prev + curr, 0);
 // this.allInterestSubventionAmount=InterestSubventionAmount
 if(InterestSubventionAmount != 0)
 { 
  this.allInterestSubventionAmount=this.numDifferentiation(InterestSubventionAmount)
 }

});
console.log("kpi daat--------")
console.log(this.allNoOfEntrepreneursRegistered)
console.log(this.allProjectCostEntrepreneursRegistered)
//console.log(this.allProjectsEligibleByDepartment)
//console.log(this.allProjectsCostOfEligible)
    },
    (err)=>{
      console.log(err)
    }
  );
  }

  nlmDashboardstatewiseKpi()
  {
     this.allProjectsEligibleByDepartment=0
     this.allProjectsCostOfEligible=0
     this.subsidyAmountReleasedCount=0;
     this.subsidyAmountReleasedd=0;
    this.nlmDashboardstatewisekpiData=[];
    this.serv.getDashboardstatewisekpiData().subscribe((res)=>{
      console.log("nlmstatewisekpiData by ab------------")
      console.log(res)
      this.nlmDashboardstatewisekpiData=res

      this.totalNlmSateWiseTypeNlmDataCount=[];
      
   
    this.totalMatriStatewiseArray=[];
    var groupByMvuData = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var totalMatristaewiseData=groupByMvuData( this.nlmDashboardstatewisekpiData, 'State_Code')
    console.log(this.nlmDashboardstatewisekpiData)
    this.totalMatriStatewiseArray = Object.entries(totalMatristaewiseData)
    this.selectStateNlmData=[];
    console.log("this.nlmDashboardstatewisekpiData");
    console.log(this.totalMatriStatewiseArray)
    this.totalMatriStatewiseArray.forEach((y:any) =>
    {
     
    if((y[0]==this.selectStateCode))
    {
      
  this.selectStateNlmData['0']=y[1];
  
   } 
   });
   console.log("state wise kpi data by ab--------")
   console.log(this.selectStateNlmData);
   this.selectStateNlmData.forEach((y:any) =>
   {     
     const ProjectsEligibleByDepartment = y.map((item:any) => Number(item['Applications_Eligible'])).reduce((prev:any, curr:any) => prev + curr, 0);
     this.allProjectsEligibleByDepartment=ProjectsEligibleByDepartment
     const ProjectsCostOfEligible = y.map((item:any) => Number(item['Project_Cost'])).reduce((prev:any, curr:any) => prev + curr, 0);
    if(ProjectsCostOfEligible!=0)
    { 
      this.allProjectsCostOfEligible=this.numDifferentiation(ProjectsCostOfEligible)
    }    
    const subsidyAmountReleasedCount = y.map((item:any) => Number(item['No_of_projects_received_inst'])).reduce((prev:any, curr:any) => prev + curr, 0);
    this.subsidyAmountReleasedCount=subsidyAmountReleasedCount
    const subsidyAmountReleasedd = y.map((item:any) => Number(item['Cumulative_amt_of_subsidy_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
   if(subsidyAmountReleasedd!=0)
   { 
     this.subsidyAmountReleasedd=this.numDifferentiation(subsidyAmountReleasedd)
   }    
   });
      
      // const subsidy_Amount_Released = this.nlmDashboardstatewisekpiData.map((item:any) => Number(item['Cumulative_amt_of_subsidy_released'])).reduce((prev:any, curr:any) => prev + curr, 0);
      //  this.subsidyAmountReleasedd=this.numDifferentiation(subsidy_Amount_Released);
    //   //console.log(this.subsidyAmountReleasedCount)
    },
    (err)=>{
      console.log(err)
    }
  ); 
  }

  
}
