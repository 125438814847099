import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../core/data.service';
import { Globals } from '../globals';
import { JsonToCSVService } from '../json-to-csv.service';
import {HttpClient} from '@angular/common/http';
@Component({
  selector: 'app-rgmentryform',
  templateUrl: './rgmentryform.component.html',
  styleUrls: ['./rgmentryform.component.scss']
})
export class RgmentryformComponent implements OnInit {
  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  registerForm: any = FormGroup;
  rgmDetailInJson: any = new Array()
  submitted = false;
  colsUsers: any;
  globals!: Globals;
  csvData: any;
  constructor(private formBuilder: FormBuilder, private router: Router, globals: Globals, private dataService: DataService,private JsonToCSVService:JsonToCSVService,public http: HttpClient) {
    this.globals = globals;
  }
  get f() { return this.registerForm.controls; }
  public error = (controlName: string, errorName: string) => {
    return this.registerForm.get(controlName)!.hasError(errorName);
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if (this.submitted) {
		var state = this.globals.indianState.find(c => c.code ===this.registerForm.value.state );

	if(state === undefined || state === null){
	  
        console.log("your key or value exists!");
      }
      else {
        var finalObj = Object.assign({ "state": state.name }, { "stateCode": state.code }, { "ivfp": this.registerForm['value']['ivfp'] }, { "mt": this.registerForm['value']['mt'] }, { "sssp": this.registerForm['value']['sssp'] },
         { "ssss": this.registerForm['value']['ssss'] }, { "sssad": this.registerForm['value']['sssad'] }, { "bmfs": this.registerForm['value']['bmfs'] });
        this.rgmDetailInJson.push(finalObj);
      }
    }
    console.log("form submmit value")
    console.log(this.rgmDetailInJson)
  }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      state: new FormControl('', [Validators.required]),
      ivfp: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      mt: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      sssp: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      ssss: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      sssad: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
      bmfs: new FormControl('', [Validators.pattern(this.globals.intRegex)]),
    });
    this.initializeColumns();
  }
  initializeColumns() {
    this.colsUsers = [
      { field: 'state', header: 'State' },
      { field: 'ivfp', header: 'In Vitro Fertilization' },
      { field: 'mt', header: 'MAITRI Trained' },
      { field: 'sssp', header: 'Sex Sorted Semen Produced' },
      { field: 'ssss', header: 'Sex Sorted Semen Sold' },
      { field: 'sssad', header: 'Sex Sorted Semen AI Done' },
      { field: 'bmfs', header: 'Breed Multiplication Farms Sanctioned' },

    ];
  }
  getEventValue($event: any): string {
    return $event.target.value;
  }

  uploadListener($event: any): void {
    let files = $event.srcElement.files;
    if (this.globals.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        this.csvData = reader.result;
        let csvRecordsArray = (this.csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };
      reader.onerror = function () {
        console.log('error is occurred while reading file!');
      };
    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord = new RgmCsvData();
        csvRecord.stateCode = curruntRecord[1].trim();
        csvRecord.state = curruntRecord[2].trim();

        csvRecord.ivfp = this.globals.setDefault(curruntRecord[3]).trim();
        csvRecord.mt = this.globals.setDefault(curruntRecord[4]).trim();
        csvRecord.sssp = this.globals.setDefault(curruntRecord[5]).trim();
        csvRecord.ssss = this.globals.setDefault(curruntRecord[6]).trim();
        csvRecord.sssad = this.globals.setDefault(curruntRecord[7]).trim();
        csvRecord.bmfs = this.globals.setDefault(curruntRecord[8]).trim();

        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  getJsonData() {
    this.rgmDetailInJson = [];
    this.rgmDetailInJson = this.records;

  }


  deleteRow(state: any) {
    for (let [i, user] of this.rgmDetailInJson.entries()) {
      if (user.state === state) {
        this.rgmDetailInJson.splice(i, 1); // Tim is now removed from "users"
      }
    }
    console.log(this.rgmDetailInJson)
  }

  onUpload() {

    const data = this.rgmDetailInJson;
    if (data.length > 0) {
      this.dataService.rgmCreate(data).subscribe((result: any) => {
        //alert(result.message);
        alert("added sucessfully");
        this.rgmDetailInJson = [];
      });
    }

  }
// -- Add by Abhishek for JSON To CSV
allRGMData:any
download(){
  this.getRGMDashboardData();  
}
getRgmData() {
  return this.http.get('https://dahddashboard.ndlm.co.in/api/maitri');
//return this.http.get('/assets/didf.json');
 }

getRGMDashboardData()
{
this.getRgmData().subscribe((res)=>{
  console.log("RGM Data Result");
  console.log(res);
  this.allRGMData=res;
  this.JsonToCSVService.downloadFile(this.allRGMData, 'RGM');
},
(err)=>{
console.log(err)
}
); 
}
// ---- End Abhi Code -----
}

export class RgmCsvData {
  public stateCode: any;
  public state: any;
  public ivfp: any;
  public mt: any;
  public sssp: any;
  public ssss: any;
  public sssad: any;
  public bmfs: any;
}