<app-header></app-header>


<div class="container">
  <div class="bg-light mb-5 mt-4 p-4">
  <div class="row box justify-content-center">
    <div class="col-sm-4 pading"  style="cursor:pointer" (click)="clickLhdcDiv()">
      <div class="card" style="border-radius: 10px;" >
        <div class="card-body"><div class="w-100 bg-white p-3 heading-one"><p>Livestock Health<br>and Disease Control Programme <br> (LHDCP)</p></div></div></div></div>
    <div class="col-sm-4 pading" style="cursor:pointer" (click)="clickNlmDiv()">
      <div class="card" style="border-radius: 10px;" >
        <div class="card-body"><div class="w-100 bg-white p-3 heading-one"><p>National Livestock Mission <br> (NLM)</p></div></div></div></div>
    <div class="col-sm-4 pading" style="cursor:pointer" (click)="clickAhidfDiv()">
      <div class="card" style="border-radius: 10px;" >
        <div class="card-body"><div class="w-100 bg-white p-3 heading-one"><p>Animal Husbandry<br>Infrastructure Development <br>Fund(AHIDF)</p></div></div></div></div>    
    <div class="col-sm-4 pading" style="cursor:pointer" (click)="clicknpddDiv()"> <div class="card" style="border-radius: 10px;" >
      <div class="card-body"><div class="w-100 bg-white p-3 heading-one"> <p>National Programme for<br> Dairy Development<br>(NPDD)</p></div></div></div></div>
    <div class="col-sm-4 pading" style="cursor:pointer" (click)="clickrgmDiv()"><div class="card" style="border-radius: 10px;" >
      <div class="card-body"><div class="w-100 bg-white p-3 heading-one"> <p>Rashtriya Gokul Mission<br>(RGM)</p></div></div></div></div>

    <div class="col-sm-4 pading" style="cursor:pointer" (click)="clicksdcfpoDiv()"> <div class="card" style="border-radius: 10px;" >
      <div class="card-body"><div class="w-100 bg-white p-3 heading-one"> <p>Supporting Dairy Cooperatives and <br>Farmer Producer Organizations<br>(SDCFPO)</p></div></div></div></div>
    <div class="col-sm-4 pading" style="cursor:pointer" (click)="clickdidfDiv()"> <div class="card" style="border-radius: 10px;" >
      <div class="card-body"><div class="w-100 bg-white p-3 heading-one"> <p>Dairy Processing and <br> Infrastructure Development Fund<br>(DIDF)</p></div></div></div></div>
  </div>
  </div>
</div>