import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatewiseLhdcschemeService {

  constructor(public http: HttpClient) { }
getLhdcData() 
{
    // return this.http.get('/assets/finalsud25012022.json');
		return this.http.get('https://dahddashboard.ndlm.co.in/api/nadcp');
    //return this.http.get('/assets/nadcp.json');
}
getMvuData()
{
		return this.http.get('https://dahddashboard.ndlm.co.in/api/mvu');
     //return this.http.get('/assets/mvu.json');
}
}
